import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SITE_NAME,
  LOGIN_DEAFULT_STATE,
  ERROR,
  PAGE_NOT_FOUND,
  NO_DEFAULT_PAGE_LOAD_ANALYTICS_PAGES,
} from '../../constants';
import {
  setUtagForViews,
  setAnalyticsListenerForInteractions,
} from '../../lib/analytics';

import { utagDataModel } from './utagModel';

export const utag404Data = {
  page_name: PAGE_NOT_FOUND,
  page_section: PAGE_NOT_FOUND,
  page_error: PAGE_NOT_FOUND,
  page_purpose: PAGE_NOT_FOUND,
  error_state: PAGE_NOT_FOUND,
};

const evarPath = ({ location, root, locale }) => {
  const separator = '|';
  let breadLoaf = root + separator;
  let { pathname } = location;
  if (pathname === `/${locale}`) return `${breadLoaf}homepage`;
  pathname = decodeURI(pathname);
  pathname = pathname
    // remove '/en-CA/' from pathname
    .replace(`/${locale}/`, '')
    // remove / from the end of the string if present
    .replace(/\/$/, '')
    // replace all '/' with $separator
    .replace(/\//g, separator)
    // replace all spaces with '-'
    .replace(/ /g, '-')
    // transform all to lowercase
    .toLowerCase();
  // and append the transformed pathname to evar1
  breadLoaf += `${pathname || 'browse'}`;
  // Set finalPageName to a valid evar1
  return breadLoaf;
};

const getEvar2Data = () => {
  const param = window.location.pathname.split('/')[2];
  return !param ? 'browse|homepage' : `browse|${param}`;
};

const canonicalUrl = url => {
  let finalUrl = url.replace(/\?.*|#.*/, '');
  if (finalUrl.slice(-1) !== '/') {
    finalUrl += '/';
  }
  return finalUrl;
};

export const generatePageViewAnalyticsData = props => {
  if (typeof window === 'undefined') {
    return {};
  }
  const { page, locale, isFourOhFour, isLoggedIn } = props;

  let utagData = { ...utagDataModel };
  utagData.site_name = SITE_NAME;
  utagData.page_canonical_url = canonicalUrl(window.location.href);
  utagData.site_version = window.process && window.process.env.version;
  utagData.page_language = locale;
  utagData.user_login_state = isLoggedIn ? 'logged-in' : LOGIN_DEAFULT_STATE;
  // for some reason if the page_url is moved to the first position it grabs the incorrect url (previous page url), please dont move it from here
  utagData.page_url = window.location.href;
  utagData.page_name =
    page && Object.keys(page).length !== 0
      ? evarPath({
          location: window.location,
          root: 'browse',
          locale,
        })
      : PAGE_NOT_FOUND;

  // CASE 1: 404 page
  if (isFourOhFour) {
    utagData = { ...utagData, ...utag404Data };
    return utagData;
  }

  const { pageName, pagePurpose } = page;

  if (pageName && pageName === ERROR) {
    utagData.page_error = PAGE_NOT_FOUND;
  }

  // CASE 2: Regular page

  let breadCrumb = window.location.href.split(`/${locale}/`)[1];
  if (breadCrumb && breadCrumb.indexOf('?')) {
    const query = breadCrumb.indexOf('?');
    if (query !== -1) {
      breadCrumb = breadCrumb.substring(0, query);
    }
  }

  breadCrumb =
    breadCrumb &&
    breadCrumb
      .replace(/\/$/, '')
      .replace(/\//g, ' > ')
      .replace(/-/g, ' ');

  breadCrumb =
    breadCrumb && breadCrumb.indexOf('?') === -1
      ? `home > ${breadCrumb}`
      : pageName;

  utagData.page_section = getEvar2Data() || ERROR;
  utagData.page_breadcrumb = breadCrumb || ERROR;
  utagData.page_purpose = pagePurpose || ERROR;

  return utagData;
};

const withAnalytics = WrappedComponent =>
  class AnalyticsHoc extends Component {
    static propTypes = {
      page: PropTypes.object,
      match: PropTypes.object,
      locale: PropTypes.string,
      isFourOhFour: PropTypes.bool,
      isAuthLoading: PropTypes.bool,
    };

    static defaultProps = {
      page: null,
      match: null,
      locale: null,
      isFourOhFour: false,
      isAuthLoading: null,
    };

    state = {
      analyticsTriggered: false,
    };

    cleanup = () => {};

    componentWillUnmount() {
      this.cleanup();
    }

    componentDidUpdate(prevProps) {
      if (!this.isValidPage()) return;

      const { analyticsTriggered } = this.state;

      // The lifecycle methods ensure that page view tracking occurs for:
      // 1. Pages with the same ContentPage but still need to be treated as unique
      // (e.g. support categories page is re-used for different topics)
      // 2. Pages that are visited for the first time, pages visited through relative path and so content is lazy loaded
      if (prevProps.match?.url !== this.props.match?.url) {
        this.makeAnalyticsCall();
      } else if (
        !analyticsTriggered &&
        !this.props.isAuthLoading &&
        this.isValidPage()
      ) {
        this.makeAnalyticsCall();
      }
    }

    isValidPage = () => {
      const { isFourOhFour, page } = this.props;
      return isFourOhFour || (page && !page.isPlaceHolder);
    };

    makeAnalyticsCall = () => {
      const { isFourOhFour } = this.props;
      const analyticsData = generatePageViewAnalyticsData(this.props);

      // CASE 1: 404 page
      if (isFourOhFour) {
        setUtagForViews(analyticsData);
        this.setState({ analyticsTriggered: true });
        return;
      }

      // custom Page view analytics are ebing triggered on the search results page
      if (
        !NO_DEFAULT_PAGE_LOAD_ANALYTICS_PAGES.includes(this.props.page.pageName)
      ) {
        setUtagForViews(analyticsData);
      }

      // For case (2)--different pages sharing the same ContentPage--we need to
      // clean up old event listeners and set new ones since no unmount happens
      this.cleanup();
      this.cleanup = setAnalyticsListenerForInteractions();

      this.setState({ analyticsTriggered: true });
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withAnalytics;
