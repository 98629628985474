import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Divider from 'web_component_library/divider';
import Input from 'web_component_library/input';
import TextBlock from 'web_component_library/textBlock';
import Text from 'web_component_library/text';
import { Flex, Box, Heading } from 'rebass/styled-components';
import { Button } from './css';
import RateBlock from '../ratesBlock';

const defaultVariant = 'light';
const defaultLinkText = '';
const defaultSelectedCountry = {};

const RoamingRates = ({
  country = null,
  title,
  leftBlock,
  variant = defaultVariant,
  validateField,
  clearForm,
  label,
  list,
  errorMessage = null,
  search,
  rightBlock,
  openPopup,
  analyticsSeeAllCountries,
  linkText = defaultLinkText,
  roamingRatesText,
  standardRatesText,
  worldTravellerRatesText,
  dataText,
  talkText,
  textText,
  ratesPlanDisclaimer = null,
  selectedCountry = defaultSelectedCountry,
}) => {
  const {
    fields: {
      name,
      roamingRatesDescription,
      standardDataRate,
      standardTalkRate,
      standardTextRate,
      worldSaverDataRate,
      worldSaverTalkRate,
      worldSaverTextRate,
    } = {},
  } = selectedCountry;

  return (
    <>
      <Flex flexWrap="wrap" pt={[21, 26]} pb={[15, 21]}>
        <Box width={[1, 1 / 3]} pb={7}>
          <Heading fontSize={[7, 9]} lineHeight={[5, 7]}>
            {title}
          </Heading>
        </Box>

        <Flex flexWrap="wrap" width={1}>
          <Box width={[1, 1 / 2]}>
            <Box width={1} pr={[0, 64]}>
              <TextBlock data={leftBlock} blockVariant={variant} />
            </Box>
          </Box>
          <Box width={[1, 1 / 2]} pt={[17, 0]} alignSelf="center">
            <Box width={[1, 11 / 12]}>
              <Formik
                initialValues={{
                  country: '',
                }}
                novalidate
                validate={values => validateField(values.country)}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  search(values.country);
                }}
                render={({ values, handleChange, setFieldValue }) => (
                  <Form>
                    <Box>
                      <Input
                        id="country"
                        name="country"
                        type="text"
                        label={label}
                        list={list}
                        value={values.country}
                        onChange={handleChange}
                        clearForm={clearForm}
                        errorMessage={errorMessage}
                        showInline
                        setFieldValue={setFieldValue}
                        search={search}
                        isSearchButtonVisible
                      />
                    </Box>
                  </Form>
                )}
              />
              <TextBlock data={rightBlock} blockVariant={variant} />
            </Box>
          </Box>
        </Flex>
        {linkText && (
          <Flex width={1}>
            <Box width={1} pt={[13, 9]}>
              <Button
                testId="open-popup-button"
                onClick={openPopup}
                {...analyticsSeeAllCountries}
              >
                <Text withAnimation fontWeight={2}>
                  {linkText}
                </Text>
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>
      {country && (
        <>
          <Box>
            <Divider thickness={1} color="freedomCharcoal" />
          </Box>
          <Flex flexWrap="wrap" pt={21} pb={29}>
            <Flex flexWrap="wrap" width={1}>
              <Box width={1}>
                <Heading fontSize={[7, 9]} lineHeight={[5, 7]}>
                  {`${name} ${roamingRatesText}`}
                </Heading>
              </Box>
              <Box width={[1, 1 / 2]} pr={[0, 64]} pt={[7, 13]}>
                {roamingRatesDescription && (
                  <Box>
                    <TextBlock data={roamingRatesDescription} />
                  </Box>
                )}
                {ratesPlanDisclaimer && (
                  <Box pt={11}>
                    <TextBlock data={ratesPlanDisclaimer} />
                  </Box>
                )}
              </Box>
              <Box width={[1 / 2, 1 / 4]} pt={[7, 13]} pr={21}>
                <Box pb={7}>
                  <Text
                    lineHeight={[1, 7]}
                    fontWeight={1}
                    color="freedomCharcoal"
                  >
                    {standardRatesText}
                  </Text>
                </Box>
                <Divider thickness={1} color="freedomCharcoal" />
                <RateBlock
                  rateType={dataText}
                  rate={standardDataRate}
                  pt={[7, 11]}
                  pb={[7, 11]}
                  renderAsRow={!worldTravellerRatesText}
                />
                <RateBlock
                  rateType={talkText}
                  rate={standardTalkRate}
                  pt={0}
                  pb={[7, 11]}
                  renderAsRow={!worldTravellerRatesText}
                />
                <RateBlock
                  rateType={textText}
                  rate={standardTextRate}
                  pt={0}
                  pb={0}
                  renderAsRow={!worldTravellerRatesText}
                />
              </Box>
              {worldTravellerRatesText ? (
                <Box width={[1 / 2, 1 / 4]} pt={[7, 13]}>
                  <Box pb={7}>
                    <Text
                      lineHeight={[1, 7]}
                      fontWeight={1}
                      color="freedomCharcoal"
                    >
                      {worldTravellerRatesText}
                    </Text>
                  </Box>
                  <Divider thickness={1} color="freedomCharcoal" />
                  <RateBlock
                    rateType={dataText}
                    rate={worldSaverDataRate}
                    pt={[7, 11]}
                    pb={[7, 11]}
                  />
                  <RateBlock
                    rateType={talkText}
                    rate={worldSaverTalkRate}
                    pt={0}
                    pb={[7, 11]}
                  />
                  <RateBlock
                    rateType={textText}
                    rate={worldSaverTextRate}
                    pt={0}
                    pb={0}
                  />
                </Box>
              ) : null}
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

RoamingRates.propTypes = {
  country: PropTypes.string,
  title: PropTypes.string.isRequired,
  leftBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  variant: PropTypes.string,
  validateField: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  entryTitle: PropTypes.string.isRequired,
  entryKey: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  search: PropTypes.func.isRequired,
  rightBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  openPopup: PropTypes.func.isRequired,
  analyticsSeeAllCountries: PropTypes.object.isRequired,
  linkText: PropTypes.string,
  roamingRatesText: PropTypes.string.isRequired,
  standardRatesText: PropTypes.string.isRequired,
  worldTravellerRatesText: PropTypes.string.isRequired,
  dataText: PropTypes.string.isRequired,
  talkText: PropTypes.string.isRequired,
  textText: PropTypes.string.isRequired,
  ratesPlanDisclaimer: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  selectedCountry: PropTypes.object,
};

export default RoamingRates;
