import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadFeatures } from './actions';
import { selectHasLoadedFeatures, makeSelectFeature } from './selectors';
import { FEATURE_FLAGS } from './flags';

export function FeatureFlagsWrapper({ children }) {
  const dispatch = useDispatch();
  const hasLoadedFeatures = useSelector(selectHasLoadedFeatures);

  useEffect(() => {
    dispatch(loadFeatures());
  }, [dispatch]);

  return hasLoadedFeatures ? children : null;
}

FeatureFlagsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFeature = ticketNum => {
  const featureStatus = useSelector(makeSelectFeature(ticketNum));
  return featureStatus;
};

export { FEATURE_FLAGS };
