import Faqs from './FaqsModel';
import { MAIN_SITE_URL } from '../../constants';

const CONTENTFUL_MODELS_TYPES = {
  termsAndConditions: 'termsAndConditions',
  header: 'HeaderV2',
  footer: 'footer',
  questionsAndAnswers: 'questionsAndAnswers',
};

const getRelativePaths = options => {
  const paths = [];
  if (options && Array.isArray(options)) {
    options.forEach(option => {
      const {
        fields: { url, ctaUrl, optionsList, menuItems, navPromoCards },
      } = option;
      if (url && url?.startsWith(MAIN_SITE_URL)) {
        const relativePath = url.replace(`${MAIN_SITE_URL}/`, '');
        paths.push(relativePath);
      }
      if (ctaUrl && ctaUrl?.startsWith(MAIN_SITE_URL)) {
        const relativePath = ctaUrl.replace(`${MAIN_SITE_URL}/`, '');
        paths.push(relativePath);
      }
      paths.push(...getRelativePaths(optionsList));
      paths.push(...getRelativePaths(menuItems));
      paths.push(...getRelativePaths(navPromoCards));
    });
  }
  return paths;
};

const getRelativePathsFromHeader = headerData => {
  const paths = [];
  if (headerData && headerData.fields) {
    const {
      fields: { headerOptions, rightNavigation, superiorNavigation },
    } = headerData;
    paths.push(...getRelativePaths(headerOptions));
    paths.push(...getRelativePaths(rightNavigation));
    paths.push(...getRelativePaths(superiorNavigation?.fields?.rightMenuItems));
    paths.push(
      ...getRelativePaths(superiorNavigation?.fields?.siteTypeOptions),
    );
  }
  return paths;
};

const getRelativePathsFromFooter = footerData => {
  if (!footerData) {
    return [];
  }
  return getRelativePaths(footerData.fields?.menuItems);
};

export default class CommonCatalog {
  constructor(payload) {
    this.termsAndConditions = {};
    this.header = {};
    this.footer = {};
    this.questionsAndAnswers = {};
    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }
    for (let count = 0; count < payload.length; count += 1) {
      if (payload[count] && payload[count].sys) {
        const { id } = payload[count].sys.contentType.sys;
        if (id === CONTENTFUL_MODELS_TYPES.termsAndConditions) {
          this.termsAndConditions = payload[count].fields;
        } else if (id === CONTENTFUL_MODELS_TYPES.header) {
          this.header = payload[count];
        } else if (id === CONTENTFUL_MODELS_TYPES.footer) {
          this.footer = payload[count];
        } else if (id === CONTENTFUL_MODELS_TYPES.questionsAndAnswers) {
          this.questionsAndAnswers = new Faqs(payload[count]);
        }
      }
    }

    this.relativePaths = [
      ...getRelativePathsFromHeader(this.header),
      ...getRelativePathsFromFooter(this.footer),
    ];
  }
}
