/* eslint-disable no-underscore-dangle, indent, prettier/prettier */

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const configureStore = (url = '/') => {
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url],
      })
    : createBrowserHistory();

  const middlewares = [routerMiddleware(history), sagaMiddleware];
  const enhancers = [];

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    ...enhancers,
  );

  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  const store = createStore(rootReducer(history), initialState, enhancer);

  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      /* eslint-disable global-require */
      const nextRootReducer = require('../reducers').default;
      /* eslint-enable global-require */
      store.replaceReducer(nextRootReducer(history));
    });
  }

  return {
    store: {
      ...store,
      runSaga: sagaMiddleware.run,
      close: () => store.dispatch(END),
    },
    history,
  };
};

export default configureStore;
