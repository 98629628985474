import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import AutoHeight from 'embla-carousel-auto-height';
import { Box } from 'rebass/styled-components';
import { NavBar } from './navBar';

import { NavArrow as BaseNavArrow } from './navArrow';
import withCtaAnalytics from '../ctaAnalyticsHoc';

const NavArrow = withCtaAnalytics(BaseNavArrow, {
  staticModuleType: 'carousel',
  useFlexContainer: false,
});

const BoxWithCtaAnalytics = withCtaAnalytics(Box, {
  staticModuleType: 'carousel',
});

const defaultPreviousSlideText = 'Previous slide';
const defaultNextSlideText = 'Next slide';
const defaultSelectSlideText = 'View slide %s';
const defaultPlayText = 'Start autoplay for slides';
const defaultPauseText = 'Stop autoplay for slides';

const Carousel = ({
  children,
  entryTitle,
  viewDuration, // in seconds
  areAllSlidesSameHeight = false,
  previousSlideText = defaultPreviousSlideText,
  nextSlideText = defaultNextSlideText,
  selectSlideText = defaultSelectSlideText,
  playText = defaultPlayText,
  pauseText = defaultPauseText,
  showProgressBar,
  slideFixedHeight = 350,
  slideMobileFixedHeight = null,
}) => {
  const getEmblaDependencies = () =>
    areAllSlidesSameHeight ? [] : [AutoHeight()];

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true },
    getEmblaDependencies(),
  );
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const intervalRef = useRef(null);

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleReset = () => {
    setIsRunning(false);
    setTime(0);
    // Due to delay in update the states we have to add some time before call the handleStart
    setTimeout(() => {
      handleStart();
    }, 10);
  };

  const handlePlayPause = () => {
    setIsRunning(!isRunning);
  };

  // This is related to the NavArrow
  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [
    emblaApi,
  ]);

  // This is related to the NavArrow
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [
    emblaApi,
  ]);

  // This is related to the dots
  const scrollTo = useCallback(index => emblaApi && emblaApi.scrollTo(index), [
    emblaApi,
  ]);

  const onInit = useCallback(eApi => {
    setScrollSnaps(eApi.scrollSnapList());
  }, []);

  const onSelect = useCallback(eApi => {
    setSelectedIndex(eApi.selectedScrollSnap());
    setPrevBtnDisabled(!eApi.canScrollPrev());
    setNextBtnDisabled(!eApi.canScrollNext());
    handleReset();
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTime(prevTime => {
          if (emblaApi && prevTime >= viewDuration * 1000) {
            clearInterval(intervalRef.current);
            setIsRunning(false);
            emblaApi.scrollNext();
            return prevTime;
          }
          return prevTime + 100;
        });
      }, 100);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  return (
    <BoxWithCtaAnalytics entryTitle={entryTitle}>
      <Box
        ref={emblaRef}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          height: areAllSlidesSameHeight
            ? [
                `${slideMobileFixedHeight || slideFixedHeight}px`,
                `${slideFixedHeight}px`,
              ]
            : 'unset',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            transition: 'height 0.1s',
            height: areAllSlidesSameHeight
              ? [
                  `${slideMobileFixedHeight || slideFixedHeight}px`,
                  `${slideFixedHeight}px`,
                ]
              : 'unset',
          }}
        >
          {children.map(child => (
            <Box
              key={child?.props?.entryTitle}
              sx={{
                flex: '0 0 100%',
                minWidth: 0,
                maxWidth: '100%',
                height: areAllSlidesSameHeight
                  ? [
                      `${slideMobileFixedHeight || slideFixedHeight}px`,
                      `${slideFixedHeight}px`,
                    ]
                  : 'unset',
              }}
            >
              {child}
            </Box>
          ))}
        </Box>
        {scrollSnaps.length > 0 && (
          <>
            <NavArrow
              isPrevArrow
              onClick={scrollPrev}
              disabled={prevBtnDisabled}
              variant={
                children[selectedIndex]?.props?.textVariant ||
                children[selectedIndex]?.props?.textBlockVariant
              }
            >
              {previousSlideText}
            </NavArrow>
            <NavArrow
              isNextArrow
              onClick={scrollNext}
              disabled={nextBtnDisabled}
              variant={
                children[selectedIndex]?.props?.textVariant ||
                children[selectedIndex]?.props?.textBlockVariant
              }
            >
              {nextSlideText}
            </NavArrow>
          </>
        )}
      </Box>
      {scrollSnaps.length > 0 && (
        <NavBar
          scrollTo={scrollTo}
          handlePlayPause={handlePlayPause}
          isRunning={isRunning}
          playText={playText}
          pauseText={pauseText}
          selectSlideText={selectSlideText}
          selectedIndex={selectedIndex}
          viewDuration={viewDuration}
          time={time}
          showProgressBar={showProgressBar}
        >
          {children}
        </NavBar>
      )}
    </BoxWithCtaAnalytics>
  );
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  entryTitle: PropTypes.string.isRequired,
  viewDuration: PropTypes.number.isRequired,
  areAllSlidesSameHeight: PropTypes.bool,
  previousSlideText: PropTypes.string,
  nextSlideText: PropTypes.string,
  selectSlideText: PropTypes.string,
  playText: PropTypes.string,
  pauseText: PropTypes.string,
  showProgressBar: PropTypes.bool.isRequired,
  slideFixedHeight: PropTypes.number,
  slideMobileFixedHeight: PropTypes.number,
};

export default Carousel;
