import { parse } from 'qs';

export const getFilteredPlans = (plans, plansList, getPlan) => {
  const plansSorted = [];
  for (let index = 0; index < plansList.length; index += 1) {
    if (plansList[index] && plansList[index].fields) {
      const {
        fields: { sku },
      } = plansList[index];
      if (plans[sku]) plansSorted.push(plans[sku]);
    }
  }
  let plansFiltered = [];
  if (plansSorted.length > 0) {
    plansFiltered =
      plansSorted.length && plansSorted.map(plan => getPlan(plan));
  }

  return plansFiltered;
};

export const getPlanSkuFromQuery = query => {
  const { planSku } = parse(query, { ignoreQueryPrefix: true });

  if (!planSku) return null;
  return planSku.replace(/ {3,}/g, ' + ');
};
