import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { arrayOf, string, func } from 'prop-types';
import Flex from 'web_component_library/flex';
import Box from 'web_component_library/box';
import ToggleButton from 'web_component_library/toggleButton';
import ListBox from 'web_component_library/listBox';
import Button from 'web_component_library/button';

import withTranslation from '../../containers/translationHoc';

import { PT_PREPAID } from '../../constants';

import { selectCurrentPage } from '../../containers/contentfulHoc/selectors';
import { getPageName } from '../ctaAnalyticsHoc/utils';
import { naturalToKebab } from '../../../utils/stringHelpers';
import {
  BUSINESS_TOGGLES,
  getBusinessToggle,
} from '../../../utils/toggleHelpers';
import { setUtagForLinks } from '../../lib/analytics';

const AddOnsFilters = ({
  translate,
  initialPaymentType = undefined,
  initialCategory = undefined,
  categories,
  onFilterChange,
}) => {
  // TODO: Remove this business toggle when prepaid addons are all set up in Contentful, and everything is stable
  const doesBusinessAllowPrepaidFilter = getBusinessToggle(
    BUSINESS_TOGGLES.PREPAID_ADDON_FILTER,
  );

  // State
  const currentPage = useSelector(selectCurrentPage());
  const [filters, setFilters] = useState({
    paymentType: initialPaymentType,
    category: initialCategory,
  });

  // Derived state
  const pageName = getPageName(currentPage);
  const categoryOptions = categories.map(category => ({
    label: category,
    value: category,
  }));

  // Setters
  const updateFilter = (key, value) => {
    const newFilters = {
      ...filters,
      [key]: value,
    };
    // Payment type is a primary filter, while category is a secondary filter.
    // Changing payment type should therefore reset category.
    if (key === 'paymentType') newFilters.category = undefined;

    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  return (
    <Flex
      mt={[11, 0]}
      flexDirection={['column', 'row']}
      justifyContent={
        doesBusinessAllowPrepaidFilter ? 'space-between' : ['center', 'end']
      }
      alignItems={['start', 'center']}
    >
      {doesBusinessAllowPrepaidFilter && (
        <ToggleButton
          identity="prepaidAddOnsToggle"
          testId="prepaidAddOnsToggle" // this is actually the id attribute for the input
          isChecked={filters.paymentType === PT_PREPAID}
          onChangeHandler={isToggleOn => {
            updateFilter('paymentType', isToggleOn ? PT_PREPAID : undefined);

            setUtagForLinks({
              custom_link: `content|${pageName}|prepaid-add-ons-toggle|${naturalToKebab(
                translate('prepaidToggleText'),
              )}|${isToggleOn ? 'selected' : 'deselected'}`,
            });
          }}
          toggleStateText={{ on: '', off: '' }}
          shortDescription={translate('prepaidToggleText')}
        />
      )}
      <Flex
        mt={[7, 0]}
        width={[1, 2 / 5]}
        flexDirection={['column', 'row']}
        alignItems="center"
      >
        <Box mb={7} flex={1} width={[1, 'auto']}>
          <ListBox
            id="addOnCategoriesDropdown"
            onChange={e => {
              updateFilter('category', e.target.value);

              setUtagForLinks({
                custom_link: `content|${pageName}|add-ons-category-filter|${naturalToKebab(
                  translate('categoryFilterLabel'),
                )}|${naturalToKebab(e.target.value)}`,
              });
            }}
            options={categoryOptions}
            value={filters.category}
            label={translate('categoryFilterLabel')}
            isLabelHidden
          />
        </Box>
        <Button
          data-testid="clearFilter"
          variant="none"
          sx={{
            ml: 7,
            bg: 'transparent',
            color: 'freedomCharcoal',
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            updateFilter('category', undefined);

            setUtagForLinks({
              custom_link: `content|${pageName}|add-ons-category-filter|${naturalToKebab(
                translate('categoryFilterLabel'),
              )}|${naturalToKebab(translate('categoryFilterClear'))}`,
            });
          }}
        >
          {translate('categoryFilterClear')}
        </Button>
      </Flex>
    </Flex>
  );
};

AddOnsFilters.propTypes = {
  translate: func.isRequired,
  initialPaymentType: string,
  initialCategory: string,
  categories: arrayOf(string).isRequired,
  onFilterChange: func.isRequired,
};
export default withTranslation(AddOnsFilters);
