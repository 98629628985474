export default class ChatCtaModal {
  constructor(data) {
    if (data && data.fields) {
      const {
        slug,
        arrowDirection,
        position,
        value,
        variant,
        type,
        chatEngagementId,
        appleChatBizIntentId,
        appleChatBizGroupId,
        appleChatBody,
        isAppleChatOn,
      } = data.fields;
      this.slug = slug;
      this.arrowDirection = arrowDirection;
      this.position = position;
      this.value = value;
      this.variant = variant;
      this.type = type;
      this.chatEngagementId = chatEngagementId;
      this.appleChatBizIntentId = appleChatBizIntentId;
      this.appleChatBizGroupId = appleChatBizGroupId;
      this.appleChatBody = appleChatBody;
      this.isAppleChatOn = isAppleChatOn;
    }
  }
}
