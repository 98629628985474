import React from 'react';
import PropTypes from 'prop-types';
import FeaturesCard from './featuresCard';
import FeaturesPanel from './featuresPanel';

const FeaturesGrid = ({
  headingText,
  featureColumnGroup,
  themeVariant,
  identifier,
}) => {
  if (identifier === 'featuresCard') {
    return (
      <FeaturesCard
        headingText={headingText}
        featureColumnGroup={featureColumnGroup}
        themeVariant={themeVariant}
      />
    );
  }

  return (
    <FeaturesPanel
      headingText={headingText}
      featureColumnGroup={featureColumnGroup}
      themeVariant={themeVariant}
    />
  );
};

FeaturesGrid.propTypes = {
  featureColumnGroup: PropTypes.array.isRequired,
  headingText: PropTypes.object.isRequired,
  themeVariant: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
};

export default FeaturesGrid;
