import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import Divider from 'web_component_library/divider';
import PageHeading from '../pageHeading';
import { Wrapper, Container, Block } from './css';

const defaultTextBlockVariant = 'light';
const LegalTerms = ({
  mainHeading,
  personalInfoContent = null,
  plainContent,
  purposeAndApplicationContent = null,
  textBlockVariant = defaultTextBlockVariant,
}) => (
  <Block width={1} mt={[9, 17]}>
    <Container>
      <PageHeading
        textBlock={mainHeading}
        textBlockVariant={textBlockVariant}
        margins={{ mt: [11, 15] }}
      />
    </Container>
    <Box my={29}>
      <Divider color="black" thickness={2} my={17} />
    </Box>
    <Wrapper mb={-3}>
      <TextBlock
        data={purposeAndApplicationContent}
        blockVariant={textBlockVariant}
        headingStyles={{ mt: 21 }}
        paragraphStyles={{ mt: 7, mb: 13 }}
      />
    </Wrapper>
    {personalInfoContent && (
      <TextBlock
        paragraphMargin={{ mt: 7, mb: 13 }}
        data={personalInfoContent}
        blockVariant={textBlockVariant}
      />
    )}
    {plainContent && (
      <Box mb={[-8]}>
        <TextBlock
          headingStyles={{ mt: 21 }}
          data={plainContent}
          blockVariant={textBlockVariant}
          paragraphStyles={{ mt: 7, mb: 13 }}
        />
      </Box>
    )}
  </Block>
);

LegalTerms.propTypes = {
  // Main page heading
  mainHeading: PropTypes.object.isRequired,
  // content for personal Info section
  personalInfoContent: PropTypes.object,
  // reamining content
  plainContent: PropTypes.object.isRequired,
  // content for purposeAndApp section
  purposeAndApplicationContent: PropTypes.object,
  // color of text
  textBlockVariant: PropTypes.string,
};

export default LegalTerms;
