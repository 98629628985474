export default class BusinessToggles {
  constructor(items) {
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      if (item.fields) {
        const { ticket, toggle } = item.fields;
        this[ticket] = toggle;
      }
    }
  }
}
