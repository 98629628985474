import {
  REQUIRED_ANALYTICS_BTN_PROPS,
  DATA_ANALYTICS_BTN,
  OPTIONAL_ANALYTICS_BTN_PROPS,
} from '../constants';

export function setUtagForViews(analyticData) {
  if (typeof window !== 'undefined' && window.utag && window.utag.view) {
    window.utag_data = analyticData;
    window.utag.view(window.utag_data);
  }
}

export function setUtagForLinks(analyticData, url) {
  if (window.utag && window.utag.link) {
    window.utag.link(analyticData, () => {
      if (url) {
        setTimeout(() => {
          window.location.assign(url);
        }, 300);
      }
    });
  }
}

export function setAnalyticsListenerForInteractions() {
  const targetElements = document.querySelectorAll(
    `button[${DATA_ANALYTICS_BTN}], a[${DATA_ANALYTICS_BTN}]`,
  );
  for (let i = 0; i < targetElements.length; i += 1) {
    const targetElement = targetElements[i];
    if (targetElement) {
      targetElement.addEventListener('click', extractDataAttributes);
    }
  }

  // Destroy/cleanup function
  return () =>
    targetElements.forEach(el =>
      el.removeEventListener('click', extractDataAttributes),
    );
}

export function extractDataAttributes(event) {
  const targetElement = event.currentTarget;
  const analyticData = {};
  let triggerAnalytics = true;
  const analyticKeys = Object.keys(REQUIRED_ANALYTICS_BTN_PROPS);
  for (let j = 0; j < analyticKeys.length; j += 1) {
    const key = analyticKeys[j];
    const analyticValue = targetElement.getAttribute(key);
    const analyticKey = key.substring(5);
    if (analyticValue) {
      analyticData[analyticKey] = analyticValue;
    } else {
      triggerAnalytics = false;
    }
  }
  if (triggerAnalytics) {
    const optinalAnalyticKeys = Object.keys(OPTIONAL_ANALYTICS_BTN_PROPS);
    for (let i = 0; i < optinalAnalyticKeys.length; i += 1) {
      const key = optinalAnalyticKeys[i];
      const analyticValue = targetElement.getAttribute(key);
      const analyticKey = key.substring(5);
      if (analyticValue) {
        analyticData[analyticKey] = analyticValue;
      }
    }
    setUtagForLinks(analyticData);
  }
}
