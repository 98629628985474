import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const IframeWrapper = styled.iframe`
  border: 0;
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
`;

export const Container = styled(Box)`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
`;
