import produce from 'immer';
import { handleActions } from 'redux-actions';

import {
  loadGetUserProfile,
  loadedGetUserProfile,
  errorGetUserProfile,
  clearGetUserProfile,
} from './actions';

// The initial state of the Main
export const initState = {
  result: {},
  isLoading: false,
  hasLoaded: false,
  errorCode: null,
};

/* eslint-disable default-case, no-param-reassign */
const getUserProfileReducer = handleActions(
  {
    [loadGetUserProfile]: produce(draft => {
      draft.isLoading = true;
      return draft;
    }),
    [loadedGetUserProfile]: produce((draft, { payload }) => {
      draft.isLoading = false;
      draft.hasLoaded = true;
      draft.errorCode = null;
      draft.result = {
        ...payload,
      };
      return draft;
    }),
    [errorGetUserProfile]: produce((draft, action) => {
      draft.isLoading = false;
      draft.errorCode = action.payload.errorCode;
      return draft;
    }),
    [clearGetUserProfile]: produce(() => initState),
  },
  initState,
);

export default getUserProfileReducer;
