import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from 'web_component_library/box';
import freedomTheme from 'web_component_library/theme/freedom';
import TertiaryNavigationButton from 'web_component_library/tertiaryNavigationButton';
import { isAbsolutePath } from '../ctaAnalyticsHoc';
import TertiaryNavigationSectionModel from '../../containers/contentful/tertiaryNavigationSection';

const TertiaryNavigationSection = props => {
  const history = useHistory();
  const { iconsList, iconsVariant } = new TertiaryNavigationSectionModel(props);

  if (!iconsList) return null;

  const onClick = url => {
    if (isAbsolutePath(url)) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  const maxDesktopGridWidth = parseInt(
    freedomTheme.sizes[2].replace('px', ''),
    10,
  );
  const columnGapWidth = freedomTheme.space[13];

  const columnGapWidthInPixel =
    parseInt(columnGapWidth.replace('rem', ''), 10) * 16;

  // To not change the width when there are less than 7 columns
  // the item width is being calculated using the freedom theme sizes and spaces
  const maxItemWidth = (maxDesktopGridWidth - 7 * columnGapWidthInPixel) / 8;

  const columns = iconsList.length;
  const maxMobileGridWidth =
    columns * maxItemWidth + (columns - 1) * columnGapWidthInPixel;

  return (
    <nav data-testid="tertiary-navigation-containter">
      <Box
        data-testid="tertiary-navigation-inner-containter"
        py="xxl"
        sx={{
          overflowX: 'scroll',
          '&::-webkit-scrollbar': {
            marginLeft: 'xl',
            marginRight: 'xl',
            height: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'accessibleGrey',
          },
        }}
      >
        <Box
          data-testid="tertiary-navigation-grid"
          sx={{
            display: 'grid',
            // The grid must be centralized accordingly with the number of columns
            gridTemplateColumns: `repeat(${columns}, ${maxItemWidth}px)`,
            justifyContent: 'center',
            columnGap: columnGapWidth,
            alignItems: 'flex-start',
            // Apply the desktop's maxWidth for mobile view
            // to have the scroll bar and the same grid desktop's styles
            // accommodating a maximum of eight (8) icons in a straight line
            width: [`${maxMobileGridWidth}px`, '100%'],
          }}
        >
          {iconsList &&
            iconsList.map(icon => (
              <Box key={icon.title}>
                <TertiaryNavigationButton
                  title={icon.title}
                  img={icon.imageUrl}
                  url={icon.url}
                  variant={iconsVariant}
                  onClick={onClick}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </nav>
  );
};

export default TertiaryNavigationSection;
