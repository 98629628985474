import React from 'react';

import RatesCountriesList from '../common/components/ratesCountriesList';
import VideoModal from '../common/components/video/videoModal';
import SmartConnectForm from '../common/containers/smartConnectForm';

const mapping = {
  callingRates: RatesCountriesList,
  roamingRates: RatesCountriesList,
  modalVideo: VideoModal,
  smartConnect: SmartConnectForm,
};

export const popUpComponentHelpers = slug => mapping[slug] || React.Fragment;
