import React, { Fragment, PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { createStructuredSelector } from 'reselect';
import HeaderNav from 'web_component_library/headerNav';
import Footer from 'web_component_library/footer';
import SkipLink from 'web_component_library/skipLink';
import { setCurrentPage as setCurrentPageAction } from '../contentfulHoc/actions';

import withAnalytics from '../analyticsHoc';
import { ModalContext } from '../../components/modal/modalContext';
import {
  selectPopupModals,
  selectCommonCatalog,
  selectBrowseCatalog,
} from '../contentfulHoc/selectors';
import Modal from '../../components/modalComponent';
import { launchLiveChat } from '../../../utils/liveChatHelper';
import { Main, Container, HeaderContainer } from '../contentPage/css';
import { MAIN_SITE_URL } from '../../constants';
import ErrorBody from '../../components/errorBody';
import withCtaAnalytics from '../../components/ctaAnalyticsHoc';
import { withFreedomAuth } from '../providers/oidcProvider/hook';
import { withRegion } from '../providers/regionProvider/hook';
import { renderLinkTags, renderMetaTags } from '../../../utils/seo';
import { DEFAULT_REGION } from '../contentfulHoc/constants';

const SkipLinkWithanalytics = withCtaAnalytics(SkipLink, {
  staticModuleType: 'skip-link',
  includeModuleTitle: false,
});

class FourOhFourPage extends PureComponent {
  static contextType = ModalContext;

  static displayName = 'FourOhFourPage';

  static propTypes = {
    commonCatalog: PropTypes.object,
    popupModals: PropTypes.object,
    staticContext: PropTypes.object,
    browseCatalog: PropTypes.object,
    locale: PropTypes.string.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    doFreedomAuthLogin: PropTypes.func.isRequired,
    doFreedomAuthLogout: PropTypes.func.isRequired,
    region: PropTypes.object,
    availableRegions: PropTypes.arrayOf(PropTypes.string).isRequired,
    updateRegion: PropTypes.func.isRequired,
    regionsIsDisabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    commonCatalog: null,
    popupModals: null,
    browseCatalog: null,
    staticContext: {},
    region: DEFAULT_REGION,
  };

  mainContent = createRef();

  header = createRef();

  state = {
    headerHeight: 0,
  };

  componentDidMount() {
    const {
      setCurrentPage,
      browseCatalog: {
        error: { error },
      },
    } = this.props;
    setCurrentPage(error);

    window.addEventListener('resize', this.updateHeight);
    this.updateHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  updateHeight = () => {
    const header = this.header.current;
    if (!header) return;

    const newHeaderHeight = header.offsetHeight;
    if (this.state.headerHeight !== newHeaderHeight) {
      this.setState({ headerHeight: newHeaderHeight });
    }
  };

  openPopup = popUpIdentifier => {
    const popupModal = this.props.popupModals[popUpIdentifier];
    this.context.showModal(Modal, popupModal);
  };

  render() {
    const {
      commonCatalog: { header, footer },
      browseCatalog: {
        error: { error },
      },
      staticContext,
      locale,
      isLoggedIn,
      doFreedomAuthLogin,
      doFreedomAuthLogout,
      region,
      availableRegions,
      updateRegion,
      regionsIsDisabled,
    } = this.props;

    const { metaTag, linkTag, schema, maintenance } = error;

    const { headerHeight } = this.state;

    staticContext.status = 404;

    return (
      <Fragment>
        <Helmet>
          {metaTag.title ? <title>{metaTag.title}</title> : null}
          {renderLinkTags(linkTag?.tags)}
          {renderMetaTags(metaTag?.tags)}
          <script type="application/ld+json">{`${JSON.stringify(
            schema,
          )}`}</script>
        </Helmet>
        <SkipLinkWithanalytics targetId="mainContent">
          Skip Navigation
        </SkipLinkWithanalytics>
        <HeaderContainer ref={this.header}>
          <HeaderNav
            headerData={header}
            launchLiveChat={launchLiveChat}
            locale={locale}
            position="static"
            currentSiteUrl={MAIN_SITE_URL}
            isLoggedIn={isLoggedIn}
            onLogout={doFreedomAuthLogout}
            onLogin={doFreedomAuthLogin}
            province={region}
            provinceOptions={availableRegions}
            onProvinceChange={updateRegion}
            provinceDisabled={regionsIsDisabled}
          />
        </HeaderContainer>
        <Main
          tabIndex="-1"
          ref={this.mainContent}
          mt={[headerHeight || 64, headerHeight || 80]}
          id="mainContent"
        >
          <Container>
            <ErrorBody maintenance={maintenance} />
          </Container>
        </Main>
        <Footer
          footerData={footer}
          openPopup={this.openPopup}
          launchLiveChat={launchLiveChat}
          locale={locale}
          currentSiteUrl={MAIN_SITE_URL}
        />
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  commonCatalog: selectCommonCatalog(),
  popupModals: selectPopupModals(),
  browseCatalog: selectBrowseCatalog(),
});

export const mapDispatchToProps = dispatch => ({
  setCurrentPage: page => dispatch(setCurrentPageAction(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFreedomAuth(withAnalytics(withRegion(FourOhFourPage))));
