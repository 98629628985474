import React, { Fragment } from 'react';
import { string, arrayOf, object } from 'prop-types';

import MediaFile from '../../containers/contentful/mediaFile';

import MultiImageShowcaseAnimation from './components/multiImageShowcaseAnimation';
import HorizontalPanelAnimation from './components/horizontalPanelAnimation';
import FactShowcaseAnimation from './components/factShowcaseAnimation';

const JSONComponent = ({ componentIdentifier, data, images }) => {
  const mappedImages = images.map(
    image => new MediaFile(image, { isTransparent: true }),
  );
  return (
    <Fragment>
      {
        {
          multiImageShowcaseAnimation: (
            <MultiImageShowcaseAnimation data={data} images={mappedImages} />
          ),
          horizontalPanelAnimation: (
            <HorizontalPanelAnimation data={data} images={mappedImages} />
          ),
          factShowcaseAnimation: (
            <FactShowcaseAnimation data={data} images={mappedImages} />
          ),
        }[componentIdentifier]
      }
    </Fragment>
  );
};

JSONComponent.propTypes = {
  componentIdentifier: string.isRequired,
  data: object.isRequired,
  images: arrayOf(object).isRequired,
};

export default JSONComponent;
