import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import Text from 'web_component_library/text';

import ContactUsDetailsBlock from '../contactUsDetailsBlock';

const ContactUsDetailsSection = ({ details, time }) => (
  <Box width={1} mt={[7, 13]} mr={11}>
    <Text
      fontSize={[2, 3]}
      fontWeight={0}
      letterSpacing={3}
      lineHeight={[4, 5]}
      mb={[7, 11]}
      as="p"
    >
      {time}
    </Text>
    <ContactUsDetailsBlock data={details} />
  </Box>
);

ContactUsDetailsSection.propTypes = {
  details: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired,
};

export default ContactUsDetailsSection;
