import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { Box } from 'rebass/styled-components';

export const PlayerWrapper = styled(Box)`
  position: relative;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  video {
    object-fit: cover;
  }
`;

export const VideoBackgroundContainer = styled(Box)`
  position: relative;
`;

export const ComponentWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

export const FallbackImage = styled.img`
  max-width: 100%;
`;
