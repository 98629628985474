import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import freedomTheme from 'web_component_library/theme/freedom';

const { colors } = freedomTheme;
// accessing nodes directly as it is needed to change the para color inside rich text when it lies under list
export const HeadingWrappper = styled(Box)`
  display: inline-block;
  text-align: ${props => props.headingPosition || 'center'};
  ul > li > p {
    color: ${colors.black};
  }
  ol > li > p {
    color: ${colors.black};
  }
`;

HeadingWrappper.propTypes = {
  headingPosition: PropTypes.string,
};
