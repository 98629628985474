import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import TextBlock from 'web_component_library/textBlock';
import { StyleableText } from 'web_component_library/text';
import Divider from 'web_component_library/divider';

const TableTitle = ({ textData, paragraphStyles = null }) => {
  const activeTheme = useContext(ThemeContext);

  return (
    <>
      {typeof textData === 'object' ? (
        <TextBlock data={textData} paragraphStyles={paragraphStyles} />
      ) : (
        <StyleableText
          {...activeTheme.text.headingTwo}
          as="p"
          m={0}
          fontSize={[4, 5]}
          lineHeight={4}
          {...paragraphStyles}
        >
          {textData}
        </StyleableText>
      )}
      <Divider mt={11} />
    </>
  );
};

TableTitle.propTypes = {
  textData: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  paragraphStyles: PropTypes.object,
};

export default TableTitle;
