export const PRIMARY_TYPE = 'primaryButton';
export const TERTIARY_TYPE = 'ArrowButton';

// Analytics constants
export const SITE_NAME = 'freedommobile.ca';
export const ANALYTICS_EV_TYPE = 'interaction';
export const LOGIN_DEAFULT_STATE = 'anonymous';
export const DATA_ANALYTICS_BTN = 'data-analytics-btn';
export const DATA_ANALYTICS_BTN_TYPE = 'data-ev_type';
export const DATA_ANALYTICS_BTN_ACTION = 'data-ev_action';
export const DATA_ANALYTICS_BTN_NAME = 'data-ev_name';
export const CONTENT_ANALYTICS_NAME = 'content';
export const MODAL_ANALYTICS_NAME = 'modal';
export const ERROR = 'error';
export const ERROR_CODE = '403';
export const PAGE_NOT_FOUND = 'page-not-found';
export const ANALYTICS_RESULTS_TEXT = 'were these results helpful';
export const ANALYTICS_QUESTION_TEXT = 'did your question get answered';

// required object for button handler for analytics
export const REQUIRED_ANALYTICS_BTN_PROPS = {
  [DATA_ANALYTICS_BTN_TYPE]: 'interaction',
  [DATA_ANALYTICS_BTN_ACTION]: '',
  [DATA_ANALYTICS_BTN_NAME]: '',
};

export const OPTIONAL_ANALYTICS_BTN_PROPS = {
  'data-ev_data': '',
  'data-email_optin': '',
  'data-link_obj': '',
  'data-link_name': '',
  'data-link_type': '',
  'data-link_region': '',
  'data-product_ids': '',
  'data-product_categories': '',
  'data-product_prices': '',
  'data-product_discounts': '',
  'data-product_promos': '',
};

export const ANALYTICS_BTN_PROPS = {
  [DATA_ANALYTICS_BTN]: '1',
  ...REQUIRED_ANALYTICS_BTN_PROPS,
};

export const PHONE_PLAN_TAG = 'Phone';

export const PLANS_DEVICE_TYPES = {
  TabletOnlyPlan: 'Tablet',
  WearableOnlyPlan: 'Wearable Device',
};

export const ANALYTICS_CHOOSE_THIS_PLAN = 'Choose this Plan';
export const ANALYTICS_PLANS = 'plans';
export const ANALYTICS_PREPAID_PLANS = 'prepaid plans';
export const ANALYTICS_BYOP_PLANS = 'byop plans';
export const ANALYTICS_PLAN_TYPE = 'plan type';
export const ANALYTICS_BRINGING_MY_OWN_PHONE = 'bringing my own phone';

export const VIDEO_FILE_TYPE = 'video/mp4';

// Tags
export const FREEDOM = 'Freedom';

// Meta tags type for SEO
export const META_TAG_PROP_TYPE = 'name';
export const VARIANT_LIGHT = 'light';

export const FULL_WIDTH = 'fullWidth';
export const HALF_WIDTH = 'halfWidth';
export const MEDIUM_HEIGHT = 'mediumHeight';
export const TOP = 'top';
export const BOTTOM = 'bottom';

export const REQUIRED_SIGN = '*';

// Letter for indexing catalogues
export const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

// Separator Component Heights.
export const BlockSize = {
  small36px: ['6px', '6px', '0'],
  medium48px: ['12px', '12px', '12px'],
  large96px: ['36px', '36px', '60px'],
  large64px: ['16px', '16px', '16px'],
  large72px: ['12px', '12px', '48px'],
};

// Plan Types
export const PT_POSTPAID = 'postpaid';
export const PT_PREPAID = 'prepaid';
export const PT_BYOP = 'bringYourOwnPhone';

// Button Types
export const BT_TOGGLE = 'Toggle';
export const BT_FILTER = 'Filter';

// Form type
export const CHECKBOX = 'checkbox';

// URLs
export const HOMEURL = '/';
export const ROOT = 'ROOT';
export const URL_POSTPAID =
  'https://shop.freedommobile.ca/en-CA/devices?planSku=';
export const URL_BYOP =
  'https://shop.freedommobile.ca/en-CA/plans?sku=bringYourOwnPhone&planSku=';

export const LIVE_MESSAGE = 'live-message';

export const MAIN_SITE_URL = 'https://www.freedommobile.ca';

export const FRDM_APPLE_BUSINESS_CHAT_URL =
  'https://bcrw.apple.com/messages/api/messageprofiles/redirecthelper?service=iMessage&recipient=urn:biz:ce7f4ecf-8f02-4c39-ab74-d265a7e65365';

export const OIDC_REDIRECT_URI = '/callback-oidc';
export const OIDC_POST_LOGOUT_REDIRECT_URI = '/signout-callback-oidc';

export const SPLIT_TEXT_CONTAINER_PADDING = {
  small: { pt: [13, 21], pb: [13, 17], pl: [11, 17], pr: [11, 17] },
  normal: { pt: [13, 26], pb: [13, '4.25em'] },
  initialBlock: { pt: [13, '5.25em'], pb: [13, '5.5em'] },
  finalBlock: { pt: [13, '4.5em'], pb: [13, '5.25em'] },
};

export const SPLIT_TEXT_BUTTON_WIDTH = {
  small: { width: 1 },
  normal: { width: [1, 2 / 3] },
  initialBlock: { width: [1, 2 / 3] },
  finalBlock: { width: [1, 2 / 3] },
};

export const SPLIT_TEXT_BUTTON_POSITION = {
  left: { ml: 0 },
  right: { ml: [0, 'auto'] },
  center: { mx: [0, 'auto'] },
};

// Support page related constants
export const PAGE_SUPPORT_BASE = 'support';
export const PAGE_SEARCH = 'search-results';
export const PAGE_CATEGORIES = 'categories';
export const getSupportUrl = url =>
  url ? `${PAGE_SUPPORT_BASE}/${url}` : `${PAGE_SUPPORT_BASE}`;

// Rates filter
export const CALLING_RATES = 'callingRates';
export const ROAMING_RATES = 'roamingRates';
export const CALLING_RATES_WITH_ADDONS = 'callingRatesWithAddOns';
export const ROAMING_RATES_WITH_ADDONS = 'roamingRatesWithAddOns';

export const BUTTON_GREY_VARIANT = 'greyed';
export const BUTTON_PRIMARY_VARIANT = 'primary';

export const TEALIUM_TARGET_AUDIENCE = 'va.properties.5430';

// Smart Connect channels
export const IOS_MESSAGING_CHANNEL = 'abc';
export const ANDROID_MESSAGING_CHANNEL = 'grbm';

// Plan Tags
export const UNLIMITED_PLAN = 'Unlimited';
export const FREEDOM_PLAN = 'Freedom Plans';

export const PLACEHOLDER_QUALITY = 25;

export const DO_NOT_TRACK_CLASS = 'do_not_track';

export const NO_DEFAULT_PAGE_LOAD_ANALYTICS_PAGES = ['search results'];

export const ARTICLE_NOT_FOUND = 'Not Found';

export const BREAKPOINT = 992;
