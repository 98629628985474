import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';

import TextBlock from 'web_component_library/textBlock';
import { Paragraph } from 'web_component_library/typography';
import { Box } from 'rebass/styled-components';

import { Helmet } from 'react-helmet-async';
import {
  selectArticle,
  selectLocale,
  selectRegion,
} from '../../containers/contentfulHoc/selectors';

import { ARTICLE_NOT_FOUND } from '../../constants';

import {
  getArticle,
  clearArticle,
} from '../../containers/contentfulHoc/actions';
import withTranslation from '../../containers/translationHoc';
import { renderLinkTags, renderMetaTags } from '../../../utils/seo';

const ArticlePage = ({ translate }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const locale = useSelector(selectLocale());
  const region = useSelector(selectRegion());

  const match = useRouteMatch();
  const articleSlug = match?.params?.article;

  // NOTE: We store location.state in a ref as it gets cleared whenever route changes happen,
  // which is common with the TableOfContents component (rendered by TextBlock)
  const { current: articleFromRouter } = useRef(location.state); // passed from SupportSearchInput
  const articleFromRedux = useSelector(selectArticle()); // fetched if articleFromRouter not available
  const article = articleFromRedux || articleFromRouter;

  const htmlRef = useRef();

  useEffect(() => {
    if (articleSlug) {
      dispatch(getArticle({ slug: articleSlug, locale, region }));
    }

    return () => {
      dispatch(clearArticle());
    };
  }, [articleSlug]);

  useEffect(() => {
    if (article?.html && !article.content && htmlRef.current) {
      htmlRef.current.innerHTML = article.html;
    }
    if (article !== ARTICLE_NOT_FOUND && typeof article !== 'undefined') {
      window.isArticleShowing = true;
    } else {
      window.isArticleShowing = false;
    }

    return () => {
      window.isArticleShowing = false;
    };
  }, [article]);

  const messageToShow =
    article === ARTICLE_NOT_FOUND
      ? translate('articleNotFound')
      : translate('loading');

  const SeoForArticles = () =>
    article ? (
      <Helmet>
        {article.metaTag?.title ? <title>{article.metaTag.title}</title> : null}
        {renderLinkTags(article?.linkTag?.tags)}
        {renderMetaTags(article?.metaTag?.tags)}
        {article?.schema ? (
          <script type="application/ld+json">{`${JSON.stringify(
            article.schema,
          )}`}</script>
        ) : null}
      </Helmet>
    ) : null;

  if (article?.content) {
    return (
      <>
        <SeoForArticles />
        <Box data-testid="rich-text" mt={19}>
          <TextBlock
            data={article.content}
            headingStyles={{ mb: 5 }}
            paragraphStyles={{ mt: 7 }}
            buttonStyles={{ width: [1, 1 / 2], my: 5 }}
          />
        </Box>
      </>
    );
  }
  if (article?.html) {
    return (
      <>
        <SeoForArticles />
        <Box data-testid="html" mt={17} ref={htmlRef}></Box>
      </>
    );
  }

  return (
    <Box>
      {typeof messageToShow === 'string' ? (
        <Paragraph>{messageToShow}</Paragraph>
      ) : (
        <TextBlock data={messageToShow} />
      )}
    </Box>
  );
};

ArticlePage.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslation(ArticlePage);
