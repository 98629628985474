import React from 'react';
import PropTypes from 'prop-types';

import Box from 'web_component_library/box';
import RestrictedBox from 'web_component_library/restrictedBox';
import AutoplayCarousel from '../autoplayCarousel';
import { componentMapping } from '../../../utils/helpers';

import {
  getTargetedComponent,
  getComponentToRender,
} from '../../../utils/targetHelpers';

const defaultTargetAudience = '';
const HeroBanner = ({
  content,
  targetAudience = defaultTargetAudience,
  openPopup,
}) => {
  let bannerProps = { ...content };
  switch (content.identifier) {
    case 'targetedPromoBannerList':
      bannerProps = getTargetedComponent(content, targetAudience);
      break;
    case 'carousel':
      bannerProps.components = bannerProps.components.reduce(
        (acc, component) =>
          getComponentToRender(acc, component, targetAudience),
        [],
      );
      break;
    default:
      break;
  }

  if (bannerProps.identifier === 'carousel') {
    return (
      <Box maxWidth="2048px" mx="auto">
        <AutoplayCarousel {...bannerProps}>
          {bannerProps.components.reduce(
            (componentsToRender, componentProps) => {
              const Component = componentMapping[componentProps.identifier];
              const {
                areAllSlidesSameHeight,
                slideFixedHeight,
                slideMobileFixedHeight,
              } = bannerProps;
              const fixedHeight = slideFixedHeight
                ? `${slideFixedHeight}px`
                : '350px';
              const fixedMobileHeight = slideMobileFixedHeight
                ? `${slideMobileFixedHeight}px`
                : null;
              if (Component) {
                componentsToRender.push(
                  <Component
                    key={componentProps.id}
                    {...componentProps}
                    isHeroBanner
                    openPopup={openPopup}
                    fixedHeight={areAllSlidesSameHeight ? fixedHeight : null}
                    fixedMobileHeight={
                      areAllSlidesSameHeight ? fixedMobileHeight : null
                    }
                  />,
                );
              }
              return componentsToRender;
            },
            [],
          )}
        </AutoplayCarousel>
      </Box>
    );
  }

  const HeroPromoBanner = componentMapping[bannerProps.identifier];

  if (!HeroPromoBanner) return null;

  return bannerProps.appearance !== 'fullWidth' ? (
    <Box bg={bannerProps.textBlockBackground}>
      <RestrictedBox maxWidth="2048px">
        <HeroPromoBanner {...bannerProps} isHeroBanner openPopup={openPopup} />
      </RestrictedBox>
    </Box>
  ) : (
    <HeroPromoBanner {...bannerProps} isHeroBanner openPopup={openPopup} />
  );
};

HeroBanner.propTypes = {
  content: PropTypes.shape({
    identifier: PropTypes.oneOf([
      'promoBanner',
      'promoBannerL',
      'targetedPromoBannerList',
      'carousel',
    ]),
    components: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  targetAudience: PropTypes.string,
  openPopup: PropTypes.func.isRequired,
};

export default HeroBanner;
