import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  getTradeInCalculatorDevicesList,
  getTradeInCalculatorDevicesListSuccess,
  getTradeInCalculatorDevicesListError,
  getTradeInCalculatorDeviceQuestions,
  getTradeInCalculatorDeviceQuestionsSuccess,
  getTradeInCalculatorDeviceQuestionsError,
  getTradeInCalculatorDeviceUpdatedPrice,
  getTradeInCalculatorDeviceUpdatedPriceSuccess,
  getTradeInCalculatorDeviceUpdatedPriceError,
  setIsLoadingDeviceQuestions,
  setIsLoadingDevicePrice,
} from './actions';
import { selectTradeInDeviceCode } from './selectors';
import request, { postRequest } from '../../../utils/request';
import getEnvVar from '../../lib/envvars';

export function* tradeInCalculatorGetDevicesListEvent({ payload }) {
  const { deviceName } = payload;

  const url = `${getEnvVar(
    'apiBaseUrl',
  )}v1/trade-in/devices?searchValue=${deviceName}`;

  try {
    const response = yield call(request, url);
    if (!response.length) {
      return yield put(getTradeInCalculatorDevicesListError());
    }
    return yield put(getTradeInCalculatorDevicesListSuccess(response));
  } catch (error) {
    return yield put(getTradeInCalculatorDevicesListError());
  }
}

export function* tradeInCalculatorGetDeviceQuestionsEvent({ payload }) {
  const { estimatedPrice, deviceCode, deviceImage, deviceModel } = payload;

  const url = `${getEnvVar(
    'apiBaseUrl',
  )}v1/trade-in/questions?modelCode=${deviceCode}`;

  try {
    yield put(setIsLoadingDeviceQuestions(true));
    const response = yield call(request, url);

    // request function doesn't handle the errors (like status 4XX or 5XX)
    if (response?.errors?.length) {
      return yield put(getTradeInCalculatorDeviceQuestionsError());
    }

    // Adding the "sku" as TRUE
    // it means initially all questions are checked as true
    // so the user will be able to uncheck any question (device condition)
    // We filter to remove any question that is not a Yes or No answer
    const deviceQuestions = response.reduce((acc, res) => {
      if (res.questionType === 'SINGLE_SELECT') {
        acc.push({ ...res, sku: true });
      }
      return acc;
    }, []);
    return yield put(
      getTradeInCalculatorDeviceQuestionsSuccess({
        deviceCode,
        deviceImage,
        deviceModel,
        estimatedPrice:
          typeof estimatedPrice === 'string'
            ? parseFloat(estimatedPrice)
            : estimatedPrice,
        deviceQuestions,
      }),
    );
  } catch (error) {
    return yield put(getTradeInCalculatorDeviceQuestionsError());
  } finally {
    yield put(setIsLoadingDeviceQuestions(false));
  }
}

export function* tradeInCalculatorDeviceUpdatedPriceEvent({ payload }) {
  const { deviceQuestions } = payload;

  const deviceCode = yield select(selectTradeInDeviceCode());

  const url = `${getEnvVar(
    'apiBaseUrl',
  )}v1/trade-in/price?modelCode=${deviceCode}`;

  const bodyRequest = [];

  deviceQuestions.map(condition =>
    bodyRequest.push({
      skuCode: condition.sku ? 'Y' : 'N',
      questionCode: condition.questionCode,
      answerText: condition.sku ? 'Yes' : 'No',
    }),
  );

  try {
    yield put(setIsLoadingDevicePrice(true));
    const response = yield call(postRequest, url, {
      body: JSON.stringify({ questionsAndAnswers: [...bodyRequest] }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // postRequest function doesn't handle the errors (like status 4XX or 5XX)
    if (response?.errors?.length) {
      return yield put(getTradeInCalculatorDeviceUpdatedPriceError());
    }

    const { amount } = response;
    return yield put(
      getTradeInCalculatorDeviceUpdatedPriceSuccess({
        adjustedPrice: amount,
        deviceQuestions,
      }),
    );
  } catch (error) {
    return yield put(getTradeInCalculatorDeviceUpdatedPriceError());
  } finally {
    yield put(setIsLoadingDevicePrice(false));
  }
}

export default function* tradeInCalculatorSaga() {
  yield takeLatest(
    getTradeInCalculatorDevicesList,
    tradeInCalculatorGetDevicesListEvent,
  );
  yield takeLatest(
    getTradeInCalculatorDeviceUpdatedPrice,
    tradeInCalculatorDeviceUpdatedPriceEvent,
  );
  yield takeLatest(
    getTradeInCalculatorDeviceQuestions,
    tradeInCalculatorGetDeviceQuestionsEvent,
  );
}
