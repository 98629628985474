import styled from 'styled-components';
import freedomTheme from 'web_component_library/theme/freedom';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import { Box } from 'rebass/styled-components';

const { colors } = freedomTheme;

const { pureBlack, freedomCharcoal } = colors;

export const Panel = styled(Box)`
  height: 100vh;
  position: absolute;
  text-align: center;
`;

export const StickyContainer = styled(Box)`
  position: relative;
  height: 100vh;
  background: ${({ backgroundColor }) => backgroundColor || pureBlack};
  text-align: center;
  overflow: hidden;
`;

export const PanelsContainer = styled(Box)`
  height: 100vh;
  position: absolute;
`;

export const PanelImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  object-fit: cover;
`;

export const ContentWrapper = styled(Box)`
  position: relative;
  height: 100%;
`;

export const ForegroundImage = styled.img`
  z-index: 50;
  height: 75vh;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const HeaderWrapper = styled(Box)`
  position: relative;
  z-index: 55;
  margin: 0 auto;

  ${MediaQuery.desktop`
    max-width: 640px;
  `}
`;

export const IconSpacer = styled(Box)`
  border-bottom: 4px solid ${freedomCharcoal};
`;

export const IconTab = styled(Box)`
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: ${props => props.color};
  img {
    height: 35px;
    width: 40px;
  }
`;
