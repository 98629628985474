import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ModalContext } from '../common/components/modal/modalContext';
import Modal from '../common/components/modalComponent';
import { selectPopupModals } from '../common/containers/contentfulHoc/selectors';

// `withPopup` adds an openPopup prop to your component,
// linking the component to the modal context
const withPopup = Component => props => {
  const popupModals = useSelector(selectPopupModals());
  const { showModal } = useContext(ModalContext);
  const openPopup = modalIdentifier => {
    showModal(Modal, popupModals[modalIdentifier]);
  };
  return <Component openPopup={openPopup} {...props} />;
};

export default withPopup;
