import { naturalToKebab, snakeToKebab } from '../../../utils/stringHelpers';
import { ERROR, PAGE_NOT_FOUND, PAGE_SUPPORT_BASE } from '../../constants';

const getLargestHeading = headings => {
  const sortedHeadings = [...headings].sort((headingA, headingB) => {
    if (headingA.localName > headingB.localName) return 1;
    if (headingA.localName < headingB.localName) return -1;
    return 0;
  });
  return sortedHeadings[0];
};

export const getPageName = page => {
  if (!page) return null;

  if (page.pageName === ERROR) return snakeToKebab(PAGE_NOT_FOUND);
  const isMainSupportPage = page.url === PAGE_SUPPORT_BASE;
  if (page.isSupport && !isMainSupportPage) {
    const supportHeading = document.querySelector('h1, h2, h3');
    return naturalToKebab(supportHeading?.innerText);
  }

  return naturalToKebab(page.pageBreadcrumb);
};

export const getModuleTitle = (container, groupRowTitle, contentfulOptions) => {
  // FIRST CHOICE: Return largest heading under the assumption that it represents the title
  const allHeadings = Array.from(
    container.querySelectorAll('h1, h2, h3, h4, h5, h6'),
  );
  const largestHeading = getLargestHeading(allHeadings);
  if (largestHeading?.innerText) {
    return naturalToKebab(largestHeading.innerText);
  }

  // SECOND CHOICE: Use group row heading
  if (groupRowTitle) return naturalToKebab(groupRowTitle);

  // FALLBACK: Return internal Contentful title
  const contentfulTitle = contentfulOptions.find(
    option => typeof option === 'string' && option.length,
  );
  if (contentfulTitle) {
    // pre-pended with "ct-" to denote title comes from Contentful
    return `ct-${naturalToKebab(contentfulTitle)}`;
  }

  return null;
};

// Using the path in which the click event bubbles up through parents,
// if 1 parent element is a button or anchor or checkbox,
// then we assume the user must have clicked on a CTA.
export const findCta = bubblingPath => {
  if (!bubblingPath) return null;

  for (let i = 0; i < bubblingPath.length; i += 1) {
    const elem = bubblingPath[i];
    if (
      elem.localName === 'button' ||
      elem.localName === 'a' ||
      elem.type === 'checkbox' ||
      elem.getAttribute?.('role') === 'button'
    ) {
      return elem;
    }
    if (elem.getAttribute?.('data-radio')) {
      return elem.querySelector('input[type="radio"]');
    }
  }
  return null;
};

export const getCtaText = (cta, type, customCtaTextKey) => {
  const expanded = type === 'accordion' && cta.getAttribute('aria-expanded');
  // for enhanced images, image buttons, etc.
  const ctaImage = !cta.innerText && cta.querySelector('img');
  const ctaPosition = cta.getAttribute('data-position');
  const customCtaLabel = cta.getAttribute('data-label');

  let ctaText;
  switch (type) {
    case 'accordion':
      // values are opposite b/c the event happens AFTER this function runs
      if (expanded === 'true') ctaText = 'hide';
      else if (expanded === 'false') ctaText = 'show';
      else ctaText = cta.innerText;
      break;
    default:
      if (ctaImage) {
        ctaText = ctaImage.alt;
      } else if (cta.type === 'checkbox') {
        ctaText = cta.checked ? 'on' : 'off';
      } else if (cta.type === 'radio') {
        const label = document.querySelector(`label[for="${cta.id}"]`);
        ctaText = label.innerText;
      } else if (ctaPosition) {
        ctaText = `${cta.innerText}-${ctaPosition}`;
      } else if (customCtaLabel) {
        ctaText = customCtaLabel;
      } else {
        ctaText = cta[customCtaTextKey] || cta.innerText;
        // This checks if the ctaText only contains special characters to use the aria label of the cta instead of the inner text.
        ctaText =
          /^[^a-zA-Z0-9]+$/.test(ctaText) || !ctaText ? cta.ariaLabel : ctaText;
      }
  }
  return naturalToKebab(ctaText);
};

// Traverses ABOVE a DOM node (previous siblings) and UP the DOM tree (parents) in search of a heading tag
// NOTE: This doesn't search through cousins (i.e. elements that are children of a parent's siblings)
// b/c it could mistakenly grab a cousin that is too distant from the starting element
export const getClosestHeading = (startingElement, elementToStopAt) => {
  const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const isHeading = tag => headingTags.includes(tag);

  let currentElement = startingElement;
  while (currentElement !== elementToStopAt) {
    if (isHeading(currentElement.localName)) {
      return naturalToKebab(currentElement.innerText);
    }

    const { previousElementSibling, parentElement } = currentElement;
    currentElement = previousElementSibling || parentElement;
  }

  return null;
};
