import React from 'react';
import PropTypes from 'prop-types';
import Box from 'web_component_library/box';
import Button from 'web_component_library/button';
import A11yContent from 'web_component_library/A11yContent';
import freedomTheme from 'web_component_library/theme/freedom';
import { LeftArrowIcon, RightArrowIcon, PauseIcon, PlayIcon } from './icons';
const { colors } = freedomTheme;

export const DotButton = props => {
  const { selectSlideText, ...restProps } = props;

  return (
    <Button variant="none" {...restProps}>
      <A11yContent>{selectSlideText}</A11yContent>
    </Button>
  );
};

DotButton.propTypes = {
  selectSlideText: PropTypes.string.isRequired,
};

export const PlayPauseButton = ({
  onClick,
  isRunning,
  playText,
  pauseText,
}) => (
  <Button
    variant="none"
    css={{
      zIndex: 10,
      width: '1.5rem',
      height: '1.5rem',
      border: `1px solid ${colors.black}`,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.accessibleBlue,
      padding: '6px',
      marginTop: '4px',
    }}
    onClick={onClick}
  >
    {isRunning ? <PauseIcon /> : <PlayIcon />}
    <A11yContent>{isRunning ? pauseText : playText}</A11yContent>
  </Button>
);

PlayPauseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isRunning: PropTypes.bool.isRequired,
  playText: PropTypes.string.isRequired,
  pauseText: PropTypes.string.isRequired,
};

const defaultOnlick = () => {};
export const NavArrow = ({
  children,
  onClick = defaultOnlick,
  isPrevArrow = false,
  isNextArrow = false,
  disabled = false,
  variant = null,
}) => (
  <Box
    display={['none', 'block']}
    position="absolute"
    css={{ 'z-index': '1' }}
    left={isPrevArrow && 10}
    right={isNextArrow && 10}
    bottom="calc(50% - 25px)"
  >
    <Button
      onClick={onClick}
      disabled={disabled}
      variant="none"
      bg="transparent"
    >
      {isPrevArrow && <LeftArrowIcon variant={variant} />}
      {isNextArrow && <RightArrowIcon variant={variant} />}
      <A11yContent>{children}</A11yContent>
    </Button>
  </Box>
);

NavArrow.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isPrevArrow: PropTypes.bool,
  isNextArrow: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};
