import styled from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import { Box } from 'rebass/styled-components';

export const OverLappingCardSection = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
  height: 100%;
  width: 100%;
  ${MediaQuery.desktop`
    flex-direction: ${props => (props.isReverse ? 'row-reverse' : 'row')};
  `};
`;

export const TextBlockSection = styled(Box)`
  position: relative;
  z-index: 2;
  background-color: white;
  ${MediaQuery.desktop`
    position: absolute;
    width: 50%;
    left: ${props => (props.isReverse ? '0' : '50%')}
    bottom: 0;
    max-height: 30.625em;
  `};
`;

export const ImageBlock = styled(Box)`
  position: relative;
  z-index: 1;
  width: 100%;
  ${MediaQuery.desktop`
    width: 66.66%;
  `}
`;

export const ImageWrapper = styled(Box)`
  background: url(${props => props.src}?w=576);
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 15em;
  max-height: 21.375em;
  ${MediaQuery.desktop`
    background: url(${props => props.src}?w=1152);
    min-height: 40em;
    max-height: 40em;
  `}
`;

export const TextBlockInner = styled(Box)`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 1.5em;
  ${MediaQuery.desktop`
    padding: 6.875em 4.375em;
  `};
`;

export const TextBlockOuter = styled(Box)`
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  position: relative;
  z-index: 10;
  align-items: left;
  justify-content: center;
`;
