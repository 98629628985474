import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box as BaseBox } from 'rebass/styled-components';

export const Box = styled(BaseBox)`
  align-self: ${props => (props.isCenterAligned ? 'center' : 'normal')};
`;

Box.propTypes = {
  isCenterAligned: PropTypes.bool,
};
