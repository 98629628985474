export default class QuestionAndAnswer {
  constructor({
    identifier,
    title,
    askAwayButton,
    askQuestionsSection,
    questionsList,
  }) {
    this.identifier = identifier;
    this.title = title;
    this.askAwayButton = askAwayButton;
    this.askQuestionsSection = askQuestionsSection;
    this.questionsList = questionsList;
  }

  static instance = data => {
    if (data?.fields) {
      return new QuestionAndAnswer(data.fields);
    }
    return null;
  };
}
