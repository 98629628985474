import styled from 'styled-components';
import { Paragraph as BaseParagraph } from 'web_component_library/typography';
import { Button as BaseButton } from 'rebass/styled-components';
export const Paragraph = styled(BaseParagraph)`
  word-wrap: break-word;
`;

export const Button = styled(BaseButton)`
  background: none;
  border: none;
  cursor: pointer;
  width: unset;
  padding: 0;
  &:hover {
    background-image: none;
  }
`;
