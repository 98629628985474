export default class Promotion {
  constructor(data) {
    if (data && data.fields) {
      const { title, titleRichText, color, value, width } = data.fields;
      this.title = title;
      this.titleRichText = titleRichText || null;
      this.color = color || null;
      this.value = value || null;
      this.width = width || null;
    }
  }
}
