import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import qs from 'qs';
import { Box } from 'rebass/styled-components';

import CategorySearchResults from './categorySearchResults';
import QuerySearchResults from './querySearchResults';
import withTranslation from '../../containers/translationHoc';

const SupportSearchResultsPage = props => {
  const { translate } = props;
  const location = useLocation();
  const match = useRouteMatch();

  const searchQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const categorySlug = match.params?.category;
  const searchQueryQ = searchQuery.q;
  const isSelected = searchQuery.isSelected === 'true';
  const searchQuerySkip = searchQuery.skip;
  const searchResultsLimitPerPage = translate('searchResultsLimitPerPage');
  const searchResultsIsPaginationEnabled =
    translate('searchResultsIsPaginationEnabled') === 'true';

  return (
    <Box mt={[11, 15]} mb={[13, 21]} width={1}>
      {categorySlug && <CategorySearchResults categorySlug={categorySlug} />}
      {!categorySlug && searchQueryQ && searchResultsLimitPerPage && (
        <QuerySearchResults
          isSelected={isSelected}
          searchQueryQ={searchQueryQ}
          limit={parseInt(searchResultsLimitPerPage, 10) || 10}
          skip={parseInt(searchQuerySkip, 10) || 0}
          searchResultsIsPaginationEnabled={searchResultsIsPaginationEnabled}
        />
      )}
    </Box>
  );
};

SupportSearchResultsPage.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withTranslation,
)(SupportSearchResultsPage);
