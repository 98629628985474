import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'web_component_library/flex';

const defaultMobileBackgroundImageUrl = '';
const BannerContainer = ({
  backgroundImageUrl,
  mobileBackgroundImageUrl = defaultMobileBackgroundImageUrl,
  children,
}) => (
  <Flex
    sx={{
      justifyContent: 'center',
      minHeight: ['15em', '25em'],
      background: [
        `rgb(0, 0, 0) url(${mobileBackgroundImageUrl ||
          backgroundImageUrl}) repeat scroll center top / cover`,
        `rgb(0, 0, 0) url(${backgroundImageUrl}) repeat scroll center top / cover`,
      ],
      alignItems: 'center',
      flexDirection: 'column',
      backgroundRepeat: 'no-repeat',
      px: '13',
      textAlign: 'center',
      position: 'relative',
      py: '6rem',
    }}
  >
    {children}
  </Flex>
);

BannerContainer.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
  mobileBackgroundImageUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BannerContainer;
