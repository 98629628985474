import React from 'react';
import PropTypes from 'prop-types';

import { ModalConsumer } from './modalContext';

const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, props, hideModal }) => {
      if (!Component) return null;

      const onRequestClose = () => {
        if (props.onRequestClose) {
          props.onRequestClose();
          hideModal();
        } else {
          hideModal();
        }
      };
      return <Component {...props} onRequestClose={onRequestClose} />;
    }}
  </ModalConsumer>
);

ModalRoot.propTypes = {
  onRequestClose: PropTypes.func,
};

export default ModalRoot;
