import Cookies from 'js-cookie';
import { TEALIUM_TARGET_AUDIENCE } from '../common/constants';
import TargetedList from '../common/containers/contentful/targetedList';
import { getSsrQueryParams } from './ssrHelpers';

// Gets the freshest target audience value directly from Tealium
export const getTargetAudience = () => {
  if (
    typeof window !== 'undefined' &&
    window.utag &&
    window.utag.data &&
    window.utag.data[TEALIUM_TARGET_AUDIENCE] &&
    window.utag.data[TEALIUM_TARGET_AUDIENCE] !== 'unknown'
  ) {
    return window.utag.data[TEALIUM_TARGET_AUDIENCE];
  }

  return null;
};

// Gets a target audience value guaranteed to be shared between server-side and client-side
export const getTargetAudienceCookie = () =>
  typeof window !== 'undefined'
    ? Cookies.get('tealiumTargetAudience')
    : global.tealiumTargetAudience;

export const getTargetedComponent = (
  { componentOptions, defaultComponent, allowedBrands },
  targetAudience,
) => {
  // Allows targeted component to render if at least one of the following is true:
  // * Brand query param does not exist
  // * Targeted list does not specify any allowed brands (so accepts all)
  // * The brand query param is included in the allowed brands
  const { brand: brandQuery } = getSsrQueryParams();
  const isBrandAllowed =
    !brandQuery || !allowedBrands || allowedBrands.includes(brandQuery);
  if (!isBrandAllowed) return null;

  if (!targetAudience) return defaultComponent;

  // This returns the FIRST component that matches target audience
  const targetedComponent = componentOptions?.find(option =>
    option.targetedAudiences.some(audience => audience.name === targetAudience),
  );

  if (!targetedComponent) return defaultComponent;

  return targetedComponent.component;
};

export const getComponentToRender = (array, component, targetAudience) => {
  if (component && component.fields) {
    const isTargetedList =
      component.fields.identifier === 'targetedList' ||
      component.fields.identifier === 'targetedGroupRowList' ||
      component.fields.identifier === 'targetedPromoBannerList';

    let componentToRender;
    if (isTargetedList) {
      const targetedComponent = getTargetedComponent(
        new TargetedList(component),
        targetAudience,
      );
      if (targetedComponent) {
        componentToRender = {
          ...targetedComponent.fields,
          id: targetedComponent.sys.id,
        };
      }
    } else {
      componentToRender = { ...component.fields, id: component.sys.id };
    }

    if (componentToRender) return [...array, componentToRender];
  }
  return array;
};
