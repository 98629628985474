import styled from 'styled-components';
import freedomTheme from 'web_component_library/theme/freedom';
import RestrictedBox from 'web_component_library/restrictedBox';
import { Box } from 'rebass/styled-components';

const { pureWhite, pureBlack } = freedomTheme.colors;

export const SectionCards = styled(Box)`
  background-color: ${({ themeVariant }) =>
    themeVariant === 'light' ? 'transparent' : pureBlack};
`;

export const WrapperCards = styled(RestrictedBox)`
  background-color: ${({ themeVariant }) =>
    themeVariant === 'light' ? 'transparent' : pureBlack};
`;

export const SectionPanel = styled(Box)`
  background-color: ${({ themeVariant }) =>
    themeVariant === 'light' ? pureWhite : pureBlack};
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: ${({ themeVariant }) =>
      themeVariant === 'light' ? pureBlack : pureWhite};
  }
`;

export const WrapperPanel = styled(RestrictedBox)`
  background-color: ${({ themeVariant }) =>
    themeVariant === 'light' ? pureWhite : pureBlack};
`;
