import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

import MediaQuery from 'web_component_library/utils/mediaQuery';

export const Wrapper = styled(Box)`
  .scrollmagic-pin-spacer {
    will-change: height, padding-top, padding-bottom, width;
  }
`;

export const Sticky = styled(Box)`
  height: 100vh;
  text-align: center;
  position: relative;
  overflow: hidden;
  will-change: position, top, padding-top;

  ${MediaQuery.desktop`
    height: calc(100vh - 80px);
  `};
`;

export const BgContainer = styled(Box)`
  height: 100vh;
  max-height: calc(100vh - 17rem);
  position: absolute;
  bottom: 0;
  z-index: 1;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  ${MediaQuery.desktop`
    max-height: none;
    height: calc(90vh - 80px);
    left: -1rem;
  `};
`;

const BgImageBase = styled(Box)`
  height: 100%;
  position: absolute;
  bottom: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  will-change: opacity;
`;

export const BgImage = styled(BgImageBase)`
  height: 90%;
  z-index: 1;
`;

export const BgImageForeground = styled(BgImageBase)`
  z-index: 2;
`;

export const Bullet = styled(Flex)`
  position: absolute;
  z-index: 2;
  opacity: 0;
  font-weight: bold;
  top: 1rem;
  left: 5%;
  width: 90%;
  text-align: left;
  height: 15rem;
  will-change: opacity, transform;

  ${MediaQuery.desktop`
    left: 50%;
    width: 45%;
    top: auto;
    max-width: 434px;
    bottom: 0;
    height: calc(90vh - 80px);
  `};
`;

export const BulletInner = styled(Box)`
  ${MediaQuery.desktop`
    position: relative;
    top: -12%;
    width: 100%;
    height: auto;
  `};
`;

export const TextWrapper = styled(Box)`
  text-align: center;

  ${MediaQuery.desktop`
    text-align: left;
  `};
`;
