import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AddOn from '../contentful/addOn';

import { selectAddOns, selectLocale } from '../contentfulHoc/selectors';
import { getAddOns } from '../contentfulHoc/actions';

import Addon from '../../components/addon';

export const Addons = ({
  addOns,
  infoSection,
  onGetAddOns,
  locale,
  identifier,
}) => {
  useEffect(() => {
    if (!addOns.length) {
      onGetAddOns(locale);
    }
  }, []);

  if (!addOns.length) return null;

  return (
    <React.Fragment>
      <Addon
        addOns={addOns}
        infoSection={infoSection}
        identifier={identifier}
      />
    </React.Fragment>
  );
};

Addons.propTypes = {
  addOns: PropTypes.arrayOf(PropTypes.instanceOf(AddOn)).isRequired,
  locale: PropTypes.string.isRequired,
  onGetAddOns: PropTypes.func.isRequired,
  infoSection: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    nodeType: PropTypes.string,
  }).isRequired,
  identifier: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  addOns: selectAddOns(),
  locale: selectLocale(),
});

const mapDispatchToProps = dispatch => ({
  onGetAddOns: payload => dispatch(getAddOns(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Addons);
