import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from 'web_component_library/textBlock';
import { HeadingWrappper } from './css';

const defaultTextBlockBackground = 'transparent';
const defaultTextBlockVariant = 'light';
const defaultTextAlignment = 'center';
const defaultDesignVariant = '';

const PageSubHeading = ({
  textBlockBackground = defaultTextBlockBackground,
  headingData,
  textBlockVariant = defaultTextBlockVariant,
  textAlignment = defaultTextAlignment,
  designVariant = defaultDesignVariant,
}) => {
  let headingMargins = { mb: [7] };
  let wrapperMargings = { mt: [17, 5], mb: [7, 29] };
  if (designVariant === 'forBenefits') {
    headingMargins = { mt: 6, mb: 5 };
    wrapperMargings = { mt: [17, '2.75em'], mb: [17, '4.25em'] };
  }
  return (
    <HeadingWrappper
      headingPosition={textAlignment}
      bg={textBlockBackground}
      width={1}
      wrapperMargings
      mt={wrapperMargings.mt}
      mb={wrapperMargings.mb}
    >
      <TextBlock
        headingStyles={headingMargins}
        data={headingData}
        blockVariant={textBlockVariant}
      />
    </HeadingWrappper>
  );
};

PageSubHeading.propTypes = {
  textBlockBackground: PropTypes.string,
  textBlockVariant: PropTypes.string,
  headingData: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired, // RichText,
  textAlignment: PropTypes.string,
  designVariant: PropTypes.string,
};

export default PageSubHeading;
