import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import freedomTheme from 'web_component_library/theme/freedom';

const { colors } = freedomTheme;
// Rich Text, Paragraph needs to be with 0 margin top in cities.
// City Color is black for now so overriding Typography
export const City = styled(Box)`
  margin-right: 0;
  margin-left: 0;
  p {
    color: ${colors.black};
    margin-top: 0;
    margin-bottom: 0;
  }
`;
