import React, { useEffect, useState } from 'react';
import qs, { stringify } from 'qs';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from 'web_component_library/button';
import MediaFile from 'web_component_library/utils/models/mediaFile';
import Box from 'web_component_library/box';
import Flex from 'web_component_library/flex';
import Icon from 'web_component_library/icon';
import { Paragraph } from 'web_component_library/typography';
import TextBlock from 'web_component_library/textBlock';
import Link from 'web_component_library/link';
import A11yContent from 'web_component_library/A11yContent';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from './loader';

import BannerContainer from '../compatibilityCheckSelection/bannerContainer';
import {
  selectCompatibilityCheckOptions,
  selectLocale,
} from '../contentfulHoc/selectors';
import { getCompatibilityCheckOptions } from '../contentfulHoc/actions';
import { launchLiveChat } from '../../../utils/liveChatHelper';
import { isAbsolutePath } from '../../components/ctaAnalyticsHoc';
import { SITE_NAME } from '../../constants';
import { naturalToKebab } from '../../../utils/stringHelpers';

const defaultBackgroundImageMobile = {};
const defaultCompatibilityCheckOptions = {};
const defaultByopCompatibleDeviceButtons = [];
const defaultImeiResultSpecificBrand = [];
const defaultImeiResultGenericPage = [];
const defaultBrandLocation = '';

const CompatibilityCheckResult = ({
  compatibleByopHeaderTextBlock,
  compatibilityCheckOptions = defaultCompatibilityCheckOptions,
  compatibilityCheckerUrl,
  locale,
  backgroundImage,
  onGetCompatibilityCheckOptions,
  featuresDescriptions,
  imeiCheckerUrl,
  backgroundImageMobile = defaultBackgroundImageMobile,
  byopCompatibleDeviceButtonsUsingReference = null,
  byopCompatibleDeviceButtons = defaultByopCompatibleDeviceButtons,
  imeiResultGenericPage = defaultImeiResultGenericPage,
  imeiResultSpecificBrand = defaultImeiResultSpecificBrand,
  brandLocation = defaultBrandLocation,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [featuresToRender, setFeaturesToRender] = useState([]);
  const [pageContent, setPageContent] = useState(null);

  const backgroundUrl = new MediaFile(backgroundImage).url;
  const mobileBackgroundImageUrl = backgroundImageMobile
    ? new MediaFile(backgroundImageMobile).url
    : '';

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  // For BYOP Compatibility Result Page there are basically 2 main scenarios
  // 01 - The user come from BYOP Compatibility Check page
  // 02 - The user come from IMEI Check page and here there are 2 conditionals:
  // isCompatible === true (imei compatible) or isCompatible === false (imei not compatible)

  // Only users from IMEI Check page have isCompatible URL search param
  const { isCompatible } = queryParams;
  const isImeiFlow = !!isCompatible;
  const isImeiCompatible = JSON.parse(isCompatible || false);

  useEffect(() => {
    if (!Object.keys(compatibilityCheckOptions).length)
      onGetCompatibilityCheckOptions(locale);
  }, []);

  useEffect(() => {
    // 01 -> BYOP Compatibility FLOW STARTS HERE
    if (!isImeiFlow && Object.keys(compatibilityCheckOptions).length) {
      const { brand, model, purchaseLocation, ref } = queryParams;

      const params = { ...queryParams };

      if (ref) {
        const url = new URL(ref);

        if (!url.host.endsWith(SITE_NAME)) {
          delete params.ref;
          history.replace({
            pathname: location.pathname,
            search: stringify(params),
          });
        }
      }

      // If the user came from BYOP Compatibility Page or lands directly on this page
      // The URL must contain valid search options otherwise the user must be redirect back
      // to BYOP Compatibility Page
      if (!paramsAreValid(brand, model, purchaseLocation)) {
        history.replace({
          pathname: compatibilityCheckerUrl,
          search: params?.ref ? `?ref=${params?.ref}` : '',
        });
      } else {
        // If the app receives a "ref" on location.search
        // the CTA (byopCompatibleDeviceButtonsUsingReference) must have the url value replaced by this "ref"
        if (params?.ref) {
          Object.assign(byopCompatibleDeviceButtonsUsingReference.fields, {
            url: params.ref,
          });
        }

        // setup the correct page content
        setPageContent(prevState => ({
          ...prevState,
          ctas: [
            byopCompatibleDeviceButtonsUsingReference,
            ...byopCompatibleDeviceButtons,
          ],
          header: compatibleByopHeaderTextBlock,
        }));

        getByopFeaturesToRender({ brand, model, purchaseLocation });
      }
    }
    // 01 -> BYOP Compatibility Resul FLOW ENDS HERE
  }, [Object.keys(compatibilityCheckOptions).length]);

  useEffect(() => {
    // 02 -> IMEI FLOW STARTS HERE
    if (isImeiFlow) {
      const { ref } = queryParams;

      const getImeiParams = Object.keys(queryParams);
      // If the URL search has any other param besides brand and ref that is not a boolean value
      // the user must be redirect to the IMEI Check Page
      getImeiParams.forEach(value => {
        if (
          value !== 'brand' &&
          value !== 'ref' &&
          (queryParams[value] !== 'true' && queryParams[value] !== 'false')
        ) {
          history.replace({
            pathname: imeiCheckerUrl,
            search: `${ref ? `?ref=${ref}` : ''}`,
          });
        }
      });

      // On Compatibility Check Result (contentful) is possible to add trasnlations and ctas
      // to specific brands (IMEI Result Specific Brand) like Appe, Samsung, etc...
      const specificBrandContent = imeiResultSpecificBrand.find(
        ({ fields }) => fields.brand === queryParams.brand,
      )?.fields;

      const { fields } = imeiResultGenericPage;

      const getImeiContent = specificBrandContent || fields;

      const finalContent = {};

      if (isImeiCompatible) {
        // If the app receives a "ref" on location.search
        // the CTA (imeiCompatibleDeviceButtonsUsingReference)
        // will have the url value replaced by this "ref"
        if (ref) {
          Object.assign(
            getImeiContent.imeiCompatibleDeviceButtonsUsingReference.fields,
            { url: ref },
          );
        }
        // setup the correct page content
        Object.assign(finalContent, {
          ctas: getImeiContent.imeiCompatibleDeviceButtons
            ? [
                getImeiContent.imeiCompatibleDeviceButtonsUsingReference,
                ...getImeiContent.imeiCompatibleDeviceButtons,
              ]
            : [getImeiContent.imeiCompatibleDeviceButtonsUsingReference],
          header: getImeiContent.compatibleImeiHeaderText,
        });
      } else {
        // If the app receives a "ref" on location.search
        // the CTA (imeiNotCompatibleDeviceButtonsUsingReference)
        // will have the url value replaced by this "ref"
        if (ref) {
          Object.assign(
            getImeiContent.imeiNotCompatibleDeviceButtonsUsingReference.fields,
            { url: ref },
          );
        }
        // setup the correct page content
        Object.assign(finalContent, {
          ctas: getImeiContent.imeiNotCompatibleDeviceButtons
            ? [
                getImeiContent.imeiNotCompatibleDeviceButtonsUsingReference,
                ...getImeiContent.imeiNotCompatibleDeviceButtons,
              ]
            : [getImeiContent.imeiNotCompatibleDeviceButtonsUsingReference],
          header: getImeiContent.notCompatibleImeiHeaderText,
        });
      }

      setPageContent(prevState => ({ ...prevState, ...finalContent }));
      getImeiFeaturesToRender(getImeiParams);
    }
    // 02 -> IMEI FLOW ENDS HERE
  }, [location.search]);

  const paramsAreValid = (brand, model, purchaseLocation) => {
    const {
      modelOptions,
      brandsOptions,
      locationOptions,
    } = compatibilityCheckOptions;

    const isPhoneValid =
      brand && brandsOptions?.some(phoneOption => phoneOption === brand);
    const isLocationValid =
      purchaseLocation &&
      (purchaseLocation === brandLocation ||
        locationOptions?.some(
          locationOption => locationOption.name === purchaseLocation,
        ));

    const isModelValid =
      model &&
      modelOptions?.some(
        modelOption =>
          modelOption.brand === brand && modelOption.modelName === model,
      );

    return isPhoneValid && isLocationValid && isModelValid;
  };

  const getByopFeaturesToRender = ({ brand, model, purchaseLocation }) => {
    let modelFeatures = location.state?.modelFeatures;
    if (!modelFeatures) {
      const isOtherButton = brand === 'Other';
      const { modelOptions, locationOptions } = compatibilityCheckOptions;
      const isSelectedLocationCustom = purchaseLocation === brandLocation;
      let selectedLocationId;
      if (isSelectedLocationCustom) {
        selectedLocationId = 'freedomModel';
      } else {
        selectedLocationId = locationOptions.find(
          locationOption => locationOption.name === purchaseLocation,
        ).locationId;
      }
      const locationId = isOtherButton
        ? locationOptions[0]
        : selectedLocationId;

      modelFeatures = modelOptions.find(
        modelOption =>
          modelOption.brand === brand &&
          (isOtherButton || modelOption.modelName === model),
      ).features[locationId].fields;
    }

    if (modelFeatures.requireImeiCheck) {
      history.replace({
        pathname: imeiCheckerUrl,
        search: '',
      });
      return;
    }

    const finalFeaturesToRender = [];
    featuresDescriptions.forEach(feature => {
      if (feature.fields) {
        const {
          featureId,
          featureMainDescription,
          featureSecondaryText,
        } = feature.fields;
        const featureToRender = {
          featureMainDescription,
          featureSecondaryText,
          isCompatible: modelFeatures[featureId],
        };
        if (modelFeatures[featureId] !== undefined)
          finalFeaturesToRender.push(featureToRender);
      }
    });
    setFeaturesToRender(finalFeaturesToRender);
  };

  const getImeiFeaturesToRender = imeiFeatures => {
    const imeiFeatureListToRender = [];
    featuresDescriptions.forEach(feature => {
      if (feature.fields) {
        const {
          featureId,
          featureMainDescription,
          featureSecondaryText,
        } = feature.fields;
        if (imeiFeatures.includes(featureId)) {
          const featureToRender = {
            featureMainDescription,
            featureSecondaryText,
            isCompatible:
              new URLSearchParams(location.search).get(featureId) === 'true'
                ? true
                : null,
          };
          imeiFeatureListToRender.push(featureToRender);
        }

        setFeaturesToRender(imeiFeatureListToRender);
      }
    });
  };

  if (!featuresToRender.length || !pageContent) {
    return <Loader />;
  }

  const onClickHandler = cta => {
    if (cta.url && isAbsolutePath(cta.url)) {
      window.location.href = cta.url;
    } else if (cta.url && !isAbsolutePath(cta.url)) {
      history.push(cta.url);
    } else {
      launchLiveChat(
        null,
        cta.chatEngagementId,
        cta.appleChatBizIntentId,
        cta.appleChatBizGroupId,
        cta.appleChatBody,
        cta.isAppleChatOn,
      );
    }
  };

  return (
    <Box pb={17}>
      <BannerContainer
        backgroundImageUrl={backgroundUrl}
        mobileBackgroundImageUrl={mobileBackgroundImageUrl}
      >
        <Box width={[1, 0.65]}>
          <TextBlock
            headingStyles={{ pb: [0, 7], mb: 7 }}
            data={pageContent.header}
            blockVariant="dark"
          />
        </Box>
      </BannerContainer>
      <Box py={7} maxWidth="1104px" mx="auto">
        <Flex
          as="ul"
          flexDirection="column"
          justifyContent="center"
          px={[13, 0]}
          mb={17}
        >
          {featuresToRender.map(feature => (
            <Flex
              as="li"
              justifyContent="center"
              flexDirection="column"
              width={[1, 1 / 2]}
              mx="auto"
              mt={7}
              key={feature.featureMainDescription}
            >
              <Flex alignItems="baseline" justifyContent="space-between">
                <Paragraph width={3 / 4} pr={7} isStrong>
                  {feature.featureMainDescription}
                </Paragraph>

                <Icon
                  color="blue"
                  variant={
                    feature.isCompatible ? 'icon_checkmark' : 'icon_close'
                  }
                  size={1}
                />
                <A11yContent as="span">
                  {feature.isCompatible ? 'is compatible' : 'is not compatible'}
                </A11yContent>
              </Flex>
              <Paragraph my={0}>{feature.featureSecondaryText}</Paragraph>
            </Flex>
          ))}
        </Flex>
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          flexDirection={['column', 'row']}
          px={[7, 0]}
          sx={{
            a: {
              minWidth: '10rem',
              width: ['100%', 'unset'],
            },
            button: {
              minWidth: '10rem',
              width: ['100%', 'unset'],
            },
            '*:last-child': {
              minWidth: '100%',
              textAlign: 'center',
              justifyContent: 'center',
            },
          }}
        >
          {pageContent.ctas.map(cta => (
            <Button
              id={
                cta.chatEngagementId ||
                naturalToKebab(cta.fields.value || cta.fields.title)
              }
              key={cta.fields.value || cta.fields.title}
              as={cta.fields.url ? Link : null}
              to={cta.fields.url || null}
              mx={[0, 5]}
              mt={13}
              onClick={() => onClickHandler(cta.fields)}
            >
              {cta.fields.value || cta.fields.title}
            </Button>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

CompatibilityCheckResult.propTypes = {
  onGetCompatibilityCheckOptions: PropTypes.func.isRequired,
  compatibilityCheckOptions: PropTypes.shape({
    brandsOptions: PropTypes.arrayOf(PropTypes.string),
    modelOptions: PropTypes.arrayOf(PropTypes.object),
    locationOptions: PropTypes.arrayOf(
      PropTypes.shape({
        locationId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
  compatibleByopHeaderTextBlock: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object.isRequired,
  compatibilityCheckerUrl: PropTypes.string.isRequired,
  featuresDescriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  imeiCheckerUrl: PropTypes.string.isRequired,
  backgroundImageMobile: PropTypes.object,
  byopCompatibleDeviceButtonsUsingReference: PropTypes.object,
  byopCompatibleDeviceButtons: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object),
  }),
  imeiResultSpecificBrand: PropTypes.arrayOf(PropTypes.object),
  imeiResultGenericPage: PropTypes.arrayOf(PropTypes.object),
  brandLocation: PropTypes.string,
};
const mapStateToProps = createStructuredSelector({
  compatibilityCheckOptions: selectCompatibilityCheckOptions(),
  locale: selectLocale(),
});

const mapDispatchToProps = dispatch => ({
  onGetCompatibilityCheckOptions: locale =>
    dispatch(getCompatibilityCheckOptions({ locale })),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CompatibilityCheckResult);
