import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

export const FeedbackForm = styled(Box)`
  margin: 0 auto;
  padding: 3em 0;
  text-align: center;

  @supports (-ms-flow-from: thingy) {
    /* Edge only */
    .space-evenly {
      justify-content: space-around;
      margin: 0 2.5em;
    }
  }
`;
