import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box, Flex } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import {
  AskAwayAnswerButtonWrapper,
  AskAwayQuestionButtonWrapper,
  QuestionsListWrapper,
  Link,
} from './css';
import CTA from '../cta';
import { CtaModal } from '../../containers/contentful';

const getCTA = data => {
  const { position, url, value, variant, type } = data;
  return (
    <CTA
      position={position}
      url={url}
      value={value}
      variant={variant}
      type={type}
    />
  );
};

const QuestionAndAnswer = ({
  askAwayButton,
  askQuestionsSection,
  questionsList,
}) => {
  const cTAContent = new CtaModal(askAwayButton);
  const cTa = getCTA(cTAContent);
  return (
    <Flex
      className="restrict-content"
      flexDirection={['column', 'row']}
      py={[13, 29]}
    >
      <Box width={[1, 1 / 2]} pr={[0, 11]}>
        <TextBlock data={askQuestionsSection} />
        <AskAwayQuestionButtonWrapper mt={[0, 7]}>
          {cTa}
        </AskAwayQuestionButtonWrapper>
      </Box>
      <Box mb={11} pt={[0, 19]} width={[1, 1 / 2]}>
        {questionsList.map(question => {
          const {
            fields: { url, title },
          } = question;
          return (
            <QuestionsListWrapper key={shortid.generate()}>
              <Link to={url}>{title}</Link>
            </QuestionsListWrapper>
          );
        })}
      </Box>
      <AskAwayAnswerButtonWrapper>{cTa}</AskAwayAnswerButtonWrapper>
    </Flex>
  );
};

QuestionAndAnswer.propTypes = {
  askAwayButton: PropTypes.object.isRequired,
  askQuestionsSection: PropTypes.object.isRequired,
  questionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default QuestionAndAnswer;
