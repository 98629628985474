export default class InnerPromoBanner {
  constructor(data) {
    if (data && data.fields) {
      const {
        bannerImage,
        bannerUrl,
        backgroundStyle,
        description,
        descriptionAlignment,
        icon,
        iconAlignment,
        iconMarginRight,
        name,
        tooltipContent,
      } = data.fields;
      this.backgroundStyle = backgroundStyle || null;
      this.bannerImage = bannerImage?.fields || null;
      this.bannerUrl = bannerUrl || null;
      this.description = description;
      this.descriptionAlignment = descriptionAlignment;
      this.icon = icon?.fields || null;
      this.iconAlignment = iconAlignment;
      this.iconMarginRight = iconMarginRight;
      this.name = name;
      this.tooltipContent = tooltipContent;
    }
  }
}
