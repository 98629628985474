import { createGlobalStyle } from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    background-color: ${({ theme }) => theme.colors.softWhite};
  }

  body.ReactModal__Body--open {
    overflow:hidden;
    position:fixed;
    ${MediaQuery.desktop`
    position: relative;
  `};
  }

  /* When mouse is detected, ALL focused elements have outline removed. */
  /* You could apply this selector only to buttons, if you wanted. */
  body.using-mouse :focus {
   outline: none;
  }
  .noScroll{
    overflow: hidden;
  }

  .noTooltipScroll{
    overflow:hidden;
    position:fixed;
    ${MediaQuery.desktop`
    position: relative;
  `};
  }

  .ReactModalPortal {
    position: relative;
    z-index: ${({ theme }) => theme.zIndices[4]};
  }

  .restrict-content {
    max-width: 1104px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  p,
  span,
  label {
    line-height: 1.5em;
    font-weight: 300;
  }

  input, select, textarea {
    background-color: transparent;
    border-style: none;
    color: inherit;
    font-size: 1em;
    margin: 0;
  }

  body, h1, h2, h3, h4 {
    margin: 0;
  }

  .noPaddingLeftRight div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  `;

export default GlobalStyle;
