import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import { SectionPanel, WrapperPanel } from './css';

const FeaturesPanel = ({ headingText, featureColumnGroup, themeVariant }) => (
  <SectionPanel
    as="section"
    width={1}
    themeVariant={themeVariant}
    data-testid="features-panel"
  >
    <WrapperPanel themeVariant={themeVariant} px={7}>
      <Box width={1} pt={9} mt={17}>
        <TextBlock
          data={headingText}
          headingStyles={{ pb: 5, textAlign: 'center' }}
          paragraphStyles={{ textAlign: 'center' }}
        />
      </Box>
      <Flex
        width={1}
        py={13}
        flexDirection={['column', 'row']}
        justifyContent="space-between"
      >
        {featureColumnGroup.length > 0 &&
          featureColumnGroup.map((feature, index) => {
            const { content } = feature.fields;
            return (
              <Box
                width={[1, 1 / featureColumnGroup.length]}
                key={feature.sys.id}
                pr={[0, index !== featureColumnGroup.length - 1 ? 11 : 0]}
                py={[13, 0]}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    height: '100%',
                  }}
                >
                  <TextBlock
                    data={content}
                    headingStyles={{ pt: [7, 11], pb: [13] }}
                    buttonStyles={{
                      pt: [13],
                      pb: [7],
                      alignSelf: 'end',
                      display: ['flex', 'block'],
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  />
                </Box>
              </Box>
            );
          })}
      </Flex>
    </WrapperPanel>
  </SectionPanel>
);

FeaturesPanel.propTypes = {
  featureColumnGroup: PropTypes.array.isRequired,
  headingText: PropTypes.object.isRequired,
  themeVariant: PropTypes.string.isRequired,
};

export default FeaturesPanel;
