import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import freedomTheme from 'web_component_library/theme/freedom';
import stringInterpolation from 'web_component_library/utils/stringInterpolation';
import ProgressBar from './progressBar';
import { DotButton, PlayPauseButton } from './navArrow';

const { colors } = freedomTheme;

export const NavBar = ({
  children,
  scrollTo,
  handlePlayPause,
  isRunning,
  selectSlideText,
  playText,
  pauseText,
  selectedIndex,
  viewDuration = null,
  time = null,
  showProgressBar,
}) => (
  <Box
    data-testid="progressbarCarrousel"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: showProgressBar ? ['-48px', '-52px'] : '-42px',
    }}
  >
    <Box
      sx={{
        zIndex: 9,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: ['0', 'xs'],
        height: ['2rem', 'unset'],
      }}
    >
      {children.map((child, index) => (
        <Box
          sx={{
            marginRight: 'xs',
          }}
          key={child?.props?.entryTitle}
        >
          <DotButton
            onClick={() => scrollTo(index)}
            sx={{
              width: '1.4rem',
              height: '1.4rem',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                index === selectedIndex
                  ? colors.accessibleOrange
                  : colors.white,
              border: `1px solid ${colors.black}`,
            }}
            selectSlideText={stringInterpolation(selectSlideText, index + 1)}
          />
        </Box>
      ))}
      <PlayPauseButton
        onClick={() => {
          handlePlayPause();
        }}
        isRunning={isRunning}
        playText={playText}
        pauseText={pauseText}
      />
    </Box>
    {showProgressBar && (
      <Box
        sx={{
          zIndex: 9,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ProgressBar currentTime={time} maxTime={viewDuration} />
      </Box>
    )}
  </Box>
);

NavBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  scrollTo: PropTypes.func.isRequired,
  handlePlayPause: PropTypes.func.isRequired,
  isRunning: PropTypes.bool.isRequired,
  selectSlideText: PropTypes.string.isRequired,
  playText: PropTypes.string.isRequired,
  pauseText: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  viewDuration: PropTypes.number,
  time: PropTypes.number,
  showProgressBar: PropTypes.bool.isRequired,
};
