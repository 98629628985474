import React from 'react';
import PropTypes from 'prop-types';
import WclEnhancedImage from 'web_component_library/enhancedImage';
import { getImageUrl } from '../../../utils/imageHelper';

const defaultImage = undefined;
const defaultMobileImage = undefined;

const EnhancedImage = ({
  image = defaultImage,
  mobileImage = defaultMobileImage,
  ...props
}) => {
  const passedProps = {
    imageTitle: props.enhancedImageTitle,
    image,
    mobileImage,
    ...props,
  };

  // `placeholderUrl` is set to opt into image lazy loading
  if (image && image.fields) {
    passedProps.imageURL = image.fields.file.url;
    passedProps.lazyPlaceholder = getImageUrl(image.fields.file.url);
  }
  if (mobileImage && mobileImage.fields) {
    passedProps.mobileImageURL = mobileImage.fields.file.url;
    passedProps.lazyMobilePlaceholder = getImageUrl(
      mobileImage.fields.file.url,
    );
  }

  return <WclEnhancedImage {...passedProps} fillContainer />;
};

EnhancedImage.propTypes = {
  enhancedImageTitle: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  mobileImage: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default EnhancedImage;
