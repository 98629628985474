import styled from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import { Flex } from 'rebass/styled-components';

import { WRAPPER_SIZE } from '../../theme/style-helpers';

export const StyledFlex = styled(Flex)`
  max-width: ${props => (props.restrictContent ? `${WRAPPER_SIZE}px` : '100%')};
  padding-top: ${props => (props.cushionContent ? props.theme.space[17] : 0)};
  padding-bottom: ${props =>
    props.cushionContent ? props.theme.space[17] : 0};

  ${props =>
    props.cushionContent
      ? `
    > *:not(:last-child) {
      padding-bottom: ${props.theme.space[13]};
    }
  `
      : ''}

  ${MediaQuery.desktop`
  padding-top: ${props => (props.cushionContent ? props.theme.space[21] : 0)};
  padding-bottom: ${props =>
    props.cushionContent ? props.theme.space[21] : 0};

  ${props =>
    props.cushionContent
      ? `
    > *:not(:last-child) {
      padding-right: ${props.theme.space[21]};
    }
  `
      : ''}
  `}
`;
