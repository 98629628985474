import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import { H3 } from 'web_component_library/typography';
import Divider from 'web_component_library/divider';
import PageHeading from '../pageHeading';
import Iframe from '../Iframe';
import { Container } from './css';
import ExpandCollapse from './compatiblePhones';

const defaultTextVariant = 'light';
const defaultSplitTextBlock = { fields: {} };

const Accessibility = ({
  contentSection,
  mainDescription,
  videoHeading,
  videoLink,
  textVariant = defaultTextVariant,
  accordion,
  accordionGroup,
  splitTextBlock = defaultSplitTextBlock,
}) => {
  const { fields } = splitTextBlock;
  const { leftBlock, rightBlock } = fields;
  const stylingExpandCollapse = {
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1rem',
    divider: true,
    tag: 'h4',
    my: 7,
  };
  const stylingExpandCollapseCompatiblePhones = {
    lineHeight: '1rem',
    divider: true,
    tag: 'h4',
    my: 14,
    fontSize: 8,
    fontWeight: 3,
  };

  return (
    <Box alignItems="left">
      {videoHeading && (
        <Box mt={[16, 27]}>
          <Container>
            <PageHeading
              textBlock={mainDescription}
              textBlockVariant={textVariant}
              margins={{ mt: [7, 15] }}
            />
          </Container>
          <Divider color="black" thickness={2} my={[7, 29]} />
          <TextBlock data={videoHeading} blockVariant={textVariant} />
        </Box>
      )}
      {videoLink && (
        <Box mt={15}>
          {videoLink && (
            <Iframe iframeUrl={videoLink} title="accessibility-page-video" />
          )}
        </Box>
      )}
      {contentSection && (
        <Box>
          <TextBlock
            data={contentSection}
            blockVariant={textVariant}
            paragraphStyles={{ mt: 7, mb: 13 }}
            headingStyles={{ mt: 21 }}
          />
        </Box>
      )}
      {fields && (
        <Flex flexWrap="wrap">
          <Flex flexWrap="wrap">
            {leftBlock && (
              <Box width={[1, 2 / 3]} py={[13, 7]}>
                <Box width={[1, 4 / 5]}>
                  <TextBlock data={leftBlock} blockVariant={textVariant} />
                </Box>
              </Box>
            )}
            {rightBlock && (
              <Box width={[1, 1 / 4]} py={[13, 7]} isCenterAligned>
                <Box width={[1, 4 / 5]}>
                  <TextBlock blockVariant={textVariant} data={rightBlock} />
                </Box>
              </Box>
            )}
          </Flex>
        </Flex>
      )}
      <Box mt={2}>
        {accordion && (
          <ExpandCollapse
            accordions={accordion}
            headingProps={stylingExpandCollapseCompatiblePhones}
          />
        )}
        <Box pt={[3, 13]}>
          {accordionGroup[0].fields.title && (
            <H3 py={11}>{accordionGroup[0].fields.title}</H3>
          )}
          {accordionGroup && accordionGroup[0] && (
            <ExpandCollapse
              accordions={accordionGroup[0].fields.accordion}
              headingProps={stylingExpandCollapse}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

Accessibility.propTypes = {
  // first text block for the page
  mainDescription: PropTypes.object.isRequired,
  // heading for video
  videoHeading: PropTypes.object.isRequired,
  // Link to video
  videoLink: PropTypes.string.isRequired,
  // main content section
  contentSection: PropTypes.object.isRequired,
  // main content section
  accordion: PropTypes.arrayOf(PropTypes.object).isRequired,
  //  text color
  textVariant: PropTypes.string,
  // main content section
  accordionGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
  // split text for new page
  splitTextBlock: PropTypes.object,
};

export default Accessibility;
