import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import content from '../containers/contentfulHoc/reducer';
import app from '../containers/app/reducer';
import imeiCheck from '../containers/imeiCompatibilityCheck/reducer';
import getUserProfile from '../services/getUserProfile/reducer';
import { serviceKey as getUserProfileServiceKey } from '../services/getUserProfile/constants';
import flagsmith from '../containers/featuresWrapper/reducer';
import tradeIn from '../containers/tradeInCalculator/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    content,
    app,
    tradeIn,
    imeiCheck,
    flagsmith,
    [getUserProfileServiceKey]: getUserProfile,
  });
