import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from 'rebass/styled-components';
import GenericForm from 'web_component_library/genericForm';
import TextBlock from 'web_component_library/textBlock';
import { selectFormErrors } from '../contentfulHoc/selectors';

const defaultEmailDomainsList = [];
const defaultFormErrors = [];

const PageForm = ({
  description = null,
  submitButtonLabel,
  fields,
  validateOnBlur = false,
  formKey,
  formType = null,
  successMessage,
  successMessageUrl = null,
  errorMessage,
  contactKey = null,
  eventDefinitionKey = null,
  negativeResponseMessage = null,
  emailDomainsList = defaultEmailDomainsList,
  formErrors = defaultFormErrors,
}) => {
  // This errors come from the component "FORM > ERROR" in contentful (it's a kind of generic error)
  const genericFormErrors = useSelector(selectFormErrors());

  const formatedFormErrors = {};

  // The formErrors come from "Form Errors" field that's inside the FORM contentful component
  if (formErrors?.length > 0) {
    formErrors.forEach(error =>
      Object.assign(formatedFormErrors, {
        [error.fields.id]: error.fields.message,
      }),
    );
  }

  return (
    <Box py={19}>
      <TextBlock data={description} />
      <Box py={17}>
        <GenericForm
          submitButtonLabel={submitButtonLabel}
          fields={fields}
          validateOnBlur={validateOnBlur}
          formKey={formKey}
          formType={formType}
          successMessage={successMessage}
          successMessageUrl={successMessageUrl}
          errorMessage={errorMessage}
          contactKey={contactKey}
          eventDefinitionKey={eventDefinitionKey}
          negativeResponseMessage={negativeResponseMessage}
          formErrors={
            Object.values(formatedFormErrors).length
              ? formatedFormErrors
              : genericFormErrors
          }
          emailDomainsList={emailDomainsList}
        />
      </Box>
    </Box>
  );
};

PageForm.propTypes = {
  description: PropTypes.shape({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object),
    nodeType: PropTypes.string,
  }),
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  successMessage: PropTypes.string.isRequired,
  successMessageUrl: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  validateOnBlur: PropTypes.bool,
  contactKey: PropTypes.string,
  eventDefinitionKey: PropTypes.string,
  negativeResponseMessage: PropTypes.string,
  formType: PropTypes.string,
  emailDomainsList: PropTypes.arrayOf(PropTypes.string),
  formErrors: PropTypes.array,
};

export default PageForm;
