import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Divider from 'web_component_library/divider';
import Input from 'web_component_library/input';
import TextBlock from 'web_component_library/textBlock';
import Text from 'web_component_library/text';
import { Flex, Box, Heading } from 'rebass/styled-components';
import { Button } from './css';

const defaultVariant = 'light';
const defaultLinkText = '';
const defaultSelectedCountry = {};

const CallingRates = ({
  country = null,
  title,
  leftBlock,
  variant = defaultVariant,
  validateField,
  clearForm,
  label,
  list,
  errorMessage = null,
  search,
  rightBlock,
  openPopup,
  analyticsSeeAllCountries,
  linkText = defaultLinkText,
  callingRatesText,
  standardRateText,
  worldSaverRateText,
  ratesPlanDisclaimer = null,
  selectedCountry = defaultSelectedCountry,
}) => {
  const {
    fields: {
      name,
      callingRatesDescription,
      standardRate,
      worldSaverRate,
    } = {},
  } = selectedCountry;

  return (
    <>
      <Flex flexWrap="wrap" pt={[21, 26]} pb={[15, 21]}>
        <Box width={[1, 1 / 3]} pb={7}>
          <Heading fontSize={[7, 9]} lineHeight={[5, 7]}>
            {title}
          </Heading>
        </Box>

        <Flex flexWrap="wrap" width={1}>
          <Box width={[1, 1 / 2]}>
            <Box width={1} pr={[0, 64]}>
              <TextBlock data={leftBlock} blockVariant={variant} />
            </Box>
          </Box>
          <Box width={[1, 1 / 2]} pt={[17, 0]} alignSelf="center">
            <Box width={[1, 11 / 12]}>
              <Formik
                initialValues={{
                  country: '',
                }}
                novalidate
                validate={values => validateField(values.country)}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  search(values.country);
                }}
                render={({ values, handleChange, setFieldValue }) => (
                  <Form>
                    <Box>
                      <Input
                        id="country"
                        name="country"
                        type="text"
                        label={label}
                        list={list}
                        value={values.country}
                        onChange={handleChange}
                        clearForm={clearForm}
                        errorMessage={errorMessage}
                        showInline
                        setFieldValue={setFieldValue}
                        search={search}
                        isSearchButtonVisible
                      />
                    </Box>
                  </Form>
                )}
              />
              <TextBlock data={rightBlock} blockVariant={variant} />
            </Box>
          </Box>
        </Flex>
        {linkText && (
          <Flex width={1}>
            <Box width={1} pt={[13, 9]}>
              <Button
                testId="open-popup-button"
                onClick={openPopup}
                {...analyticsSeeAllCountries}
              >
                <Text withAnimation fontWeight={2}>
                  {linkText}
                </Text>
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>
      {country && (
        <Box mb={13}>
          <Box>
            <Divider thickness={1} color="grey" />
          </Box>
          <Flex flexWrap="wrap" width={1} pt={8} pb={8}>
            <Box width={1} pt={[8, 16]}>
              <Heading fontSize={[3, 9]} lineHeight={[4, 7]}>
                {`${name} ${callingRatesText}`}
              </Heading>
            </Box>
            <Box width={[1, 1 / 2]} pr={21}>
              <Box pt={1}>
                <TextBlock data={callingRatesDescription} />
              </Box>
              {ratesPlanDisclaimer && (
                <Box pt={3}>
                  <TextBlock data={ratesPlanDisclaimer} />
                </Box>
              )}
            </Box>
            <Box width={[1 / 2, 1 / 4]} pt={[11, 7]}>
              <Box width={1}>
                <Text
                  lineHeight={[9, 4]}
                  fontWeight={1}
                  color="freedomCharcoal"
                >
                  {standardRateText}
                </Text>
              </Box>
              <Box width={1}>
                <Text lineHeight={[9, 4]} fontWeight={2} fontColor="black">
                  {standardRate}
                </Text>
              </Box>
            </Box>
            <Box width={[1 / 2, 1 / 4]} pt={[11, 7]}>
              <Box width={1}>
                <Text
                  lineHeight={[9, 4]}
                  fontWeight={1}
                  color="freedomCharcoal"
                >
                  {worldSaverRateText}
                </Text>
              </Box>
              <Box width={1}>
                <Text lineHeight={[9, 4]} fontWeight={2} fontColor="black">
                  {worldSaverRate}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

CallingRates.propTypes = {
  country: PropTypes.string,
  title: PropTypes.string.isRequired,
  leftBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  variant: PropTypes.string,
  validateField: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  entryTitle: PropTypes.string.isRequired,
  entryKey: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  search: PropTypes.func.isRequired,
  rightBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  openPopup: PropTypes.func.isRequired,
  analyticsSeeAllCountries: PropTypes.object.isRequired,
  linkText: PropTypes.string,
  callingRatesText: PropTypes.string.isRequired,
  standardRateText: PropTypes.string.isRequired,
  worldSaverRateText: PropTypes.string.isRequired,
  ratesPlanDisclaimer: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  selectedCountry: PropTypes.object,
};

export default CallingRates;
