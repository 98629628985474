import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from 'web_component_library/textBlock';
import freedomTheme from 'web_component_library/theme/freedom';
import { Box, Flex } from 'rebass/styled-components';

import { SectionCards, WrapperCards } from './css';
const { pureWhite, pureBlack } = freedomTheme.colors;

const FeaturesCard = ({ headingText, featureColumnGroup, themeVariant }) => (
  <SectionCards
    as="section"
    width={1}
    themeVariant={themeVariant}
    data-testid="features-card"
  >
    <WrapperCards themeVariant={themeVariant} px={['xl', 'md']}>
      <Box width={1} pt="xxxl" mt="xxl">
        <TextBlock
          data={headingText}
          headingStyles={{
            pb: 13,
            textAlign: 'center',
            color: themeVariant === 'light' ? pureBlack : pureWhite,
          }}
          paragraphStyles={{
            textAlign: 'center',
            color: themeVariant === 'light' ? pureBlack : pureWhite,
          }}
        />
      </Box>
      <Flex
        width={1}
        py="md"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
      >
        {featureColumnGroup.length > 0 &&
          featureColumnGroup.map((feature, index) => {
            const { content } = feature.fields;
            return (
              <Box
                width={[1, 1 / featureColumnGroup.length]}
                key={feature.sys.id}
                pr={[0, index !== featureColumnGroup.length - 1 ? 'lg' : 0]}
                py={['md', 0]}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    height: '100%',
                    backgroundColor: pureWhite,
                    textAlign: 'center',
                    border: `1px solid charcoal`,
                    '& img': {
                      display: 'block',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto',
                      marginTop: 0,
                    },
                  }}
                  px={['lg', 'sm']}
                  pt="xxl"
                  pb="xs"
                >
                  <TextBlock
                    data={content}
                    headingStyles={{
                      pt: ['xxl'],
                      pb: ['md'],
                      color: pureBlack,
                    }}
                    buttonStyles={{
                      pt: ['xl'],
                      pb: ['md'],
                      alignSelf: 'end',
                      alignItems: 'stretch',
                      justifyContent: 'center',
                    }}
                  />
                </Box>
              </Box>
            );
          })}
      </Flex>
    </WrapperCards>
  </SectionCards>
);

FeaturesCard.propTypes = {
  featureColumnGroup: PropTypes.array.isRequired,
  headingText: PropTypes.object.isRequired,
  themeVariant: PropTypes.string.isRequired,
};

export default FeaturesCard;
