export default class LinkTagItem {
  constructor(data) {
    if (data && data.fields) {
      const { rel, type, href } = data.fields;
      this.rel = rel;
      this.type = type;
      this.href = href;
    }
  }
}
