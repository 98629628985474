import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import Divider from 'web_component_library/divider';
import Text from 'web_component_library/text';

import ContactUsDetailsSection from '../contactUsDetailsSection';

const defaultTitle = '';
const ContactUsDetailsColumn = ({ title = defaultTitle, sections }) => (
  <Box width={1} pb={[13, 0]}>
    <Text
      fontSize={[3, 7]}
      fontWeight={0}
      letterSpacing={[3, 0]}
      textTransform="uppercase"
      as="p"
      mr={11}
    >
      {title}
    </Text>
    <Divider thickness={1} color="charcoal" mt={[3, 7]} mr={11} />
    {sections &&
      sections.map(({ fields: { title: sectionTitle, details, time } }) => (
        <ContactUsDetailsSection
          key={sectionTitle}
          details={details}
          time={time}
        />
      ))}
  </Box>
);

ContactUsDetailsColumn.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
};

export default ContactUsDetailsColumn;
