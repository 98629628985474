import ContentfulModel from './contentfulModel';

export default class PopupModal extends ContentfulModel {
  constructor(data) {
    super(data);
    if (data && data.fields) {
      const {
        entryTitle,
        title,
        closeText,
        identifier,
        components,
        showHeader,
        showFooter,
        showDividers,
        showHeaderDivider,
        showFooterDivider,
      } = data.fields;
      this.entryTitle = entryTitle;
      this.title = title;
      this.identifier = identifier;
      this.closeText = closeText;
      this.showHeader = showHeader;
      this.showFooter = showFooter;
      this.showDividers = showDividers;
      this.components = this.extractEntriesToList(components);
      this.showHeaderDivider = showHeaderDivider;
      this.showFooterDivider = showFooterDivider;
    }
  }
}
