export default class Carousel {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const {
        entryTitle,
        identifier,
        viewDuration,
        showProgressBar,
        areAllSlidesSameHeight,
        slideFixedHeight,
        components,
        previousSlideText,
        nextSlideText,
        selectSlideText,
        playText,
        pauseText,
        slideMobileFixedHeight,
      } = data.fields;
      this.id = data.sys.id;
      this.entryTitle = entryTitle;
      this.identifier = identifier;
      this.viewDuration = viewDuration;
      this.showProgressBar = showProgressBar;
      this.areAllSlidesSameHeight = areAllSlidesSameHeight;
      this.slideFixedHeight = slideFixedHeight;
      this.slideMobileFixedHeight = slideMobileFixedHeight;
      this.components = components;
      this.previousSlideText = previousSlideText;
      this.nextSlideText = nextSlideText;
      this.selectSlideText = selectSlideText;
      this.playText = playText;
      this.pauseText = pauseText;
    }
  }
}
