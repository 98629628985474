import React from 'react';
import { node } from 'prop-types';
import Flex from 'web_component_library/flex';
import Box from 'web_component_library/box';

export const Table = ({ children }) => <Box as="table">{children}</Box>;
Table.propTypes = {
  children: node.isRequired,
};

export const Thead = ({ children }) => <Box as="thead">{children}</Box>;
Thead.propTypes = {
  children: node.isRequired,
};

export const Tbody = ({ children }) => (
  <Flex as="tbody" flexDirection={['column', 'row']}>
    {children}
  </Flex>
);
Tbody.propTypes = {
  children: node.isRequired,
};

export const Tr = ({ children }) => (
  <Flex
    as="tr"
    flexDirection="column"
    width={1}
    flexWrap="wrap"
    sx={{
      '&:not(:last-child)': {
        mr: [0, 21],
        mb: [17, 0],
      },
    }}
  >
    {children}
  </Flex>
);
Tr.propTypes = {
  children: node.isRequired,
};

export const Th = ({ children }) => <Box as="th">{children}</Box>;
Th.propTypes = {
  children: node.isRequired,
};

export const Td = props => <Box as="td" {...props} />;
