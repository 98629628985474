import React from 'react';
import PropTypes from 'prop-types';

import freedomTheme from 'web_component_library/theme/freedom';
import BreadcrumbNavigation from 'web_component_library/breadcrumbNavigation';
import withCtaAnalytics from '../../components/ctaAnalyticsHoc';

const { colors } = freedomTheme;

const defaultColor = 'white';
const Breadcrumb = ({ color = defaultColor, locale, pages }) => {
  if (typeof window !== 'undefined') {
    const location = {
      pathname: window.location.pathname,
    };
    return (
      <BreadcrumbNavigation
        location={location}
        pages={pages}
        locale={locale}
        color={colors[color]}
      />
    );
  }
  return React.Fragment;
};

Breadcrumb.propTypes = {
  color: PropTypes.string,
  pages: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};
export default withCtaAnalytics(Breadcrumb, {
  staticModuleType: 'breadcrumb',
  useFlexContainer: false,
  includeModuleTitle: false,
});
