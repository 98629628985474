import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { Box } from 'rebass/styled-components';
import Link from 'web_component_library/link';
import Text from 'web_component_library/text';
import RichText from '../richText';
import { LIVE_MESSAGE } from '../../constants';
import { launchLiveChat } from '../../../utils/liveChatHelper';
import { Button as TransparentButton } from '../cardTemplate/css';
import { noSubmissionOnCloseAnalyticsEvent } from '../../containers/smartConnectForm';

export const onRequestClose = () => {
  launchLiveChat();
  noSubmissionOnCloseAnalyticsEvent();
};

const generateOptions = () => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Text
        fontSize={[2, 3]}
        lineHeight={[4, 5]}
        fontWeight={2}
        letterSpacing={2}
        color="black"
        display="block"
        my={7}
        as="p"
      >
        {children}
      </Text>
    ),
    // Embedded block entry to handle Links for text block.
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (!(node.data && node.data.target && node.data.target.fields)) {
        return null;
      }
      const { url, title, isAnimationEnabled } = node.data.target.fields;
      let linkProps = {};
      if (url === LIVE_MESSAGE) {
        linkProps = {
          as: TransparentButton,
          onClick: ev => launchLiveChat(ev),
        };
      }
      return (
        <Box>
          <Link to={url} {...linkProps}>
            <Text
              mt={7}
              mb={0}
              fontSize={[2, 3]}
              lineHeight={[4, 5]}
              fontWeight={2}
              letterSpacing={2}
              withAnimation={isAnimationEnabled}
              as="p"
            >
              {title}
            </Text>
          </Link>
        </Box>
      );
    },
  },
});

const defaultData = {};

export const ContactUsDetailsBlock = ({ data = defaultData }) => {
  if (!data) return null;
  return <RichText document={data} options={generateOptions()} />;
};

ContactUsDetailsBlock.propTypes = {
  /**
   * Template based text block for page design card
   */
  data: PropTypes.object,
};

// To use with styleguidist, we need to use a special annotation
/** @component */
export default ContactUsDetailsBlock;
