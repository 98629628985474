import Plan from './plan';
import AddOn from './addOn';

export const CATALOGUE_MODELS_TYPES = {
  plan: 'plan',
  addon: 'addon',
};
export default class ProductCatalog {
  constructor(payload) {
    this.plans = {};
    this.addOns = [];
    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }
    for (let count = 0; count < payload.length; count += 1) {
      if (payload[count] && payload[count].sys) {
        const { id } = payload[count].sys.contentType.sys;
        if (id === CATALOGUE_MODELS_TYPES.plan) {
          const { sku } = payload[count].fields;
          this.plans[sku] = new Plan(payload[count]);
        } else if (id === CATALOGUE_MODELS_TYPES.addon) {
          if (
            !payload[count].fields.hideFromBrowse &&
            payload[count].fields.category !== 'Legacy'
          ) {
            this.addOns.push(new AddOn(payload[count]));
          }
        }
      }
    }
  }
}
