import { put, call, takeLatest } from 'redux-saga/effects';
import { loadFeatures, loadedFeatures } from './actions';
import { FEATURE_FLAGS } from './flags';
import createClient from './client';

const isSiteUsingFeatureFlags = Object.keys(FEATURE_FLAGS).length > 0;

const client = createClient({
  // TODO: This code does NOT work right now. `put` is outside of redux saga, so nothing is running on flag changes.
  onChange: featureFlags => put(loadedFeatures(featureFlags)),
});

export function* loadFeaturesEvent() {
  try {
    if (!isSiteUsingFeatureFlags) {
      yield put(loadedFeatures({})); // set hasLoaded to true
      return;
    }

    const features = yield call(client.getFeatureFlags);
    yield put(loadedFeatures(features));
  } catch {
    // Do nothing
  }
}

export default function* getFeaturesSaga() {
  yield takeLatest(loadFeatures().type, loadFeaturesEvent);
}
