import React from 'react';
import PropType from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import { H4 } from 'web_component_library/typography';
import Divider from 'web_component_library/divider';
import { calculateWidth } from '../../../utils/calculateColumnWidth';

import { City } from './css';

const defaultVariantLight = 'light';
export const ComingSoonSection = ({
  title,
  cities,
  variantLight = defaultVariantLight,
}) => (
  <Box width={1}>
    {title && (
      <Box pt={[15, 23]} pb={7}>
        <H4 color="freedomCharcoal">{title}</H4>
      </Box>
    )}
    {title && <Divider thickness={1} />}
    <Flex flexWrap="wrap" justifyContent="start" pb={[15, '5.25em']}>
      {cities &&
        cities.map((city, index) => (
          <City
            key={`unique-slug-${index + 1}`}
            width={calculateWidth(cities)}
            pt={6}
          >
            <TextBlock
              blockVariant={variantLight}
              data={city.fields.title}
              headingStyles={{ mb: [1] }}
            />
          </City>
        ))}
    </Flex>
  </Box>
);

ComingSoonSection.propTypes = {
  title: PropType.string.isRequired,
  cities: PropType.arrayOf(PropType.object).isRequired,
  variantLight: PropType.string,
};
export default ComingSoonSection;
