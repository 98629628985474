import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PlanCard from 'web_component_library/planCard';
import PlanCardV2 from 'web_component_library/planCardV2';
import Box from 'web_component_library/box';
import { H4, Paragraph } from 'web_component_library/typography';
import Link from 'web_component_library/link';
import Text from 'web_component_library/text';
import PlanModal from '../contentful/plan';
import { selectPlans, selectLocale } from '../contentfulHoc/selectors';
import { PT_BYOP, URL_BYOP } from '../../constants';
import { getFilteredPlans } from '../../../utils/planHelpers';
import { prettifyThePriceWithZero } from '../../../utils/number';
import {
  getBusinessToggle,
  BUSINESS_TOGGLES,
} from '../../../utils/toggleHelpers';
import { typePlan } from '../../types/plan';
import { getByopPlans } from '../contentfulHoc/actions';
import withTranslation from '../translationHoc';
import withCtaAnalytics from '../../components/ctaAnalyticsHoc';

const PlanCardWithCtaAnalytics = withCtaAnalytics(PlanCard, {
  includeModuleTitle: false,
  staticModuleType: 'plan-card',
  includeSubtitle: true,
  customSubtitleKeys: ['planTitle', 'bonusData', 'saleOrPromo', 'price'],
});

const PlanCardV2WithCtaAnalytics = withCtaAnalytics(PlanCardV2, {
  includeModuleTitle: false,
  staticModuleType: 'plan-card',
  includeSubtitle: true,
  customSubtitleKeys: ['planName'],
});

const BoxWithCtaAnalytics = withCtaAnalytics(Box, {
  includeModuleTitle: false,
});

const getPrice = plan => {
  const { availablePromotionsByod } = plan || {};
  const totalDiscount =
    availablePromotionsByod?.reduce(
      (total, discount) => (discount.value ? total + discount.value : total),
      0,
    ) || 0;
  return plan?.price ? plan.price - totalDiscount : 0;
};

const defaultByopUrl = URL_BYOP;
const defaultSubHeading = null;
export const ByopPlans = ({
  byopUrl = defaultByopUrl,
  subHeading = defaultSubHeading,
  locale,
  onGetPlans,
  identifier,
  findAStoreUrl,
  translate,
  plans,
  plansList,
  listHeading,
  postpaidPlansEntryPoint,
}) => {
  const [hasIsNewPlanCardOnLoaded, setHasIsNewPlanCardOnLoaded] = useState(
    undefined,
  );

  const isNewPlanCardOn =
    getBusinessToggle(BUSINESS_TOGGLES.NEW_PLAN_CARD) || false;

  useEffect(() => {
    if (!Object.keys(plans).length) {
      onGetPlans(locale);
    }
  }, []);

  useEffect(() => {
    if (isNewPlanCardOn !== undefined)
      setHasIsNewPlanCardOnLoaded(isNewPlanCardOn);
  }, [isNewPlanCardOn]);

  const getPlan = plan => {
    const { categories, directToFindAStore } = plan;

    if (!categories[PT_BYOP]) return null;

    const onPlanClick = () => {
      const redirectUrl = directToFindAStore
        ? findAStoreUrl
        : `${byopUrl}?planSku=${encodeURIComponent(plan.sku)}`;

      setTimeout(() => window.location.assign(redirectUrl), 100);
    };

    return (
      hasIsNewPlanCardOnLoaded && (
        <Box as="li" css={{ 'list-style': 'none' }}>
          {isNewPlanCardOn ? (
            <PlanCardV2WithCtaAnalytics
              addTooltipListener={() => {}}
              ctaButtonLabel={translate('findAStore')}
              discountedPriceAmount={prettifyThePriceWithZero(getPrice(plan))}
              includesDigitalDiscountText={translate('includesDigitalDiscount')}
              isByopFlow
              isChecked={false}
              onClick={() => onPlanClick()}
              planData={plan}
              planName={plan?.planNameOnCard?.title}
              pricePeriodText={plan.pricePeriod || translate('mo')}
            />
          ) : (
            <PlanCardWithCtaAnalytics
              identifier={identifier}
              planTitle={plan.name}
              bonusData={plan.data.bonusSize}
              saleOrPromo={plan.promotionFlag?.title}
              price={prettifyThePriceWithZero(plan.price)}
              planData={plan}
              onClick={() => onPlanClick()}
              discountedPriceAmount={prettifyThePriceWithZero(getPrice(plan))}
              monthlyTabCharge={plan.monthlyTabCharge}
              regularPriceText={translate('reg')}
              pricePeriodText={plan.pricePeriod || translate('mo')}
              includesDigitalDiscountText={translate('includesDigitalDiscount')}
              ctaButtonLabel={translate('findAStore')}
              promotionTooltipContent={plan.promotionTooltipContent}
              shouldShowRegularPrice={
                plan.showRegularPrice === 'BYOP' ||
                plan.showRegularPrice === 'Both'
              }
              shouldShowConditionDescription
              isByopFlow
            />
          )}
        </Box>
      )
    );
  };

  const plansFiltered = getFilteredPlans(plans, plansList, getPlan);

  return (
    <Box py={17}>
      <H4 mb={13}>{listHeading}</H4>
      {subHeading && <Paragraph my={7}>{subHeading}</Paragraph>}
      <Box
        as="ul"
        width={1}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['auto', 'repeat(3, 1fr)'],
          alignItems: 'end',
          gridGap: 11,
          // Remove ul/li default styling
          p: 0,
        }}
      >
        {plansFiltered}
      </Box>
      {postpaidPlansEntryPoint?.fields ? (
        <BoxWithCtaAnalytics identifier={identifier} width={1} pt={17}>
          <Link to={postpaidPlansEntryPoint.fields.url}>
            <Text withAnimation>{postpaidPlansEntryPoint.fields.title}</Text>
          </Link>
        </BoxWithCtaAnalytics>
      ) : null}
    </Box>
  );
};

ByopPlans.propTypes = {
  plansList: PropTypes.arrayOf(PropTypes.shape(typePlan)).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape(PlanModal)).isRequired,
  locale: PropTypes.string.isRequired,
  onGetPlans: PropTypes.func.isRequired,
  listHeading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  postpaidPlansEntryPoint: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  byopUrl: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  findAStoreUrl: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  plans: selectPlans(),
  locale: selectLocale(),
});

const mapDispatchToProps = dispatch => ({
  onGetPlans: payload => dispatch(getByopPlans(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(ByopPlans));
