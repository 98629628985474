import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { object, arrayOf, func } from 'prop-types';

export class PageViewChangeListener extends Component {
  static propTypes = {
    location: object.isRequired,
    sideEffects: arrayOf(func).isRequired,
  };

  componentDidMount() {
    this.props.sideEffects.forEach(fn => fn());
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.pageNav();
    }
  }

  pageNav = () => {
    this.props.sideEffects.forEach(fn => fn());
  };

  render() {
    return null;
  }
}

export default withRouter(PageViewChangeListener);
