import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import Divider from 'web_component_library/divider';

import {
  selectLocale,
  selectCategories,
} from '../../containers/contentfulHoc/selectors';

import { getSupportUrl } from '../../constants';
import withTranslation from '../../containers/translationHoc';
import ListOfLinks from '../listOfLinks';

import { getSupportEntries } from '../../containers/contentfulHoc/actions';

const CategorySearchResults = props => {
  const { translate, categorySlug } = props;
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories());

  const locale = useSelector(selectLocale());

  const [results, setResults] = useState(null);

  useEffect(() => {
    if (!Object.keys(categories).length) {
      dispatch(getSupportEntries({ locale }));
    } else {
      const articlesAvailable = categories[categorySlug]?.articles || [];
      setResults(articlesAvailable);
    }
  }, [categories, categorySlug]);

  const linksList = useMemo(
    () =>
      results?.map(({ title, slug, url }) => ({
        to: url || `/${locale}/${getSupportUrl()}/${slug}`,
        text: title,
      })),
    [results],
  );

  let title = translate('loading');
  if (results) {
    title = translate('articles');
  }

  return (
    <>
      <Text
        as="h2"
        fontSize={[5, 7]}
        letterSpacing="0.07em"
        fontWeight={0}
        color="freedomCharcoal"
        style={{ textTransform: 'uppercase' }}
      >
        {title}
      </Text>
      {linksList && <ListOfLinks links={linksList} />}
      <Divider color="black" thickness={2} mt={[7, 17]} />
    </>
  );
};

CategorySearchResults.propTypes = {
  translate: PropTypes.func.isRequired,
  categorySlug: PropTypes.string.isRequired,
};
export default compose(
  withRouter,
  withTranslation,
)(CategorySearchResults);
