import { Article } from './article';

export class SupportCategory {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const { title, articles } = data.fields;
      this.title = title;
      this.articles = articles?.map(article => new Article(article));
    }
  }
}

export default class SupportCategories {
  constructor(payload) {
    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }

    payload.forEach(category => {
      if (category && category.fields) {
        const { title } = category.fields;
        this[title] = new SupportCategory(category);
      }
    });
  }
}
