import { handleActions, combineActions } from 'redux-actions';

import {
  getClientSideEntries,
  getServerSideEntries,
  getContentSuccess,
  getContentError,
  setTranslations,
  getProductEntriesSuccess,
  getPrepaidPlansSuccess,
  getPageSuccess,
  setCurrentPage,
  setRegion,
  getCompatibilityCheckOptionsSuccess,
  getSupportEntries,
  getSupportEntriesSuccess,
  getArticleSuccess,
  clearArticle,
  getSearchQueryEntries,
  getSearchQueryEntriesSuccess,
  getSearchQueryEntriesFinally,
} from './actions';

export const initialState = {
  translations: [],
  entries: {
    productCatalog: {
      plans: {},
      prepaidPlans: {},
      addOns: [],
    },
    region: '',
    availableRegions: [],
  },
  loading: false,
  error: false,
  emailDomainsList: [],
  supportEntries: {
    questions: {},
    categories: {},
    articlesList: {},
  },
  hasFinishedEntriesFetching: false,
  searchQueryEntries: {},
  searchQueryEntriesFetching: false,
  article: null,
};

const reducer = handleActions(
  {
    [combineActions(getClientSideEntries, getServerSideEntries)]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getProductEntriesSuccess]: (state, { payload }) => ({
      ...state,
      entries: {
        ...state.entries,
        productCatalog: {
          ...state.entries.productCatalog,
          ...payload,
        },
      },
      loading: false,
    }),
    [getPrepaidPlansSuccess]: (state, { payload }) => ({
      ...state,
      entries: {
        ...state.entries,
        productCatalog: {
          ...state.entries.productCatalog,
          prepaidPlans: { ...payload },
        },
      },
      loading: false,
    }),
    [getPageSuccess]: (state, { payload }) => ({
      ...state,
      entries: {
        ...state.entries,
        browseCatalog: {
          ...state.entries.browseCatalog,
          pages: {
            ...state.entries.browseCatalog.pages,
            ...payload.pages,
          },
        },
      },
      loading: false,
    }),
    [setCurrentPage]: (state, { payload }) => ({
      ...state,
      entries: {
        ...state.entries,
        browseCatalog: {
          ...state.entries.browseCatalog,
          currentPage: payload,
        },
      },
    }),
    [getContentSuccess]: (state, { payload }) => ({
      ...state,
      entries: { ...state.entries, ...payload },
      loading: false,
    }),
    [getContentError]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [setTranslations]: (state, { payload }) => ({
      ...state,
      translations: payload,
    }),
    [setRegion]: (state, { payload }) => ({
      ...state,
      entries: {
        ...state.entries,
        region: payload,
      },
    }),
    [getCompatibilityCheckOptionsSuccess]: (state, { payload }) => ({
      ...state,
      compatibilityCheckOptions: { ...payload },
    }),
    [getSupportEntries]: state => ({
      ...state,
      hasFinishedEntriesFetching: false,
    }),
    [getSupportEntriesSuccess]: (state, { payload }) => ({
      ...state,
      supportEntries: payload,
      hasFinishedEntriesFetching: true,
    }),
    [getSearchQueryEntries]: state => ({
      ...state,
      searchQueryEntriesFetching: true,
    }),
    [getSearchQueryEntriesSuccess]: (state, { payload }) => ({
      ...state,
      searchQueryEntries: payload,
    }),
    [getSearchQueryEntriesFinally]: state => ({
      ...state,
      searchQueryEntriesFetching: false,
    }),
    [getArticleSuccess]: (state, { payload }) => ({
      ...state,
      article: payload,
    }),
    [clearArticle]: state => ({
      ...state,
      article: null,
    }),
  },
  initialState,
);

export default reducer;
