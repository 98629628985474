import getEnvVar from '../common/lib/envvars';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Intercepts fetch options to add custom global configuration
 * @param {string} url
 * @param {RequestInit} fetchOptions
 * @returns {RequestInit}
 */
function interceptFetchOptions(url, fetchOptions = {}) {
  const interceptedFetchOptions = { ...fetchOptions };
  interceptedFetchOptions.headers = {
    ...fetchOptions.headers,
  };

  if (url.startsWith(getEnvVar('apiBaseUrl'))) {
    // Added to enhance logging in DSL2
    interceptedFetchOptions.headers['s-client'] = window.build_tag;
  }

  return interceptedFetchOptions;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, interceptFetchOptions(url, options)).then(parseJSON);
}

export function deleteRequest(url, options) {
  return fetch(url, {
    method: 'DELETE',
    ...interceptFetchOptions(url, options),
  });
}

export function postRequest(url, options) {
  return fetch(url, {
    method: 'POST',
    ...interceptFetchOptions(url, options),
  }).then(parseJSON);
}
