import { FREEDOM } from '../../constants';

export default class ModelsOptions {
  constructor(data) {
    this.models = [];
    if (data && data.fields) {
      const { phoneModelsOrder } = data.fields;
      phoneModelsOrder.forEach(phoneModel => {
        if (phoneModel && phoneModel.fields) {
          const {
            name,
            manufacturer,
            freedomModel,
            internationalModel,
            otherCanModel,
            unknownModel,
            usaModel,
            tags,
          } = phoneModel.fields;
          const isShawDevice = tags?.some(tag => tag.fields.tag === FREEDOM);
          if (!isShawDevice) return;
          const model = {};
          model.brand =
            manufacturer && manufacturer.fields && manufacturer.fields.name;
          model.modelName = name;
          model.features = {
            freedomModel,
            internationalModel,
            otherCanModel,
            unknownModel,
            usaModel,
          };
          this.models.push(model);
        }
      });
    }
  }
}
