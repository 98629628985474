import React from 'react';
import { object, array, string } from 'prop-types';
import { Flex } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import {
  Section,
  Wrapper,
  Benefit,
  EyebrowText,
  BenefitBox,
  HeadlineText,
} from './benefitsSection.css';
import { VARIANT_LIGHT } from '../../constants';
import { getTheme } from './utils';
import { WRAPPER_SIZE } from '../../theme/style-helpers';

function BenefitsSection(props) {
  const { benefitGroup, eyeBrowText, headlineText, colorVariation } = props;
  const { accentColor, backgroundColor } = getTheme(colorVariation);
  return (
    <Section backgroundColor={backgroundColor} width={1}>
      <Wrapper
        className="wrapper"
        maxWidth={WRAPPER_SIZE}
        margin="0 auto"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor={backgroundColor}
        py={[21, 115]}
        px={[7, 0]}
      >
        <EyebrowText accentColor={accentColor}>
          <TextBlock data={eyeBrowText} />
        </EyebrowText>

        <HeadlineText width={1} mb={[7, 21]}>
          <TextBlock
            data={headlineText}
            blockVariant={colorVariation === 'white' ? VARIANT_LIGHT : ''}
          />
        </HeadlineText>

        <Flex
          flexDirection={['column', 'row']}
          justifyContent="center"
          width={1}
          as="ul"
          m={0}
          p={0}
        >
          {benefitGroup.length > 0 &&
            benefitGroup.map(benefit => (
              <Benefit
                key={benefit.sys.id}
                borderColor={accentColor}
                px={[0, 11]}
                pt={[11, 0]}
                as="li"
                width={1}
              >
                <BenefitBox
                  alignItems="center"
                  justifyContent="center"
                  as="span"
                  flex={1}
                  m={0}
                >
                  <TextBlock
                    data={benefit.fields.benefitText}
                    blockVariant={
                      colorVariation === 'white' ? VARIANT_LIGHT : ''
                    }
                  />
                </BenefitBox>
              </Benefit>
            ))}
        </Flex>
      </Wrapper>
    </Section>
  );
}

BenefitsSection.propTypes = {
  benefitGroup: array.isRequired,
  eyeBrowText: object.isRequired,
  headlineText: object.isRequired,
  colorVariation: string.isRequired,
};

export default BenefitsSection;
