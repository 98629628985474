import styled from 'styled-components';
import { Button as BaseButton } from 'rebass/styled-components';

export const Button = styled(BaseButton)`
  padding-left: 0;
  background: none;
  width: unset;
  &:hover {
    background-image: none;
  }
  &:active {
    background-image: none;
    background-color: transparent;
  }
`;
