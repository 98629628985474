import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import freedomTheme from 'web_component_library/theme/freedom';
import Divider from 'web_component_library/divider';

import { Flex, Box, Text } from 'rebass/styled-components';
import { letters } from '../../constants';
import {
  LettersPanelContainer,
  LettersPanel,
  LetterContainer,
  Letter,
  NoRatesCountry,
} from './css';

const { colors } = freedomTheme;

export class RatesCountriesList extends PureComponent {
  static propTypes = {
    countries: PropTypes.array.isRequired,
    countryText: PropTypes.string.isRequired,
    standardRateText: PropTypes.string.isRequired,
    worldSaverRateText: PropTypes.string.isRequired,
    dataText: PropTypes.string,
    talkText: PropTypes.string,
    textText: PropTypes.string,
  };

  static defaultProps = {
    dataText: '',
    talkText: '',
    textText: '',
  };

  state = {
    selectedLetter: 'A',
    isRoaming: false,
  };

  componentDidMount = () => {
    const { dataText } = this.props;
    if (!dataText) {
      return null;
    }
    return this.setState({
      isRoaming: true,
    });
  };

  renderCountries = (countries, selectedLetter, renderOneColumn) => {
    const { isRoaming } = this.state;
    return countries.map(country => {
      if (!isRoaming) {
        const {
          fields: { name, standardRate, worldSaverRate },
        } = country;
        if (name.slice(0, 1) !== selectedLetter) {
          return null;
        }

        return (
          <Fragment key={name}>
            <Box width={1}>
              <Divider thickness={2} color={colors.grey} />
            </Box>
            <Flex
              flexWrap="wrap"
              py={15}
              width={1}
              justifyContent={renderOneColumn ? 'space-between' : 'unset'}
            >
              <Box width={[1 / 3, 2 / 3]} pr={21}>
                <Text lineHeight={[4, 5]} fontWeight={2} color={colors.black}>
                  {name}
                </Text>
              </Box>
              {!renderOneColumn ? (
                <Box width={[1 / 3, 1 / 6]} pr={21}>
                  <Text
                    lineHeight={[4, 5]}
                    fontWeight={1}
                    color={colors.freedomCharcoal}
                  >
                    {worldSaverRate}
                  </Text>
                </Box>
              ) : null}
              <Box width={[1 / 3, 1 / 6]}>
                <Text
                  lineHeight={[4, 5]}
                  fontWeight={1}
                  color={colors.freedomCharcoal}
                >
                  {standardRate}
                </Text>
              </Box>
            </Flex>
          </Fragment>
        );
      }
      const { dataText, talkText, textText } = this.props;
      const {
        fields: {
          name,
          standardDataRate,
          standardTalkRate,
          standardTextRate,
          worldSaverDataRate,
          worldSaverTalkRate,
          worldSaverTextRate,
        },
      } = country;
      if (name.slice(0, 1) !== selectedLetter) {
        return null;
      }

      return (
        <Fragment key={name}>
          <Box width={1}>
            <Divider thickness={2} color={colors.grey} />
          </Box>

          <Flex flexWrap="wrap" pt={15} width={1}>
            <Box width={1}>
              <Text lineHeight={[4, 5]} fontWeight={2} color={colors.black}>
                {name}
              </Text>
            </Box>
          </Flex>
          {this.renderRateBlock(
            dataText,
            standardDataRate,
            renderOneColumn ? null : worldSaverDataRate,
            3,
            1,
          )}
          {this.renderRateBlock(
            talkText,
            standardTalkRate,
            renderOneColumn ? null : worldSaverTalkRate,
            1,
            1,
          )}
          {this.renderRateBlock(
            textText,
            standardTextRate,
            renderOneColumn ? null : worldSaverTextRate,
            1,
            15,
          )}
        </Fragment>
      );
    });
  };

  renderRateBlock = (type, standardRate, worldSaverRate, pt, pb) => (
    <Flex
      flexWrap="wrap"
      pt={pt}
      pb={pb}
      width={1}
      justifyContent={worldSaverRate ? 'unset' : 'space-between'}
    >
      <Box width={[1 / 3, 2 / 3]} pr={21}>
        <Text lineHeight={[4, 5]} fontWeight={1} color={colors.freedomCharcoal}>
          {type}
        </Text>
      </Box>
      {worldSaverRate ? (
        <Box width={[1 / 3, 1 / 6]} pr={21}>
          <Text
            lineHeight={[4, 5]}
            fontWeight={1}
            color={colors.freedomCharcoal}
          >
            {worldSaverRate}
          </Text>
        </Box>
      ) : null}
      <Box width={[1 / 3, 1 / 6]}>
        <Text
          textAlign={worldSaverRate ? 'start' : 'end'}
          width={worldSaverRate ? 'unset' : '100%'}
          lineHeight={[4, 5]}
          fontWeight={1}
          color={colors.freedomCharcoal}
        >
          {standardRate}
        </Text>
      </Box>
    </Flex>
  );

  choose = selectedLetter => {
    this.setState({
      selectedLetter,
    });
  };

  defineNextLetter = index => {
    const { countries } = this.props;
    const lettersArray = letters.split('');
    let allNextEmpty;
    let nextLetter;
    for (let i = index + 1; i < lettersArray.length; i += 1) {
      if (
        countries.find(
          country => country.fields.name.slice(0, 1) === lettersArray[i],
        )
      ) {
        nextLetter = lettersArray[i];
        return nextLetter;
      }
      allNextEmpty = true;
    }
    if (allNextEmpty) {
      for (let i = 0; i < lettersArray.length; i += 1) {
        if (
          countries.find(
            country => country.fields.name.slice(0, 1) === lettersArray[i],
          )
        ) {
          nextLetter = lettersArray[i];
          return nextLetter;
        }
      }
    }
    return nextLetter;
  };

  render() {
    const {
      countries,
      countryText,
      standardRateText,
      worldSaverRateText,
    } = this.props;
    const { selectedLetter } = this.state;
    const lettersArray = letters.split('');
    return (
      <Box width={1}>
        <LettersPanelContainer>
          <Divider thickness={1} color={colors.grey} mt="1.75rem" />
          <LettersPanel width={1} flexWrap="nowrap">
            {lettersArray.map((letter, index) => {
              const key = `Letter${index}`;
              const isNotEmpty = countries.find(
                country => country.fields.name.slice(0, 1) === letter,
              );
              if (!isNotEmpty) {
                const nextLetter = this.defineNextLetter(index);
                return (
                  <LetterContainer key={key} width={1}>
                    <Letter
                      variant="text"
                      onClick={() => this.choose(nextLetter)}
                      py={7}
                    >
                      <NoRatesCountry
                        lineHeight={5}
                        color={colors.freedomCharcoal}
                        fontWeight={0}
                      >
                        {letter}
                      </NoRatesCountry>
                    </Letter>
                  </LetterContainer>
                );
              }
              return (
                <LetterContainer key={key} width={1}>
                  <Letter
                    variant="text"
                    onClick={() => this.choose(letter)}
                    py={7}
                    selected={letter === selectedLetter}
                    width="2em"
                  >
                    <Text
                      lineHeight={5}
                      color={
                        letter === selectedLetter
                          ? colors.black
                          : colors.freedomCharcoal
                      }
                      fontWeight={letter === selectedLetter ? 2 : 0}
                    >
                      {letter}
                    </Text>
                  </Letter>
                </LetterContainer>
              );
            })}
          </LettersPanel>
        </LettersPanelContainer>
        <Flex
          flexWrap="wrap"
          alignItems="flex-end"
          pt={[17, 29]}
          pb={[13, 11]}
          width={1}
          justifyContent={worldSaverRateText ? 'unset' : 'space-between'}
        >
          <Box width={[1 / 3, 2 / 3]} pr={21}>
            <Text
              lineHeight={[4, 5]}
              fontWeight={2}
              color={colors.freedomCharcoal}
            >
              {countryText}
            </Text>
          </Box>
          {worldSaverRateText ? (
            <Box width={[1 / 3, 1 / 6]} pr={21}>
              <Text
                lineHeight={[4, 5]}
                fontWeight={2}
                color={colors.freedomCharcoal}
              >
                {worldSaverRateText}
              </Text>
            </Box>
          ) : null}
          <Box width={worldSaverRateText ? [1 / 3, 1 / 6] : 'unset'}>
            <Text
              lineHeight={[4, 5]}
              fontWeight={2}
              color={colors.freedomCharcoal}
            >
              {standardRateText}
            </Text>
          </Box>
        </Flex>
        <Flex width={1} flexWrap="wrap" justifyContent="space-between">
          {this.renderCountries(countries, selectedLetter, !worldSaverRateText)}
        </Flex>
      </Box>
    );
  }
}

export default RatesCountriesList;
