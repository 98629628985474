import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { WebStorageStateStore, UserManager } from 'oidc-client-ts';
import { AuthProvider, useAuth, hasAuthParams } from 'react-oidc-context';
import { Switch, Route, useHistory } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import getEnvVar from '../../../lib/envvars';
import {
  OIDC_REDIRECT_URI,
  OIDC_POST_LOGOUT_REDIRECT_URI,
} from '../../../constants';
import {
  loadGetUserProfile,
  clearGetUserProfile,
} from '../../../services/getUserProfile/actions';
import {
  makeSelectGetUserProfileIsLoading,
  makeSelectHasGetUserProfileLoaded,
  makeSelectGetUserProfileError,
} from '../../../services/getUserProfile/selectors';

let oidcConfig = null;
if (typeof window !== 'undefined') {
  const oidcConfigEnvVar = getEnvVar('oidc');
  oidcConfig = {
    authority: oidcConfigEnvVar?.baseUrl,
    client_id: oidcConfigEnvVar?.clientId,
    response_type: 'code',
    scope: `openid ${oidcConfigEnvVar?.browseScope}`,
    redirect_uri: `${window.location.origin}${OIDC_REDIRECT_URI}`,
    post_logout_redirect_uri: `${window.location.origin}${OIDC_POST_LOGOUT_REDIRECT_URI}`,
    userStore: new WebStorageStateStore({
      store: localStorage,
    }),
    automaticSilentRenew: false,
    // This callback runs after a successful sign-in

    monitorSession: true,
  };
}

const userManager = new UserManager({
  ...oidcConfig,
});

const SignInListener = () => {
  const hasAlreadyAttempted = useRef(false);
  const oidcAuth = useAuth();
  const dispatch = useDispatch();
  const profileIsLoading = useSelector(makeSelectGetUserProfileIsLoading);
  const profileHasLoaded = useSelector(makeSelectHasGetUserProfileLoaded);
  const profileError = useSelector(makeSelectGetUserProfileError);

  const shouldAttemptSignIn =
    !hasAuthParams() &&
    !oidcAuth.isAuthenticated &&
    !oidcAuth.activeNavigator &&
    !oidcAuth.isLoading;
  useEffect(() => {
    if (shouldAttemptSignIn && !hasAlreadyAttempted.current) {
      hasAlreadyAttempted.current = true;
      oidcAuth.signinSilent();
    }
  }, [shouldAttemptSignIn]);

  useEffect(() => {
    if (
      oidcAuth.isAuthenticated &&
      !profileIsLoading &&
      !profileHasLoaded &&
      !profileError
    ) {
      const {
        // eslint-disable-next-line camelcase
        user: { access_token },
      } = oidcAuth;
      dispatch(loadGetUserProfile({ token: access_token }));
    }
  }, [oidcAuth, profileIsLoading, profileHasLoaded, profileError]);
  return null;
};

const SignOutListener = () => {
  const oidcAuth = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!oidcAuth.isAuthenticated) {
      dispatch(clearGetUserProfile());
    }
  }, [oidcAuth]);

  return null;
};

const SessionMonitorListener = React.memo(() => {
  const oidcAuth = useAuth();

  const doSilentSignOut = () => {
    oidcAuth.signoutSilent();
  };

  useEffect(() => {
    userManager.events.addUserSignedOut(doSilentSignOut);
  }, []);

  return null;
});

const TokenRenewalListener = () => {
  const oidcAuth = useAuth();
  const [isUserActive, setIsUserActive] = useState(true);

  useEffect(() => {
    const renewTokenIfUserActive = () => {
      if (isUserActive) oidcAuth.signinSilent();
    };

    oidcAuth.events.addAccessTokenExpiring(renewTokenIfUserActive);

    return () => {
      oidcAuth.events.removeAccessTokenExpiring(renewTokenIfUserActive);
    };
  }, [isUserActive, oidcAuth]);

  return (
    <IdleTimer
      timeout={5 * 60 * 1000} // 5 minutes
      onIdle={() => setIsUserActive(false)}
      onActive={() => setIsUserActive(true)}
    />
  );
};

const OidcProvider = ({ children, locale }) => {
  const history = useHistory();
  const onSigninCallback = useCallback(
    user => {
      const stateUponReturningToSite = user.state ? JSON.parse(user.state) : {};
      const { location } = stateUponReturningToSite;

      if (location) {
        history.replace({
          pathname: location.pathname,
          search: location.search,
        });
      } else {
        history.replace({
          pathname: `/${locale}`,
        });
      }
    },
    [history.replace, locale],
  );

  return (
    <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
      {typeof window !== 'undefined' && (
        <Fragment>
          <SignInListener />
          <SignOutListener />
          <SessionMonitorListener />
          <TokenRenewalListener />
        </Fragment>
      )}

      <Switch>
        <Route exact path={`/${locale}${OIDC_REDIRECT_URI}`} component={null} />
        <Route
          exact
          path={`/${locale}${OIDC_POST_LOGOUT_REDIRECT_URI}`}
          component={null}
        />
        <Route path="*">{children}</Route>
      </Switch>
    </AuthProvider>
  );
};

OidcProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
};

export default OidcProvider;
