import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

export const HeadingBlock = styled(Box)`
  display: inline-block;
  text-align: ${props => props.headingPosition || 'center'};
`;

HeadingBlock.propTypes = {
  headingPosition: PropTypes.string,
};
