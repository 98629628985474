import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Flex } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import Divider from 'web_component_library/divider';
import { ModalContext } from '../modal/modalContext';
import Modal from '../modalComponent';
import { selectPopupModals } from '../../containers/contentfulHoc/selectors';
import { launchLiveChat, isAppleUser } from '../../../utils/liveChatHelper';

import { Box } from './css';
import {
  SPLIT_TEXT_CONTAINER_PADDING,
  SPLIT_TEXT_BUTTON_WIDTH,
  SPLIT_TEXT_BUTTON_POSITION,
  VARIANT_LIGHT,
} from '../../constants';
import { noSubmissionOnCloseAnalyticsEvent } from '../../containers/smartConnectForm';
import {
  getBusinessToggle,
  BUSINESS_TOGGLES,
} from '../../../utils/toggleHelpers';

const defaultVariant = VARIANT_LIGHT;
const defaultDesignVariant = 'normal';
const defaultLiveChatEngagementId = '';
const defaultAppleChatBizIntentId = '';
const defaultAppleChatBizGroupId = '';
const defaultAppleChatBody = '';
const defaultRightBlockButtonPosition = 'left';

export function SplitTextBlock({
  leftBlock,
  dividerVertical,
  rightBlock,
  divider,
  variant = defaultVariant,
  isRightBlockCenterAligned = false,
  rightBlockButtonPosition = defaultRightBlockButtonPosition,
  isBackgroundWhite = false,
  designVariant = defaultDesignVariant,
  popupModals,
  liveChatEngagementId = defaultLiveChatEngagementId,
  appleChatBizIntentId = defaultAppleChatBizIntentId,
  appleChatBizGroupId = defaultAppleChatBizGroupId,
  appleChatBody = defaultAppleChatBody,
  isAppleChatOn = true,
}) {
  const containerPadding = SPLIT_TEXT_CONTAINER_PADDING[designVariant];
  const buttonWidth = SPLIT_TEXT_BUTTON_WIDTH[designVariant];
  const buttonPosition = SPLIT_TEXT_BUTTON_POSITION[rightBlockButtonPosition];

  const liveChat = () =>
    launchLiveChat(
      null,
      liveChatEngagementId,
      appleChatBizIntentId,
      appleChatBizGroupId,
      appleChatBody,
      isAppleChatOn,
    );

  const { showModal } = useContext(ModalContext);
  const onRequestClose = liveChatEngagementId
    ? () => {
        noSubmissionOnCloseAnalyticsEvent();
        liveChat();
      }
    : null;

  const openPopup = modalIdentifier => {
    const isAppleChatToggleOn = getBusinessToggle(BUSINESS_TOGGLES.APPLE_CHAT);
    if (!isAppleUser || !isAppleChatToggleOn) {
      showModal(Modal, {
        ...popupModals[modalIdentifier],
        onRequestClose,
      });
    } else {
      liveChat();
    }
  };

  return (
    <Flex
      flexWrap="wrap"
      bg={isBackgroundWhite ? 'white' : 'transparent'}
      mb={divider ? '-0.75em' : ''}
      width={1}
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        width={1}
        {...containerPadding}
      >
        <Box width={[1, 5 / 12]}>
          <TextBlock data={leftBlock} blockVariant={variant} />
        </Box>
        {dividerVertical && (
          <Box sx={{ borderRight: ['none', '2px solid black'] }} />
        )}
        <Box width={[1, 1 / 2]} isCenterAligned={isRightBlockCenterAligned}>
          <TextBlock
            blockVariant={variant}
            data={rightBlock}
            openPopup={openPopup}
            buttonStyles={{ pt: [13, 7], ...buttonWidth, ...buttonPosition }}
          />
        </Box>
      </Flex>
      {divider && <Divider color="freedomCharcoal" thickness={2} width={1} />}
    </Flex>
  );
}

SplitTextBlock.displayName = 'SplitTextBlock';

SplitTextBlock.propTypes = {
  leftBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  dividerVertical: PropTypes.bool.isRequired,
  rightBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  divider: PropTypes.bool.isRequired,
  isRightBlockCenterAligned: PropTypes.bool,
  isBackgroundWhite: PropTypes.bool,
  variant: PropTypes.string,
  designVariant: PropTypes.string,
  popupModals: PropTypes.object.isRequired,
  liveChatEngagementId: PropTypes.string,
  appleChatBizIntentId: PropTypes.string,
  appleChatBizGroupId: PropTypes.string,
  appleChatBody: PropTypes.string,
  rightBlockButtonPosition: PropTypes.string,
  isAppleChatOn: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  popupModals: selectPopupModals(),
});

export default connect(mapStateToProps)(SplitTextBlock);
