export default class Maintenance {
  constructor(data) {
    if (data && data.fields) {
      const { slug, textBlock, image, textBlockBackground } = data.fields;
      this.entryTitle = slug;
      this.slug = slug;
      this.textBlock = textBlock;
      this.image = image;
      this.textBlockBackground = textBlockBackground;
    }
  }
}
