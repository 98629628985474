import flagsmith from 'flagsmith/isomorphic';
import getEnvVar from '../../lib/envvars';
import { isServer } from '../../store/configureStore';

// TODO: When we upgrade to node 18+, we won't need node-fetch anymore
// https://nodejs.org/de/blog/announcements/v18-release-announce/#fetch-experimental
const nodeFetch = require('node-fetch');

const flagsmithConfig = getEnvVar('flagsmith');

const mapFeatureFlags = flags =>
  Object.entries(flags).reduce(
    (_flags, [key, value]) => ({
      ..._flags,
      ...{ [key]: value.enabled },
    }),
    {},
  );

export default ({ onChange = () => {} } = {}) => {
  flagsmith.init({
    api: flagsmithConfig?.api,
    environmentID: flagsmithConfig?.environmentID,
    fetch: isServer ? nodeFetch : null,
    cacheFlags: true,
    preventFetch: true,
    onChange: () => onChange(mapFeatureFlags(flagsmith.getAllFlags())),
  });
  return {
    getFeatureFlags: async () => {
      await flagsmith.getFlags();
      return mapFeatureFlags(flagsmith.getAllFlags());
    },
  };
};
