import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { Flex } from 'rebass/styled-components';

import Box from 'web_component_library/box';
import TextBlock from 'web_component_library/textBlock';
import { AddonList, Li } from './css';
import CardTemplate from '../cardTemplate';
import AddOnsFilters from './addOnsFilters';
import withCtaAnalytics from '../ctaAnalyticsHoc';

import {
  getBusinessToggle,
  BUSINESS_TOGGLES,
} from '../../../utils/toggleHelpers';

import AddOn from '../../containers/contentful/addOn';

import { PT_PREPAID } from '../../constants';

const AddonCardWithCtaAnalytics = withCtaAnalytics(CardTemplate);
const TextBlockWithCtaAnalytics = withCtaAnalytics(TextBlock, {
  includeModuleTitle: false,
});

export const Addon = ({ addOns, infoSection, identifier }) => {
  // TODO: Remove this business toggle when prepaid addons are all set up in Contentful, and everything is stable
  const doesBusinessAllowPrepaidFilter = getBusinessToggle(
    BUSINESS_TOGGLES.PREPAID_ADDON_FILTER,
  );

  // Third-party hooks
  const history = useHistory();
  const { location } = history;
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  // Derived state
  const addOnsByPaymentType = addOns.filter(addOn => {
    if (!doesBusinessAllowPrepaidFilter) return true; // don't filter anything

    // primary filter by payment type
    return queryParams.paymentType === PT_PREPAID
      ? addOn.isPrepaid
      : !addOn.isPrepaid;
  });

  const addOnCategoriesByPaymentType = [
    ...new Set(addOnsByPaymentType.map(addOn => addOn.category)),
  ].sort();

  const isPaymentTypeFilterValid =
    !queryParams.paymentType || queryParams.paymentType === PT_PREPAID;
  const isCategoryFilterValid = addOnCategoriesByPaymentType.includes(
    queryParams.category,
  );

  const renderableAddOns = addOnsByPaymentType
    .filter(
      addOn =>
        // secondary filter by category
        !isCategoryFilterValid || addOn.category === queryParams.category,
    )
    .sort((a, b) => b.rank - a.rank); // TODO: Move to using a list for ranking in Contentful (like we do with plans and devices)

  // Setters
  const updateQueryParams = newQueryParams =>
    history.replace({
      search: stringify({
        ...queryParams,
        ...newQueryParams,
      }),
    });

  // Renderers
  const renderAddOns = () =>
    renderableAddOns.map(
      (
        {
          category,
          name,
          description,
          descriptionPriceText,
          descriptionRichText,
          ctaLearnMore,
          price,
          priceUnit,
          learnMoreText,
          tooltipContent,
          buttonCta,
          buttonCtaUrl,
        },
        index,
      ) => {
        const key = `${name}${index}`;

        return (
          <Li key={key} data-testid="addon-list-item">
            <AddonCardWithCtaAnalytics
              identifier={identifier}
              category={category}
              name={name}
              description={description}
              descriptionRichText={descriptionRichText}
              cta={ctaLearnMore}
              amount={price}
              period={priceUnit}
              learnMoreText={learnMoreText}
              tooltipContent={tooltipContent}
              descriptionPriceText={descriptionPriceText}
              buttonCta={buttonCta}
              buttonCtaUrl={buttonCtaUrl}
              rowNumber={Math.ceil((index + 1) / 3)}
            />
          </Li>
        );
      },
    );

  return (
    <Flex flexDirection="column" width={1}>
      <Box width={1} mt={[5, 3]} mb={[13, 21]}>
        <TextBlockWithCtaAnalytics
          identifier={identifier}
          data={infoSection}
          blockVariant="light"
        />
      </Box>
      <Box width={1} mb={13}>
        <AddOnsFilters
          initialPaymentType={
            isPaymentTypeFilterValid ? queryParams.paymentType : undefined
          }
          initialCategory={
            isCategoryFilterValid ? queryParams.category : undefined
          }
          categories={addOnCategoriesByPaymentType}
          onFilterChange={filters => updateQueryParams(filters)}
        />
      </Box>
      <AddonList>{renderAddOns()}</AddonList>
    </Flex>
  );
};

Addon.propTypes = {
  addOns: PropTypes.arrayOf(PropTypes.instanceOf(AddOn)).isRequired,
  infoSection: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    nodeType: PropTypes.string,
  }).isRequired,
  identifier: PropTypes.string.isRequired,
};

export default Addon;
