import React, { PureComponent, Fragment, createRef } from 'react';
import { arrayOf, shape, object, array, string, number } from 'prop-types';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { Box, Flex } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import freedomTheme from 'web_component_library/theme/freedom';

import { findImageByTitle } from '../../helpers';
import {
  Panel,
  StickyContainer,
  PanelsContainer,
  PanelImage,
  ForegroundImage,
  HeaderWrapper,
  IconTab,
  IconSpacer,
  ContentWrapper,
} from './css';

import { BREAKPOINT } from '../../../../constants';

const { colors } = freedomTheme;

let INDICATORS = false;
// safeguard to make sure indicators are turned off in prod
if (process.env.NODE_ENV === 'production') {
  INDICATORS = false;
}

class HorizontalPanelAnimation extends PureComponent {
  static propTypes = {
    images: arrayOf(
      shape({
        title: string,
        url: string,
        description: string,
        contentType: string,
      }),
    ).isRequired,
    data: shape({
      slideDuration: number,
      foregroundImageTitle: string,
      backgroundColor: string,
      slides: arrayOf(
        shape({
          id: string,
          textNode: shape({
            id: string.isRequired,
            data: object,
            content: arrayOf(
              shape({
                data: object,
                content: arrayOf(
                  shape({
                    data: object,
                    marks: array,
                    value: string,
                    nodeType: string,
                  }),
                ),
                nodeType: string,
              }),
            ),
            nodeType: string,
          }),
          panelImageTitle: string,
          nextPanelImageTitle: string,
          iconActiveImageTitle: string,
          iconDefaultImageTitle: string,
        }),
      ),
    }).isRequired,
  };

  static getProgressForSlide(progress, ratio, slideIndex) {
    return Math.max((progress - ratio * slideIndex) / ratio, 0);
  }

  static getWrapperTopPosition(wrapperRef, offset) {
    let wrapperTop = wrapperRef.current ? wrapperRef.current.offsetTop : 0;
    wrapperTop += offset;
    return wrapperTop;
  }

  state = {
    offset: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    this.windowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  wrapperRef = createRef();

  controllerRef = createRef();

  windowResize = () => {
    let offset = 0;
    if (window.innerWidth >= BREAKPOINT) {
      offset = -80;
    }
    this.setState({
      offset,
    });
  };

  render() {
    const { images, data } = this.props;
    const { offset } = this.state;

    const animationSlides = [...data.slides];
    animationSlides.pop();

    const { slideDuration, foregroundImageTitle, backgroundColor } = data;
    const foregroundIMG = findImageByTitle(images, foregroundImageTitle);

    const ratio = 1 / animationSlides.length;
    const sceneDuration = slideDuration * animationSlides.length;

    return (
      <Box width={1} ref={this.wrapperRef} data-testid="panel-animation">
        <Controller ref={this.controllerRef}>
          <Scene
            duration={sceneDuration}
            offset={offset}
            triggerHook="onLeave"
            indicators={INDICATORS}
            pin
          >
            {progress => {
              const tabRatio = 1 / data.slides.length;
              const currentSlide = Math.min(
                Math.floor(progress / tabRatio),
                data.slides.length - 1,
              );
              return (
                <StickyContainer width={1} backgroundColor={backgroundColor}>
                  {animationSlides.map((slide, index) => {
                    const progressCalc = HorizontalPanelAnimation.getProgressForSlide(
                      progress,
                      ratio,
                      index,
                    );
                    const panelImage = findImageByTitle(
                      images,
                      slide.panelImageTitle,
                    );
                    const nextPanelImage = findImageByTitle(
                      images,
                      slide.nextPanelImageTitle,
                    );
                    const fromRight = 100 * index;
                    const toRight = 100 * (index + 1);
                    const panelRightStyle = 100 * index * -1;
                    const nextPanelRightStyle = 100 * (index + 1) * -1;
                    const panelContainerIndex = 30 - index;

                    return (
                      <Timeline
                        key={slide.id}
                        duration={slideDuration}
                        totalProgress={progressCalc}
                        target={
                          <PanelsContainer width="300vw">
                            <Panel
                              style={{ right: `${panelRightStyle}vw` }}
                              width="100vw"
                            >
                              <PanelImage
                                src={panelImage.url}
                                alt={panelImage.description}
                              />
                            </Panel>
                            {nextPanelImage && (
                              <Panel
                                style={{ right: `${nextPanelRightStyle}vw` }}
                                width="100vw"
                              >
                                <PanelImage
                                  src={nextPanelImage.url}
                                  alt={nextPanelImage.description}
                                />
                              </Panel>
                            )}
                          </PanelsContainer>
                        }
                        indicators={INDICATORS}
                        paused
                      >
                        <Tween
                          duration={slideDuration}
                          from={{ right: `${fromRight}vw` }}
                          to={{ right: `${toRight}vw` }}
                          ease="Strong.easeInOut"
                        />
                        <Tween
                          duration={1}
                          from={{
                            css: {
                              zIndex: panelContainerIndex,
                            },
                          }}
                          to={{
                            css: {
                              zIndex: 10,
                            },
                          }}
                        />
                      </Timeline>
                    );
                  })}

                  <ContentWrapper width={1}>
                    <HeaderWrapper p={13}>
                      <TextBlock id="header" data={data.header} />
                      <Flex
                        justifyContent="center"
                        textAlign="center"
                        flexWrap="no-wrap"
                      >
                        {data.slides.map((slide, index) => {
                          let icon = findImageByTitle(
                            images,
                            slide.iconDefaultImageTitle,
                          );
                          if (index === currentSlide) {
                            icon = findImageByTitle(
                              images,
                              slide.iconActiveImageTitle,
                            );
                          }
                          return (
                            <Fragment key={slide.id}>
                              <IconTab
                                color={
                                  index === currentSlide
                                    ? colors.orange
                                    : colors.charcoal
                                }
                                px={[3, 13]}
                                py={3}
                                onClick={e => {
                                  e.preventDefault();
                                  const wrapperTop = HorizontalPanelAnimation.getWrapperTopPosition(
                                    this.wrapperRef,
                                    offset,
                                  );
                                  const slideStart =
                                    wrapperTop + offset + slideDuration * index;

                                  this.controllerRef.current.state.controller.scrollTo(
                                    Math.max(slideStart, wrapperTop),
                                  );
                                }}
                              >
                                <img src={icon.url} alt={icon.description} />
                              </IconTab>
                              {index < data.slides.length - 1 ? (
                                <IconSpacer px={[13, 21]} />
                              ) : null}
                            </Fragment>
                          );
                        })}
                      </Flex>
                      <Box mt={3}>
                        <TextBlock
                          id="text"
                          data={data.slides[currentSlide].textNode}
                        />
                      </Box>
                    </HeaderWrapper>
                    {foregroundIMG && (
                      <ForegroundImage
                        src={foregroundIMG.url}
                        alt={foregroundIMG.description}
                      />
                    )}
                  </ContentWrapper>
                </StickyContainer>
              );
            }}
          </Scene>
        </Controller>
      </Box>
    );
  }
}

export default HorizontalPanelAnimation;
