import qs from 'qs';
import { extractProperty } from './extractProperty';

const extractSsrProperty = ({
  windowProp,
  globalProp,
  mapWindowProp = a => a,
  mapGlobalProp = a => a,
}) =>
  typeof window !== 'undefined'
    ? mapWindowProp(extractProperty(window, windowProp))
    : mapGlobalProp(extractProperty(global, globalProp));

export const getSsrPath = () =>
  extractSsrProperty({
    windowProp: 'location.pathname',
    globalProp: 'ssrLocation.pathname',
  });

export const getSsrQueryParams = () =>
  extractSsrProperty({
    windowProp: 'location.search',
    globalProp: 'ssrLocation.queryParams',
    mapWindowProp: queryString =>
      qs.parse(queryString, { ignoreQueryPrefix: true }),
  });
