import { META_TAG_PROP_TYPE } from '../common/constants';

const addHardcodedMetaTagsAndDedupe = (paramTags = []) => {
  const tags = paramTags || [];
  if (Array.isArray(tags)) {
    const hardcodedTags = [
      {
        metaType: 'property',
        nameOrProperty: 'og:image',
        content:
          'https://images.ctfassets.net/jtlkz9qbebd9/5VxXHNkcXM5txbvLWsGNov/21d70896cc9667cb698fb4585e9d9d2b/Freedom-Logo-Mobile.png',
      },
      {
        metaType: 'property',
        nameOrProperty: 'og:site_name',
        content: 'Freedom Mobile',
      },
      {
        metaType: 'property',
        nameOrProperty: 'og:type',
        content: 'website',
      },
    ];

    // Eventually, the value for locale will come from the app itself
    const locale = 'en-CA';
    const localeTag = {
      metaType: 'property',
      nameOrProperty: 'og:locale',
      content: (locale || 'en-CA').replace(/-/g, '_'),
    };

    const tagMap = new Map(tags.map(tag => [tag.nameOrProperty, tag]));

    // ADD OR REPLACE og:locale with locale from app
    tagMap.set('og:locale', localeTag);

    // Add hardcoded tags if they aern't in tagMap
    hardcodedTags.forEach(hardcodedTag => {
      if (!tagMap.has(hardcodedTag.nameOrProperty)) {
        tagMap.set(hardcodedTag.nameOrProperty, hardcodedTag);
      }
    });

    return Array.from(tagMap.values());
  }
  return [];
};

/* eslint-disable react/react-in-jsx-scope */
export const renderMetaTags = (tags = []) =>
  addHardcodedMetaTagsAndDedupe(tags).map(
    ({ metaType, nameOrProperty, content }) => {
      const metaProps = {};
      if (metaType === META_TAG_PROP_TYPE) {
        metaProps.name = nameOrProperty;
      } else {
        metaProps.property = nameOrProperty;
      }
      return (
        <meta
          key={`unique-key-${nameOrProperty}`}
          {...metaProps}
          content={content}
        />
      );
    },
  );

export const renderLinkTags = (tags = []) =>
  tags &&
  Array.isArray(tags) &&
  tags.map(({ rel, type, href }) => {
    if (!type) {
      return <link key={href} rel={rel} href={href} />;
    }
    return <link key={href} rel={rel} type={type} href={href} />;
  });
