import { getImageUrl } from '../../../utils/imageHelper';
const OPTS = { isTransparent: false };
export default class MediaFile {
  constructor(data, opts = OPTS) {
    if (data && data.fields) {
      const {
        title,
        description,
        file: { url, contentType, details },
      } = data.fields;
      this.title = title;
      this.description = description;
      this.url = getImageUrl(url);
      // NOTE: transparent PNGs/SVGs stop rendering transparently on Safari with the above line
      if (opts.isTransparent) {
        this.url = url;
      }
      this.contentType = contentType;
      this.details = details;
    }
  }
}
