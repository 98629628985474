import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { selectAddOns, selectLocale } from '../contentfulHoc/selectors';
import { getAddOns } from '../contentfulHoc/actions';
import AddonCardsComponent from '../../components/addonCards';
import AddOn from '../contentful/addOn';

export const AddonCards = ({
  addOns,
  categoryFilter,
  locale,
  onGetAddOns,
  identifier,
}) => {
  useEffect(() => {
    if (!addOns.length) {
      onGetAddOns(locale);
    }
  }, []);

  const filteredAddons = addOns.filter(
    addon => addon.category === categoryFilter,
  );
  return (
    <AddonCardsComponent addOns={filteredAddons} identifier={identifier} />
  );
};

AddonCards.propTypes = {
  addOns: PropTypes.arrayOf(PropTypes.instanceOf(AddOn)).isRequired,
  categoryFilter: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  onGetAddOns: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  addOns: selectAddOns(),
  locale: selectLocale(),
});

const mapDispatchToProps = dispatch => ({
  onGetAddOns: payload => dispatch(getAddOns(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddonCards);
