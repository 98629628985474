import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import qs from 'qs';
import { Formik, Form } from 'formik';
import { Box } from 'rebass/styled-components';
import { H1 } from 'web_component_library/typography';

import { naturalToKebab } from '../../../utils/stringHelpers';
import { getPageName } from '../ctaAnalyticsHoc/utils';

import {
  selectCurrentPage,
  selectArticlesList,
  selectLocale,
} from '../../containers/contentfulHoc/selectors';
import { getSupportEntries } from '../../containers/contentfulHoc/actions';

import { Input } from './css';

import { getSupportUrl, PAGE_SEARCH } from '../../constants';
import withTranslation from '../../containers/translationHoc';
import { isMobile } from '../../../utils/animationHelper';
import { breakpoints } from '../../../utils/mediaQuery';
import { setUtagForLinks } from '../../lib/analytics';

const SupportSearchInput = ({
  translate,
  options,
  isDarkBackground = false,
}) => {
  const articleList = useSelector(selectArticlesList());

  const locale = useSelector(selectLocale());
  const match = useRouteMatch();

  const categoryPageTitle = match?.params?.category;

  const availableOptions = options
    .map(option => option?.fields?.title)
    .filter(Boolean);

  const location = useLocation();
  const history = useHistory();
  const currentPage = useSelector(selectCurrentPage());

  const dispatch = useDispatch();

  const searchQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (!Object.keys(articleList).length) {
      dispatch(getSupportEntries({ locale }));
    }
  }, []);
  const onSubmit = (value, isSelectedFromList) => {
    let results;
    let article;
    const search = value;
    if (isSelectedFromList) {
      results = availableOptions.find(option =>
        option.toLowerCase().includes(search.toLowerCase()),
      );
      article = articleList[results || search];
    }

    const searchedTerm = results || search;

    const pageName = getPageName(currentPage);

    setUtagForLinks({
      custom_link: `content|${pageName}|search|${naturalToKebab(searchedTerm)}`,
      search_string: search,
    });

    if (article) {
      history.push(`/${locale}/${getSupportUrl()}/${article.slug}`, article);
    } else if (searchedTerm) {
      history.push(
        `/${locale}/${getSupportUrl()}/${PAGE_SEARCH}?q=${searchedTerm}${
          isSelectedFromList ? '&isSelected=true' : ''
        }`,
      );
    }
  };

  const onDoSubmitHandler = value => {
    onSubmit(value.search, false);
  };

  const onDoSearchHandler = value => {
    onSubmit(value, true);
  };

  return (
    <Box>
      {/* When this input is used in the Support Categories page, we want to display the category as the page title */}
      {categoryPageTitle && (
        <H1
          color={isDarkBackground ? 'white' : 'black'}
          mb={13}
          style={{ textTransform: 'uppercase' }}
        >
          {categoryPageTitle}
        </H1>
      )}

      <Box pt={0} pb={17} mb={[13, '30px']} mt={7}>
        <Formik
          initialValues={{
            search: searchQuery.q || '',
          }}
          onSubmit={onDoSubmitHandler}
          render={({ values, handleChange, setFieldValue }) => (
            <Form data-testid="support-search-form">
              <Input
                id="search"
                testId="support-search-input"
                className="support-search"
                value={values.search}
                label={
                  isMobile(breakpoints[1])
                    ? translate('inputLabelMobile')
                    : translate('whatCanWeHelpYouWith')
                }
                placeholder={
                  isMobile(breakpoints[1])
                    ? translate('inputPlaceholderMobile')
                    : translate('askUsQuestion')
                }
                onChange={handleChange}
                setFieldValue={setFieldValue}
                list={availableOptions}
                search={onDoSearchHandler}
                isSearchButtonVisible
                editButtonText=""
                isDarkBackground={isDarkBackground}
              />
            </Form>
          )}
        />
      </Box>
    </Box>
  );
};

SupportSearchInput.propTypes = {
  translate: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isDarkBackground: PropTypes.bool,
};

export default compose(
  withRouter,
  withTranslation,
)(SupportSearchInput);
