import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'web_component_library/divider';
import Button from 'web_component_library/button';
import TextBlock from 'web_component_library/textBlock';
import { Flex, Box } from 'rebass/styled-components';

import { ChatCtaModal, CtaModal } from '../../containers/contentful';
import { launchLiveChat } from '../../../utils/liveChatHelper';
import ChatCta from '../chatCta';
import { naturalToKebab } from '../../../utils/stringHelpers';

const defaultVariantLight = 'light';
const ContactUsToday = ({
  contactUsTodayTitle,
  liveChat,
  variantLight = defaultVariantLight,
}) => {
  const isCtaButton = liveChat.sys.contentType.sys.id === 'ctaButton';
  const isLpButton = liveChat.sys.contentType.sys.id === 'ctaChatButton';
  const liveChatModal = isCtaButton
    ? new CtaModal(liveChat)
    : new ChatCtaModal(liveChat);
  return (
    <Box width={1}>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        pt={[13, 23]}
        pb={[13, 29]}
        bg="pureWhite"
      >
        <Box width={[1, 1 / 2]} mb={[11, 0]}>
          <TextBlock data={contactUsTodayTitle} blockVariant={variantLight} />
        </Box>
        <Box mx="auto" />
        <Box width={[1, 1 / 3]} px={[0, 3]}>
          {isCtaButton && (
            <Button
              id={
                liveChatModal.chatEngagementId ||
                naturalToKebab(liveChatModal.value)
              }
              onClick={() => launchLiveChat()}
              variant={liveChatModal.variant}
            >
              {liveChatModal.value}
            </Button>
          )}
          {isLpButton && (
            <ChatCta
              isFullImageBackground={false}
              chatEngagementId={liveChatModal.chatEngagementId}
              id={
                liveChatModal.chatEngagementId ||
                naturalToKebab(liveChatModal.value)
              }
              appleChatBizIntentId={liveChatModal.appleChatBizIntentId}
              appleChatBizGroupId={liveChatModal.appleChatBizGroupId}
              appleChatBody={liveChatModal.appleChatBody}
              value={liveChatModal.value}
              variant={liveChatModal.variant}
              type={liveChatModal.type}
              isAppleChatOn={liveChatModal.isAppleChatOn}
            ></ChatCta>
          )}
        </Box>
      </Flex>
      <Divider thickness={2} color="black" />
    </Box>
  );
};

ContactUsToday.propTypes = {
  contactUsTodayTitle: PropTypes.object.isRequired,
  liveChat: PropTypes.object.isRequired,
  variantLight: PropTypes.string,
};

export default ContactUsToday;
