import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import { AddonList, Li } from '../addon/css';
import CardTemplate from '../cardTemplate';
import AddOn from '../../containers/contentful/addOn';
import withCtaAnalytics from '../ctaAnalyticsHoc';

const AddonCardWithCtaAnalytics = withCtaAnalytics(CardTemplate);

export const AddonCards = ({ addOns, identifier }) => {
  if (!addOns.length) return null;

  return (
    <Box width={1} mb={[13, '3.25em']} data-testid="addonCards">
      <Flex width={1} flexWrap="wrap" justifyContent="space-between">
        <AddonList>
          {addOns.map(
            (
              {
                category,
                name,
                image,
                description,
                descriptionRichText,
                customDescription,
                descriptionPriceText,
                ctaLearnMore,
                price,
                priceUnit,
                learnMoreText,
                tooltipContent,
                buttonCta,
                buttonCtaUrl,
              },
              index,
            ) => (
              <Li key={name}>
                <AddonCardWithCtaAnalytics
                  identifier={identifier}
                  imageUrl={image ? image.url : null}
                  category={category}
                  name={name}
                  description={description}
                  descriptionRichText={descriptionRichText}
                  customDescription={customDescription}
                  cta={ctaLearnMore}
                  amount={price}
                  period={priceUnit}
                  learnMoreText={learnMoreText}
                  tooltipContent={tooltipContent}
                  descriptionPriceText={descriptionPriceText}
                  buttonCta={buttonCta}
                  buttonCtaUrl={buttonCtaUrl}
                  rowNumber={Math.ceil((index + 1) / 3)}
                />
              </Li>
            ),
          )}
        </AddonList>
      </Flex>
    </Box>
  );
};

AddonCards.propTypes = {
  addOns: PropTypes.arrayOf(PropTypes.instanceOf(AddOn)).isRequired,
  identifier: PropTypes.string.isRequired,
};

export default AddonCards;
