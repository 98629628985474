import ContentfulModel from './contentfulModel';
import PlanSpec from './planSpec';
import Promotion from './promotion';
import AvailablePromotion from './availablePromotion';
import Feature from './feature';
import Tag from './tag';
import Category from './planCategory';
import PromoCard from './promoCard';
import PlanDataHeader from './planDataHeader';
import TertiaryPromotion from './tertiaryPromotion';
import PlanCoverageZone from './planCoverageZone';
import InnerPromoBanner from './innerPromoBanner';
import PlanNameOnCard from './planNameOnCard';

export default class Plan extends ContentfulModel {
  constructor(payload) {
    super(payload);
    if (payload && payload.fields && payload.sys) {
      const {
        sku,
        name,
        shortName,
        nameEyebrowText,
        enlargeEyebrowText,
        nameTailText,
        data,
        talk,
        text,
        coverageZones,
        includedFeatures,
        nationwideNetwork,
        price,
        discountedPrice,
        byodPrice,
        promotion,
        availablePromotions,
        socCode,
        tags,
        categories,
        promoCard,
        primaryDataHeader,
        talkTextDataHeader,
        pricePeriod,
        conditionsDescription,
        promotionFlag,
        tertiaryPromotion,
        planDetailsTooltipContent,
        planDetailsSubcopy,
        directToFindAStore,
        promotionTooltipContent,
        showRegularPrice,
        tertiaryPromotionForByop,
        availablePromotionsByod,
        planNameOnCard,
        priceTextColor,
        planCardHeaderColor,
        innerPromoBanner,
      } = payload.fields;
      this.id = payload.sys.id;
      this.sku = sku;
      this.name = name;
      this.shortName = shortName || '';
      this.nameEyebrowText = nameEyebrowText;
      this.enlargeEyebrowText = enlargeEyebrowText;
      this.nameTailText = nameTailText;
      this.data = new PlanSpec(data);
      this.talk = new PlanSpec(talk);
      this.text = new PlanSpec(text);
      this.nationwideNetwork = this.extractEntriesToList(
        nationwideNetwork,
        PlanSpec,
      );

      this.coverageZones = this.extractEntriesToList(
        coverageZones,
        PlanCoverageZone,
      );
      // DEPRECATED: We only use these fields to populate coverage zones if `coverageZones` field is empty
      if (this.coverageZones.length === 0) {
        // This is "Freedom Network" header
        const primaryBucketHeader = primaryDataHeader
          ? new PlanDataHeader(primaryDataHeader)
          : null;
        // This is "Freedom Nationwide" header
        const secondaryBucketHeader = talkTextDataHeader
          ? new PlanDataHeader(talkTextDataHeader)
          : null;
        this.coverageZones = [
          {
            header: primaryBucketHeader,
            specs: [this.data, this.talk, this.text],
          },
          { header: secondaryBucketHeader, specs: [this.nationwideNetwork[0]] },
        ];
      }

      this.includedFeatures = this.extractEntriesToList(
        includedFeatures,
        Feature,
      );
      this.price = price;
      this.pricePeriod = pricePeriod;
      this.discountedPrice = discountedPrice || 0;
      this.byodPrice = byodPrice;
      this.showRegularPrice = showRegularPrice;
      this.promotion = this.extractEntriesToList(promotion, Promotion);
      this.availablePromotions = this.extractEntriesToList(
        availablePromotions,
        AvailablePromotion,
      );
      this.socCode = socCode;
      this.tags = this.extractEntriesToList(tags, Tag);
      this.categories = this.extractEntriesToObject(
        categories,
        Category,
        'category',
      );
      this.promoCard = new PromoCard(promoCard);
      this.conditionsDescription = conditionsDescription;
      this.promotionFlag = promotionFlag ? new Promotion(promotionFlag) : null;
      this.tertiaryPromotion = tertiaryPromotion
        ? new TertiaryPromotion(tertiaryPromotion)
        : null;
      this.tertiaryPromotionForByop = tertiaryPromotionForByop
        ? new TertiaryPromotion(tertiaryPromotionForByop)
        : null;
      this.planDetailsTooltipContent = planDetailsTooltipContent
        ? new PlanDataHeader(planDetailsTooltipContent)
        : null;
      this.planDetailsSubcopy = planDetailsSubcopy;
      this.directToFindAStore = directToFindAStore;
      this.promotionTooltipContent =
        promotionTooltipContent?.fields?.tooltipContent || null;
      this.availablePromotionsByod = this.extractEntriesToList(
        availablePromotionsByod,
        AvailablePromotion,
      );

      this.planNameOnCard = planNameOnCard?.fields
        ? new PlanNameOnCard(planNameOnCard)
        : null;
      this.priceTextColor = priceTextColor;
      this.planCardHeaderColor = planCardHeaderColor || null;
      this.innerPromoBanner = innerPromoBanner?.length
        ? innerPromoBanner.map(item => new InnerPromoBanner(item))
        : [];
    }
  }
}
