import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Button from 'web_component_library/button';
import TextBlock from 'web_component_library/textBlock';
import Text from 'web_component_library/text';
import LoadingButton from 'web_component_library/loadingButton';
import Input from 'web_component_library/input';
import Box from 'web_component_library/box';
import Flex from 'web_component_library/flex';
import MediaFile from 'web_component_library/utils/models/mediaFile';

import { getImeiCheckStatus, getImeiCheckStatusClear } from './actions';
import { makeSelectImeiCheckStatus } from './selectors';
import BannerContainer from '../compatibilityCheckSelection/bannerContainer';

const defaultBackgroundMobileImage = {};
const defaultImeiCheckDescription = [];
const defaultOnGetImeiCheckStatus = () => {};
const defaultOnGetImeiCheckStatusClear = () => {};
const ImeiCompatibilityCheck = ({
  backButton,
  backgroundDesktopImage,
  backgroundMobileImage = defaultBackgroundMobileImage,
  checkButton,
  headerTextBlock,
  imeiCheckDescription = defaultImeiCheckDescription,
  inputImeiField,
  imeiCheckMessage,
  onGetImeiCheckStatus = defaultOnGetImeiCheckStatus,
  onGetImeiCheckStatusClear = defaultOnGetImeiCheckStatusClear,
  imeiCompatibilityCheckResultPageUrl,
  invalidImeiMessage,
  errorImeiCheckCompatibility,
}) => {
  const backgroundDesktopUrl = new MediaFile(backgroundDesktopImage).url;
  const backgroundMobileUrl = backgroundMobileImage
    ? new MediaFile(backgroundMobileImage).url
    : '';
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const { brand } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const validateField = value => {
    const { imeiValue } = value;
    const errors = {};
    if (imeiValue.length < 15) {
      setErrorMessage(invalidImeiMessage);
      errors.imeiValue = { invalidImeiMessage };
    }
    return errors;
  };

  const onSubmit = imeiNumber => {
    setIsLoading(true);
    onGetImeiCheckStatus({ imei: imeiNumber });
  };

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (imeiCheckMessage?.hasOwnProperty('isCompatible')) {
      const imeiResult = qs.stringify(imeiCheckMessage);
      history.push({
        pathname: imeiCompatibilityCheckResultPageUrl,
        search: brand ? `${imeiResult}&brand=${brand}` : imeiResult,
      });
    }
    if (imeiCheckMessage?.status) {
      setErrorMessage(errorImeiCheckCompatibility);
      setIsLoading(false);
    }
  }, [imeiCheckMessage]);

  useEffect(
    () => () => {
      onGetImeiCheckStatusClear();
    },
    [],
  );

  const digitOnly = value => {
    if (value && typeof value !== 'number') {
      return value.replace(/[^\d]/g, '');
    }
    return value;
  };

  return (
    <Box>
      <BannerContainer
        backgroundImageUrl={backgroundDesktopUrl}
        mobileBackgroundImageUrl={backgroundMobileUrl}
      >
        <Box width={[1, 0.6]}>
          <TextBlock
            headingStyles={{ pb: [0, 7], mb: 7 }}
            data={headerTextBlock}
            blockVariant="dark"
          />
        </Box>
      </BannerContainer>
      <Box mt={17} px={[13, 0]} mx="auto" maxWidth="700px">
        <Formik
          initialValues={{ imeiValue: '' }}
          validate={validateField}
          validateOnChange={false}
          onSubmit={values => onSubmit(values.imeiValue)}
          render={({ values, handleChange, setFieldValue }) => (
            <Form>
              <Input
                id="imeiValue"
                label={inputImeiField.fields.label}
                name="imeiValue"
                value={digitOnly(values.imeiValue)}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                data-testid="imei-input-number"
                maxLength="15"
                mb={[0, 7]}
                mt={7}
              />
              {errorMessage && (
                <Box mt={7}>
                  <Text
                    data-testid="imei-input-error-message"
                    color="errorRed"
                    fontSize={3}
                  >
                    {errorMessage}
                  </Text>
                </Box>
              )}
              <Flex
                width={1}
                justifyContent="space-between"
                flexWrap="wrap"
                pt={13}
              >
                <Box width={[1, '48%']} mt={[7, 3]} maxHeight="200px">
                  <LoadingButton
                    variant={checkButton.fields.variant}
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    {checkButton.fields.value}
                  </LoadingButton>
                </Box>
                <Box width={[1, '48%']} mt={[7, 3]}>
                  <Button
                    variant={backButton.fields.variant}
                    onClick={e => {
                      e.preventDefault();
                      history.goBack();
                    }}
                    fontSize={4}
                  >
                    {backButton.fields.value}
                  </Button>
                </Box>
              </Flex>
            </Form>
          )}
        />
        <Box mt={[13, 17]} width={1} textAlign="left">
          {imeiCheckDescription.map(description => (
            <TextBlock
              data={description.fields.title}
              key={description.sys.id}
              paragraphStyles={{ mb: '17' }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

ImeiCompatibilityCheck.propTypes = {
  backButton: PropTypes.object.isRequired,
  backgroundDesktopImage: PropTypes.object.isRequired,
  backgroundMobileImage: PropTypes.object,
  checkButton: PropTypes.object.isRequired,
  headerTextBlock: PropTypes.object.isRequired,
  imeiCheckDescription: PropTypes.arrayOf(PropTypes.object),
  inputImeiField: PropTypes.object.isRequired,
  imeiCheckMessage: PropTypes.object.isRequired,
  onGetImeiCheckStatus: PropTypes.func,
  onGetImeiCheckStatusClear: PropTypes.func,
  imeiCompatibilityCheckResultPageUrl: PropTypes.string.isRequired,
  invalidImeiMessage: PropTypes.string.isRequired,
  errorImeiCheckCompatibility: PropTypes.string.isRequired,
};

const mapStateToProps = () =>
  createStructuredSelector({
    imeiCheckMessage: makeSelectImeiCheckStatus(),
  });

export const mapDispatchToProps = dispatch => ({
  onGetImeiCheckStatus: data =>
    dispatch(getImeiCheckStatus({ imeiNumber: data })),
  onGetImeiCheckStatusClear: () => dispatch(getImeiCheckStatusClear()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImeiCompatibilityCheck);
