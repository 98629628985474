import React, { useEffect, useState } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import MediaFile from 'web_component_library/utils/models/mediaFile';
import Image from 'web_component_library/image';
import stringInterpolation from 'web_component_library/utils/stringInterpolation';
import { H1, Paragraph } from 'web_component_library/typography';
import Button from 'web_component_library/button';
import Flex from 'web_component_library/flex';
import Box from 'web_component_library/box';
import Input from 'web_component_library/input';

import {
  selectCompatibilityCheckOptions,
  selectLocale,
} from '../contentfulHoc/selectors';
import { getCompatibilityCheckOptions } from '../contentfulHoc/actions';
import BannerContainer from './bannerContainer';
import withTranslation from '../translationHoc';

export const autocompleteResultSpacer = ' - ';
export const otherBrandName = 'Other';

const defaultBackgroundImageMobile = {};
const defaultCompatibilityCheckOptions = {};
const CompatibilityCheckSelection = ({
  brandHeaderText,
  modelHeaderText,
  locationHeaderText,
  onGetCompatibilityCheckOptions,
  compatibilityCheckOptions = defaultCompatibilityCheckOptions,
  brandSelectionImage,
  locale,
  backgroundImage,
  backgroundImageMobile = defaultBackgroundImageMobile,
  compatibilityCheckResultPageUrl,
  modelSelectionImage,
  locationSelectionImage,
  areImagesTransparent = false,
  imeiCheckerUrl,
  translate,
}) => {
  const backButtonText = translate('backButtonText');
  const disclaimerLabel = translate('disclaimerLabel');
  const disclaimerDescription = translate('disclaimerDescription');
  const noResultsErrorMessage = translate('noResultsErrorMessage');
  const brandStepInputLabel = translate('brandStepInputLabel');
  const modelStepInputLabel = translate('modelStepInputLabel');
  const requiredBrandOrModelError = translate('requiredBrandOrModelError');
  const requiredModelError = translate('requiredModelError');
  const brandStepInputInstructions = translate('brandStepInputInstructions');
  const modelStepInputInstructions = translate('modelStepInputInstructions');

  const firstStep = 'brand';
  const history = useHistory();
  const location = useLocation();
  const [elementsToRender, setElementsToRender] = useState(null);
  const [step, setStep] = useState(firstStep);
  const backgroundUrl = new MediaFile(backgroundImage).url;
  const mobileBackgroundImageUrl = backgroundImageMobile
    ? new MediaFile(backgroundImageMobile).url
    : '';
  const [selectedBrandOrModel, setSelectedBrandOrModel] = useState(null);
  const [lastSubmittedValue, setLastSubmittedValue] = useState('');

  const paramsAreValid = (brand, model, purchaseLocation) => {
    const {
      modelOptions,
      brandsOptions,
      locationOptions,
    } = compatibilityCheckOptions;

    const isPhoneValid =
      !brand || brandsOptions?.some(phoneOption => phoneOption === brand);
    const isLocationValid =
      !purchaseLocation ||
      locationOptions?.some(
        locationOption => locationOption.name === purchaseLocation,
      );
    const isModelValid =
      !model ||
      modelOptions?.some(
        modelOption =>
          modelOption.brand === brand && modelOption.modelName === model,
      );

    return isPhoneValid && isLocationValid && isModelValid;
  };

  useEffect(() => {
    if (Object.keys(compatibilityCheckOptions).length) {
      window.scrollTo(0, 0);
      const {
        modelOptions,
        brandsOptions,
        locationOptions,
      } = compatibilityCheckOptions;
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      const { brand, model, purchaseLocation } = queryParams;

      if (
        (!brand && model) ||
        (purchaseLocation && !model) ||
        !paramsAreValid(brand, model, purchaseLocation)
      ) {
        setStep(firstStep);

        history.replace({
          pathname: location.pathname,
          search: '',
        });
        return;
      }

      const isTransparent = areImagesTransparent;

      if (purchaseLocation && step !== 'location') {
        history.replace({
          pathname: compatibilityCheckResultPageUrl,
          search: qs.stringify(queryParams),
        });
      } else if (model) {
        const locationOptionsNames = locationOptions.map(locationOption => {
          if (locationOption.locationId === 'freedomModel') {
            return translate('brandLocation') || locationOption.name;
          }
          return locationOption.name;
        });
        setElementsToRender({
          header: locationHeaderText,
          options: locationOptionsNames,
          image: new MediaFile(locationSelectionImage, { isTransparent }),
        });
        setStep('location');
      } else if (brand) {
        const modelOptionsAvailable = modelOptions?.reduce(
          (acc, modelOption) => {
            if (modelOption.brand === brand) {
              acc.push(modelOption.modelName);
            }
            return acc;
          },
          [],
        );
        setElementsToRender({
          header: modelHeaderText,
          options: modelOptionsAvailable,
          autocompleteSuggestions: modelOptionsAvailable,
          image: new MediaFile(modelSelectionImage, { isTransparent }),
        });
        setStep('model');
      } else {
        setElementsToRender({
          header: brandHeaderText,
          options: brandsOptions,
          image: new MediaFile(brandSelectionImage, { isTransparent }),
          autocompleteSuggestions: modelOptions?.map(
            ({ brand: brandName, modelName }) =>
              `${brandName}${autocompleteResultSpacer}${modelName}`,
          ),
        });
        setStep(firstStep);
      }
    }
  }, [Object.keys(compatibilityCheckOptions).length, location.search]);

  useEffect(() => {
    if (!Object.keys(compatibilityCheckOptions).length)
      onGetCompatibilityCheckOptions(locale);
  }, []);

  const onOtherSelect = () => {
    history.push({
      pathname: imeiCheckerUrl,
      search: '',
    });
  };

  const onSelect = selection => {
    if (selection === otherBrandName) {
      onOtherSelect();
    } else {
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const search = {
        ...queryParams,
        brand: step === 'brand' ? selection : queryParams.brand,
        model: step === 'model' ? selection : queryParams.model,
        purchaseLocation:
          step === 'location' ? selection : queryParams.purchaseLocation,
      };
      let { pathname } = location;
      let modelFeatures = {};
      if (step === 'location') {
        const { modelOptions, locationOptions } = compatibilityCheckOptions;
        const isSelectedLocationCustom =
          selection === translate('brandLocation');
        let selectedLocationId;
        if (isSelectedLocationCustom) {
          selectedLocationId = 'freedomModel';
        } else {
          selectedLocationId = locationOptions.find(
            locationOption => locationOption.name === selection,
          ).locationId;
        }
        modelFeatures = modelOptions?.find(
          modelOption =>
            modelOption.brand === queryParams.brand &&
            modelOption.modelName === queryParams.model,
        ).features[selectedLocationId].fields;
        pathname = !modelFeatures.requireImeiCheck
          ? compatibilityCheckResultPageUrl
          : imeiCheckerUrl;
      }

      // passing the modelFeatures as state to avoid iterating through the same object again in the compatibility check result
      history.push({
        pathname,
        search: qs.stringify(search),
        state: { modelFeatures },
      });
    }
  };

  const handleAutocompleteSelect = selectedOption => {
    const [brand, model] = selectedOption.split(autocompleteResultSpacer);

    if (brand === otherBrandName) {
      onOtherSelect();
      return;
    }

    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const search = {
      ...queryParams,
      brand: step === firstStep ? brand : queryParams.brand,
      model: step === firstStep ? model : selectedOption,
    };

    history.push({
      search: qs.stringify(search),
    });
  };

  const isValidBrand = value =>
    compatibilityCheckOptions.brandsOptions?.some(
      brand => brand.toLowerCase() === value.toLowerCase(),
    );

  /*
    Given that the autocomplete list is both used for rendering and validating the user input
    we need to force the value to conform to the format of the results.

    If the user has entered a space and has not explicitly included a hyphen we check if the entered value
    matches with one of the brand names before adding a hyphen. We don't automatically add a hyphen after the first space because
    although currently no brands have spaces in their name doesn't mean there will never.
  */
  const addResultSpacerToInputValue = value =>
    !value.includes(autocompleteResultSpacer) &&
    value.includes(' ') &&
    isValidBrand(value.trim())
      ? value.replace(/ /, autocompleteResultSpacer)
      : value;

  const validateInput = values => {
    const inputValue = values.compatibilityCheckOption;

    if (!inputValue) {
      setLastSubmittedValue('');
      return {
        compatibilityCheckOption:
          step === 'brand' ? requiredBrandOrModelError : requiredModelError,
      };
    }

    if (step === 'model') {
      const modelsOfSelectedBrand = elementsToRender.autocompleteSuggestions;
      const validModel = modelsOfSelectedBrand.find(
        model => model.toLowerCase() === inputValue.toLowerCase(),
      );

      if (!validModel) {
        setLastSubmittedValue(inputValue);
        return { compatibilityCheckOption: noResultsErrorMessage };
      }

      setSelectedBrandOrModel(validModel);
    } else {
      const validBrandOrPhoneModel = compatibilityCheckOptions.modelOptions?.find(
        ({ brand, modelName }) => {
          if (inputValue.includes(autocompleteResultSpacer)) {
            const [inputBrand, inputModel] = inputValue.split(
              autocompleteResultSpacer,
            );

            return (
              brand.toLowerCase() === inputBrand.toLowerCase() &&
              modelName.toLowerCase() === inputModel.toLowerCase()
            );
          }

          return (
            brand.toLowerCase() === inputValue.toLowerCase() ||
            modelName.toLowerCase() === inputValue.toLowerCase()
          );
        },
      );

      if (!validBrandOrPhoneModel) {
        setLastSubmittedValue(inputValue);
        return { compatibilityCheckOption: noResultsErrorMessage };
      }

      const isBrand =
        validBrandOrPhoneModel.brand.toLowerCase() === inputValue.toLowerCase();

      setSelectedBrandOrModel(
        isBrand
          ? { brand: validBrandOrPhoneModel.brand }
          : validBrandOrPhoneModel,
      );
    }

    return {};
  };

  const handleSubmit = () => {
    if (selectedBrandOrModel.brand === otherBrandName) {
      onOtherSelect();
      return;
    }

    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (step === 'model') {
      const search = {
        ...queryParams,
        brand: queryParams.brand,
        model: selectedBrandOrModel,
      };

      history.push({
        search: qs.stringify(search),
      });
    } else {
      const search = {
        ...queryParams,
      };

      if (selectedBrandOrModel.brand) {
        search.brand = selectedBrandOrModel.brand;
      }

      if (selectedBrandOrModel.modelName) {
        search.model = selectedBrandOrModel.modelName;
      }

      history.push({
        search: qs.stringify(search),
      });
    }
  };

  if (!elementsToRender) return null;
  return (
    <Box pb={17}>
      <BannerContainer
        backgroundImageUrl={backgroundUrl}
        mobileBackgroundImageUrl={mobileBackgroundImageUrl}
      >
        <H1 color="white">{elementsToRender.header}</H1>
        <Box mt={17}>
          <Image
            url={elementsToRender.image.url}
            alt={elementsToRender.image.description || ''}
            mobileUrl={elementsToRender.image.url}
          />
        </Box>
      </BannerContainer>
      <Box maxWidth="1104px" mx="auto">
        <Flex flexWrap="wrap" justifyContent="center" px={[3, 7]} pt={17}>
          {elementsToRender.autocompleteSuggestions && (
            <Box width={1} mx={[3, 0]} mb={[7, 17]}>
              <Box width={[1, 1 / 2]} mx="auto">
                <Formik
                  initialValues={{ compatibilityCheckOption: '' }}
                  validateOnChange={false}
                  validate={validateInput}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    setFieldValue,
                    handleChange,
                    setValues,
                    errors,
                  }) => (
                    <Form>
                      <Box>
                        <Input
                          id="compatibilityCheckOption"
                          onChange={handleChange}
                          value={values.compatibilityCheckOption}
                          onKeyUp={e => {
                            if (e.key === 'Backspace' || step !== firstStep) {
                              return;
                            }

                            setValues({
                              compatibilityCheckOption: addResultSpacerToInputValue(
                                e.target.value,
                              ),
                            });
                          }}
                          placeholder={
                            step === 'brand'
                              ? brandStepInputInstructions
                              : modelStepInputInstructions
                          }
                          label={
                            step === 'brand'
                              ? brandStepInputLabel
                              : modelStepInputLabel
                          }
                          isSearchButtonVisible
                          searchButtonText=""
                          clearButtonText=""
                          setFieldValue={setFieldValue}
                          list={elementsToRender.autocompleteSuggestions}
                          search={handleAutocompleteSelect}
                        />
                      </Box>
                      {errors.compatibilityCheckOption && (
                        <Paragraph color="errorRed">
                          {lastSubmittedValue
                            ? stringInterpolation(
                                errors.compatibilityCheckOption,
                                lastSubmittedValue,
                              )
                            : errors.compatibilityCheckOption}
                        </Paragraph>
                      )}
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          )}
          {elementsToRender.options?.map(option => (
            <Box
              key={option}
              width={1 / 6}
              minWidth={['15rem', '10rem']}
              minHeight="12rem"
              py={7}
              mx={[3, 7]}
            >
              <Button
                onClick={() => onSelect(option)}
                variant="primaryWhiteSquare"
                width={1}
              >
                {option}
              </Button>
            </Box>
          ))}
        </Flex>
        {step !== firstStep && (
          <Box
            width={[1, 1 / 4]}
            pt={17}
            mx="auto"
            px={[7, 0]}
            textAlign="center"
          >
            <Button
              variant="secondary"
              sx={{ minWidth: '10rem' }}
              onClick={() => history.goBack()}
            >
              {backButtonText}
            </Button>
          </Box>
        )}
        {step === firstStep && (
          <Box width={1} mx="auto" px={17} pt={7}>
            <Paragraph isStrong>{disclaimerLabel}</Paragraph>
            <Paragraph>{disclaimerDescription}</Paragraph>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CompatibilityCheckSelection.propTypes = {
  brandHeaderText: PropTypes.string.isRequired,
  onGetCompatibilityCheckOptions: PropTypes.func.isRequired,
  compatibilityCheckOptions: PropTypes.shape({
    brandsOptions: PropTypes.arrayOf(PropTypes.string),
    modelOptions: PropTypes.arrayOf(PropTypes.object),
    locationOptions: PropTypes.arrayOf(
      PropTypes.shape({
        locationId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
  modelHeaderText: PropTypes.string.isRequired,
  locationHeaderText: PropTypes.string.isRequired,
  brandSelectionImage: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object.isRequired,
  backgroundImageMobile: PropTypes.object,
  compatibilityCheckResultPageUrl: PropTypes.string.isRequired,
  modelSelectionImage: PropTypes.object.isRequired,
  locationSelectionImage: PropTypes.object.isRequired,
  areImagesTransparent: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  imeiCheckerUrl: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  compatibilityCheckOptions: selectCompatibilityCheckOptions(),
  locale: selectLocale(),
});

const mapDispatchToProps = dispatch => ({
  onGetCompatibilityCheckOptions: locale =>
    dispatch(getCompatibilityCheckOptions({ locale })),
});

export default compose(
  withTranslation,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CompatibilityCheckSelection);
