import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import ExpandCollapse from '../accessibility/compatiblePhones';

const stylingExpandCollapseCompatiblePhones = {
  lineHeight: '1rem',
  divider: true,
  tag: 'h4',
  my: 14,
  fontSize: 8,
  fontWeight: 3,
};
const defaultTextVariant = 'light';

const WirelessPublicAlert = ({
  accordion,
  contentSection,
  textVariant = defaultTextVariant,
}) => (
  <Box width={1} alignItems="left">
    <Box>
      <TextBlock
        data={contentSection}
        blockVariant={textVariant}
        paragraphStyles={{ mt: 7, mb: 13 }}
        headingStyles={{ mt: 21 }}
      />
    </Box>
    <Box mt={3}>
      {accordion && (
        <ExpandCollapse
          accordions={accordion}
          headingProps={stylingExpandCollapseCompatiblePhones}
        />
      )}
    </Box>
  </Box>
);

WirelessPublicAlert.propTypes = {
  contentSection: PropTypes.object.isRequired,
  accordion: PropTypes.arrayOf(PropTypes.object).isRequired,
  textVariant: PropTypes.string,
};

export default WirelessPublicAlert;
