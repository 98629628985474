import LinkTag from './linkTag';
import MetaTag from './metaTag';

export class Article {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const {
        content,
        title,
        slug,
        html,
        identifier,
        url,
        metaTag,
        schema,
        linkTag,
      } = data.fields;
      this.id = data.sys.id;
      this.title = title;
      this.content = content;
      this.html = html;
      this.slug = slug;
      this.identifier = identifier;
      this.url = url;
      this.metaTag = new MetaTag(metaTag);
      this.linkTag = new LinkTag(linkTag);
      this.schema = schema;
    }
  }
}

export default class ArticlesList {
  constructor(payload) {
    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }

    payload.forEach(article => {
      if (article && article.fields) {
        const { title } = article.fields;
        this[title] = new Article(article);
      }
    });
  }
}
