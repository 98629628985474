import ContentfulModel from './contentfulModel';
import PromoBanner from './promoBanner';
import PromoBannerL from './promoBannerL';
import PromoBannerList from './promoBannerList';
import Carousel from './carousel';
import AlertBanner from './alertBanner';
import Maintenance from './maintenance';
import MetaTag from './metaTag';
import LinkTag from './linkTag';
import Translation from './translation';
import QuestionAndAnswer from './questionAndAnswer';
import { PAGE_SUPPORT_BASE } from '../../constants';

export default class Page extends ContentfulModel {
  constructor(data) {
    super(data);
    if (data && data.fields && data.sys) {
      const {
        title,
        url,
        topAlertBanner,
        bottomAlertBanner,
        maintenance,
        promoBanner,
        components,
        metaTag,
        linkTag,
        schema,
        isTermsAndConditionsVisible,
        pageName,
        pageSection,
        pageBreadcrumb,
        pagePurpose,
        breadcrumbColor,
        translations,
        questions,
        supplementalTermsAndConditions,
        includeRegionsDropdown,
        exactUrlNotRequired,
        hideBreadCrumbs,
        redirectTo,
        hideHeaderAndFooter,
      } = data.fields;
      this.title = title;

      this.url = url;
      this.topAlertBanner = topAlertBanner
        ? new AlertBanner(topAlertBanner)
        : null;
      this.bottomAlertBanner = bottomAlertBanner
        ? new AlertBanner(bottomAlertBanner)
        : null;
      this.maintenance = maintenance ? new Maintenance(maintenance) : null;

      if (promoBanner && promoBanner.fields) {
        switch (promoBanner.fields.identifier) {
          case 'promoBanner':
            this.heroContent = new PromoBanner(promoBanner);
            break;
          case 'promoBannerL':
            this.heroContent = new PromoBannerL(promoBanner);
            break;
          case 'targetedPromoBannerList':
            this.heroContent = new PromoBannerList(promoBanner);
            break;
          case 'carousel':
            this.heroContent = new Carousel(promoBanner);
            break;
          default:
            this.heroContent = null;
        }
      }
      this.components = components;
      this.isTermsAndConditionsVisible = isTermsAndConditionsVisible;
      this.pageName = pageName;
      this.pageSection = pageSection;
      this.pageBreadcrumb = pageBreadcrumb;
      this.pagePurpose = pagePurpose;
      this.metaTag = new MetaTag(metaTag);
      this.linkTag = new LinkTag(linkTag);
      this.schema = schema;
      this.breadcrumbColor = breadcrumbColor;
      this.translations = this.extractEntriesToList(translations, Translation);
      this.questions = QuestionAndAnswer.instance(questions);
      this.supplementalTermsAndConditions = supplementalTermsAndConditions;
      this.includeRegionsDropdown = includeRegionsDropdown;
      this.id = data.sys.id;
      this.isPlaceHolder = !components;
      this.isSupport = this.url.split('/')[0] === PAGE_SUPPORT_BASE;
      this.exactUrlNotRequired = Boolean(exactUrlNotRequired);
      this.hideBreadCrumbs = hideBreadCrumbs;
      this.redirectTo = redirectTo;
      this.hideHeaderAndFooter = hideHeaderAndFooter;
    }
  }
}
