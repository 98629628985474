import styled from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import Button from 'web_component_library/button';

export const FooterCloseButton = styled(Button)`
  max-width: 100%;

  ${MediaQuery.desktop`
    max-width: 22em;
  `}
`;
