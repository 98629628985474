import React from 'react';
import WclRichText, {
  defaultRichTextOptions,
} from 'web_component_library/richText';
import { shape, object } from 'prop-types';

const defaultPropOptions = {
  renderMark: {},
  renderNode: {},
};
/**
 * RichText wrapper for library to set default options.
 * @param content
 * @param options
 * @returns {*}
 */
const RichText = ({
  document,
  options: {
    renderMark = defaultPropOptions.renderMark,
    renderNode = defaultPropOptions.renderNode,
  } = defaultPropOptions,
}) => {
  const mergedOptions = {
    renderMark: {
      ...defaultRichTextOptions.renderMark,
      ...renderMark,
    },
    renderNode: {
      ...defaultRichTextOptions.renderNode,
      ...renderNode,
    },
  };
  return <WclRichText document={document} options={mergedOptions} />;
};

RichText.propTypes = {
  document: object.isRequired,
  options: shape({
    renderMark: object,
    renderNode: object,
  }),
};

export default RichText;
