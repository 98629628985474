import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextBlock from 'web_component_library/textBlock';
import { Box, Text } from 'rebass/styled-components';

import { ExpandCollapseWrapper } from './css';
import { VARIANT_LIGHT } from '../../constants';

class ExpandCollapse extends Component {
  static propTypes = {
    accordions: PropTypes.arrayOf(PropTypes.object).isRequired,
    headingProps: PropTypes.object,
  };

  static defaultProps = {
    headingProps: {
      tag: 'h4',
      divider: true,
      headerNode: '',
    },
  };

  state = {
    panelStatus: {
      isPanelClosed: true,
      id: '',
    },
  };

  renderHeader = (title, panelStatus, headingProps) => {
    const isPanelExpanded =
      !panelStatus.isPanelClosed && panelStatus.id === title;
    const headingColor = isPanelExpanded ? 'pureBlack' : 'charcoal';
    return (
      <Box
        alignItems="flex-center"
        flex="1 1 auto"
        width={1}
        mr={7}
        {...headingProps}
      >
        <Text {...headingProps} letterSpacing={2} color={headingColor}>
          {title}
        </Text>
      </Box>
    );
  };

  handler = (id, isPanelClosed) => {
    const { panelStatus } = this.state;
    Object.assign(panelStatus, { id, isPanelClosed });
    this.setState({ panelStatus });
  };

  render() {
    const { accordions, headingProps } = this.props;
    const { panelStatus } = this.state;
    return accordions.map(({ fields: { title, content } }) => {
      const isPanelExpanded =
        !panelStatus.isPanelClosed && panelStatus.id === title;
      return (
        <Box pb={isPanelExpanded ? 7 : 0}>
          <ExpandCollapseWrapper
            options={headingProps}
            key={title}
            headerNode={this.renderHeader(title, panelStatus, headingProps)}
            handler={this.handler}
          >
            <TextBlock id={title} data={content} blockVariant={VARIANT_LIGHT} />
          </ExpandCollapseWrapper>
        </Box>
      );
    });
  }
}

export default ExpandCollapse;
