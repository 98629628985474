import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import ContentPage from '../contentPage';
import { ROOT, HOMEURL } from '../../constants';
import FourOhFour from '../fourOhFour';

/**
 * Creates the RoutingService and uses its output to create a list of routes
 * for react router.
 * @param baseRoute
 * @param match
 * @param FourOhFour
 * @returns {*}
 * @constructor
 */

export const NavigationRouter = ({ pages, locale }) => {
  // Because <Switch /> renders the first matching route, routes with dynamic paths (e.g. /support/:article) need to be
  // rendered last or else static paths might get caught by them by accident (e.g. /support/search-results)
  const pagesWithDynamicPathsLast = Object.values(pages).sort((_, page) =>
    page.url?.includes(':') ? -1 : 0,
  );

  return (
    <Switch>
      {/* Route to get the current version that has been deployed in the environment */}
      <Route
        exact
        path={`/${locale}/version`}
        render={() => (
          <p>
            Version:
            {typeof window !== 'undefined' &&
            (window.package_version || window.build_version)
              ? ` ${window.package_version}.${window.build_version}`
              : ' Development'}
          </p>
        )}
      />
      {pagesWithDynamicPathsLast.map(page => (
        <Route
          key={page.url}
          exact={!page.exactUrlNotRequired}
          path={page.url === ROOT ? `/${locale}` : `/${locale}/${page.url}`}
          render={({ match }) => (
            <ContentPage locale={locale} page={page} match={match} />
          )}
        />
      ))}
      <Redirect exact path={HOMEURL} to={`${locale}${HOMEURL}`} />
      <Route
        render={({ staticContext }) => {
          if (staticContext) {
            staticContext.status = 404;
          }
          return <FourOhFour locale={locale} isFourOhFour />;
        }}
      />
    </Switch>
  );
};

NavigationRouter.propTypes = {
  pages: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

NavigationRouter.displayName = 'NavigationRouter';

export default NavigationRouter;
