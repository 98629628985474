import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

import ListOfLinks from '../listOfLinks';

const LinksList = ({ listOfLinks }) => {
  const formattedLinks = listOfLinks.map(entry => ({
    to: entry.fields.url,
    text: entry.fields.title,
    video: entry.fields.video,
  }));

  return (
    <Box width={1}>
      <ListOfLinks links={formattedLinks} />
    </Box>
  );
};

LinksList.propTypes = {
  listOfLinks: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default LinksList;
