export default class PromoBannerL {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const {
        entryTitle,
        identifier,
        slug,
        ctasTextBlock,
        image,
        mobileImage,
        video,
        mobileVideo,
        videoUrl,
        videoVttFile,
        textBlockVariant,
        isAutoplay,
        imageAboveText,
        textBoxWidth,
        eyebrowText,
        isImageTransparent,
        ctasSideBySide,
        imageCtAsSideBySide,
        imageButtons,
        textAlignment,
        textVariant,
        contentTextBlock,
        isImageAboveTextTransparent,
        isTextBlockOnTheLeft,
        textBlockBackground,
        appearance,
        isAnimationEnabled,
        mobileTextPosition,
        desktopImageFocus,
        videoFocus,
        topTitleXL,
        textBoxMobileHeight,
        fixedMobileHeight,
        fixedHeight,
      } = data.fields;
      this.id = data.sys.id;
      this.entryTitle = entryTitle;
      this.identifier = identifier;
      this.slug = slug;
      this.isAutoplay = isAutoplay;
      this.ctasTextBlock = ctasTextBlock;
      this.image = image;
      this.mobileImage = mobileImage;
      this.video = video;
      this.mobileVideo = mobileVideo;
      this.textBlockVariant = textBlockVariant;
      this.imageAboveText = imageAboveText;
      this.textBoxWidth = textBoxWidth;
      this.eyebrowText = eyebrowText;
      this.isImageTransparent = isImageTransparent;
      this.ctasSideBySide = ctasSideBySide;
      this.imageButtons = imageButtons;
      this.textAlignment = textAlignment;
      this.textVariant = textVariant;
      this.contentTextBlock = contentTextBlock;
      this.imageCtAsSideBySide = imageCtAsSideBySide;
      this.isTextBlockOnTheLeft = isTextBlockOnTheLeft;
      this.isImageAboveTextTransparent = isImageAboveTextTransparent;
      this.textBlockBackground = textBlockBackground;
      this.appearance = appearance;
      this.isAnimationEnabled = isAnimationEnabled;
      this.mobileTextPosition = mobileTextPosition;
      this.desktopImageFocus = desktopImageFocus;
      this.videoUrl = videoUrl;
      this.videoVttFile = videoVttFile;
      this.videoFocus = videoFocus;
      this.topTitleXL = topTitleXL;
      this.textBoxMobileHeight = textBoxMobileHeight;
      this.fixedMobileHeight = fixedMobileHeight
        ? `${fixedMobileHeight}px`
        : null;
      this.fixedHeight = fixedHeight ? `${fixedHeight}px` : null;
    }
  }
}
