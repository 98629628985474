import React from 'react';
import PropTypes from 'prop-types';
import Box from 'web_component_library/box';
import A11yContent from 'web_component_library/A11yContent';

import { extractProperty } from '../../../utils/extractProperty';

import TableTitle from './tableTitle';
import TableText from './tableText';
import { Table, Thead, Tbody, Tr, Th, Td } from './css';

export const extractCellValue = cell =>
  cell.content
    ? extractProperty(cell, 'content[0].content[0].value', JSON.stringify(cell))
    : cell;

const SideBySideTable = ({ header, rows }) => {
  // Any given cell value fall backs to an empty string if the entry is unpublished
  const headerCells = header.fields.row.map(
    item => item.fields?.title || item.fields?.value || '',
  );
  const cellRows = rows.map(
    rowItem =>
      rowItem.fields.row.map(
        item => item.fields?.title || item.fields?.value,
      ) || '',
  );

  return (
    <Table>
      <Thead>
        <Tr>
          {headerCells.map(cell => (
            <Th key={extractCellValue(cell)}>
              <A11yContent>
                <TableText textData={cell} />
              </A11yContent>
            </Th>
          ))}
        </Tr>
      </Thead>

      <Tbody>
        {cellRows.map(row => (
          <Tr key={extractCellValue(row[0])}>
            {row.map((cell, i) => (
              <Td
                key={extractCellValue(headerCells[i]) + extractCellValue(cell)}
                mt={i !== 0 && 11}
              >
                {i !== 0 && (
                  <Box aria-hidden="true">
                    <TableText
                      textData={headerCells[i]}
                      paragraphStyles={{ m: 0 }}
                    />
                  </Box>
                )}
                {i === 0 ? (
                  <TableTitle textData={cell} />
                ) : (
                  <TableText
                    textData={cell}
                    paragraphStyles={{ m: 0, isStrong: true }}
                  />
                )}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const plainTextPropTypes = PropTypes.shape({
  fields: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
});

const richTextBlockPropTypes = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.object.isRequired,
  }).isRequired,
});

SideBySideTable.propTypes = {
  header: PropTypes.shape({
    fields: PropTypes.shape({
      row: PropTypes.arrayOf(
        PropTypes.oneOfType([richTextBlockPropTypes, plainTextPropTypes]),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        row: PropTypes.arrayOf(
          PropTypes.oneOfType([richTextBlockPropTypes, plainTextPropTypes]),
        ).isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default SideBySideTable;
