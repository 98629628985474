import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import freedomTheme from 'web_component_library/theme/freedom';
import { Box } from 'rebass/styled-components';
const { colors } = freedomTheme;

const Progress = styled.span`
  display: block;
  background-color: ${colors.accessibleOrange};
  height: 100%;
  transition: width 0.1s linear;
  border-radius: 0 5px 5px 0;
  width: ${({ progress }) => `${progress}%`};
`;

const ProgressBar = ({ maxTime, currentTime }) => {
  const maxTimeInSeconds = maxTime * 1000;
  const currentProgress = (currentTime / maxTimeInSeconds) * 100;
  return (
    <Box
      width={1}
      height="0.50rem"
      bg="transparent"
      p={0}
      overflow="hidden"
      mt="xs"
    >
      <Progress
        progress={currentProgress}
        data-testid="carousel-progress-bar"
      />
    </Box>
  );
};

ProgressBar.propTypes = {
  maxTime: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
};

export default ProgressBar;
