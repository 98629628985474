import { createSelector } from 'reselect';

export const selectApp = state => state.app;

export const selectPlanSize = () =>
  createSelector(
    selectApp,
    app => app.planSize,
  );

export const selectArticleId = () =>
  createSelector(
    selectApp,
    app => app.articleId || '',
  );

export const selectGlobalAlertDismissed = () =>
  createSelector(
    selectApp,
    app => app.globalAlertDismissed,
  );

export const selectHeaderHeight = () =>
  createSelector(
    selectApp,
    app => app.headerHeight,
  );
