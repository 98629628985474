import Maintenance from './maintenance';
import MetaTag from './metaTag';
import LinkTag from './linkTag';

export default class PageNotFound {
  constructor(data) {
    if (data && data.fields) {
      const {
        title,
        url,
        maintenance,
        metaTag,
        linkTag,
        schema,
        pageName,
        pageSection,
        pagePurpose,
      } = data.fields;
      this.title = title;
      this.url = url;
      this.maintenance = maintenance ? new Maintenance(maintenance) : null;
      this.pageName = pageName;
      this.pageSection = pageSection;
      this.pagePurpose = pagePurpose;
      this.metaTag = new MetaTag(metaTag);
      this.linkTag = new LinkTag(linkTag);
      this.schema = schema;
    }
  }
}
