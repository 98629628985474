import React from 'react';
import Video from './index';
import { PlayerWrapper } from './css';

function VideoModal(props) {
  return (
    <PlayerWrapper mt={[40, 0]} pt="56.25%">
      <Video {...props} />
    </PlayerWrapper>
  );
}

export default VideoModal;
