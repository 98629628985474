import styled from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import { Box, Flex } from 'rebass/styled-components';

export const TextBox = styled(Box)`
  display: inline-flex;
  min-height: 16.5em;
  margin-top: 1.5em;

  ${MediaQuery.desktop`
    margin-top: 0;
  `}
`;

export const Container = styled(Flex)`
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: top center;
  background-color: ${props => (props.bg ? `${props.bg}` : 'transparent')};

  ${MediaQuery.desktop`
    min-height: 40em;
    `};
`;

export const MediaContainer = styled(Box)`
  position: relative;
  background-image: url("${props => props.url}");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 90%;

  ${MediaQuery.desktop`
    padding-top: 0;
    min-height: 40em;
    position: absolute;
    background-position: top center;
    top: 0;
    right: 0;
  `};
`;
