import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';

import {
  OverLappingCardSection,
  ImageBlock,
  TextBlockSection,
  TextBlockOuter,
  TextBlockInner,
  ImageWrapper,
} from './css';

const OverlappingCard = ({ backGroundImage, isReverse = false, textBlock }) => (
  <Box width={1} pt={[5, 26]}>
    <Box>
      <OverLappingCardSection isReverse={isReverse}>
        <ImageBlock>
          <ImageWrapper
            src={backGroundImage.fields.file.url}
            alt={backGroundImage.fields.description}
            title={backGroundImage.fields.title}
          />
        </ImageBlock>
        <TextBlockSection isReverse={isReverse}>
          <TextBlockOuter>
            <TextBlockInner>
              <TextBlock data={textBlock} blockVariant="light" />
            </TextBlockInner>
          </TextBlockOuter>
        </TextBlockSection>
      </OverLappingCardSection>
    </Box>
  </Box>
);

OverlappingCard.displayName = 'OverlappingCard';

OverlappingCard.propTypes = {
  backGroundImage: PropTypes.object.isRequired, // TODO:Media Shape Required
  textBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired, // RichText
  isReverse: PropTypes.bool,
};

export default OverlappingCard;
