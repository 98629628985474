import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import { H3, Paragraph } from 'web_component_library/typography';
import Button from 'web_component_library/button';
import { superscriptSpecialCharacters } from 'web_component_library/utils/stringReplace';
import {
  MediaFile as ImageModal,
  PopupButtonCta,
  ChatCtaModal,
} from '../../containers/contentful';
import {
  TextBlock,
  CtaBlock,
  Container,
  Video,
  ImageContainer,
  VideoBlock,
  Body,
} from './css';
import { ModalContext } from '../modal/modalContext';
import Modal from '../modalComponent';
import PopupModal from '../../containers/contentful/popupModal';
import ChatCta from '../chatCta';
import { launchLiveChat, isAppleUser } from '../../../utils/liveChatHelper';
import { noSubmissionOnCloseAnalyticsEvent } from '../../containers/smartConnectForm';
import {
  getBusinessToggle,
  BUSINESS_TOGGLES,
} from '../../../utils/toggleHelpers';

const renderTextBlock = (title, description, colLength, subTitle) => (
  <React.Fragment>
    <H3 pt={[7, 13]} color="black">
      {superscriptSpecialCharacters(title)}
    </H3>
    {subTitle && (
      <Paragraph color="black" isStrong pt={1} pb={0} my={0}>
        {superscriptSpecialCharacters(subTitle)}
      </Paragraph>
    )}
    <Paragraph>{superscriptSpecialCharacters(description)}</Paragraph>
  </React.Fragment>
);

const renderMediaContent = (
  imageContent,
  videoUrl,
  isTextBottomAligned,
  colsLength,
) => {
  if (videoUrl) {
    return (
      <ImageContainer
        url={imageContent.url}
        role="img"
        aria-label={imageContent.title}
        alt={imageContent.title}
        col={colsLength}
      >
        <Box width={[0, 1]}>
          <Video
            width="100"
            height={295}
            url={videoUrl}
            playing
            loop
            muted
            playsinline
          />
        </Box>
      </ImageContainer>
    );
  }
  return (
    imageContent.url && (
      <ImageContainer
        url={imageContent.url}
        role="img"
        aria-label={imageContent.title}
        alt={imageContent.title}
        title={imageContent.title}
        order={isTextBottomAligned ? '1' : '2'}
        col={colsLength}
      />
    )
  );
};

const renderVideoBlock = videoUrl => (
  <VideoBlock width="100" url={videoUrl} playing loop muted />
);

// TODO: Replace URL with fallback
const defaultTitle = '';
const defaultSubTitle = '';
const defaultDescription = '';
const defaultImage = {
  url: '',
};
const defaultVideo = {
  url: '',
};
const LiveChatPromoCard = ({
  title = defaultTitle,
  subTitle = defaultSubTitle,
  description = defaultDescription,
  cta,
  isTextBottomAligned = true,
  isFullImageBackground = false,
  image = defaultImage,
  video = defaultVideo,
  columnsLength = 2,
  liveChatEngagementId,
  appleChatBizIntentId = null,
  appleChatBizGroupId = null,
  appleChatBody = null,
  isAppleChatOn = true,
}) => {
  const isPopupButton = cta.sys.contentType.sys.id === 'ctaPopupButton';
  const isLpButton = cta.sys.contentType.sys.id === 'ctaChatButton';
  const CtaModel = isPopupButton ? PopupButtonCta : ChatCtaModal;
  const ctaContent = new CtaModel(cta);

  const { showModal } = useContext(ModalContext);
  const popupModal = new PopupModal(ctaContent.component);

  const imageContent = new ImageModal(image);
  const videoContent = new ImageModal(video);
  const textBlockHeight = columnsLength > 2 ? '18em' : '13em';

  const liveChat = () =>
    launchLiveChat(
      null,
      liveChatEngagementId,
      appleChatBizIntentId,
      appleChatBizGroupId,
      appleChatBody,
      isAppleChatOn,
    );

  return (
    <Container
      ml={4}
      isFullImageBackground={isFullImageBackground}
      url={imageContent.url}
      role="img"
      aria-label={imageContent.title}
      alt={imageContent.title}
      isVideoBackground={videoContent.url}
      bg={isFullImageBackground ? 'transparent' : 'pureWhite'}
    >
      {isFullImageBackground &&
        videoContent.url &&
        renderVideoBlock(videoContent.url)}
      <Flex justifyContent="left" flexDirection="column">
        {!isFullImageBackground &&
          renderMediaContent(
            imageContent,
            videoContent.url,
            isTextBottomAligned,
            columnsLength,
          )}
        <TextBlock
          justifyContent="left"
          flexDirection="column"
          order={isTextBottomAligned ? '2' : '1'}
          px={[7, 11]}
          isFullImageBackground={isFullImageBackground}
          columnsLength={columnsLength}
        >
          <Body height={textBlockHeight}>
            {!isFullImageBackground &&
              renderTextBlock(title, description, columnsLength, subTitle)}
          </Body>
        </TextBlock>
      </Flex>
      <CtaBlock
        width={1}
        isBottomCenter={isFullImageBackground}
        px={[7, 11]}
        py={isFullImageBackground ? 2 : '1.4375em'}
      >
        {isPopupButton && (
          <Button
            onClick={() => {
              const isAppleChatToggleOn = getBusinessToggle(
                BUSINESS_TOGGLES.APPLE_CHAT,
              );
              if (!isAppleUser || !isAppleChatToggleOn || !isAppleChatOn) {
                showModal(Modal, {
                  ...popupModal,
                  onRequestClose: () => {
                    noSubmissionOnCloseAnalyticsEvent();
                    liveChat();
                  },
                });
              } else {
                liveChat();
              }
            }}
            variant={ctaContent.variant}
          >
            {ctaContent.value}
          </Button>
        )}
        {isLpButton && (
          <ChatCta
            isFullImageBackground={isFullImageBackground}
            chatEngagementId={ctaContent.chatEngagementId}
            appleChatBizIntentId={ctaContent.appleChatBizIntentId}
            appleChatBizGroupId={ctaContent.appleChatBizGroupId}
            appleChatBody={ctaContent.appleChatBody}
            value={ctaContent.value}
            variant={ctaContent.variant}
            type={ctaContent.type}
            isAppleChatOn={ctaContent.isAppleChatOn}
          ></ChatCta>
        )}
      </CtaBlock>
    </Container>
  );
};
LiveChatPromoCard.displayName = 'LiveChatPromoCard';

LiveChatPromoCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  cta: PropTypes.object.isRequired,
  isTextBottomAligned: PropTypes.bool,
  isFullImageBackground: PropTypes.bool,
  columnsLength: PropTypes.number,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
  liveChatEngagementId: PropTypes.string.isRequired,
  appleChatBizIntentId: PropTypes.string,
  appleChatBizGroupId: PropTypes.string,
  appleChatBody: PropTypes.string,
  isAppleChatOn: PropTypes.bool,
};

export default LiveChatPromoCard;
