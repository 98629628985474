import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { parse } from 'qs';

import freedomTheme from 'web_component_library/theme/freedom';
import { ModalProvider } from '../../components/modal/modalContext';
import ModalRoot from '../../components/modal/modalRoot';

import {
  selectBrowseCatalog,
  selectLocale,
  selectRegion,
} from '../contentfulHoc/selectors';
import { getPage } from '../contentfulHoc/actions';
import { getSlug } from '../contentfulHoc/saga';
import { selectLocation } from '../navigationRouter/selectors';
import FreedomFonts from '../../theme/fonts';

import NavigationRouter from '../navigationRouter';

import GlobalStyles from '../../theme/globalStyles';
import PageViewChangeListener from '../../components/pageViewChangeListener';
import { PAGE_NAVIGATION_EVENT } from '../../components/pageViewChangeListener/constants';
import { PAGE_SUPPORT_BASE } from '../../constants';
import { FeatureFlagsWrapper } from '../featuresWrapper';
import OidcProvider from '../providers/oidcProvider';

const defaultLocale = 'en-CA';
const defaultRegion = '';
/**
 * @return {null}
 */
export function App({
  browseCatalog,
  locale = defaultLocale,
  location,
  region = defaultRegion,
  onGetPage,
}) {
  if (!browseCatalog) return null;

  const { pages } = browseCatalog;
  useEffect(() => {
    const slug = getSlug(location.pathname, locale);
    const isPlaceholderPage =
      pages[slug] &&
      pages[slug].isPlaceHolder &&
      !slug.includes(PAGE_SUPPORT_BASE);
    if (isPlaceholderPage) {
      onGetPage(locale, region, location.pathname);
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={freedomTheme}>
      <Fragment>
        <Helmet>
          <title>
            Freedom Mobile | Talk, Text & Data Plans | Cell Phones & Smartphones
          </title>
        </Helmet>
        <PageViewChangeListener
          sideEffects={[
            () =>
              setTimeout(() => {
                if (typeof window.scrollHandler !== 'undefined') {
                  window.scrollHandler();
                }
                if (window.dataLayer && window.dataLayer.push) {
                  window.dataLayer.push({
                    event: PAGE_NAVIGATION_EVENT,
                  });
                }
              }, 0),
            () => window.scrollTo(0, 0),
            () => {
              // Scrolls to component based on query string
              const { goTo } = parse(location.search, {
                ignoreQueryPrefix: true,
              });
              if (goTo) {
                const component = document.getElementById(goTo);
                if (component) {
                  window.scrollTo({
                    top: component.offsetTop - 100,
                    behavior: 'smooth',
                  });
                }
              }
            },
          ]}
        />
        <FreedomFonts />
        <GlobalStyles />
        <ModalProvider>
          <ModalRoot />
          <FeatureFlagsWrapper>
            <OidcProvider locale={locale}>
              <NavigationRouter pages={pages} locale={locale} />
            </OidcProvider>
          </FeatureFlagsWrapper>
        </ModalProvider>
      </Fragment>
    </ThemeProvider>
  );
}

App.propTypes = {
  browseCatalog: PropTypes.shape({
    pages: PropTypes.object.isRequired,
  }).isRequired,
  locale: PropTypes.string,
  region: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onGetPage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onGetPage: (locale, region, url) =>
    dispatch(getPage({ locale, region, url })),
});

const mapStateToProps = createStructuredSelector({
  browseCatalog: selectBrowseCatalog(),
  locale: selectLocale(),
  location: selectLocation(),
  region: selectRegion(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
