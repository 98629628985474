import produce from 'immer';
import { handleActions } from 'redux-actions';
import { loadedFeatures } from './actions';

export const initState = { hasLoaded: false, features: {} };

/* eslint-disable default-case, no-param-reassign */
const featuresReducer = handleActions(
  {
    [loadedFeatures]: produce((draft, action) => {
      draft.hasLoaded = true;
      draft.features = { ...draft.features, ...action.payload };
      return draft;
    }),
  },
  initState,
);

export default featuresReducer;
