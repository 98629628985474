import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import Button from 'web_component_library/button';
import Icon from 'web_component_library/icon';
import WclFlex from 'web_component_library/flex';
import Divider from 'web_component_library/divider';
import Link from 'web_component_library/link';
import withTranslation from '../../containers/translationHoc';

import Modal from '../modalComponent';

import { List, ListItem, ArrowRight, Result } from './css';

const ListOfLinks = ({ links, translate }) => {
  const [videoModal, setVideoModal] = useState(null);
  const onRequestClose = () => {
    setVideoModal(null);
  };

  return (
    <List>
      {links.map(({ to, text, video }) => {
        const videoData = video?.fields
          ? {
              identifier: 'videoModal',
              ...video?.fields,
            }
          : null;

        return (
          <Box as="li" key={`${to}${text}`} data-testid="link-list-item">
            <Divider color="charcoal" width={['85%', '93%']} />
            <Flex>
              <Result width={['85%', '93%']}>
                <Link css={{ width: '100%' }} to={to}>
                  <Box py={[11, 13]} color="freedomCharcoal">
                    <ListItem>{text}</ListItem>
                    <Box width="40px">
                      <ArrowRight ml={19} />
                    </Box>
                  </Box>
                </Link>
              </Result>

              <WclFlex
                alignContent="center"
                justifyContent="flex-end"
                width="7%"
                minWidth="60px"
              >
                {videoData && (
                  <Button
                    variant="transparent"
                    data-testid="playButton"
                    onClick={() => {
                      setVideoModal(videoData);
                    }}
                    aria-label="Open modal with video"
                  >
                    <Icon size={2.5} color="charcoal" variant="icon_play" />
                  </Button>
                )}
              </WclFlex>
            </Flex>
          </Box>
        );
      })}
      {videoModal && (
        <Modal
          onRequestClose={onRequestClose}
          components={[videoModal]}
          closeText={translate('modalCloseText')}
          isOpen
        />
      )}
    </List>
  );
};

ListOfLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslation(ListOfLinks);
