export default class PopupButtonCta {
  constructor(data) {
    if (data && data.fields) {
      const {
        arrowDirection,
        position,
        value,
        variant,
        type,
        component,
      } = data.fields;
      this.arrowDirection = arrowDirection;
      this.position = position;
      this.value = value;
      this.variant = variant;
      this.type = type;
      this.component = component;
    }
  }
}
