import { Article } from './article';

export class Question {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const { title, articles, video, url } = data.fields;
      this.id = data.sys.id;
      this.title = title;
      this.articles = articles?.map(article => new Article(article));
      this.video = video;
      this.url = url;
      this.identifier = 'question';
    }
  }
}

export default class QuestionsList {
  constructor(payload) {
    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }

    payload.forEach(question => {
      if (question && question.fields) {
        const { title } = question.fields;
        this[title] = new Question(question);
      }
    });
  }
}
