export const naturalToKebab = str =>
  str
    ?.toString()
    ?.replace(/([a-zA-Z0-9]+)[^a-zA-Z0-9'’.]+(?=[a-zA-Z0-9]+)/g, '$1 ') // replaces word connectors with spaces (skips some word connectors like contractions and periods)
    .replace(/\.(?!\d)/g, '') // removes all periods except decimal points
    .split(/\s+/)
    .map(subStr => subStr.replace(/[^\w.]+/g, '')) // removes non-word chars (except periods)
    .filter(Boolean) // clears empty strings after removing non-words
    .join('-')
    .toLowerCase();

// This function assumes every part of your camel-case string
// begins with a letter (not a number)
export const camelToKebab = str =>
  str?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const snakeToKebab = str => str?.replace(/_/g, '-').toLowerCase();
