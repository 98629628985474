import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import TertiaryButton from 'web_component_library/tertiaryButton';
import Button from 'web_component_library/button';
import { launchLiveChat } from '../../../utils/liveChatHelper';
import { naturalToKebab } from '../../../utils/stringHelpers';

const defaultChatEngagementId = '';
const defaultAppleChatBizIntentId = '';
const defaultAppleChatBizGroupId = '';
const defaultAppleChatBody = '';
const ChatCta = ({
  type,
  variant,
  value,
  chatEngagementId = defaultChatEngagementId,
  appleChatBizIntentId = defaultAppleChatBizIntentId,
  appleChatBizGroupId = defaultAppleChatBizGroupId,
  appleChatBody = defaultAppleChatBody,
  isAppleChatOn = true,
}) => {
  const button = (
    <Box>
      <Button
        id={chatEngagementId || naturalToKebab(value)}
        data-engagement-id={chatEngagementId}
        onClick={() =>
          launchLiveChat(
            null,
            chatEngagementId,
            appleChatBizIntentId,
            appleChatBizGroupId,
            appleChatBody,
            isAppleChatOn,
          )
        }
        variant={variant}
      >
        {value}
      </Button>
    </Box>
  );
  const tertiaryButton = (
    <Flex>
      <Box>
        <TertiaryButton
          data-engagement-id={chatEngagementId}
          id={chatEngagementId || naturalToKebab(value)}
          onClick={() =>
            launchLiveChat(
              null,
              chatEngagementId,
              appleChatBizIntentId,
              appleChatBizGroupId,
              appleChatBody,
              isAppleChatOn,
            )
          }
          text={value}
          textColor="freedomCharcoal"
        />
      </Box>
    </Flex>
  );
  if (type === 'primaryButton') return button;
  return tertiaryButton;
};

ChatCta.propTypes = {
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  chatEngagementId: PropTypes.string,
  appleChatBizIntentId: PropTypes.string,
  appleChatBizGroupId: PropTypes.string,
  appleChatBody: PropTypes.string,
  isAppleChatOn: PropTypes.bool,
};

export default ChatCta;
