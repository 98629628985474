// import BrowserRouter from 'react-router-dom/BrowserRouter';\
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import configureStore from '../common/store/configureStore';
import App from '../common/containers/app/app';
import ContentfulService from '../common/lib/contentful';
import contentfulHocSaga from '../common/containers/contentfulHoc/saga';
import {
  getClientSideEntries,
  setRegion,
} from '../common/containers/contentfulHoc/actions';
import { rootSaga, appSaga } from '../common/store/sagas';

// Import polyfills for IE 11
import '@babel/polyfill';
import { locales } from '../common/containers/contentfulHoc/constants';

// TODO: How to instantiate service object without eslint complaining?
/* eslint-disable */
const contentfulService = new ContentfulService({
  productCatalogCredentials: {
    spaceId: window.process.env.CONTENTFUL_PRODUCT_CATALOG.SPACE_ID,
    accessToken: window.process.env.CONTENTFUL_PRODUCT_CATALOG.ACCESS_TOKEN,
    environment: window.process.env.CONTENTFUL_PRODUCT_CATALOG.ENVIRONMENT,
    preview: window.process.env.CONTENTFUL_PRODUCT_CATALOG.PREVIEW,
  },
  browseCatalogCredentials: {
    spaceId: window.process.env.CONTENTFUL_BROWSE_CATALOG.SPACE_ID,
    accessToken: window.process.env.CONTENTFUL_BROWSE_CATALOG.ACCESS_TOKEN,
    environment: window.process.env.CONTENTFUL_BROWSE_CATALOG.ENVIRONMENT,
    preview: window.process.env.CONTENTFUL_BROWSE_CATALOG.PREVIEW,
  },
  commonCatalogCredentials: {
    spaceId: window.process.env.CONTENTFUL_COMMON_CATALOG.SPACE_ID,
    accessToken: window.process.env.CONTENTFUL_COMMON_CATALOG.ACCESS_TOKEN,
    environment: window.process.env.CONTENTFUL_COMMON_CATALOG.ENVIRONMENT,
    preview: window.process.env.CONTENTFUL_COMMON_CATALOG.PREVIEW,
  },
  commonBrowseCredentials: {
    spaceId: window.process.env.CONTENTFUL_COMMON_BROWSE_CATALOG.SPACE_ID,
    accessToken:
      window.process.env.CONTENTFUL_COMMON_BROWSE_CATALOG.ACCESS_TOKEN,
    environment:
      window.process.env.CONTENTFUL_COMMON_BROWSE_CATALOG.ENVIRONMENT,
    preview: window.process.env.CONTENTFUL_COMMON_BROWSE_CATALOG.PREVIEW,
  },
});
/* eslint-enable */

const { store, history } = configureStore();

const MOUNT_NODE = document.getElementById('root');

const hydrate = () =>
  ReactDOM.hydrate(
    <HelmetProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </HelmetProvider>,
    MOUNT_NODE,
  );

if (module.hot) {
  module.hot.accept('../common/containers/app/app', () => {
    ReactDOM.hydrate(
      <HelmetProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      </HelmetProvider>,
      MOUNT_NODE,
    );
  });
}

store.runSaga(rootSaga).done.then(hydrate);
const getLanguage = url => {
  // get the locale from the URL
  const currentLocale = url.split('/')[1];
  // get the users locale from the browser settings
  const navigatorLanguage = navigator.language;
  if (Object.values(locales).indexOf(currentLocale) === -1) {
    if (
      navigatorLanguage &&
      Object.values(locales).indexOf(navigatorLanguage) === -1
    ) {
      // if the browser locale is not supported default to english Canadian
      return locales.enCA;
    }
    // if the url locale is not supported but the browse locale is use that
    return locales[navigatorLanguage];
  }
  // if the url locale is in the list of supported locale use it
  return currentLocale;
};
const url = window.location.pathname;
const locale = getLanguage(url);
const region = Cookies.get('region');
store.dispatch(setRegion(region));
store.dispatch(
  getClientSideEntries({
    locale,
    region,
    url,
  }),
);
// Tell all sagas to finish, then resolve 'done' condition above
store.close();
store.runSaga(appSaga);
store.runSaga(contentfulHocSaga);

// eslint-disable-next-line no-undef
window.build_tag = BUILD_TAG;
// eslint-disable-next-line no-undef
window.build_version = BUILD_VERSION;
// eslint-disable-next-line no-undef
window.package_version = PACKAGE_VERSION;
// eslint-disable-next-line no-undef
window.config = window.process.env;
