import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'web_component_library/popup';
import { H2 } from 'web_component_library/typography';

import { FooterCloseButton } from '../modal/css';
import { popUpComponentHelpers } from '../../../utils/popUpComponentHelpers';
import { ANALYTICS_BTN_PROPS } from '../../constants';
import ErrorBoundary from '../../containers/errorBoundary';
import withCtaAnalytics from '../ctaAnalyticsHoc';

const PopupWithAnalytics = withCtaAnalytics(Popup, {
  staticModuleType: 'full-screen-modal',
  containerType: 'portal',
});

const defaultOnRequestClose = () => {};
const Modal = ({
  onRequestClose = defaultOnRequestClose,
  showHeader = true,
  showDividers = true,
  showFooter = true,
  showHeaderDivider = true,
  showFooterDivider = true,
  ...otherProps
}) => {
  const { components, closeText, title } = otherProps;

  const analyticsClosePopup = {
    ...ANALYTICS_BTN_PROPS,
    'data-ev_type': 'interaction',
    'data-ev_action': 'click',
    'data-ev_name': 'close',
  };

  const getHeaderContent = () => {
    if (!showHeader) return null;
    return <H2>{title}</H2>;
  };

  const getFooterContent = () => {
    if (!showFooter) return null;
    return (
      <FooterCloseButton
        {...analyticsClosePopup}
        variant="secondary"
        onClick={onRequestClose}
        data-position="bottom"
      >
        {closeText}
      </FooterCloseButton>
    );
  };

  if (!Array.isArray(components)) return null;
  return (
    <PopupWithAnalytics
      isOpen // TODO: use this prop to actually open and close modal
      headerDivider={showDividers && showHeaderDivider}
      footerDivider={showDividers && showFooterDivider}
      header={getHeaderContent()}
      footer={getFooterContent()}
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <ErrorBoundary>
        {components.map(component => {
          const Template = popUpComponentHelpers(component.identifier);
          return <Template key={component.entryTitle} {...component} />;
        })}
      </ErrorBoundary>
    </PopupWithAnalytics>
  );
};

Modal.propTypes = {
  onRequestClose: PropTypes.func,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  showDividers: PropTypes.bool,
  showHeaderDivider: PropTypes.bool,
  showFooterDivider: PropTypes.bool,
};

export default Modal;
