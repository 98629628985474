export default class PromoBanner {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const {
        entryTitle,
        identifier,
        slug,
        textBlock,
        image,
        mobileImage,
        video,
        mobileVideo,
        isTextLeftAligned,
        textBlockBackground,
        textBlockVariant,
        isAnimationEnabled,
        appearance,
        textPosition,
        imageAboveText,
        imageAboveTextAlignment,
        respectImageRatio,
        isAutoplay,
        topTitleXL,
        textBoxMobileHeight,
        fixedMobileHeight,
        fixedHeight,
      } = data.fields;
      this.id = data.sys.id;
      this.entryTitle = entryTitle;
      this.identifier = identifier;
      this.slug = slug;
      this.textBlock = textBlock;
      this.image = image;
      this.mobileImage = mobileImage;
      this.video = video;
      this.mobileVideo = mobileVideo;
      this.textPosition = textPosition;
      this.isTextLeftAligned = isTextLeftAligned;
      this.textBlockBackground = textBlockBackground;
      this.textBlockVariant = textBlockVariant;
      this.isAnimationEnabled = isAnimationEnabled;
      this.appearance = appearance;
      this.imageAboveText = imageAboveText;
      this.imageAboveTextAlignment = imageAboveTextAlignment;
      this.respectImageRatio = respectImageRatio;
      this.isAutoplay = isAutoplay;
      this.topTitleXL = topTitleXL;
      this.textBoxMobileHeight = textBoxMobileHeight;
      this.fixedMobileHeight = fixedMobileHeight
        ? `${fixedMobileHeight}px`
        : null;
      this.fixedHeight = fixedHeight ? `${fixedHeight}px` : null;
    }
  }
}
