import React, { PureComponent, createRef } from 'react';
import { arrayOf, shape, string, number, array, object } from 'prop-types';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { Box } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import freedomTheme from 'web_component_library/theme/freedom';
import { findImageByTitle } from '../../helpers';

import {
  Sticky,
  FadeFrameContainer,
  TextWrapper,
  ShowcaseImage,
  TextContainer,
  TextInner,
  OverlayImg,
  BgContainer,
  ShowcaseImageContainer,
} from './css';
import { BREAKPOINT } from '../../../../constants';

const { colors } = freedomTheme;

const WHITE = colors.pureWhite;
const BLACK = colors.pureBlack;
let INDICATORS = false;
// safeguard to make sure indicators are turned off in prod
if (process.env.NODE_ENV === 'production') {
  INDICATORS = false;
}

class MultiImageShowcaseAnimation extends PureComponent {
  static propTypes = {
    images: arrayOf(object).isRequired,
    data: shape({
      controllerDuration: number,
      showcaseImageTitle: string,
      backgroundColor: string,
      slides: arrayOf(
        shape({
          id: string,
          imageTitle: string,
          textNodeId: string,
        }),
      ),
      textNodes: arrayOf(
        shape({
          id: string.isRequired,
          data: object,
          content: arrayOf(
            shape({
              data: object,
              content: arrayOf(
                shape({
                  data: object,
                  marks: array,
                  value: string.isRequired,
                  nodeType: string.isRequired,
                }),
              ),
              nodeType: string.isRequired,
            }),
          ),
          nodeType: string.isRequired,
        }),
      ),
    }),
  };

  static defaultProps = {
    data: null,
  };

  static getProgressForSlide(progress, ratio, slideIndex) {
    return Math.max((progress - ratio * slideIndex) / ratio, 0);
  }

  state = {
    innerWidth: 0,
    innerHeight: 0,
  };

  showcaseImageContainerRef = createRef();

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      innerWidth: window.innerWidth,
      innerHeight: this.showcaseImageContainerRef.current
        ? this.showcaseImageContainerRef.current.clientHeight
        : 0,
    });
  };

  render() {
    const { data, images } = this.props;
    if (!data) return null;

    const { innerWidth, innerHeight } = this.state;
    const { slides, showcaseImageTitle, backgroundColor } = data;
    const foregroundImage = findImageByTitle(images, showcaseImageTitle);

    const ratio = 1 / (slides.length + 1);
    const { slideDuration } = data;
    const sceneDuration = slideDuration * (slides.length + 1);

    let offset = 0;
    if (innerWidth >= BREAKPOINT) {
      offset = -80;
    }

    return (
      <Box
        position="relative"
        bg={backgroundColor || BLACK}
        data-testid="multi-showcase"
      >
        <Controller>
          <Scene
            triggerHook="onLeave"
            duration={sceneDuration}
            offset={offset}
            indicators={INDICATORS}
            pin
          >
            {progress => {
              const imageProgress = progress * (slides.length + 1);
              return (
                <Sticky width={[1]} bg={backgroundColor || BLACK}>
                  <BgContainer
                    width={[1, 0.5]}
                    ref={this.showcaseImageContainerRef}
                  >
                    <Timeline
                      totalProgress={imageProgress}
                      target={
                        <ShowcaseImageContainer width={[1]}>
                          <ShowcaseImage
                            src={foregroundImage.url}
                            alt={foregroundImage.description}
                          />
                        </ShowcaseImageContainer>
                      }
                      paused
                    >
                      <Tween
                        duration={slideDuration}
                        to={{
                          height: innerHeight,
                        }}
                        ease="Strong.easeOut"
                      />
                    </Timeline>

                    {slides.map((scene, index) => {
                      const img = findImageByTitle(images, scene.imageTitle);
                      const progressCalc = MultiImageShowcaseAnimation.getProgressForSlide(
                        progress - ratio, // offset the first animation
                        ratio,
                        index,
                      );
                      return (
                        <FadeFrameContainer width={1} key={scene.id}>
                          <Timeline
                            duration={slideDuration}
                            totalProgress={progressCalc}
                            indicators={INDICATORS}
                            target={
                              <OverlayImg src={img.url} alt={img.description} />
                            }
                            paused
                          >
                            <Tween
                              duration={slideDuration / 2}
                              from={{
                                opacity: 0,
                                delay: (slideDuration / 2) * (index + 1),
                              }}
                              to={{ opacity: 1 }}
                              ease="Strong.easeInOut"
                            />
                            <Tween
                              duration={slideDuration / 2}
                              to={{ opacity: 0 }}
                              ease="Strong.easeInOut"
                            />
                          </Timeline>
                        </FadeFrameContainer>
                      );
                    })}
                  </BgContainer>

                  <TextContainer width={[1, 0.5]}>
                    <TextInner
                      width="90%"
                      flexDirection="column"
                      alignItems={['center', 'center', 'center', 'flex-start']}
                      justifyContent="center"
                      ml="auto"
                      mr="auto"
                    >
                      {slides.map((scene, index) => {
                        const progressCalc = MultiImageShowcaseAnimation.getProgressForSlide(
                          progress - ratio, // offset the first animation
                          ratio,
                          index,
                        );
                        return (
                          <Timeline
                            key={scene.id}
                            duration={slideDuration}
                            totalProgress={progressCalc}
                            indicators={INDICATORS}
                            target={
                              <TextWrapper mr={[0, 21]}>
                                <TextBlock
                                  data={scene.textNode}
                                  blockVariant={WHITE}
                                />
                              </TextWrapper>
                            }
                            paused
                          >
                            <Tween
                              duration={slideDuration / 2}
                              from={{
                                opacity: 0,
                                delay: slideDuration * (index + 1),
                              }}
                              to={{ opacity: 1 }}
                              ease="Strong.easeInOut"
                            />
                            <Tween
                              duration={slideDuration / 2}
                              to={{ opacity: 0 }}
                              ease="Strong.easeInOut"
                            />
                          </Timeline>
                        );
                      })}
                    </TextInner>
                  </TextContainer>
                </Sticky>
              );
            }}
          </Scene>
        </Controller>
      </Box>
    );
  }
}

export default MultiImageShowcaseAnimation;
