import { createGlobalStyle } from 'styled-components';

import 'web_component_library/fonts.css';

// import GothamUltraWoff2 from '../assets/fonts/Gotham-Ultra_Web.woff2';
// import GothamUltraWoff from '../assets/fonts/Gotham-Ultra_Web.woff';

export default createGlobalStyle`

  @font-face { font-display: swap; }

  * {
    font-family: Ambra, sans-serif !important;
    font-style: normal;
  }
  i, em, i *, em * {
    font-style: italic;
  }
`;
