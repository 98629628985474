import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import ToolTip from 'web_component_library/toolTip';
import Text from 'web_component_library/text';
import freedomTheme from 'web_component_library/theme/freedom';
import { Button } from './css';

import RichText from '../richText';
import withCtaAnalytics from '../ctaAnalyticsHoc';

const { colors } = freedomTheme;

const ToolTipWithAnalytics = withCtaAnalytics(ToolTip, {
  staticModuleType: 'side-modal',
  containerType: 'portal',
});

export class Category extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
  };

  state = {
    open: false,
  };

  setBackdropVisibility = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const { content, label, options } = this.props;
    return (
      <Box>
        <Button>
          <Text
            color={colors.freedomCharcoal}
            fontSize={[2, 3]}
            fontWeight={500}
            onClick={this.setBackdropVisibility}
            withAnimation
          >
            {label}
          </Text>
        </Button>
        <ToolTipWithAnalytics
          open={this.state.open}
          content={<RichText document={content} options={options} />}
          hideBackdrop={this.setBackdropVisibility}
        />
      </Box>
    );
  }
}
