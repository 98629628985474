import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import { MediaFile } from '../../containers/contentful';
import { TextBox, Container, MediaContainer } from './css';

import withCtaAnalytics from '../ctaAnalyticsHoc';

const defaultTextBlockVariant = 'light';
const defaultTextBlockBackground = 'transparent';

const Maintenance = ({
  textBlock,
  textBlockVariant = defaultTextBlockVariant,
  image,
  textBlockBackground = defaultTextBlockBackground,
}) => {
  const imageContent = new MediaFile(image);
  return (
    <Container
      width={1}
      px={0}
      mb={0}
      bg={textBlockBackground}
      flexDirection="column"
    >
      <Flex className="restrict-content">
        <TextBox width={[1, 1 / 2]}>
          <Box width={[1, 0.85]} px={[3, 2]} m={['auto 0']}>
            <TextBlock
              buttonStyles={{ pb: [0] }}
              paragraphMargin={{ my: [0], pb: [7, '2.75em'] }}
              data={textBlock}
              blockVariant={textBlockVariant}
            />
          </Box>
        </TextBox>
        <Box width={[0, 1 / 2]} />
      </Flex>
      <MediaContainer
        url={imageContent.url}
        title={imageContent.description}
        width={[1, 1 / 2]}
      />
    </Container>
  );
};

Maintenance.displayName = 'Maintenance';

Maintenance.propTypes = {
  textBlock: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  textBlockVariant: PropTypes.string,
  textBlockBackground: PropTypes.string,
};

export default withCtaAnalytics(Maintenance, {
  staticModuleType: 'maintenance',
  includeModuleTitle: false,
});
