import { createActions } from 'redux-actions';

export const {
  getTradeInCalculatorDevicesList,
  getTradeInCalculatorDevicesListSuccess,
  getTradeInCalculatorDevicesListError,
  getTradeInCalculatorDeviceQuestions,
  getTradeInCalculatorDeviceQuestionsSuccess,
  getTradeInCalculatorDeviceQuestionsError,
  getTradeInCalculatorDeviceUpdatedPrice,
  getTradeInCalculatorDeviceUpdatedPriceSuccess,
  getTradeInCalculatorDeviceUpdatedPriceError,
  setIsLoadingDeviceQuestions,
  setIsLoadingDevicePrice,
  clearTradeInCalculator,
} = createActions(
  'GET_TRADE_IN_CALCULATOR_DEVICES_LIST',
  'GET_TRADE_IN_CALCULATOR_DEVICES_LIST_SUCCESS',
  'GET_TRADE_IN_CALCULATOR_DEVICES_LIST_ERROR',
  'GET_TRADE_IN_CALCULATOR_DEVICE_QUESTIONS',
  'GET_TRADE_IN_CALCULATOR_DEVICE_QUESTIONS_SUCCESS',
  'GET_TRADE_IN_CALCULATOR_DEVICE_QUESTIONS_ERROR',
  'GET_TRADE_IN_CALCULATOR_DEVICE_UPDATED_PRICE',
  'GET_TRADE_IN_CALCULATOR_DEVICE_UPDATED_PRICE_SUCCESS',
  'GET_TRADE_IN_CALCULATOR_DEVICE_UPDATED_PRICE_ERROR',
  'SET_IS_LOADING_DEVICE_QUESTIONS',
  'SET_IS_LOADING_DEVICE_PRICE',
  'CLEAR_TRADE_IN_CALCULATOR',
);
