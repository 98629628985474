import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'rebass/styled-components';
import Divider from 'web_component_library/divider';

import SpinningLogo from '../spinningLogo';
import {
  selectCurrentPage,
  selectLocale,
  selectSearchQueryEntries,
  selectSearchQueryEntriesFetching,
  selectSupportEntriesHasFinishedFetching,
  selectQuestions,
} from '../../containers/contentfulHoc/selectors';

import { getSupportUrl, PAGE_SEARCH } from '../../constants';
import withTranslation from '../../containers/translationHoc';
import { setUtagForViews } from '../../lib/analytics';
import { generatePageViewAnalyticsData } from '../../containers/analyticsHoc';
import ListOfLinks from '../listOfLinks';

import {
  getSupportEntries,
  getSearchQueryEntries,
} from '../../containers/contentfulHoc/actions';
import PaginationComponent from '../pagination';

const QuerySearchResults = ({
  translate,
  searchQueryQ,
  isSelected,
  searchResultsIsPaginationEnabled = true,
  skip = 0,
  limit = 10,
}) => {
  const dispatch = useDispatch();
  const searchQueryEntries = useSelector(selectSearchQueryEntries());
  const searchQueryEntriesFetching = useSelector(
    selectSearchQueryEntriesFetching(),
  );
  const questions = useSelector(selectQuestions());
  const questionsHasFinishedFetching = useSelector(
    selectSupportEntriesHasFinishedFetching(),
  );
  const { total } = searchQueryEntries;
  const locale = useSelector(selectLocale());
  const page = useSelector(selectCurrentPage());

  const doGetSearchQueryResults = useCallback(() => {
    dispatch(
      getSearchQueryEntries({
        locale,
        searchQuery: searchQueryQ,
        skip: searchResultsIsPaginationEnabled ? skip : 0,
        limit,
      }),
    );
  }, [limit, skip, searchResultsIsPaginationEnabled, searchQueryQ]);

  const doGetSupportEntries = useCallback(() => {
    dispatch(getSupportEntries(locale));
  }, [locale]);

  useEffect(() => {
    if (!isSelected) {
      doGetSearchQueryResults();
    }
  }, [isSelected, doGetSearchQueryResults]);

  useEffect(() => {
    if (isSelected) {
      doGetSupportEntries();
    }
  }, [isSelected, doGetSupportEntries]);

  const linksList = useMemo(() => {
    if (isSelected) {
      const articlesAvailable = questions[searchQueryQ]?.articles || [];
      return articlesAvailable.map(({ title, slug, url }) => ({
        to: url || `/${locale}/${getSupportUrl()}/${slug}`,
        text: title,
      }));
    }
    return (searchQueryEntries?.items || []).map(({ title, slug }) => ({
      to: `/${locale}/${getSupportUrl()}/${slug}`,
      text: title,
    }));
  }, [questions, searchQueryQ, searchQueryEntries?.items]);

  useEffect(() => {
    if (typeof total !== 'undefined') {
      const pageViewData = generatePageViewAnalyticsData({
        page,
        locale,
      });
      setUtagForViews({
        ...pageViewData,
        number_of_search_results: String(linksList.length),
        search_string: searchQueryQ,
      });
    }
  }, [linksList, searchQueryQ, page, locale, total]);

  const title = useMemo(() => {
    if (isSelected) {
      if (questionsHasFinishedFetching) {
        return linksList.length > 0
          ? `(${linksList.length}) ${translate('results')}`
          : translate('noResults');
      }
    } else if (searchQueryEntries?.items && !searchQueryEntriesFetching) {
      return linksList.length
        ? `(${
            searchResultsIsPaginationEnabled
              ? `${linksList.length}/${searchQueryEntries?.total}`
              : linksList.length
          }) ${translate('results')}`
        : translate('noResults');
    }
    return translate('loading');
  }, [
    isSelected,
    linksList,
    questionsHasFinishedFetching,
    searchQueryEntries,
    searchQueryEntriesFetching,
  ]);

  return (
    <>
      <Text
        as="h2"
        fontSize={[5, 7]}
        letterSpacing="0.07em"
        fontWeight={0}
        color="freedomCharcoal"
        style={{ textTransform: 'uppercase' }}
        data-testid="query-search-results-title"
      >
        {title}
      </Text>
      {!searchQueryEntriesFetching && linksList && (
        <ListOfLinks links={linksList} />
      )}
      {searchQueryEntriesFetching && (
        <div
          style={{
            height: `${81 * limit + 16 * 2}px`,
            width: '100%',
            position: 'relative',
          }}
          data-testid="loading-spinning-logo"
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <SpinningLogo />
          </div>
        </div>
      )}
      <PaginationComponent
        total={total}
        skip={skip}
        limit={limit}
        isEnabled={searchResultsIsPaginationEnabled}
        getLink={({ skipTo }) =>
          `/${locale}/${getSupportUrl()}/${PAGE_SEARCH}?q=${searchQueryQ}&skip=${skipTo}`
        }
      />
      <Divider color="black" thickness={2} mt={[7, 17]} />
    </>
  );
};

QuerySearchResults.propTypes = {
  translate: PropTypes.func.isRequired,
  searchQueryQ: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  searchResultsIsPaginationEnabled: PropTypes.bool,
  limit: PropTypes.number,
  skip: PropTypes.number,
};
export default compose(withTranslation)(QuerySearchResults);
