export function extractProperty(obj, keys, defaultValue = null) {
  if (!obj) return defaultValue;

  let keyArray;

  if (Array.isArray(keys)) keyArray = keys;
  else keyArray = keys.replace(/(\[(\d)\])/g, '.$2').split('.');

  const object = obj[keyArray[0]];

  if (object && keyArray.length > 1) {
    return extractProperty(object, keyArray.slice(1), defaultValue);
  }

  return object !== undefined ? object : defaultValue;
}
