import PropTypes from 'prop-types';
import styled from 'styled-components';
import freedomTheme from 'web_component_library/theme/freedom';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import { Flex, Box, Button, Text } from 'rebass/styled-components';

const { colors } = freedomTheme;

export const LettersPanelContainer = styled(Box)`
  margin-left: -1em;
  overflow: hidden;
  /* since we are offsetting the panel out of the container
  for mobile to 1em to the left, we need to add the doubled size
  of the offset to width */
  width: calc(100% + 2em);
  ${MediaQuery.desktop`
    margin-left: 0;
    overflow: none;
    width: 100%;
  `}
`;

export const LettersPanel = styled(Flex)`
  justify-content: flex-start;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  ${MediaQuery.desktop`
    justify-content: space-around;
    overflow-x: hidden;
  `}
`;

export const LetterContainer = styled(Box)`
  min-width: 4em;
  text-align: center;
  ${MediaQuery.desktop`
    min-width: unset;
  `}
  border-bottom: 1px ${colors.grey} solid;
`;

export const Letter = styled(Button)`
  border-bottom: ${props =>
    props.selected ? `2px ${colors.black} solid` : ''};
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
`;

Letter.PropTypes = {
  selected: PropTypes.bool,
};

export const NoRatesCountry = styled(Text)`
  opacity: 0.5;
`;
