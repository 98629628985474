import MetaTagItem from './metaTagItem';

export default class MetaTag {
  constructor(data) {
    if (data && data.fields) {
      const { title, tags } = data.fields;
      this.title = title;
      this.tags = this.extractTags(tags);
    }
  }

  extractTags(payload) {
    const tags = [];
    if (Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i += 1) {
        tags.push(new MetaTagItem(payload[i]));
      }
    }
    return tags;
  }
}
