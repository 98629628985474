import React, { useState } from 'react';
import { arrayOf, bool, object, string } from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import { isMobile } from '../../../utils/animationHelper';
import { calculateWidth } from '../../../utils/calculateColumnWidth';
import { componentMapping } from '../../../utils/helpers';
import CommonGroupRow from './commonGroupRow';
import { getComponentToRender } from '../../../utils/targetHelpers';

const template = (component, length, groupRowTitle, page) => {
  if (component.identifier) {
    const Template = componentMapping[component.identifier];
    if (!Template) return React.Fragment;
    return (
      <Template
        {...component}
        page={page}
        columnsLength={length}
        groupRowTitle={groupRowTitle}
      />
    );
  }
  return null;
};

const createFlexTemplate = (
  item,
  columns,
  columnMargin,
  contentAlignment,
  groupRowTitle,
  page,
) => {
  let justifyContent;

  switch (contentAlignment) {
    case 'center':
      justifyContent = 'center';
      break;
    case 'right':
      justifyContent = 'flex-end';
      break;
    default:
      justifyContent = 'flex-start';
  }

  return (
    <Flex
      id={item.id}
      key={item.id}
      width={calculateWidth(columns)}
      justifyContent={justifyContent}
      {...columnMargin}
    >
      {template(item, columns.length, groupRowTitle, page)}
    </Flex>
  );
};

const createBoxTemplate = (item, columns, groupRowTitle, page) => (
  <Box id={item.id} key={item.id} width={1}>
    {template(item, columns.length, groupRowTitle, page)}
  </Box>
);

/* eslint-disable react/prop-types */
function renderGroupRow({
  columnsToRender,
  heading,
  isHeadingVisible,
  groupRowTitle,
  setGroupRowTitle,
  isWhiteBackground,
  headingPosition,
  restrictContent,
  cushionContent,
  isFirstRow,
  contentAlignment,
  isBlackBackground,
  page,
}) {
  const columnMargin = isFirstRow ? { pt: [5, 11], pb: [5] } : { py: [5] };

  return (
    <CommonGroupRow
      isWhiteBackground={isWhiteBackground}
      heading={heading}
      headingPosition={headingPosition}
      isHeadingVisible={isHeadingVisible}
      setGroupRowTitle={setGroupRowTitle}
      restrictContent={restrictContent}
      cushionContent={cushionContent}
      isBlackBackground={isBlackBackground}
    >
      {columnsToRender &&
        columnsToRender.map(item =>
          createFlexTemplate(
            item,
            columnsToRender,
            columnMargin,
            contentAlignment,
            groupRowTitle,
            page,
          ),
        )}
    </CommonGroupRow>
  );
}

function renderFlexRow({
  columnsToRender,
  heading,
  isHeadingVisible,
  groupRowTitle,
  setGroupRowTitle,
  isWhiteBackground,
  headingPosition,
  restrictContent,
  cushionContent,
  isBlackBackground,
  page,
}) {
  return (
    <CommonGroupRow
      isWhiteBackground={isWhiteBackground}
      heading={heading}
      headingPosition={headingPosition}
      setGroupRowTitle={setGroupRowTitle}
      headingBlock
      isHeadingVisible={isHeadingVisible}
      restrictContent={restrictContent}
      cushionContent={cushionContent}
      isBlackBackground={isBlackBackground}
    >
      <Flex width={1}>
        {columnsToRender &&
          columnsToRender.map(item =>
            createBoxTemplate(item, columnsToRender, groupRowTitle, page),
          )}
      </Flex>
    </CommonGroupRow>
  );
}

const defaultIdentifier = 'groupRow';
const defaultContentAlignment = 'left';
const defaultHeadingPosition = 'center';
const GroupRow = ({
  identifier = defaultIdentifier,
  columns,
  heading = null,
  isHeadingVisible = false,
  isAnimationEnabled = false,
  headingPosition = defaultHeadingPosition,
  isWhiteBackground = false,
  restrictContent = true,
  cushionContent = false,
  isLastRow = false,
  isFirstRow = true,
  targetAudience = null,
  contentAlignment = defaultContentAlignment,
  isBlackBackground = false,
  page,
}) => {
  const [groupRowTitle, setGroupRowTitle] = useState('');
  let renderFunction = renderGroupRow;
  if (identifier === 'flexRow') {
    renderFunction = renderFlexRow;
  }
  const columnsToRender = columns?.reduce(
    (acc, component) =>
      getComponentToRender(acc, component, targetAudience, page),
    [],
  );

  const shouldAnimate = !isMobile() && isAnimationEnabled;
  return renderFunction({
    shouldAnimate,
    columnsToRender,
    heading,
    isHeadingVisible,
    groupRowTitle,
    setGroupRowTitle,
    isWhiteBackground,
    headingPosition,
    restrictContent,
    cushionContent,
    isFirstRow,
    isLastRow,
    contentAlignment,
    isBlackBackground,
    page,
  });
};

GroupRow.displayName = 'GroupRow';

GroupRow.propTypes = {
  identifier: string,
  columns: arrayOf(object).isRequired,
  heading: object, // TODO: Rich text content shape
  isHeadingVisible: bool,
  isAnimationEnabled: bool,
  headingPosition: string,
  isWhiteBackground: bool,
  isLastRow: bool,
  isFirstRow: bool,
  id: string.isRequired,
  targetAudience: string,
  restrictContent: bool,
  cushionContent: bool,
  contentAlignment: string,
  isBlackBackground: bool,
  page: object.isRequired,
};

export default GroupRow;
