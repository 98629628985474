export default class MetaTagItem {
  constructor(data) {
    if (data && data.fields) {
      const { metaType, nameOrProperty, content } = data.fields;
      this.metaType = metaType;
      this.nameOrProperty = nameOrProperty;
      this.content = content;
    }
  }
}
