import { createActions } from 'redux-actions';

export const {
  setImeiCheckStatus,
  getImeiCheckStatus,
  getImeiCheckStatusClear,
} = createActions(
  'SET_IMEI_CHECK_STATUS',
  'GET_IMEI_CHECK_STATUS',
  'GET_IMEI_CHECK_STATUS_CLEAR',
);
