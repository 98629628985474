import styled from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import { Flex, Box, Text } from 'rebass/styled-components';
import { WRAPPER_SIZE } from '../../theme/style-helpers';

export const Section = styled(Box)`
  position: 'relative';
`;

export const Wrapper = styled(Flex)`
  max-width: ${WRAPPER_SIZE}px;
  text-align: center;
`;

// H4 for styling contentful data.
export const BenefitBox = styled(Flex)`
  height: 100%;
  text-align: center;

  & h4 {
    margin-bottom: 0;
    margin-top: 1.5rem;
  }

  ${MediaQuery.desktop`
  & h4 {
    margin-top: 0;
  }
  `}
`;

// after elements for mobile orange dividers.
export const Benefit = styled(Text)`
  list-style-type: none;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      width: 5em;
      height: 2px;
      margin: 0 auto;
      background: ${({ borderColor }) => borderColor};
      margin-top: 1.5em;
    }
  }

  ${MediaQuery.desktop`
   &:not(:last-child) {
      border-right: 2px solid ${({ borderColor }) => borderColor};
      &::after {
        display: none;
      }
    }
  `}
`;

// Contentful styling for the H4
export const EyebrowText = styled(Box)`
  & h4 {
    color: ${({ accentColor }) => accentColor};
  }
`;

// Contentful styling for H2
export const HeadlineText = styled(Box)`
  & h2 {
    margin: 0;
  }
`;
