/**
 * Get env var without server complaining about window
 *
 * @param key string
 */
export const getEnvVar = key => {
  const windowGlobal = typeof window !== 'undefined' && window;
  if (!windowGlobal) {
    return process.env[key];
  }
  return windowGlobal.process.env[key];
};

export default getEnvVar;
