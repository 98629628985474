import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import { selectPlanSize } from '../../containers/app/selectors';

import Feature from '../feature';

const defaultPlanSize = null;
const defaultTitle = '';
const defaultFeatures = [];
/**
 * @return {null}
 */
export function PlanFeature({
  planSize = defaultPlanSize,
  title = defaultTitle,
  features = defaultFeatures,
}) {
  const content = {};
  // title & features can be updated from store OR directly title, features.
  if (planSize) {
    const {
      fields: {
        feature: {
          fields: { title: planTitle, features: planFeatures },
        },
      },
    } = planSize;
    content.title = planTitle;
    content.features = planFeatures;
  } else {
    content.title = title;
    content.features = features;
  }

  return (
    <Flex flexDirection="column" alignContent="space-between" py={[17, 26]}>
      <TextBlock
        data={content.title}
        blockVariant="light"
        headingStyles={{ m: 0 }}
      />
      <Flex flexWrap="wrap" justifyContent="space-between">
        {content.features.map((item, index) => {
          const {
            fields: { name, description },
          } = item;
          const key = `Feature${index}`;
          return (
            <Box key={key} width={[1, 1 / 3]} pt={[13, 21]}>
              <Feature
                title={name}
                description={description}
                width={[1, '67.5%']}
              />
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
}

PlanFeature.propTypes = {
  planSize: PropTypes.object,
  title: PropTypes.object,
  features: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = createStructuredSelector({
  planSize: selectPlanSize(),
});

export default connect(mapStateToProps)(PlanFeature);
