class ComponentWrapper {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const { component, targetedAudiences } = data.fields;
      this.component = component;
      this.targetedAudiences = targetedAudiences.map(
        audience => audience.fields || {},
      );
    }
  }
}

export default class TargetedList {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const {
        identifier,
        componentOptions,
        defaultComponent,
        allowedBrands,
      } = data.fields;
      this.identifier = identifier;
      this.componentOptions = componentOptions
        ? componentOptions
            .map(component => new ComponentWrapper(component))
            .filter(option => Object.keys(option).length > 0)
        : null;
      this.defaultComponent = defaultComponent;
      this.allowedBrands = allowedBrands
        ? allowedBrands.map(entry => entry.fields?.value).filter(Boolean)
        : null;
    }
  }
}
