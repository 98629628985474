import React from 'react';
import PropTypes from 'prop-types';
import WclTable from 'web_component_library/table';

const Table = props => {
  const header = props.header.fields.row.map(
    item => item.fields.title || item.fields.value,
  );

  const rows = props.rows.map(rowItem =>
    rowItem.fields.row.map(item => item.fields.title || item.fields.value),
  );

  return <WclTable rows={rows} header={header} />;
};

const plainTextPropTypes = PropTypes.shape({
  fields: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
});

const richTextBlockPropTypes = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.object.isRequired,
  }).isRequired,
});

Table.propTypes = {
  header: PropTypes.shape({
    fields: PropTypes.shape({
      row: PropTypes.arrayOf(
        PropTypes.oneOfType([richTextBlockPropTypes, plainTextPropTypes]),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        row: PropTypes.arrayOf(
          PropTypes.oneOfType([richTextBlockPropTypes, plainTextPropTypes]),
        ).isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default Table;
