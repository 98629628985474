import React, { forwardRef } from 'react';
import { node } from 'prop-types';
import styled from 'styled-components';
import Box from 'web_component_library/box';
import { Flex, Box as StyledBox } from 'rebass/styled-components';

export const Container = styled(Box)`
  margin-top: 0;
`;
export const InnerContainer = styled(Box)`
  display: block;
  margin: 0 auto;
  min-height: 100%;
  width: 100%;
`;
export const AppWrapper = styled(Flex)`
  margin: 0 auto;
  min-height: 100%;
  width: 100%;
`;

export const HeaderContainer = forwardRef(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    as="header"
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    }}
    {...props}
  >
    {children}
  </Box>
));
HeaderContainer.propTypes = { children: node.isRequired };

export const Main = forwardRef(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    as="main"
    sx={{
      '&:focus': {
        outline: 'none',
      },
    }}
    {...props}
  >
    {children}
  </Box>
));
Main.propTypes = { children: node.isRequired };

export const FooterContainer = ({ children, ...props }) => (
  <Box as="footer" {...props}>
    {children}
  </Box>
);
FooterContainer.propTypes = { children: node.isRequired };

export const BottomAlertContainer = ({ children, ...props }) => (
  <StyledBox
    sx={{
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    }}
    {...props}
  >
    {children}
  </StyledBox>
);
BottomAlertContainer.propTypes = { children: node.isRequired };
