import { createSelector } from 'reselect';

export const selectTradeIn = state => state.tradeIn;

export const selectTradeInDevicesList = () =>
  createSelector(
    selectTradeIn,
    data => data.devicesLists,
  );

export const selectTradeInDevicesListError = () =>
  createSelector(
    selectTradeIn,
    data => data.devicesListError,
  );

export const selectTradeInDeviceQuestions = () =>
  createSelector(
    selectTradeIn,
    data => data.deviceQuestions,
  );

export const selectTradeInDeviceQuestionsError = () =>
  createSelector(
    selectTradeIn,
    data => data.deviceQuestionsError,
  );

export const selectTradeInDeviceCode = () =>
  createSelector(
    selectTradeIn,
    data => data.deviceCode,
  );

export const selectTradeInDeviceImage = () =>
  createSelector(
    selectTradeIn,
    data => data.deviceImage,
  );

export const selectTradeInDeviceModel = () =>
  createSelector(
    selectTradeIn,
    data => data.deviceModel,
  );

export const selectTradeInDeviceEstimatedPrice = () =>
  createSelector(
    selectTradeIn,
    data => data.estimatedPrice,
  );

export const selectTradeInDeviceAdjustedPrice = () =>
  createSelector(
    selectTradeIn,
    data => data.adjustedPrice,
  );

export const selectTradeInIsLoadingQuestions = () =>
  createSelector(
    selectTradeIn,
    data => data.isLoadingDeviceQuestions,
  );

export const selectTradeInIsLoadingPrice = () =>
  createSelector(
    selectTradeIn,
    data => data.isLoadingDevicePrice,
  );
