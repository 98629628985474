import { handleActions } from 'redux-actions';
import { setImeiCheckStatus } from './actions';

export const initialState = {
  imeiNumber: '',
  imeiMessage: {},
};

const reducer = handleActions(
  {
    [setImeiCheckStatus]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);

export default reducer;
