import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import { H3 } from 'web_component_library/typography';
import CTA from '../cta';
import { CtaModal } from '../../containers/contentful';

const defaultCtaLink = {};
/**
 * LoginSection wrapper for library to set default options.
 * @param description
 * @param ctaLink
 * @returns {*}
 */
const LoginSection = ({ description, ctaLink = defaultCtaLink }) => {
  const ctaContent = new CtaModal(ctaLink);
  return (
    <Flex
      py={[7, 17]}
      mb={[7, '3.25em']}
      px={[7, 17]}
      mt={[0, 3]}
      bg="white"
      width={1}
      flexDirection="row"
      flexWrap="wrap"
    >
      <Box width={[1, 3 / 5]} alignSelf="center">
        <H3 pb={3} pt={[0, 7]}>
          {description}
        </H3>
      </Box>
      <Box width={[1, 2 / 5]} pr={[0, 17]}>
        <CTA
          position={ctaContent.position}
          url={ctaContent.url}
          value={ctaContent.value}
          variant={ctaContent.variant}
          type={ctaContent.type}
        />
      </Box>
    </Flex>
  );
};

LoginSection.propTypes = {
  description: PropTypes.string.isRequired,
  ctaLink: PropTypes.shape({
    data: PropTypes.object,
    content: PropTypes.object,
  }),
};

LoginSection.displayName = 'LoginSection';

export default LoginSection;
