import { takeLatest, put, call } from 'redux-saga/effects';
import {
  setImeiCheckStatus,
  getImeiCheckStatus,
  getImeiCheckStatusClear,
} from './actions';
import request from '../../../utils/request';
import getEnvVar from '../../lib/envvars';
import { setUtagForLinks } from '../../lib/analytics';

export function* imeiNumberCheck({ payload: { imeiNumber } }) {
  const url = `${getEnvVar('apiBaseUrl')}v1/imei/${
    imeiNumber.imei
  }/device-compatibility`;

  try {
    const response = yield call(request, url);
    yield put(
      setImeiCheckStatus({
        imeiMessage: response,
      }),
    );
    if (response.isCompatible) {
      setUtagForLinks({
        ev_type: 'interaction',
        ev_action: 'click',
        ev_name: 'byop compatibility',
        ev_data: 'compatible',
      });
    } else {
      setUtagForLinks({
        ev_type: 'interaction',
        ev_action: 'click',
        ev_name: 'byop compatibility',
        ev_data: 'not compatible',
      });
    }
  } catch (error) {
    yield put(
      setImeiCheckStatus({
        imeiMessage: { status: error },
      }),
    );
  }
}

export function* imeiCheckStatusClear() {
  yield put(
    setImeiCheckStatus({
      imeiMessage: {},
    }),
  );
}

export default function* imeiCheckSaga() {
  yield takeLatest(getImeiCheckStatus, imeiNumberCheck);
  yield takeLatest(getImeiCheckStatusClear, imeiCheckStatusClear);
}
