export default class AlertBanner {
  constructor(data) {
    if (data && data.fields) {
      const {
        title,
        isGlobal,
        position,
        backgroundColour,
        textColour,
        content,
        dismissText,
        closeIconColour,
      } = data.fields;

      this.title = title;
      this.isGlobal = isGlobal;
      this.position = position;
      this.backgroundColour = backgroundColour;
      this.textColour = textColour;
      this.content = content;
      this.dismissText = dismissText;
      this.id = data.sys.id;
      this.closeIconColour = closeIconColour;
    }
  }
}
