export const utagDataModel = {
  page_name: '',
  page_section: '',
  site_name: 'default',
  page_canonical_url: '',
  page_breadcrumb: '',
  site_version: '',
  page_language: '',
  user_login_state: '',
  user_account_id: '',
  user_optimizely_id: '',
  user_login_type: '',
  user_audience_id: '',
  user_entry_audience_id: '',
  user_audience_qualifier: '',
  user_mc_audience_id: '',
  user_account_type: '',
  page_purpose: '',
  user_plans: '',
  user_devices: '',
  user_servicetypes: '',
  user_service_id: '',
  page_product: '',
  page_error: '',
  error_state: '',
  page_url: '',
};
