import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'web_component_library/flex';
import Box from 'web_component_library/box';
import Button from 'web_component_library/button';
import Link from 'web_component_library/link';

import { getSupportUrl, PAGE_CATEGORIES } from '../../constants';

function SupportCategories({ categories, isFullWidth = false }) {
  const desktopWidth = isFullWidth ? 1 : 3 / 4;
  const numItemsPerRow = isFullWidth ? 4 : 2;

  if (!categories || !categories.length) return null;

  return (
    <Flex mb={['md', 'xxl']} justifyContent="center">
      <Box
        as="ul"
        width={[1, desktopWidth]}
        sx={{
          // START: Cleanup default ul styles
          pl: 0,
          listStyle: 'none',
          // END: Cleanup default ul styles
          display: 'grid',
          gridTemplateColumns: [
            '1fr',
            Array(numItemsPerRow)
              .fill('1fr')
              .join(' '),
          ],
          gridGap: 'lg',
        }}
      >
        {categories
          .filter(category => Boolean(category.fields)) // only show published content
          .map(category => {
            const {
              fields: { title },
            } = category;

            return (
              <Box as="li" key={title}>
                <Button
                  as={Link}
                  variant="secondary"
                  to={`${getSupportUrl(PAGE_CATEGORIES)}/${title}`}
                  height={1}
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {title}
                </Button>
              </Box>
            );
          })}
      </Box>
    </Flex>
  );
}

SupportCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFullWidth: PropTypes.bool,
};

export default SupportCategories;
