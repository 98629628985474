import React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from 'web_component_library/box';
import PropTypes from 'prop-types';

export const DeviceHelp = ({ page }) => {
  const baseUrl = `/${page.locale}/${page.url}`;
  const scriptAttributes = {
    src: 'https://content.sds.modeaondemand.com/portal/loader.min.js',
    'data-api-key': 'd35fb81145ee49419222ce889bed1141d263644f',
    'data-target': 'ozmo-root',
    'data-basename': baseUrl,
    id: 'ozmo',
  };

  return (
    <>
      <Helmet>
        <script defer {...scriptAttributes} />
      </Helmet>
      <Box
        mt="7"
        style={{
          position: 'relative',
          zIndex: 0,
        }}
      >
        <div data-testid="device-help-ozmo" id="ozmo-root"></div>
      </Box>
    </>
  );
};

DeviceHelp.propTypes = {
  page: PropTypes.object.isRequired,
};
