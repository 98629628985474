import styled from 'styled-components';
import { Box as BaseBox } from 'rebass/styled-components';
import { Paragraph as WclParagraph } from 'web_component_library/typography';
import freedomTheme from 'web_component_library/theme/freedom';
import MediaQuery from 'web_component_library/utils/mediaQuery';

const { colors } = freedomTheme;
const { charcoal } = colors;
export const Paragraph = styled(WclParagraph)`
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: -0.2px;
  line-height: 3em;
  a {
    color: ${charcoal};
    font-weight: 700;
  }
  strong {
    font-weight: 700;
  }
  ${MediaQuery.desktop`
    font-size: 1rem;
    line-height: 3em;
  `}
`;

export const Box = styled(BaseBox)`
  min-height: 5.75em;
`;
