import { createClient } from 'contentful';

export default class ContentfulService {
  static instance;

  productCatalogClient = null;
  browseCatalogClient = null;
  commonCatalogClient = null;
  commonBrowseClient = null;

  constructor({
    productCatalogCredentials,
    browseCatalogCredentials,
    commonCatalogCredentials,
    commonBrowseCredentials,
  }) {
    if (ContentfulService.instance) {
      return ContentfulService.instance;
    }
    this.productCatalogClient = ContentfulService._initClient(
      productCatalogCredentials,
    );
    this.browseCatalogClient = ContentfulService._initClient(
      browseCatalogCredentials,
    );
    this.commonCatalogClient = ContentfulService._initClient(
      commonCatalogCredentials,
    );
    this.commonBrowseClient = ContentfulService._initClient(
      commonBrowseCredentials,
    );

    ContentfulService.instance = this;
  }

  static _initClient({ spaceId, accessToken, environment, preview }) {
    if (preview) {
      return createClient({
        space: spaceId,
        accessToken,
        environment,
        host: 'preview.contentful.com',
      });
    }
    return createClient({
      space: spaceId,
      accessToken,
      environment,
    });
  }

  getProductCatalogClient() {
    return this.productCatalogClient;
  }

  getBrowseCatalogClient() {
    return this.browseCatalogClient;
  }

  getCommonCatalogClient() {
    return this.commonCatalogClient;
  }

  getCommonBrowseClient() {
    return this.commonBrowseClient;
  }
}
