import { createSelector } from 'reselect';

import { serviceKey } from './constants';
import { initState } from './reducer';

export const selectGlobal = state => {
  if (state && state[serviceKey]) {
    return state[serviceKey];
  }
  return initState;
};

export const makeSelectUser = createSelector(
  selectGlobal,
  state => state.result,
);
export const makeSelectUserAddress = createSelector(
  makeSelectUser,
  state => state?.address,
);
export const makeSelectUserProvince = createSelector(
  makeSelectUserAddress,
  state => state?.province,
);

export const makeSelectGetUserProfileError = createSelector(
  selectGlobal,
  state => state.errorCode,
);

export const makeSelectGetUserProfileIsLoading = createSelector(
  selectGlobal,
  state => state.isLoading,
);

export const makeSelectHasGetUserProfileLoaded = createSelector(
  selectGlobal,
  state => state.hasLoaded,
);
