export default class CtaModal {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const {
        slug,
        arrowDirection,
        position,
        url,
        value,
        variant,
        type,
      } = data.fields;
      this.id = data.sys.id;
      this.slug = slug;
      this.arrowDirection = arrowDirection;
      this.position = position;
      this.url = url;
      this.value = value;
      this.variant = variant;
      this.type = type;
    }
  }
}
