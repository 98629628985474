import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

class SpatialBuzz extends PureComponent {
  static propTypes = {
    iframeInitUrl: PropTypes.string.isRequired,
    iframeUrl: PropTypes.string.isRequired,
    bootstrapUrl: PropTypes.string,
    imageUrl: PropTypes.string,
  };

  static defaultProps = {
    bootstrapUrl: '',
    imageUrl: '',
  };

  componentDidMount() {
    const { iframeInitUrl, iframeUrl, bootstrapUrl, imageUrl } = this.props;

    // scriptjs is client-side only,
    // so we access it inside componentDidMount, which doesn't run server-side
    // eslint-disable-next-line global-require
    const $script = require('scriptjs');

    if (bootstrapUrl && imageUrl) {
      // Fix for issue when Iframe does not load after first time initialization
      if (
        typeof window !== 'undefined' &&
        window.sbHDCoverage &&
        window.sbHDCoverage.sbHDCoverageInitialised
      ) {
        window.sbHDCoverage.init();
        window.sbHDCoverage.inject_iframe();
      } else {
        $script(bootstrapUrl, () => {
          $script(iframeInitUrl, () => {
            $script(iframeUrl, () => {});
          });
        });
      }
    } else {
      /* eslint-disable */
      // If first page visit, initialize script and inject iframe
      // If subsequent page visit, just inject iframe
      if (!window.SBParamsOuter) {
        $script([iframeInitUrl, iframeUrl], () => {
          window.initSBIframe('sb_coverage');
        });
      } else {
        window.initSBIframe('sb_coverage');
      }
      /* eslint-enable */
    }
  }

  render() {
    const { bootstrapUrl, imageUrl } = this.props;

    return bootstrapUrl && imageUrl ? (
      <Box id="sbuzz_content" width={1}>
        <img alt="" src={imageUrl} />
      </Box>
    ) : (
      <Box id="sb_coverage" width={1} css={{ minHeight: '37.5rem' }} />
    );
  }
}

export default SpatialBuzz;
