import styled from 'styled-components';
import Box from 'web_component_library/box';

export const ArrowRight = styled(Box)`
  position: absolute;
  top: 40%;
  right: 1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 0;
  border: 2px solid ${({ theme }) => theme.colors.orange};
  transition: transform 0.2s ease-in-out;
  transform: rotate(315deg);
  border-left: 0;
  border-top: 0;
  border-radius: 0;
  width: 1rem;
  height: 1rem;
`;
