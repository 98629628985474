export default class PlanNameOnCard {
  constructor(data) {
    if (data && data.fields) {
      const { badge, badgeBackgroundColor, text, title } = data.fields;
      this.badge = badge?.fields || null;
      this.badgeBackgroundColor = badgeBackgroundColor || null;
      this.text = text || null;
      this.title = title || null;
    }
  }
}
