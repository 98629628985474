import { all } from 'redux-saga/effects';
import contentSaga from '../containers/contentfulHoc/saga';
import imeiCompatibilityCheck from '../containers/imeiCompatibilityCheck/saga';
import getUserProfileSaga from '../services/getUserProfile/saga';
import getFeaturesSaga from '../containers/featuresWrapper/saga';
import tradeInCalculatorSaga from '../containers/tradeInCalculator/saga';

// These sagas are isomorphic, i.e., run on both client and server
export function* rootSaga() {
  yield all([contentSaga(), getFeaturesSaga()]);
}

// These sagas are client-side only
export function* appSaga() {
  yield all([
    imeiCompatibilityCheck(),
    tradeInCalculatorSaga(),
    getUserProfileSaga(),
  ]);
}
