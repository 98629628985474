import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import { Header } from './pageHeading.css';

const defaultTextBlockBackground = 'transparent';
const defaultTextBlockVariant = 'light';
const defaultMargins = { mt: [11, '5.625em'] };
const defaultTextAlignment = 'left';

const PageHeading = ({
  textBlockBackground = defaultTextBlockBackground,
  textBlock,
  textBlockVariant = defaultTextBlockVariant,
  margins = defaultMargins,
  verticalPadding = 0,
  horizontalPadding = 0,
  textAlignment = defaultTextAlignment,
  isLargeSectionHeader = false,
}) =>
  isLargeSectionHeader ? (
    <Box bg={textBlockBackground} width={1}>
      <Header
        py={verticalPadding}
        px={horizontalPadding}
        textAlignment={textAlignment}
        data-testid="header"
        margin="0 auto"
      >
        <TextBlock data={textBlock} blockVariant={textBlockVariant} />
      </Header>
    </Box>
  ) : (
    <Box bg={textBlockBackground} width={1} {...margins}>
      <TextBlock data={textBlock} blockVariant={textBlockVariant} />
    </Box>
  );

PageHeading.propTypes = {
  textBlockBackground: PropTypes.string,
  textBlockVariant: PropTypes.string,
  textBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired, // RichText
  margins: PropTypes.object,
  verticalPadding: PropTypes.array,
  horizontalPadding: PropTypes.array,
  textAlignment: PropTypes.string,
  isLargeSectionHeader: PropTypes.bool,
};

export default PageHeading;
