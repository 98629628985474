export const BUSINESS_TOGGLES = {
  APPLE_CHAT: 'DIGM-9898',
  PREPAID_ADDON_FILTER: 'DIGM-10705',
  NEW_PLAN_CARD: 'FMBS-25',
};

export const getBusinessToggles = () => {
  const businessToggles =
    typeof window !== 'undefined'
      ? JSON.parse(window.localStorage.getItem('businessToggles'))
      : global.businessToggles;

  return businessToggles || {};
};

export const getBusinessToggle = ticketNum => getBusinessToggles()[ticketNum];
