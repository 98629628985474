export default class ContentfulModel {
  // eslint-disable-next-line no-unused-vars,no-useless-constructor
  constructor(data) {
    // Not doing anything right now here, but we could in the future add this.sysId = data.sys.id;
  }

  extractEntriesToList = (entries, EntryModel) => {
    const extractedEntries = [];
    if (Array.isArray(entries)) {
      for (let i = 0; i < entries.length; i += 1) {
        const extractedEntry = EntryModel
          ? new EntryModel(entries[i])
          : entries[i]?.fields;
        if (extractedEntry) {
          extractedEntries.push(extractedEntry);
        }
      }
    }
    return extractedEntries;
  };

  extractEntriesToObject = (entries, EntryModel, fieldKey) => {
    const obj = {};
    if (Array.isArray(entries)) {
      for (let i = 0; i < entries.length; i += 1) {
        const extractedEntry = EntryModel
          ? new EntryModel(entries[i])
          : entries[i]?.fields;
        if (extractedEntry?.[fieldKey]) {
          obj[extractedEntry[fieldKey]] = extractedEntry;
        }
      }
    }

    return obj;
  };
}
