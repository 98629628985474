import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Heading, Flex, Box } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import ExpandCollapse from 'web_component_library/expandCollapse';
import { VARIANT_LIGHT } from '../../constants';

const renderHeader = (title, isPanelClosed) => (
  <Flex alignItems="flex-start" flex="1 auto" width={1}>
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      flex="1 1 auto"
      width={1}
    >
      <Heading
        fontSize={3}
        fontWeight={2}
        lineHeight={5}
        letterSpacing={2}
        pb={7}
        color={isPanelClosed ? 'freedomCharcoal' : 'black'}
      >
        {title}
      </Heading>
    </Flex>
  </Flex>
);

export class Accordion extends PureComponent {
  static displayName = 'Accordion';

  static propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
    isBackgroundTransparent: PropTypes.bool,
    hasSidePadding: PropTypes.bool,
  };

  static defaultProps = {
    isBackgroundTransparent: false,
    hasSidePadding: false,
  };

  state = {
    isPanelClosed: true,
  };

  handler = (id, isPanelClosed) => {
    this.setState({ isPanelClosed });
  };

  render() {
    const {
      title,
      content,
      isBackgroundTransparent,
      hasSidePadding,
    } = this.props;
    const { isPanelClosed } = this.state;

    return (
      <Box width={1}>
        <ExpandCollapse
          isDivider
          isPanelOpen={false}
          tag="h4"
          headerNode={renderHeader(title, isPanelClosed)}
          pt={13}
          pb={isPanelClosed ? 13 : 3}
          handler={this.handler}
          isBackgroundTransparent={isBackgroundTransparent}
          hasSidePadding={hasSidePadding}
        >
          <TextBlock id={title} data={content} blockVariant={VARIANT_LIGHT} />
        </ExpandCollapse>
      </Box>
    );
  }
}

// To use with styleguidist, we need to use a special annotation
/** @component */
export default Accordion;
