import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';
import Divider from 'web_component_library/divider';
import { Box } from '../splitTextBlock/css';

import { ModalContext } from '../modal/modalContext';
import CtaTextBlock from '../ctaTextBlock';
import Modal from '../modalComponent';
import { setUtagForLinks } from '../../lib/analytics';

export class SplitTextPopupButton extends PureComponent {
  static contextType = ModalContext;

  static propTypes = {
    leftBlock: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    rightBlock: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    isRightBlockCenterAligned: PropTypes.bool,
    variant: PropTypes.string,
    trackingName: PropTypes.string,
  };

  static defaultProps = {
    variant: 'light',
    isRightBlockCenterAligned: false,
    trackingName: '',
  };

  constructor(props) {
    super(props);
    this.openPopup = this.openPopup.bind(this);
  }

  openPopup = component => {
    if (this.context) {
      this.context.showModal(Modal, component);

      if (this.props.trackingName) {
        setUtagForLinks({
          ev_type: 'interaction',
          ev_action: 'open',
          ev_name: this.props.trackingName,
        });
      }
    }
  };

  render() {
    const {
      leftBlock,
      rightBlock,
      variant,
      isRightBlockCenterAligned,
    } = this.props;
    const paragraphMargin = { mb: [11, 15], mt: 0 };
    return (
      <Box width={1}>
        <Flex flexWrap="wrap" pt={[11, 26]} pb={[11, 29]}>
          <Box width={[1, 0.45]} mr={[0, '5%']}>
            <TextBlock
              data={leftBlock}
              blockVariant={variant}
              headingStyles={{ my: [0] }}
            />
          </Box>
          <Box
            width={[1, '22em']}
            mr={[0, '5%']}
            isCenterAligned={isRightBlockCenterAligned}
            mt={[11, 0]}
          >
            <CtaTextBlock
              data={rightBlock}
              blockVariant={variant}
              clickHandler={this.openPopup}
              paragraphMargin={paragraphMargin}
            />
          </Box>
        </Flex>
        <Divider thickness={2} color="black" mb={[1, '-.75em']} />
      </Box>
    );
  }
}
SplitTextPopupButton.displayName = 'SplitTextPopupButton';
export default SplitTextPopupButton;
