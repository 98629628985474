import React, { PureComponent } from 'react';
import { arrayOf, array, object, string, number, shape } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';

import TextBlock from 'web_component_library/textBlock';
import freedomTheme from 'web_component_library/theme/freedom';
import { findImageByTitle } from '../../helpers';
import {
  Wrapper,
  BgContainer,
  BgImage,
  BgImageForeground,
  Bullet,
  BulletInner,
  Sticky,
  TextWrapper,
} from './css';
import { BREAKPOINT } from '../../../../constants';

const { colors } = freedomTheme;

const WHITE = colors.pureWhite;
let INDICATORS = false;
// safeguard to make sure indicators are turned off in prod
if (process.env.NODE_ENV === 'production') {
  INDICATORS = false;
}

class FactShowcaseAnimation extends PureComponent {
  static propTypes = {
    data: shape({
      backgroundColor: string,
      slideDuration: number,
      slides: array,
    }),
    images: arrayOf(object).isRequired,
  };

  static defaultProps = {
    data: {
      backgroundColor: colors.blue,
      slideDuration: 2000,
      slides: [],
    },
  };

  static getProgressForSlide(progress, ratio, slideIndex) {
    return Math.max((progress - ratio * slideIndex) / ratio, 0);
  }

  state = {
    innerWidth: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    this.windowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  windowResize = () => {
    this.setState({
      innerWidth: window.innerWidth,
    });
  };

  render() {
    const { images, data } = this.props;
    const { innerWidth } = this.state;

    let offset = 0;
    if (innerWidth >= BREAKPOINT) {
      offset = -80;
    }

    const desktopBg0 = findImageByTitle(images, 'desktop-0');
    const desktopBg1 = findImageByTitle(images, 'desktop-1');
    const mobileBg0 = findImageByTitle(images, 'mobile-0');
    const mobileBg1 = findImageByTitle(images, 'mobile-1');

    let bgImage = mobileBg0;
    let bgImageFg = mobileBg1;
    if (innerWidth >= BREAKPOINT) {
      bgImage = desktopBg0;
      bgImageFg = desktopBg1;
    }

    const ratio = 1 / data.slides.length;
    const { slideDuration } = data;
    const sceneDuration = slideDuration * (data.slides.length + 1);

    return (
      <Wrapper
        position="relative"
        bg={data.backgroundColor || WHITE}
        data-testid="fact-showcase"
      >
        <Controller globalSceneOptions={{ loglevel: 2 }}>
          <Scene
            duration={sceneDuration}
            triggerHook="onLeave"
            offset={offset}
            indicators={INDICATORS}
            pin
          >
            {progress => {
              const bgOpacityCalc = Math.min(
                FactShowcaseAnimation.getProgressForSlide(progress, ratio, 0) *
                  3 +
                  0.1, // this is to offset the animation slightly to prevent a flicker
                1,
              );
              return (
                <Sticky bg={data.backgroundColor} width={[1]}>
                  <BgContainer width={[1, 0.5]}>
                    <Timeline
                      duration={slideDuration}
                      totalProgress={bgOpacityCalc}
                      indicators={INDICATORS}
                      paused
                    >
                      <Tween
                        duration={slideDuration}
                        to={{ height: '100%' }}
                        ease="Strong.easeOut"
                      >
                        <BgImage
                          width={[1]}
                          style={{ backgroundImage: `url(${bgImage.url})` }}
                        />
                      </Tween>
                      <Tween
                        duration={1}
                        from={{ opacity: 0 }}
                        to={{ y: '0', opacity: 1 }}
                      >
                        <BgImageForeground
                          width={[1]}
                          style={{
                            backgroundImage: `url(${bgImageFg.url})`,
                          }}
                        />
                      </Tween>
                    </Timeline>
                  </BgContainer>
                  {data.slides.map((slide, index) => {
                    const progressCalc = FactShowcaseAnimation.getProgressForSlide(
                      progress,
                      ratio,
                      index,
                    );

                    return (
                      <Timeline
                        key={slide.id}
                        duration={slideDuration}
                        totalProgress={progressCalc}
                        target={
                          <Bullet alignItems="center" justifyContent="center">
                            <BulletInner>
                              <TextWrapper>
                                <TextBlock
                                  data={slide.textNode}
                                  blockVariant={WHITE}
                                />
                              </TextWrapper>
                            </BulletInner>
                          </Bullet>
                        }
                        indicators={INDICATORS}
                        paused
                      >
                        <Tween
                          duration={slideDuration / 3}
                          from={{ y: '50', opacity: 0 }}
                          to={{ y: '0', opacity: 1 }}
                          ease="Strong.easeInOut"
                        />
                        <Tween
                          duration={slideDuration / 3}
                          from={{ delay: 1500 }}
                          to={{ opacity: 0 }}
                          ease="Strong.easeInOut"
                        />
                      </Timeline>
                    );
                  })}
                </Sticky>
              );
            }}
          </Scene>
        </Controller>
      </Wrapper>
    );
  }
}

export default FactShowcaseAnimation;
