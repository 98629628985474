import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import TertiaryButton from 'web_component_library/tertiaryButton';
import Button from 'web_component_library/button';
import { setUtagForLinks } from '../../lib/analytics';

const defaultAnalyticsName = '';

const Cta = ({
  type,
  variant,
  value,
  url,
  analyticsName = defaultAnalyticsName,
}) => {
  const utagObj = {
    ev_type: 'component',
    ev_action: 'click',
    ev_name: analyticsName,
    ev_data: value,
  };

  const button = (
    <Box>
      <Button
        variant={variant}
        onClick={e => {
          e.preventDefault();
          setUtagForLinks(utagObj, url);
        }}
      >
        {value}
      </Button>
    </Box>
  );
  const tertiaryButton = (
    <Flex>
      <Box>
        <TertiaryButton
          onClick={e => {
            e.preventDefault();
            setUtagForLinks(utagObj, url);
          }}
          text={value}
          textColor="freedomCharcoal"
        />
      </Box>
    </Flex>
  );
  if (type === 'primaryButton') return button;
  return tertiaryButton;
};

Cta.propTypes = {
  analyticsName: PropTypes.string,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Cta;
