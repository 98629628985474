import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExpandCollapse from 'web_component_library/expandCollapse';
import { Box } from 'rebass/styled-components';

const defaultPropsOptions = {
  divider: true,
  tag: 'h6',
  headerNode: '',
};

export const ExpandCollapseWrapper = styled(ExpandCollapse).attrs(
  ({
    options: {
      divider = defaultPropsOptions.divider,
      tag = defaultPropsOptions.tag,
    } = defaultPropsOptions,
    headerNode = defaultPropsOptions.headerNode,
    title = '',
  }) => ({
    isDivider: divider,
    isPanelOpen: false,
    tag,
    headerNode,
    key: title,
  }),
)``;

ExpandCollapseWrapper.propTypes = {
  options: PropTypes.shape({
    divider: PropTypes.bool,
    tag: PropTypes.string,
  }),
  headerNode: PropTypes.string,
  title: PropTypes.string,
};

export const Container = styled(Box)`
  p {
    strong {
      color: #000;
    }
  }
`;
