import { createSelector } from 'reselect';
import { initState } from './reducer';

export const selectFeaturesReducer = state => {
  if (state?.flagsmith) {
    return state.flagsmith;
  }
  return initState;
};

export const selectHasLoadedFeatures = createSelector(
  selectFeaturesReducer,
  state => state.hasLoaded,
);

export const selectFeatures = createSelector(
  selectFeaturesReducer,
  state => state.features,
);

export const makeSelectFeature = ticketNum =>
  createSelector(
    selectFeatures,
    features => features[ticketNum] || false,
  );
