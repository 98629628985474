import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'web_component_library/typography';
import TextBlock from 'web_component_library/textBlock';

const TableText = ({ textData, paragraphStyles = null }) => {
  if (typeof textData === 'object') {
    return <TextBlock data={textData} paragraphStyles={paragraphStyles} />;
  }

  return <Paragraph {...paragraphStyles}>{textData}</Paragraph>;
};

TableText.propTypes = {
  textData: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  paragraphStyles: PropTypes.object,
};

export default TableText;
