import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { parse, stringify } from 'qs';

const INTERVAL_LEN_MS = 100; // 0.5s
const MAX_INTERVAL_LEN_MS = 60000; // 1 min

const ScrollToText = ({ top: topPadding = 0 }) => {
  const history = useHistory();
  const { location } = history;

  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  const { goToHeading, goToText } = queryParams;
  const textToScrollTo = goToHeading || goToText; // goToHeading takes priority

  const [hasScrolled, setHasScrolled] = useState(false);

  // Reset on new page load
  useEffect(() => {
    if (textToScrollTo) {
      setHasScrolled(false);
    }
  }, [location.pathname, textToScrollTo]);

  useEffect(() => {
    if (hasScrolled || !textToScrollTo) return () => {};

    let currIntervalLength = 0;

    const intervalId = setInterval(() => {
      currIntervalLength += INTERVAL_LEN_MS;
      // Stop polling for matching text when we pass max time limit
      if (currIntervalLength > MAX_INTERVAL_LEN_MS) {
        clearInterval(intervalId);
        return;
      }

      // This xpath string has the following search conditions:
      // * Matches elements *containing* text (`contains` function)
      // * Ignores case sensitivity (`translate` function)
      // * Only searches inside body tag
      // * Ignores header
      // * Ignores script tags
      const xpath = `
        //body//*[
          ${
            goToHeading
              ? '(self::h1 or self::h2 or self::h3 or self::h4 or self::h5 or self::h6) and'
              : ''
          }
          not(self::script) and
          not(ancestor::header) and
          contains(
            translate(text(), 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'),
            translate('${textToScrollTo}', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz')
          )
        ]
      `;

      // Though we don't support all browsers, the optional chain just ensures nothing breaks for legacy browser users
      const firstMatchingElement = document.evaluate?.(
        xpath,
        document.body,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null,
      ).singleNodeValue;

      if (firstMatchingElement) {
        const distanceFromTopOfPage =
          firstMatchingElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: distanceFromTopOfPage - topPadding,
          behavior: 'instant', // smooth scroll is unreliable
        });

        // By clearing goToHeading and goToText query param, we support links that scroll within the same page
        const newQueryParams = { ...queryParams };
        delete newQueryParams.goToHeading;
        delete newQueryParams.goToText;
        history.replace({
          search: stringify(newQueryParams),
        });

        setHasScrolled(true);
        clearInterval(intervalId);
      }
    }, INTERVAL_LEN_MS);

    return () => clearInterval(intervalId);
  }, [hasScrolled, textToScrollTo, goToHeading, topPadding]);

  return null;
};

ScrollToText.propTypes = {
  top: PropTypes.number,
};

export default ScrollToText;
