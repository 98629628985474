import React, { Fragment, PureComponent, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Helmet } from 'react-helmet-async';
import Cookies from 'js-cookie';
import { Box, Flex } from 'rebass/styled-components';
import HeaderNav from 'web_component_library/headerNav';
import Footer from 'web_component_library/footer';
import AlertBanner from 'web_component_library/alertBanner';
import SkipLink from 'web_component_library/skipLink';

import {
  Container,
  InnerContainer,
  Main,
  HeaderContainer,
  BottomAlertContainer,
  FooterContainer,
} from './css';
import TermsAndConditionsDrawer from '../../components/termsAndConditionsDrawer';

import RegionsDropdown from '../../components/regionsDropdown';
import BaseQuestionAndAnswer from '../../components/questionAndAnswer';
import GroupRow from '../../components/groupRow';
import HeroBanner from '../../components/heroBanner';
import Maintenance from '../../components/maintenance';
import {
  selectCommonCatalog,
  selectPopupModals,
  selectBrowseCatalog,
  selectGlobalAlertBanner,
} from '../contentfulHoc/selectors';
import withAnalytics from '../analyticsHoc';
import withCtaAnalytics, {
  isAbsolutePath,
} from '../../components/ctaAnalyticsHoc';
import { MAIN_SITE_URL } from '../../constants';
import { ModalContext } from '../../components/modal/modalContext';
import Modal from '../../components/modalComponent';
import {
  launchLiveChat,
  sendPropertiesToNiceChat,
} from '../../../utils/liveChatHelper';
import Breadcrumb from './breadcrumb';
import {
  setTranslations,
  setCurrentPage as setCurrentPageAction,
  getClientSideEntries as getClientSideEntriesAction,
} from '../contentfulHoc/actions';
import AlertBannerModel from '../contentful/alertBanner';
import ErrorBoundary from '../errorBoundary';
import {
  dismissGlobalAlert,
  setHeaderHeight as setHeaderHeightAction,
} from '../app/actions';
import {
  selectGlobalAlertDismissed,
  selectHeaderHeight,
} from '../app/selectors';
import {
  getTargetAudience,
  getTargetAudienceCookie,
  getComponentToRender,
} from '../../../utils/targetHelpers';
import ScrollToText from '../../components/scrollToText';
import { renderLinkTags, renderMetaTags } from '../../../utils/seo';
import { withFreedomAuth } from '../providers/oidcProvider/hook';
import { withRegion } from '../providers/regionProvider/hook';
import { DEFAULT_REGION } from '../contentfulHoc/constants';
import { makeSelectUser } from '../../services/getUserProfile/selectors';

const QuestionAndAnswer = withCtaAnalytics(BaseQuestionAndAnswer);

const SkipLinkWithanalytics = withCtaAnalytics(SkipLink, {
  staticModuleType: 'skip-link',
  includeModuleTitle: false,
});

const AlertBannerWithAnalytics = withCtaAnalytics(AlertBanner, {
  staticModuleType: 'alert',
  includeModuleTitle: false,
});

export class ContentPage extends PureComponent {
  static displayName = 'ContentPage';

  static contextType = ModalContext;

  static propTypes = {
    page: PropTypes.object.isRequired,
    commonCatalog: PropTypes.object,
    browseCatalog: PropTypes.object,
    popupModals: PropTypes.object,
    globalAlertBanner: PropTypes.instanceOf(AlertBannerModel),
    locale: PropTypes.string.isRequired,
    onSetTranslations: PropTypes.func.isRequired,
    breadcrumbColor: PropTypes.string,
    globalAlertDismissed: PropTypes.bool.isRequired,
    onDismissGlobalAlert: PropTypes.func.isRequired,
    headerHeight: PropTypes.number.isRequired,
    setHeaderHeight: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    doFreedomAuthLogin: PropTypes.func.isRequired,
    doFreedomAuthLogout: PropTypes.func.isRequired,
    getClientSideEntries: PropTypes.func.isRequired,
    region: PropTypes.object,
    availableRegions: PropTypes.arrayOf(PropTypes.string).isRequired,
    updateRegion: PropTypes.func.isRequired,
    regionsIsDisabled: PropTypes.bool.isRequired,
    userInfo: PropTypes.object,
  };

  static defaultProps = {
    commonCatalog: null,
    browseCatalog: null,
    popupModals: null,
    globalAlertBanner: new AlertBannerModel(),
    breadcrumbColor: '',
    region: '',
    userInfo: null,
  };

  mainContent = createRef();

  header = createRef();

  state = {
    targetAudience: getTargetAudienceCookie(),
    topAlertDismissed: false,
    bottomAlertDismissed: false,
    isMounted: false,
  };

  componentDidMount() {
    const {
      page,
      onSetTranslations,
      setCurrentPage,
      history,
      location,
    } = this.props;

    this.setState({ isMounted: true });
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('region')) {
      queryParams.delete('region');
      history.replace({
        search: queryParams.toString(),
      });
    }

    // We set a cookie for the server and client to use in rendering process on NEXT visit/page view
    const nextTargetAudience = getTargetAudience();
    if (nextTargetAudience) {
      Cookies.set('tealiumTargetAudience', nextTargetAudience, {
        expires: 365,
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'lax',
      });
    } else {
      Cookies.remove('tealiumTargetAudience');
    }

    onSetTranslations(page.translations);
    setCurrentPage(page);

    window.addEventListener('resize', this.updateHeight);
    this.updateHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  componentDidUpdate(prevProps) {
    const {
      page,
      onSetTranslations,
      setCurrentPage,
      getClientSideEntries,
      region,
      locale,
      location,
      history,
      userInfo,
    } = this.props;

    sendPropertiesToNiceChat(userInfo);

    if (page.redirectTo) {
      const url = page.redirectTo;
      const isAbsoluteUrl = isAbsolutePath(url);
      if (isAbsoluteUrl) {
        window.location.href = url;
      } else {
        history.replace(url);
      }
    }

    if (page !== prevProps.page) {
      onSetTranslations(page.translations);
      setCurrentPage(page);
      if (typeof window !== 'undefined' && window.cxone) {
        window.cxone(
          'chat',
          'setCaseCustomField',
          'webchaturl',
          window.location.href,
        );
      }
    }
    if (region !== prevProps.region) {
      getClientSideEntries({ locale, region, url: location.pathname });
    }

    this.updateHeight();
  }

  updateHeight = () => {
    const header = this.header.current;
    if (!header) return;

    const { headerHeight, setHeaderHeight } = this.props;

    const newHeaderHeight = header.offsetHeight;
    if (headerHeight !== newHeaderHeight) {
      setHeaderHeight(newHeaderHeight);
    }
  };

  openPopup = popUpIdentifier => {
    const popupModal = this.props.popupModals[popUpIdentifier];
    this.context.showModal(Modal, popupModal);
  };

  renderInnerContainer = (
    groupRows,
    isTermsAndConditionsVisible,
    supplementalTermsAndConditions,
    termsAndConditions,
    questions,
    page,
  ) => {
    const { targetAudience } = this.state;
    return (
      <Box>
        <InnerContainer>
          {groupRows &&
            groupRows.map((row, index) => (
              <GroupRow
                key={`unique-key-${index + 1}`}
                {...row}
                isLastRow={index === groupRows.length - 1}
                isFirstRow={index === 0}
                targetAudience={targetAudience}
                page={page}
              />
            ))}
        </InnerContainer>
        {questions && (
          <Box width={1} bg="pureWhite">
            <InnerContainer>
              <Box px={7}>
                <QuestionAndAnswer {...questions} />
              </Box>
            </InnerContainer>
          </Box>
        )}
        {isTermsAndConditionsVisible && (
          <Box width={1} bg="pureWhite">
            <InnerContainer>
              <Box px={7} data-nosnippet>
                <TermsAndConditionsDrawer
                  data={termsAndConditions}
                  supplemental={supplementalTermsAndConditions}
                />
              </Box>
            </InnerContainer>
          </Box>
        )}
      </Box>
    );
  };

  render() {
    const {
      targetAudience,
      topAlertDismissed,
      bottomAlertDismissed,
    } = this.state;
    const {
      commonCatalog: { termsAndConditions, header, footer },
      page,
      locale,
      browseCatalog: { pages },
      globalAlertBanner,
      globalAlertDismissed,
      onDismissGlobalAlert,
      headerHeight,
      isLoggedIn,
      doFreedomAuthLogin,
      doFreedomAuthLogout,
      region,
      availableRegions,
      updateRegion,
      regionsIsDisabled,
    } = this.props;

    const globalBannerExists = !!Object.keys(globalAlertBanner).length;
    const topGlobalAlertBanner =
      globalBannerExists &&
      globalAlertBanner.position !== 'bottom' &&
      globalAlertBanner;
    const bottomGlobalAlertBanner =
      globalBannerExists &&
      globalAlertBanner.position === 'bottom' &&
      globalAlertBanner;

    const { isMounted } = this.state;

    const {
      components,
      isTermsAndConditionsVisible,
      metaTag,
      linkTag,
      topAlertBanner,
      bottomAlertBanner,
      maintenance,
      heroContent,
      schema,
      breadcrumbColor,
      questions,
      supplementalTermsAndConditions,
      includeRegionsDropdown,
      hideBreadCrumbs,
      hideHeaderAndFooter,
    } = page;

    const { textBlock, textBlockVariant, image, textBlockBackground } =
      maintenance || {};

    const targetableGroupRows = Array.isArray(components)
      ? components.reduce(
          (acc, component) =>
            getComponentToRender(acc, component, targetAudience),
          [],
        )
      : [];
    const isProd =
      typeof window === 'undefined'
        ? process.env?.ENVIRONMENT === 'prod' &&
          process.env?.DEPLOYMENT === 'production'
        : window.config?.ENVIRONMENT === 'prod' &&
          window.config?.DEPLOYMENT === 'production';

    return (
      <Fragment>
        <Helmet>
          {metaTag.title ? <title>{metaTag.title}</title> : null}
          {renderLinkTags(linkTag?.tags)}
          {renderMetaTags(metaTag?.tags)}
          {schema ? (
            <script type="application/ld+json">{`${JSON.stringify(
              schema,
            )}`}</script>
          ) : null}
          {!isProd ? <meta name="robots" content="noindex" /> : null}
        </Helmet>

        <ScrollToText top={headerHeight + 20} />
        <SkipLinkWithanalytics targetId="mainContent">
          Skip Navigation
        </SkipLinkWithanalytics>

        <HeaderContainer ref={this.header} data-testid="header-container">
          {!hideHeaderAndFooter &&
            (isMounted && topGlobalAlertBanner && !globalAlertDismissed && (
              <AlertBannerWithAnalytics
                backgroundColour={topGlobalAlertBanner.backgroundColour}
                closeIconColour={topGlobalAlertBanner.closeIconColour}
                content={topGlobalAlertBanner.content}
                dismissText={topGlobalAlertBanner.dismissText}
                textColour={topGlobalAlertBanner.textColour}
                isDismissed={globalAlertDismissed}
                onDismiss={onDismissGlobalAlert}
                data-testid="top-global-alerts"
              />
            ))}
          {isMounted && topAlertBanner && (
            <AlertBannerWithAnalytics
              backgroundColour={topAlertBanner.backgroundColour}
              closeIconColour={topAlertBanner.closeIconColour}
              content={topAlertBanner.content}
              dismissText={topAlertBanner.dismissText}
              textColour={topAlertBanner.textColour}
              isDismissed={topAlertDismissed}
              onDismiss={() => this.setState({ topAlertDismissed: true })}
            />
          )}

          {!hideHeaderAndFooter && (
            <HeaderNav
              headerData={header}
              launchLiveChat={launchLiveChat}
              locale={locale}
              position="static"
              currentSiteUrl={MAIN_SITE_URL}
              isLoggedIn={isLoggedIn}
              onLogout={doFreedomAuthLogout}
              onLogin={doFreedomAuthLogin}
              province={region || DEFAULT_REGION}
              provinceOptions={availableRegions}
              onProvinceChange={updateRegion}
              provinceDisabled={regionsIsDisabled}
              data-testid="header-nav-v2"
            />
          )}
        </HeaderContainer>

        <Main
          ref={this.mainContent}
          mt={[
            hideHeaderAndFooter ? 0 : headerHeight || 64,
            hideHeaderAndFooter ? 0 : headerHeight || 80,
          ]}
          id="mainContent"
        >
          <ErrorBoundary>
            {includeRegionsDropdown && <RegionsDropdown />}
            {!hideBreadCrumbs ? (
              <Box px={7}>
                <Flex className="restrict-content">
                  <Breadcrumb
                    color={breadcrumbColor}
                    locale={locale}
                    pages={pages}
                  />
                </Flex>
              </Box>
            ) : null}
            <Container>
              {maintenance ? (
                <Maintenance
                  textBlock={textBlock}
                  textBlockVariant={textBlockVariant}
                  image={image}
                  textBlockBackground={textBlockBackground}
                />
              ) : (
                <Fragment>
                  {heroContent && (
                    <HeroBanner
                      content={heroContent}
                      targetAudience={targetAudience}
                      openPopup={this.openPopup}
                    />
                  )}
                  {this.renderInnerContainer(
                    targetableGroupRows,
                    isTermsAndConditionsVisible,
                    supplementalTermsAndConditions,
                    termsAndConditions,
                    questions,
                    { ...page, locale },
                  )}
                </Fragment>
              )}
            </Container>
          </ErrorBoundary>
        </Main>
        {!hideHeaderAndFooter && (
          <FooterContainer data-testid="footer-container">
            <Footer
              footerData={footer}
              locale={locale}
              openPopup={this.openPopup}
              launchLiveChat={launchLiveChat}
              currentSiteUrl={MAIN_SITE_URL}
            />
          </FooterContainer>
        )}
        {!hideHeaderAndFooter &&
          isMounted &&
          ((bottomGlobalAlertBanner && !globalAlertDismissed) ||
            bottomAlertBanner) && (
            <BottomAlertContainer>
              {bottomAlertBanner && (
                <AlertBannerWithAnalytics
                  backgroundColour={bottomAlertBanner.backgroundColour}
                  closeIconColour={bottomAlertBanner.closeIconColour}
                  content={bottomAlertBanner.content}
                  dismissText={bottomAlertBanner.dismissText}
                  textColour={bottomAlertBanner.textColour}
                  isDismissed={bottomAlertDismissed}
                  onDismiss={() =>
                    this.setState({ bottomAlertDismissed: true })
                  }
                />
              )}
              {bottomGlobalAlertBanner && !globalAlertDismissed && (
                <AlertBannerWithAnalytics
                  backgroundColour={bottomGlobalAlertBanner.backgroundColour}
                  closeIconColour={bottomGlobalAlertBanner.closeIconColour}
                  content={bottomGlobalAlertBanner.content}
                  dismissText={bottomGlobalAlertBanner.dismissText}
                  textColour={bottomGlobalAlertBanner.textColour}
                  isDismissed={globalAlertDismissed}
                  onDismiss={onDismissGlobalAlert}
                />
              )}
            </BottomAlertContainer>
          )}
      </Fragment>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  commonCatalog: selectCommonCatalog(),
  popupModals: selectPopupModals(),
  browseCatalog: selectBrowseCatalog(),
  globalAlertBanner: selectGlobalAlertBanner(),
  globalAlertDismissed: selectGlobalAlertDismissed(),
  headerHeight: selectHeaderHeight(),
  userInfo: makeSelectUser,
});

export const mapDispatchToProps = dispatch => ({
  onSetTranslations: translations => dispatch(setTranslations(translations)),
  onDismissGlobalAlert: () => dispatch(dismissGlobalAlert()),
  setHeaderHeight: newHeight => dispatch(setHeaderHeightAction(newHeight)),
  setCurrentPage: page => dispatch(setCurrentPageAction(page)),
  getClientSideEntries: props => dispatch(getClientSideEntriesAction(props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFreedomAuth(withAnalytics(withRouter(withRegion(ContentPage)))));
