import React from 'react';
import PropTypes from 'prop-types';
import WclPromoCard from 'web_component_library/promoCard';
import { getImageUrl } from '../../../utils/imageHelper';
import withPopup from '../../../utils/withPopup';

const PromoCard = ({
  isButtonFullWidth = true,
  image = null,
  mobileImage = null,
  ...props
}) => {
  const passedProps = {
    image,
    mobileImage,
    isButtonFullWidth,
    ...props,
  };

  // `placeholderUrl` is set to opt into image lazy loading
  if (image && image.fields) {
    passedProps.image.placeholderUrl = getImageUrl(image.fields.file.url);
  }
  if (mobileImage && mobileImage.fields) {
    passedProps.mobileImage.placeholderUrl = getImageUrl(
      mobileImage.fields.file.url,
    );
  }

  return <WclPromoCard {...passedProps} />;
};

PromoCard.propTypes = {
  isButtonFullWidth: PropTypes.bool,
  image: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  mobileImage: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default withPopup(PromoCard);
