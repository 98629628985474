export default class AvailablePromotion {
  constructor(data) {
    if (data && data.fields) {
      const {
        entryTitle,
        reference,
        content,
        value,
        showInPlan,
        label,
      } = data.fields;
      this.title = entryTitle;
      this.content = content;
      this.reference = typeof reference === 'undefined' ? '' : reference;
      this.sysId = data.sys.id;
      this.value = value;
      this.showInPlan = showInPlan;
      this.label = label;
    }
  }
}
