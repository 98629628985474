import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Box from 'web_component_library/box';
import PlanCard from 'web_component_library/planCard';
import PlanCardV2 from 'web_component_library/planCardV2';
import { H4 } from 'web_component_library/typography';
import { getPrepaidPlans } from '../contentfulHoc/actions';
import PlanModel from '../contentful/plan';
import { selectPrepaidPlans, selectLocale } from '../contentfulHoc/selectors';
import {
  URL_POSTPAID,
  PT_PREPAID,
  PLANS_DEVICE_TYPES,
  PHONE_PLAN_TAG,
} from '../../constants';
import withTranslation from '../translationHoc';
import { launchLiveChat } from '../../../utils/liveChatHelper';
import { prettifyThePriceWithZero } from '../../../utils/number';
import {
  getBusinessToggle,
  BUSINESS_TOGGLES,
} from '../../../utils/toggleHelpers';
import withCtaAnalytics from '../../components/ctaAnalyticsHoc';

export const getPlanDeviceType = tags => {
  const availableTags = tags.map(tag => tag.tag);
  const planType = availableTags.find(tag => PLANS_DEVICE_TYPES[tag]);
  return PLANS_DEVICE_TYPES[planType] || PHONE_PLAN_TAG;
};

export const generateRedirectUrl = (
  plan,
  baseUrl,
  directToFindAStore,
  findAStoreUrl,
) => {
  if (directToFindAStore) return findAStoreUrl;
  const { tags, sku } = plan;
  const deviceType = getPlanDeviceType(tags);
  return `${baseUrl}${encodeURIComponent(sku)}&deviceType=${deviceType}`;
};

const PlanCardWithCtaAnalytics = withCtaAnalytics(PlanCard, {
  includeModuleTitle: false,
  staticModuleType: 'plan-card',
  includeSubtitle: true,
  customSubtitleKeys: [
    'prepaidSectionTitle',
    'planTitle',
    'bonusData',
    'saleOrPromo',
    'price',
  ],
});

const PlanCardV2WithCtaAnalytics = withCtaAnalytics(PlanCardV2, {
  includeModuleTitle: false,
  staticModuleType: 'plan-card',
  includeSubtitle: true,
  customSubtitleKeys: ['prepaidSectionTitle', 'planName'],
});

const defaultPrepaidUrl = URL_POSTPAID;
const defaultFindAStoreUrl = '';

export const PrepaidPlans = ({
  locale,
  onGetPlans,
  findAStoreUrl = defaultFindAStoreUrl,
  prepaidUrl = defaultPrepaidUrl,
  prepaidPlans,
  chatEngagementId = null,
  translate,
  identifier,
  appleChatBizIntentId = null,
  appleChatBizGroupId = null,
  appleChatBody = null,
  isAppleChatOn = true,
}) => {
  const [hasIsNewPlanCardOnLoaded, setHasIsNewPlanCardOnLoaded] = useState(
    undefined,
  );

  const isNewPlanCardOn =
    getBusinessToggle(BUSINESS_TOGGLES.NEW_PLAN_CARD) || false;

  useEffect(() => {
    onGetPlans(locale);
  }, []);

  useEffect(() => {
    if (isNewPlanCardOn !== undefined)
      setHasIsNewPlanCardOnLoaded(isNewPlanCardOn);
  }, [isNewPlanCardOn]);

  const getPlan = (plan, prepaidSectionTitle) => {
    const { categories, directToFindAStore } = plan;
    if (!categories[PT_PREPAID]) return null;

    const onPlanClick = () => {
      if (chatEngagementId) {
        launchLiveChat(
          null,
          chatEngagementId,
          appleChatBizIntentId,
          appleChatBizGroupId,
          appleChatBody,
          isAppleChatOn,
        );
      } else {
        const redirectUrl = generateRedirectUrl(
          plan,
          prepaidUrl,
          directToFindAStore,
          findAStoreUrl,
        );

        window.location.assign(redirectUrl);
      }
    };

    return (
      hasIsNewPlanCardOnLoaded && (
        <Box as="li" py={7} key={plan.sku}>
          {isNewPlanCardOn ? (
            <PlanCardV2WithCtaAnalytics
              addTooltipListener={() => {}}
              ctaButtonLabel={translate('findAStore')}
              discountedPriceAmount={prettifyThePriceWithZero(
                plan.discountedPrice,
              )}
              includesDigitalDiscountText={translate('includesDigitalDiscount')}
              isChecked={false}
              onClick={() => onPlanClick()}
              planData={plan}
              planName={plan?.planNameOnCard?.title}
              pricePeriodText={plan.pricePeriod || translate('mo')}
            />
          ) : (
            <PlanCardWithCtaAnalytics
              identifier={identifier}
              planTitle={plan.name}
              bonusData={plan.data.bonusSize}
              saleOrPromo={plan.promotionFlag?.title}
              price={prettifyThePriceWithZero(plan.price)}
              tertiaryPromotion={plan.tertiaryPromotion?.promotionTitle}
              prepaidSectionTitle={prepaidSectionTitle}
              planData={plan}
              onClick={() => onPlanClick()}
              discountedPriceAmount={prettifyThePriceWithZero(
                plan.discountedPrice,
              )}
              monthlyTabCharge={plan.monthlyTabCharge}
              regularPriceText={translate('reg')}
              pricePeriodText={plan.pricePeriod || translate('mo')}
              includesDigitalDiscountText={translate('includesDigitalDiscount')}
              ctaButtonLabel={translate('findAStore')}
              promotionTooltipContent={plan.promotionTooltipContent}
              shouldShowRegularPrice={Boolean(plan.showRegularPrice)}
            />
          )}
        </Box>
      )
    );
  };
  return Object.entries(prepaidPlans).map(
    ([prepaidSectionTitle, prepaidSectionPlans]) => (
      <Box py={7} key={prepaidSectionTitle}>
        <H4 mb={9}>{prepaidSectionTitle}</H4>
        <Box
          as="ul"
          width={1}
          sx={{
            display: 'grid',
            gridTemplateColumns: ['auto', 'repeat(3, 1fr)'],
            alignItems: 'end',
            gridGap: 11,
            // Remove ul/li default styling
            p: 0,
            '> li': { listStyle: 'none' },
          }}
        >
          {prepaidSectionPlans.map(plan => getPlan(plan, prepaidSectionTitle))}
        </Box>
      </Box>
    ),
  );
};

PrepaidPlans.propTypes = {
  prepaidPlans: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.instanceOf(PlanModel)),
  ).isRequired,
  locale: PropTypes.string.isRequired,
  onGetPlans: PropTypes.func.isRequired,
  prepaidUrl: PropTypes.string,
  findAStoreUrl: PropTypes.string,
  translate: PropTypes.func,
  chatEngagementId: PropTypes.string,
  appleChatBizIntentId: PropTypes.string,
  appleChatBizGroupId: PropTypes.string,
  appleChatBody: PropTypes.string,
  isAppleChatOn: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  prepaidPlans: selectPrepaidPlans(),
  locale: selectLocale(),
});

const mapDispatchToProps = dispatch => ({
  onGetPlans: payload => dispatch(getPrepaidPlans(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(PrepaidPlans));
