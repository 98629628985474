import React from 'react';
import { node, bool } from 'prop-types';

import { StyledFlex } from './css';

function RestrictContent({
  children,
  restrictContent = true,
  cushionContent = false,
}) {
  return (
    <StyledFlex
      flexWrap="wrap"
      justifyContent="space-between"
      width={1}
      margin="auto"
      restrictContent={restrictContent}
      cushionContent={cushionContent}
    >
      {children}
    </StyledFlex>
  );
}

RestrictContent.propTypes = {
  children: node.isRequired,
  restrictContent: bool,
  cushionContent: bool,
};

export default RestrictContent;
