import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Box, Flex, Image } from 'rebass/styled-components';
import ToolTip from 'web_component_library/toolTip';
import TextBlock from 'web_component_library/textBlock';
import { H3, Paragraph } from 'web_component_library/typography';
import ResizeContainer from 'web_component_library/resizeContainer';
import Text from 'web_component_library/text';
import Divider from 'web_component_library/divider';
import Period from 'web_component_library/period';
import WCLButton from 'web_component_library/button';
import withCtaAnalytics from '../ctaAnalyticsHoc';

import { CardBlock, Button, Card } from './css';

const ToolTipWithAnalytics = withCtaAnalytics(ToolTip, {
  staticModuleType: 'side-modal',
  containerType: 'portal',
});

export class CardTemplate extends PureComponent {
  static propTypes = {
    /** Text for category will be string */
    imageUrl: PropTypes.string,

    /** Text for category will be string */
    category: PropTypes.string.isRequired,

    /** Text for name will be string */
    name: PropTypes.string.isRequired,

    /** Text for description will be string */
    description: PropTypes.string,

    /** Rich Text for description will be string */
    descriptionRichText: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object),
      data: PropTypes.object,
      nodeType: PropTypes.string,
    }),

    customDescription: PropTypes.any,

    /** Text for cta for Manage or Ad-ons will be string */
    cta: PropTypes.string,

    /** Text for amount will be string */
    amount: PropTypes.string,

    /** Text for period will be string */
    period: PropTypes.string,

    tooltipContent: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object),
      data: PropTypes.object,
      nodeType: PropTypes.string,
    }),

    descriptionPriceText: PropTypes.string,

    /** Optional button CTA at the end of the card */
    buttonCta: PropTypes.string,

    /** Optional: To overwrite the default URL to go to My Account Login page. */
    buttonCtaUrl: PropTypes.string,

    /** row number for the resize height container */
    rowNumber: PropTypes.number,
  };

  /**
   * CardTemplate
   */
  static defaultProps = {
    imageUrl: null,
    cta: '',
    tooltipContent: {
      content: [],
      data: {},
      nodeType: '',
    },
    descriptionRichText: null,
    description: null,
    customDescription: null,
    period: '',
    amount: null,
    descriptionPriceText: null,
    buttonCta: null,
    buttonCtaUrl: 'https://myaccount.freedommobile.ca/login',
    rowNumber: null,
  };

  state = {
    open: false,
  };

  toggleToolTip = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const {
      imageUrl,
      category,
      name,
      description,
      descriptionRichText,
      customDescription,
      descriptionPriceText,
      cta,
      amount,
      period,
      tooltipContent,
      buttonCta,
      buttonCtaUrl,
      rowNumber,
    } = this.props;

    const onButtonCTAClick = () => {
      setTimeout(() => {
        window.location.assign(
          buttonCtaUrl || 'https://myaccount.freedommobile.ca/login',
        );
      }, 300);
    };

    return (
      <Card width={1} bg="pureWhite" my={0} data-testid="addOnCard">
        <ResizeContainer
          resizeHeight
          className={`card-template-content-container-${
            rowNumber ? `-${rowNumber}` : ''
          }`}
        >
          <Flex justifyContent="left" flexDirection="column">
            {imageUrl && <Image height={236} src={imageUrl} alt={name} />}
            <Box px={[7, 11]} py={7} flexDirection="column">
              <CardBlock justifyContent="left" flexDirection="column">
                <Box>
                  <Paragraph
                    data-testid="addOnCategory"
                    color="orange"
                    textTransform="uppercase"
                  >
                    {category}
                  </Paragraph>
                  <H3 data-testid="addOnName">{name}</H3>
                  {descriptionRichText ? (
                    <TextBlock
                      paragraphStyles={{ mt: 7 }}
                      data={descriptionRichText}
                    />
                  ) : (
                    description && <Paragraph>{description}</Paragraph>
                  )}
                  {customDescription && (
                    <Box my={[6, 7]}>{customDescription}</Box>
                  )}
                </Box>
              </CardBlock>
            </Box>
          </Flex>
          <Box px={[7, 11]} pb={7}>
            {cta && (
              <>
                <Divider my={[7, 11]} />
                <Box
                  p={0}
                  as={Button}
                  bg="none"
                  onClick={this.toggleToolTip}
                  data-testid="addOnCardButton"
                >
                  {cta && (
                    <Text
                      withAnimation
                      fontSize={[2, 3]}
                      fontWeight={2}
                      lineHeight={[2, 4]}
                    >
                      {cta}
                    </Text>
                  )}
                </Box>
                <Divider mt={[7, 11]} />
              </>
            )}
            {amount !== undefined && amount !== null ? (
              <Period
                descriptionPriceText={descriptionPriceText}
                amount={amount}
                period={period}
              />
            ) : null}
          </Box>
        </ResizeContainer>

        {buttonCta && (
          <Box px={[7, 11]} pb={[7, 11]}>
            <WCLButton onClick={onButtonCTAClick}>{buttonCta}</WCLButton>
          </Box>
        )}

        <ToolTipWithAnalytics
          open={this.state.open}
          content={
            <TextBlock
              data={tooltipContent}
              blockVariant="light"
              isImageInline={false}
            />
          }
          hideBackdrop={this.toggleToolTip}
        />
      </Card>
    );
  }
}

export default CardTemplate;
