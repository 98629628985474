import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

export const useFreedomAuth = () => {
  const oidcAuth = useAuth();
  const location = useLocation();
  const stateBeforeLeavingSite = JSON.stringify({ location });
  const isLoggedIn = oidcAuth.isAuthenticated;
  const isAuthLoading = oidcAuth.isLoading;

  const onSignInHandler = () => {
    oidcAuth.signinRedirect({ state: stateBeforeLeavingSite });
  };

  const onSignOutHandler = () => {
    oidcAuth.signoutSilent();
  };

  return {
    isLoggedIn,
    isAuthLoading,
    doFreedomAuthLogin: onSignInHandler,
    doFreedomAuthLogout: onSignOutHandler,
  };
};

export const withFreedomAuth = WrappedComponent => props => {
  const authProps = useFreedomAuth();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} {...authProps} />;
};
