import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import { BlockSize } from '../../constants';

const Separator = ({ blockSize }) => <Box pb={BlockSize[blockSize]} />;

Separator.propTypes = {
  blockSize: PropTypes.oneOf([
    'extraSmall24px',
    'small36px',
    'medium48px',
    'large64px',
    'large72px',
    'large96px',
  ]).isRequired,
};

Separator.displayName = 'Separator';

export default Separator;
