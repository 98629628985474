import PromoBanner from './promoBanner';
import PromoBannerL from './promoBannerL';

class PromoBannerWrapper {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const { component, targetedAudiences } = data.fields;
      let PromoBannerConstructor = PromoBanner;
      if (component.identifier === 'promoBannerL') {
        PromoBannerConstructor = PromoBannerL;
      }
      this.component = new PromoBannerConstructor(component);
      this.targetedAudiences = targetedAudiences.map(
        audience => audience.fields || {},
      );
    }
  }
}

export default class PromoBannerList {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const {
        identifier,
        componentOptions,
        defaultComponent,
        allowedBrands,
      } = data.fields;
      this.identifier = identifier;
      this.componentOptions = componentOptions
        ? componentOptions
            .map(option => new PromoBannerWrapper(option))
            .filter(option => Object.keys(option).length > 0)
        : null;
      this.defaultComponent = new PromoBanner(defaultComponent);
      this.allowedBrands = allowedBrands
        ? allowedBrands.map(entry => entry.fields?.value).filter(Boolean)
        : null;
    }
  }
}
