import React, { useRef, useEffect } from 'react';
import { node, bool, object, string, func } from 'prop-types';
import { Flex } from 'rebass/styled-components';

import Heading from 'web_component_library/textBlock';
import RestrictContent from '../restrictContent';
import { HeadingBlock } from './css';

const CommonGroupRow = ({
  heading = null,
  headingPosition = null,
  isHeadingVisible = false,
  setGroupRowTitle,
  restrictContent = true,
  cushionContent = false,
  isWhiteBackground,
  children,
  isBlackBackground = false,
}) => {
  // Grabs group row heading text to share with components inside of group row
  const headingBlockRef = useRef(null);
  let groupRowBackgroundColor = isWhiteBackground ? 'pureWhite' : 'softWhite';

  if (isBlackBackground) {
    groupRowBackgroundColor = 'black';
  }
  useEffect(() => {
    const headingBlockNode = headingBlockRef.current;
    if (headingBlockNode) {
      const headingNode = headingBlockNode.querySelector(
        'h1, h2, h3, h4, h5, h6',
      );
      setGroupRowTitle(headingNode?.innerText);
    }
  }, []);

  return (
    <Flex
      flexWrap="wrap"
      justifyContent="space-between"
      px={!restrictContent ? 0 : 7}
      bg={groupRowBackgroundColor}
    >
      <RestrictContent
        restrictContent={restrictContent}
        cushionContent={cushionContent}
      >
        {isHeadingVisible && (
          <HeadingBlock
            ref={headingBlockRef}
            headingPosition={headingPosition}
            width={1}
            pt={[11, 23]}
            mt={[17, 5]}
          >
            <Heading
              data={heading}
              blockVariant={isBlackBackground ? 'dark' : 'light'}
            />
          </HeadingBlock>
        )}
        {children}
      </RestrictContent>
    </Flex>
  );
};

CommonGroupRow.propTypes = {
  isWhiteBackground: bool.isRequired,
  heading: object,
  isHeadingVisible: bool,
  setGroupRowTitle: func.isRequired,
  headingPosition: string,
  children: node.isRequired,
  restrictContent: bool,
  cushionContent: bool,
  isBlackBackground: bool,
};

export default CommonGroupRow;
