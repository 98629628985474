import React from 'react';
import PropTypes from 'prop-types';
import freedomTheme from 'web_component_library/theme/freedom';
const { colors } = freedomTheme;

export const LeftArrowIcon = ({ variant }) => (
  <div
    style={{
      background: 'white',
      border: variant === 'light' ? 0 : `1px solid ${colors.accessibleGrey}`,
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      boxShadow: variant === 'light' ? '0px 3px 7px 2px #c8c6c6' : null,
    }}
  >
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 17L20 25L28 33"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

LeftArrowIcon.propTypes = {
  variant: PropTypes.string.isRequired,
};

export const RightArrowIcon = ({ variant }) => (
  <div
    style={{
      background: 'white',
      border: variant === 'light' ? 0 : `1px solid ${colors.accessibleGrey}`,
      width: '50px',
      height: '50px',
      borderRadius: '100%',
      boxShadow: variant === 'light' ? '0px 3px 7px 2px #c8c6c6' : null,
    }}
  >
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 33L30 25L22 17"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

RightArrowIcon.propTypes = {
  variant: PropTypes.string.isRequired,
};

export const PlayIcon = () => (
  <svg
    width="23"
    height="33"
    viewBox="5 0 23 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.525 13.4167L4.525 0.410497C2.7375 -0.645753 0 0.379247 0 2.99175V28.998C0 31.3417 2.54375 32.7542 4.525 31.5792L26.525 18.5792C28.4875 17.423 28.4937 14.573 26.525 13.4167Z"
      fill="white"
    />
  </svg>
);

export const PauseIcon = () => (
  <svg
    width="23"
    height="33"
    viewBox="0 0 23 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 30H2.25C1.00781 30 0 28.5603 0 26.7857V3.21429C0 1.43973 1.00781 0 2.25 0H6.75C7.99219 0 9 1.43973 9 3.21429V26.7857C9 28.5603 7.99219 30 6.75 30ZM23 26.7857V3.21429C23 1.43973 21.9922 0 20.75 0H16.25C15.0078 0 14 1.43973 14 3.21429V26.7857C14 28.5603 15.0078 30 16.25 30H20.75C21.9922 30 23 28.5603 23 26.7857Z"
      fill="white"
    />
  </svg>
);
