import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

import freedomTheme from 'web_component_library/theme/freedom';

const { colors } = freedomTheme;

export const List = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled(Box)`
  line-height: 1em;
  width: 90%;
  padding-right: 1rem;
`;

export const Result = styled(Flex)`
  position: relative;
`;

export const ArrowRight = styled(Box)`
  position: absolute;
  top: 40%;
  right: 1em;
  background-color: ${colors.transparent};
  padding: 0;
  border: 2px solid ${colors.orange};
  transition: transform 0.2s ease-in-out;
  transform: rotate(315deg);
  border-left: 0;
  border-top: 0;
  border-radius: 0;
  width: 1em;
  height: 1em;
`;
