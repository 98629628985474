import styled from 'styled-components';
import freedomTheme from 'web_component_library/theme/freedom';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import Base from 'web_component_library/input';

const { colors } = freedomTheme;

export const Input = styled(Base)`
  input {
    font-size: 1.25em;
    line-height: 1.4em;
    padding-bottom: 0.5em;
    padding-right: 2em;
    height: 3em;
    ${MediaQuery.desktop`
      font-size: 2rem;
      line-height: 1.25em;
      padding-bottom: 0.5em;
      padding-right: 3em;
    `}
    /*No Color provided in mocks for placeholder - making this accessible.*/
    &::placeholder {
      color: ${props =>
        props.isDarkBackground ? colors.pureWhite : colors.accessibleGrey};
    }

    &:placeholder-shown:not(:focus)::placeholder {
      opacity: 1;
    }

    &:placeholder-shown:not(:focus) + label {
      top: 0;
      color: ${colors.freedomCharcoal};
      font-weight: 300;
    }

    &:placeholder-shown:focus + label {
      top: 0;
    }

    &:placeholder + label {
      top: 0;
    }
  }

  /* Input label */
  label {
    top: 0;
  }

  /* Search/Clear button */
  button {
    top: 1.75em;
    ${MediaQuery.desktop`
      top: 2.75em;
    `}
    label {
      visibility: hidden;
      display: none;
      font-size: 1rem;
      margin-right: 1em;
      ${MediaQuery.desktop`
        visibility: visible;
        display: block;
      `}
    }
  }
  /* Auto complete drop-down*/
  ul {
    top: 3em;
    ${MediaQuery.desktop`
      top: 4.25em;
    `}
  }
`;
