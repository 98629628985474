import { takeLatest, put, call, all } from 'redux-saga/effects';
import resolveResponse from 'contentful-resolve-response';

import {
  getServerSideEntries,
  getClientSideEntries,
  getContentSuccess,
  getProductEntriesSuccess,
  getContentError,
  getPrepaidPlans,
  getPrepaidPlansSuccess,
  getAddOns,
  getPage,
  getPageSuccess,
  getByopPlans,
  getCompatibilityCheckOptions,
  getCompatibilityCheckOptionsSuccess,
  getSupportEntries,
  getSupportEntriesSuccess,
  getArticle,
  getArticleSuccess,
  getSearchQueryEntries,
  getSearchQueryEntriesSuccess,
  getSearchQueryEntriesFinally,
} from './actions';
import {
  HOMEURL,
  ROOT,
  PAGE_SUPPORT_BASE,
  ARTICLE_NOT_FOUND,
} from '../../constants';
import ContentfulService from '../../lib/contentful';
import { CATALOGUE_MODELS_TYPES } from '../contentful/productCatalog';
import Addons from '../contentful/addOns';
import Plan from '../contentful/plan';
import ContenfulData, {
  CONTENTFUL_MODELS_TYPES,
} from '../contentful/contentfulModels';
import CommonCatalog from '../contentful/commonCatalog';
import Page from '../contentful/page';
import QuestionsList from '../contentful/question';
import ArticleList, { Article } from '../contentful/article';
import SupportCategories from '../contentful/supportCategory';
import ModelsOptions from '../contentful/modelsOptions';
import BusinessToggles from '../contentful/businessToggles';
import ByopPlans from '../contentful/plans';
import { DEFAULT_REGION } from './constants';

const genRegionalLocale = (locale, region) =>
  region ? `${locale}-${region || DEFAULT_REGION}` : locale;

const getAvailableRegions = locales =>
  locales
    .map(locale => ({
      code: locale.code.split('-')[2],
      name: locale.name.match(/\(([^)]+)\)/)?.[1],
    }))
    .filter(region => region.code && region.name);

export function* loadPrepaidPlans({ payload }) {
  try {
    const cataloguePlans = yield call(
      ContentfulService.instance.getProductCatalogClient().getEntries,
      {
        locale: payload,
        include: 5,
        content_type: 'planList',
        'fields.identifier[in]': 'browse prepaid section',
      },
    );
    const prepaidPlans = cataloguePlans.items.reduce(
      (acc, planList) => ({
        ...acc,
        [planList.fields.slug]: planList.fields.plans.map(
          plan => new Plan(plan),
        ),
      }),
      {},
    );
    yield put(getPrepaidPlansSuccess(prepaidPlans));
  } catch (error) {
    yield put(getContentError(error));
  }
}
export function* loadAddOns({ payload }) {
  try {
    const catalogeAddons = yield call(
      ContentfulService.instance.getProductCatalogClient().getEntries,
      {
        locale: payload,
        include: 3,
        limit: 1000,
        content_type: CATALOGUE_MODELS_TYPES.addon,
      },
    );
    const addonCatalog = [...catalogeAddons.items];
    yield put(getProductEntriesSuccess(new Addons(addonCatalog)));
  } catch (error) {
    yield put(getContentError(error));
  }
}
export const getSlug = (url, locale) => {
  const homePageUrl = `${HOMEURL}${locale}`;
  const otherUrls =
    url && url.includes(homePageUrl)
      ? url.replace(`${homePageUrl}/`, '')
      : '-1';
  return url === homePageUrl ? ROOT : otherUrls;
};

export function* loadServerSideEntries(params) {
  try {
    const { payload } = params;
    const { locale, region, url: requestUrl } = payload;
    // Slugs of pages are absoulte URL's on contentful,
    // so ignore query params while fetching pages from contentful.
    const absoluteUrl = requestUrl.split('?')[0];
    let url = ROOT;
    if (absoluteUrl.charAt(absoluteUrl.length - 1) === '/')
      url = absoluteUrl.replace(/\/$/, '');
    else url = absoluteUrl;
    /**
     * calling contentful api then dispatch to redux
     */
    // Homepage slug is ROOT, rest slugs are same as URL
    // so removing locale from URL to get exact slug
    const slug = getSlug(url, locale);
    const isSupport = url.includes(PAGE_SUPPORT_BASE);
    let supportPages = { items: [] };
    if (isSupport) {
      supportPages = yield call(
        ContentfulService.instance.getBrowseCatalogClient().getEntries,
        {
          locale: genRegionalLocale(locale, region),
          include: 5,
          limit: 1000,
          content_type: CONTENTFUL_MODELS_TYPES.page,
          'fields.url[match]': PAGE_SUPPORT_BASE,
        },
      );

      const possibleArticleName = slug.split('/').pop();

      yield call(loadArticle, {
        payload: { slug: possibleArticleName, locale, region },
      });
    }

    const [
      businessTogglesData,
      availableLocales,
      browsePages,
      browseErrorPages,
      commonCatalog,
      globalAlertBanner,
    ] = yield all([
      call(ContentfulService.instance.getCommonBrowseClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        limit: 1000,
        content_type: 'businessToggle',
        'fields.sites[match]': 'Freedom Browse',
      }),
      call(ContentfulService.instance.getCommonCatalogClient().getLocales),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        include: 8,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.page,
        'fields.url[match]': slug,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale,
        include: 4,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.error,
      }),
      call(ContentfulService.instance.getCommonCatalogClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        include: 3,
        limit: 1000,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        include: 4,
        limit: 1,
        content_type: CONTENTFUL_MODELS_TYPES.alertBanner,
        'fields.isGlobal': true,
      }),
    ]);

    // Storing in global object instead of Redux, so WCL can access it
    global.businessToggles = new BusinessToggles(businessTogglesData.items);

    const pages = [
      ...browsePages.items,
      ...browseErrorPages.items,
      ...supportPages.items,
    ];
    if (globalAlertBanner.items[0]) {
      pages.push(globalAlertBanner.items[0]);
    }

    yield put(
      getContentSuccess({
        browseCatalog: new ContenfulData(pages),
        commonCatalog: new CommonCatalog(commonCatalog.items),
        availableRegions: getAvailableRegions(availableLocales.items),
      }),
    );
  } catch (error) {
    yield put(getContentError(error));
  }
}

export function* loadPage(params) {
  try {
    const { payload } = params;
    const { locale, region, url } = payload;
    const slug = getSlug(url, locale);
    const otherPagesSlugs = slug.split('/').join(',');
    const page = yield call(
      ContentfulService.instance.getBrowseCatalogClient().getEntries,
      {
        locale: genRegionalLocale(locale, region),
        include: 8,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.page,
        'fields.url[in]': `${slug},${otherPagesSlugs}`,
      },
    );

    const pages = {};

    resolveResponse(page).forEach(entry => {
      pages[entry.fields.url] = new Page(entry);
    });

    yield put(
      getPageSuccess({
        pages,
      }),
    );
  } catch (error) {
    yield put(getContentError(error));
  }
}

export function* loadSupportEntries(params) {
  try {
    const {
      payload: { locale },
    } = params;
    const [
      questionsData,
      categoriesData,
      articlesDataWithContent,
      articlesDataWithHtml,
    ] = yield all([
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale,
        include: 1,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.question,
        'fields.articles[exists]': true,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale,
        include: 1,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.supportCategory,
        'fields.articles[exists]': true,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale,
        include: 3,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.article,
        'fields.content[exists]': true,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale,
        include: 3,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.article,
        'fields.html[exists]': true,
      }),
    ]);

    const questions = new QuestionsList(questionsData.items);

    const categories = new SupportCategories(categoriesData.items);

    const articlesList = new ArticleList([
      ...articlesDataWithContent.items,
      ...articlesDataWithHtml.items,
    ]);

    yield put(
      getSupportEntriesSuccess({
        questions,
        categories,
        articlesList,
      }),
    );
  } catch (error) {
    yield put(getContentError(error));
  }
}

export function* loadSearchQueryEntries(params) {
  const {
    payload: { locale, searchQuery, skip, limit },
  } = params;
  try {
    const entries = yield call(
      ContentfulService.instance.getBrowseCatalogClient().getEntries,
      {
        locale,
        include: 1,
        content_type: CONTENTFUL_MODELS_TYPES.article,
        query: searchQuery,
        skip,
        limit,
      },
    );

    const parsedEntries = {
      total: entries.total,
      skip,
      limit,
      items: entries.items.map(item => ({
        title: item.fields.title,
        slug: item.fields.slug,
      })),
    };

    yield put(getSearchQueryEntriesSuccess(parsedEntries));
  } catch (error) {
    yield put(getContentError(error));
  } finally {
    yield put(getSearchQueryEntriesFinally());
  }
}

export function* loadArticle(params) {
  try {
    const { payload } = params;
    const { locale, slug, region } = payload;
    const articleData = yield call(
      ContentfulService.instance.getBrowseCatalogClient().getEntries,
      {
        locale: genRegionalLocale(locale, region),
        include: 3,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.article,
        'fields.slug[in]': `${slug}`,
      },
    );

    const article =
      articleData.items.map(data => new Article(data))[0] || ARTICLE_NOT_FOUND;

    yield put(getArticleSuccess(article));
  } catch (error) {
    yield put(getContentError(error));
  }
}

export function* loadClientSideEntries(params) {
  try {
    /**
     * calling contentful api then dispatch to redux
     */
    const { payload } = params;
    const { locale, region, url } = payload;
    const slug = getSlug(url, locale);
    const otherPagesSlugs = slug.split('/').join(',');

    const commonCatalogData = yield call(
      ContentfulService.instance.getCommonCatalogClient().getEntries,
      {
        locale: genRegionalLocale(locale, region),
        include: 3,
        limit: 1000,
      },
    );

    const commonCatalog = new CommonCatalog(commonCatalogData.items);
    const pagesFromRelativePaths = commonCatalog.relativePaths.join(',');
    let supportPages = { items: [] };
    const isSupport =
      url.includes(PAGE_SUPPORT_BASE) ||
      pagesFromRelativePaths.includes(PAGE_SUPPORT_BASE);
    if (isSupport) {
      supportPages = yield call(
        ContentfulService.instance.getBrowseCatalogClient().getEntries,
        {
          locale: genRegionalLocale(locale, region),
          include: 5,
          limit: 1000,
          content_type: CONTENTFUL_MODELS_TYPES.page,
          'fields.url[match]': PAGE_SUPPORT_BASE,
        },
      );
    }
    const [
      businessTogglesData,
      availableLocales,
      page,
      popupModal,
      formError,
      error,
      globalAlertBanner,
      pagesPlaceholders,
    ] = yield all([
      call(ContentfulService.instance.getCommonBrowseClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        limit: 1000,
        content_type: 'businessToggle',
        'fields.sites[match]': 'Freedom Browse',
      }),
      call(ContentfulService.instance.getCommonCatalogClient().getLocales),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        include: 8,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.page,
        'fields.url[in]': `${slug},${ROOT},${otherPagesSlugs}`,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        include: 5,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.popupModal,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale,
        include: 4,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.formError,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale,
        include: 4,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.error,
      }),
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        include: 4,
        limit: 1,
        content_type: CONTENTFUL_MODELS_TYPES.alertBanner,
        'fields.isGlobal': true,
      }),
      // We only fetch the url to avoid performance issues if too many pages are added (less data to resolve)
      call(ContentfulService.instance.getBrowseCatalogClient().getEntries, {
        locale: genRegionalLocale(locale, region),
        include: 1,
        limit: 1000,
        content_type: CONTENTFUL_MODELS_TYPES.page,
        select: 'fields.url',
        'fields.url[exists]': true,
      }),
    ]);

    // Storing in local storage instead of Redux, so WCL can access it
    const businessToggles = new BusinessToggles(businessTogglesData.items);
    window.localStorage.setItem(
      'businessToggles',
      JSON.stringify(businessToggles),
    );

    // merge all included items to resolve properly.
    if (supportPages) page.items = [...page.items, ...supportPages.items];

    page.includes = {
      Asset: [
        ...page.includes.Asset,
        ...popupModal.includes.Asset,
        ...error.includes.Asset,
      ],
      Entry: [
        ...page.includes.Entry,
        ...popupModal.includes.Entry,
        ...error.includes.Entry,
      ],
    };
    const pages = resolveResponse(page);
    const normalizedResponse = [
      ...pagesPlaceholders.items,
      ...pages,
      ...popupModal.items,
      ...formError.items,
      ...error.items,
    ];
    if (globalAlertBanner.items[0]) {
      normalizedResponse.push(globalAlertBanner.items[0]);
    }

    const browseCatalog = new ContenfulData(normalizedResponse);

    yield put(
      getContentSuccess({
        browseCatalog,
        commonCatalog,
        locale,
        availableRegions: getAvailableRegions(availableLocales.items),
      }),
    );
  } catch (error) {
    yield put(getContentError(error));
  }
}

function* loadCompatibilityCheckOptions(params) {
  try {
    const { payload } = params;
    const { locale } = payload;
    /**
     * calling contentful api then dispatch to redux
     */
    const [brandsOptionsData, modelOptions, locationOptionsData] = yield all([
      call(ContentfulService.instance.getCommonBrowseClient().getEntries, {
        locale,
        include: 3,
        limit: 1000,
        content_type: 'compatibilityCheckManufacturersList',
      }),
      call(ContentfulService.instance.getCommonBrowseClient().getEntries, {
        locale,
        include: 3,
        limit: 1000,
        content_type: 'compatibilityCheckPhoneModelsList',
      }),
      call(ContentfulService.instance.getCommonBrowseClient().getEntries, {
        locale,
        include: 3,
        limit: 1000,
        content_type: 'compatibilityCheckLocationList',
      }),
    ]);

    const brandsOptions =
      (brandsOptionsData.items.length &&
        brandsOptionsData.items[0].fields &&
        brandsOptionsData.items[0].fields.manufacturersOrder.reduce(
          (acc, brand) => {
            if (brand.fields && brand.fields.name) {
              acc.push(brand.fields.name);
            }
            return acc;
          },
          [],
        )) ||
      [];

    const locationOptions =
      (locationOptionsData.items.length &&
        locationOptionsData.items[0].fields &&
        locationOptionsData.items[0].fields.locationOrder.reduce(
          (acc, location) => {
            if (location.fields) {
              acc.push(location.fields);
            }
            return acc;
          },
          [],
        )) ||
      [];

    yield put(
      getCompatibilityCheckOptionsSuccess({
        brandsOptions,
        modelOptions: new ModelsOptions(modelOptions.items[0]).models,
        locationOptions,
      }),
    );
  } catch (error) {
    yield put(getContentError(error));
  }
}

export function* loadByopPlans({ payload }) {
  try {
    const catalogePlans = yield call(
      ContentfulService.instance.getProductCatalogClient().getEntries,
      {
        locale: payload,
        include: 3,
        limit: 1000,
        content_type: CATALOGUE_MODELS_TYPES.plan,
      },
    );
    const planCatalog = [...catalogePlans.items];

    yield put(getProductEntriesSuccess(new ByopPlans(planCatalog)));
  } catch (error) {
    yield put(getContentError(error));
  }
}

export default function* entriesData() {
  yield takeLatest(getServerSideEntries, loadServerSideEntries);
  yield takeLatest(getClientSideEntries, loadClientSideEntries);
  yield takeLatest(getPrepaidPlans, loadPrepaidPlans);
  yield takeLatest(getByopPlans, loadByopPlans);
  yield takeLatest(getAddOns, loadAddOns);
  yield takeLatest(getPage, loadPage);
  yield takeLatest(getSupportEntries, loadSupportEntries);
  yield takeLatest(getSearchQueryEntries, loadSearchQueryEntries);
  yield takeLatest(getArticle, loadArticle);
  yield takeLatest(getCompatibilityCheckOptions, loadCompatibilityCheckOptions);
}
