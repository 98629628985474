export default class Faqs {
  constructor(data) {
    if (data && data.fields) {
      const {
        questionHeading,
        askQuestionsSection,
        questionsList,
        variantLight,
        askAwayButton,
      } = data.fields;
      this.questionHeading = questionHeading;
      this.askQuestionsSection = askQuestionsSection;
      this.questionsList = questionsList;
      this.variantLight = variantLight;
      this.askAwayButton = askAwayButton;
    }
  }
}
