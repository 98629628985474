import { createActions } from 'redux-actions';

export const {
  getServerSideEntries,
  getClientSideEntries,
  getContentSuccess,
  getContentError,
  setTranslations,
  getPrepaidPlans,
  getAddOns,
  getProductEntriesSuccess,
  getPrepaidPlansSuccess,
  getPage,
  getPageSuccess,
  getByopPlans,
  setCurrentPage,
  setRegion,
  getCompatibilityCheckOptions,
  getCompatibilityCheckOptionsSuccess,
  getSupportEntries,
  getSupportEntriesSuccess,
  getArticle,
  getArticleSuccess,
  clearArticle,
} = createActions(
  'GET_SERVER_SIDE_ENTRIES',
  'GET_CLIENT_SIDE_ENTRIES',
  'GET_CONTENT_SUCCESS',
  'GET_CONTENT_ERROR',
  'SET_TRANSLATIONS',
  'GET_PREPAID_PLANS',
  'GET_ADD_ONS',
  'GET_PRODUCT_ENTRIES_SUCCESS',
  'GET_PREPAID_PLANS_SUCCESS',
  'GET_PAGE',
  'GET_PAGE_SUCCESS',
  'GET_BYOP_PLANS',
  'SET_CURRENT_PAGE',
  'SET_REGION',
  'GET_EMAIL_DOMAINS_LIST',
  'GET_EMAIL_DOMAINS_LIST_SUCCESS',
  'GET_COMPATIBILITY_CHECK_OPTIONS',
  'GET_COMPATIBILITY_CHECK_OPTIONS_SUCCESS',
  'GET_SUPPORT_ENTRIES',
  'GET_SUPPORT_ENTRIES_SUCCESS',
  'GET_ARTICLE',
  'GET_ARTICLE_SUCCESS',
  'CLEAR_ARTICLE',
);
export const {
  getSearchQueryEntries,
  getSearchQueryEntriesSuccess,
  getSearchQueryEntriesFinally,
} = createActions(
  'GET_SEARCH_QUERY_ENTRIES',
  'GET_SEARCH_QUERY_ENTRIES_SUCCESS',
  'GET_SEARCH_QUERY_ENTRIES_FINALLY',
);
