import React from 'react';
import styled from 'styled-components';

import logo from './logo.svg';

const SpinImage = styled.img`
  height: 100px;
  width: 100px;
  perspective: 9999px;
  transform-style: preserve-3d;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotateY(0);
    }
    40% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
`;

export const SpinningLogo = () => <SpinImage src={logo} alt="Loading..." />;

export default SpinningLogo;
