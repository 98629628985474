import styled from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import BaseLink from 'web_component_library/link';
import { Box } from 'rebass/styled-components';

export const AskAwayAnswerButtonWrapper = styled(Box)`
  display: block;
  padding-top: 2rem;

  ${MediaQuery.desktop`
    display: none;
  `}
`;

export const AskAwayQuestionButtonWrapper = styled(Box)`
  padding-top: 1.5em;
  display: none;
  ${MediaQuery.desktop`
    display: block;
  `}
`;

// IMPORTANT: Overriding default paragraph margins in TextBlock component
export const QuestionsListWrapper = styled(Box)`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  &:not(:last-child) {
    margin-bottom: 1.5em;
    border-bottom: solid
      ${props => props.theme.colors && props.theme.colors.grey};
    border-bottom-width: 0.0625em;
  }
`;

export const Link = styled(BaseLink)`
  display: inline-block;
  margin-bottom: 1.5em;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: -0.2px;
  text-transform: ${props => props.textTransform};
  color: black;
  font-weight: ${props => (props.isStrong ? 500 : 300)};
  strong {
    font-weight: 500;
  }
  a {
    color: black;
    font-weight: 500;
  }
  ${MediaQuery.desktop`
  font-size: 1rem;
  line-height: 1.75rem;
  `};
`;
