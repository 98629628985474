import React, { PureComponent, Fragment } from 'react';
import { object, shape, string } from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import ExpandCollapse from 'web_component_library/expandCollapse';
import Divider from 'web_component_library/divider';
import { H1, H2, H3, H4, H5 } from 'web_component_library/typography';
import { Heading, Flex } from 'rebass/styled-components';

import { Paragraph, Box } from './css';
import RichText from '../richText';
import withCtaAnalytics from '../ctaAnalyticsHoc';

const tncOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Paragraph my={[0, 7]} color="charcoal">
        {children}
      </Paragraph>
    ),
    [BLOCKS.HEADING_1]: (_node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (_node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (_node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (_node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (_node, children) => <H5>{children}</H5>,
  },
};

const renderHeader = (title, isPanelClosed) => (
  <Flex alignItems="flex-start" flex="1 auto" width={1}>
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      flex="1 auto"
      width="100%"
    >
      <Heading
        fontSize={3}
        fontWeight={2}
        lineHeight={5}
        letterSpacing={2}
        color={isPanelClosed ? 'freedomCharcoal' : 'black'}
      >
        {title}
      </Heading>
    </Flex>
  </Flex>
);

export class TermsAndConditionsDrawer extends PureComponent {
  static displayName = 'TermsAndConditionsDrawer';

  static propTypes = {
    /**
     * The terms and conditions details object
     */
    data: shape({
      title: string,
      description: object,
      content: object,
    }),
    supplemental: object,
  };

  static defaultProps = {
    data: null,
    supplemental: null,
  };

  state = {
    isPanelClosed: true,
  };

  handler = (id, isPanelClosed) => {
    this.setState({ isPanelClosed });
  };

  render() {
    const { data, supplemental } = this.props;

    if (!data) return null;

    const { title, description, content } = data;

    const { isPanelClosed } = this.state;

    return (
      <Box className="restrict-content" data-testid="terms-drawer" m="auto">
        <ExpandCollapse
          isDivider
          isPanelOpen={false}
          tag="h4"
          headerNode={renderHeader(title, isPanelClosed)}
          pt={13}
          pb={isPanelClosed ? 13 : 3}
          handler={this.handler}
        >
          <div id={title}>
            {supplemental && (
              <Fragment>
                <RichText
                  data-testid="supplemental-content"
                  document={supplemental}
                  options={tncOptions}
                />
                <Divider data-testid="supplemental-divider" />
              </Fragment>
            )}
            <RichText document={description || content} options={tncOptions} />
          </div>
        </ExpandCollapse>
      </Box>
    );
  }
}

// To use with styleguidist, we need to use a special annotation
/** @component */
export default withCtaAnalytics(TermsAndConditionsDrawer, {
  staticModuleType: 'view-terms-and-conditions',
  ctaType: 'accordion',
  includeModuleTitle: false,
});
