import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectRegion,
  selectAvailableRegions,
} from '../../contentfulHoc/selectors';
import { setRegion } from '../../contentfulHoc/actions';
import {
  makeSelectUserProvince,
  makeSelectHasGetUserProfileLoaded,
} from '../../../services/getUserProfile/selectors';
import { useFreedomAuth } from '../oidcProvider/hook';
import { DEFAULT_REGION } from '../../contentfulHoc/constants';

export const useRegion = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useFreedomAuth();
  const [regionsIsDisabled, setRegionIsDisabled] = useState(isLoggedIn);
  const province = useSelector(makeSelectUserProvince);
  const profileHasLoaded = useSelector(makeSelectHasGetUserProfileLoaded);

  const reducerRegion = useSelector(selectRegion());
  const availableRegions = useSelector(selectAvailableRegions()).map(
    a => a.code,
  );

  const updateRegion = useCallback((newRegion, shouldChangeCookie = true) => {
    dispatch(setRegion(newRegion));
    if (shouldChangeCookie) {
      Cookies.set('region', newRegion, {
        expires: 30,
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'lax',
        domain: '.freedommobile.ca',
      });
    }
  }, []);

  useEffect(() => {
    // DIGM-26726: To remove the cookie name='region' with domain='wwww.freedommobile.ca'
    // Not adding the domain automatically takes the domain from the URL host
    Cookies.set('region', '', {
      expires: 0,
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'lax',
    });
  }, []);

  useEffect(() => {
    const cookieRegion = Cookies.get('region');
    if (isLoggedIn) {
      if (profileHasLoaded) {
        if (availableRegions.indexOf(province) >= 0) {
          if (reducerRegion !== province) {
            updateRegion(province, false);
          }
        } else {
          updateRegion(DEFAULT_REGION, false);
        }
      }
      setRegionIsDisabled(true);
    } else {
      updateRegion(cookieRegion, false);
      setRegionIsDisabled(false);
    }
  }, [isLoggedIn, profileHasLoaded, province]);

  return {
    region: reducerRegion,
    availableRegions,
    updateRegion,
    regionsIsDisabled,
  };
};

export const withRegion = WrappedComponent => props => {
  const regionProps = useRegion();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} {...regionProps} />;
};
