import {
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
} from 'react-device-detect';
import qs from 'qs';
import { setUtagForLinks } from '../common/lib/analytics';
import { FRDM_APPLE_BUSINESS_CHAT_URL } from '../common/constants';
import { getBusinessToggle, BUSINESS_TOGGLES } from './toggleHelpers';

export const isAppleUser =
  isIOS || isIOS13 || isIPad13 || isIPhone13 || isIPod13;

export const sendPropertiesToNiceChat = userInfo => {
  // Adding user info to nice chat
  if (typeof window !== 'undefined' && window.cxone) {
    window.cxone(
      'chat',
      'setCustomerCustomField',
      'account_number',
      userInfo?.accountNumber || 'unknown',
    );
    window.cxone(
      'chat',
      'setCustomerCustomField',
      'lastname',
      userInfo?.lastName || 'unknown',
    );
    window.cxone(
      'chat',
      'setCustomerCustomField',
      'firstname',
      userInfo?.firstName || 'unknown',
    );
    window.cxone('chat', 'setCustomerName', userInfo?.firstName || '');
  }
};

export function launchLiveChat(
  ev,
  appleChatBizIntentId,
  appleChatBizGroupId,
  appleChatBody,
  isAppleChatOn,
) {
  if (ev) {
    ev.preventDefault();
  }

  const utagObj = {
    ev_type: 'interaction',
    ev_action: 'chat open',
    ev_name: 'live chat',
  };

  // Apple Business Chat (only when business toggle on AND user is using Apple device)
  const isAppleToggleChatOn = getBusinessToggle(BUSINESS_TOGGLES.APPLE_CHAT);
  if (isAppleToggleChatOn && isAppleChatOn && isAppleUser) {
    const search = qs.stringify(
      {
        'biz-intent-id': appleChatBizIntentId,
        'biz-group-id': appleChatBizGroupId,
        body: appleChatBody,
      },
      { ignoreQueryPrefix: true },
    );
    setUtagForLinks(
      utagObj,
      `${FRDM_APPLE_BUSINESS_CHAT_URL}${search ? `&${search}` : ''}`,
    );
    return true;
  }

  setUtagForLinks(utagObj);

  // If for whatever reason live chat fails in every case, just return false
  return false;
}
