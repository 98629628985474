import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';

import MediaQuery from 'web_component_library/utils/mediaQuery';

export const Sticky = styled(Flex)`
  height: 100vh;
  text-align: center;
  position: relative;
  overflow: hidden;

  ${MediaQuery.desktop`
    height: calc(100vh - 80px);
  `};
`;

export const BgContainer = styled(Box)`
  height: 100vh;
  max-height: calc(100vh - 17rem);
  position: absolute;
  bottom: 0;
  z-index: 1;

  ${MediaQuery.desktop`
    max-height: none;
    height: calc(90vh - 80px);
  `};
`;

export const ShowcaseImageContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30vh;
  max-height: 100%;

  ${MediaQuery.desktop`
    max-height: calc(90vh - 80px);
  `}
`;

export const ShowcaseImage = styled.img`
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  object-fit: cover;
  object-position: bottom center;
  max-width: 100%;

  ${MediaQuery.desktop`
    left: 0;
    transform: none;
    object-position: bottom right;
  `};
`;

export const TextContainer = styled(Box)`
  height: 17rem;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 2;

  ${MediaQuery.desktop`
    top: unset;
    bottom: 0;
    left: 50%;
    height: 100%;
    max-height: calc(90vh - 80px);
  `}
`;

export const TextInner = styled(Flex)`
  top: 0;
  position: relative;
  height: 100%;

  ${MediaQuery.desktop`
    height: 100%;
    position: relative;
    top: unset;
  `}
`;

export const TextWrapper = styled(Box)`
  opacity: 0;
  text-align: center;
  position: absolute;

  ${MediaQuery.desktop`
    text-align: left;
  `}
`;

export const FadeFrameContainer = styled(Box)`
  position: absolute;
  top: 10%;

  ${MediaQuery.desktop`
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: none;
  `}
`;

export const OverlayImg = styled.img`
  width: 90%;
  max-width: 476px;
  opacity: 0;

  ${MediaQuery.desktop`
    width: 80%;
    max-width: 576px;
    margin-left: auto;
    margin-right: auto;
  `}
`;
