import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass/styled-components';

import TextBlock from 'web_component_library/textBlock';
import Divider from 'web_component_library/divider';
import Icon from 'web_component_library/icon';

function Feature({ title, description, ...props }) {
  return (
    <Flex flexDirection="column" {...props}>
      <Flex justifyContent="space-between">
        <Text fontWeight={2}>{title}</Text>
        <Icon size={0.8} color="blue" variant="icon_checkmark" />
      </Flex>
      <Divider width={1} my={7} color="grey" />
      <TextBlock
        data={description}
        blockVariant="light"
        paragraphStyles={{ my: 0 }}
      />
    </Flex>
  );
}

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
};

export default Feature;
