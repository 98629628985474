import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import MediaQuery from 'web_component_library/utils/mediaQuery';
import { Box, Flex, Card } from 'rebass/styled-components';
const bottomAligned = css`
  margin: 0 auto 1rem auto;
  ${MediaQuery.desktop`
    max-width: 22em;
    left: calc(50% - 11em);
  `}
`;

const TextBlock = styled(Flex)`
  position: relative;
  display: flex;
  padding-bottom: 7em;
  justify-content: space-between;
  min-height: ${props => (props.isFullImageBackground ? '18.75em' : '16.25em')};
`;

TextBlock.propTypes = {
  isFullImageBackground: PropTypes.bool,
};

const CtaBlock = styled(Box)`
  justify-content: flex-end;
  position: absolute;
  display: inline-block;
  bottom: 1px;
  ${props => (props.isBottomCenter ? bottomAligned : '')}
`;

CtaBlock.propTypes = {
  isBottomCenter: PropTypes.bool,
};

export const Video = styled(ReactPlayer)`
  position: relative;
  height: 0;
  ${MediaQuery.desktop`
    display: block;
    height: 18.44em;`}
  video {
    overflow: hidden;
    object-fit: fill;
    height: 0;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    ${MediaQuery.desktop`
    display: block;
    height: auto;`}
  }
`;

const Container = styled(Card)`
  width: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  ${props =>
    props.isFullImageBackground && props.url
      ? `background-image: url(${props.url})`
      : ''};
`;

Container.propTypes = {
  isFullImageBackground: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

const ImageContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  min-height: ${props => (props.isFullImageBackground ? '35em' : '10.625em')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: ${props => (props.url ? `url(${props.url});` : '')};
  ${MediaQuery.desktop`
      background-position: top center;
      min-height: ${props => (props.col > 2 ? '15em' : '20em')}`};
`;

ImageContainer.propTypes = {
  url: PropTypes.string.isRequired,
  isFullImageBackground: PropTypes.bool,
  col: PropTypes.number.isRequired,
};

const VideoBlock = styled(ReactPlayer)`
  height: 0;
  position: absolute;
  width: 100%;
  display: none;
  ${MediaQuery.desktop`
    display: block;
    height: auto;`}
`;

const FloatingBgVideo = styled(Video)`
  position: absolute;
`;

const Body = styled(Box)`
  min-height: 10em;
  ${MediaQuery.desktop`
    min-height: ${props => props.height};
    `}
`;

Body.propTypes = {
  height: PropTypes.string.isRequired,
};

export {
  TextBlock,
  CtaBlock,
  Container,
  ImageContainer,
  VideoBlock,
  FloatingBgVideo,
  Body,
};
