import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Box, Flex, Image } from 'rebass/styled-components';
import { H1, H2, H3 } from 'web_component_library/typography';

import { Paragraph } from './css';

import RichText from '../richText';
import { Category } from './category';

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => (
      <H1 color="pureBlack">{children}</H1>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <H2 color="pureBlack">{children}</H2>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <H3 color="pureBlack">{children}</H3>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph>{children}</Paragraph>,

    // Embedded inline entry to render image with original dimensions of the image.
    [INLINES.EMBEDDED_ENTRY]: node => {
      if (
        !(
          node.data &&
          node.data.target &&
          node.data.target.fields &&
          node.data.target.fields.source
        )
      ) {
        return null;
      }
      const {
        title,
        source: {
          fields: {
            file: { url: fileUrl },
          },
        },
      } = node.data.target.fields;
      return <Image src={fileUrl} alt={title} />;
    },

    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (!(node.data && node.data.target && node.data.target.fields)) {
        return null;
      }
      const { label, content } = node.data.target.fields;
      return (
        <Box pt={[0, 3]}>
          <Category label={label} content={content} options={options} />
        </Box>
      );
    },
  },
};

const ThreeCategories = ({ description = null }) => {
  if (!description) return null;
  return (
    <Box pr={11}>
      <Flex flexDirection="column">
        <RichText document={description} options={options} />
      </Flex>
    </Box>
  );
};

ThreeCategories.displayName = 'ThreeCategories';

ThreeCategories.propTypes = {
  /**
   * Template based text block for three different categories
   */
  description: PropTypes.object, // TODO: Rich Text description shape is required
};
// To use with styleguidist, we need to use a special annotation
/** @component */
export default ThreeCategories;
