export default class PlanDataHeader {
  constructor(data) {
    if (data && data.fields) {
      const {
        title,
        icon,
        closedTitle,
        openTitle,
        tooltipContent,
      } = data.fields;
      this.title = title;
      this.icon = icon?.fields || null;
      this.closedTitle = closedTitle;
      this.openTitle = openTitle;
      this.tooltipContent = tooltipContent;
    }
  }
}
