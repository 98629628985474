import styled from 'styled-components';
import freedomTheme from 'web_component_library/theme/freedom';
import { Box } from 'rebass/styled-components';
// accessing nodes directly as it is needed to change the para color inside rich text when it lies under list
const { colors } = freedomTheme;
export const Wrapper = styled(Box)`
  li > p {
    color: ${colors.black};
  }
`;

export const Container = styled(Box)`
  p {
    strong {
      color: #000;
    }
  }
`;

export const Block = styled(Box)`
  overflow-wrap: break-word;
`;
