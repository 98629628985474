import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image } from 'rebass/styled-components';
import { H3 } from 'web_component_library/typography';
import TertiaryButton from 'web_component_library/tertiaryButton';
import Link from 'web_component_library/link';

import { TextBlock } from './css';
import { getSupportUrl } from '../../constants';
import { MediaFile } from '../../containers/contentful/index';
import withTranslation from '../../containers/translationHoc';

function Article({ title, slug, image, translate }) {
  const { url, title: imgTitle } = new MediaFile(image);

  return (
    <Box width={1}>
      <Flex pb={[15, 26]}>
        <Box bg="white">
          <Flex flexDirection="column">
            <Image width={1} src={url} alt={imgTitle} />
            <TextBlock
              flexDirection="column"
              justifyContent="space-between"
              p={13}
            >
              <H3>{title}</H3>
              <Link to={`${getSupportUrl()}/${slug}`}>
                <TertiaryButton
                  text={translate('readArticle')}
                  as="span"
                  textColor="freedomCharcoal"
                  pt={11}
                />
              </Link>
            </TextBlock>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

Article.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslation(Article);
