import { createActions } from 'redux-actions';

export const {
  setPlanSize,
  setArticleId,
  dismissGlobalAlert,
  setHeaderHeight,
} = createActions(
  'SET_PLAN_SIZE',
  'SET_ARTICLE_ID',
  'DISMISS_GLOBAL_ALERT',
  'SET_HEADER_HEIGHT',
);
