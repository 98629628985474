import { handleActions } from 'redux-actions';

import {
  setPlanSize,
  setArticleId,
  dismissGlobalAlert,
  setHeaderHeight,
} from './actions';
export const initialState = {
  planSize: null,
  articleId: null,
  globalAlertDismissed: false,
  headerHeight: 0,
};

const appReducer = handleActions(
  {
    [setPlanSize]: (state, { payload }) => ({ ...state, planSize: payload }),
    [setArticleId]: (state, { payload }) => ({ ...state, articleId: payload }),
    [dismissGlobalAlert]: state => ({
      ...state,
      globalAlertDismissed: true,
    }),
    [setHeaderHeight]: (state, { payload }) => ({
      ...state,
      headerHeight: payload,
    }),
  },
  initialState,
);

export default appReducer;
