import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const withTranslation = WrappedComponent => {
  class TranslationHoc extends Component {
    static propTypes = {
      translations: PropTypes.object.isRequired,
    };

    translate = key => this.props.translations[key] || '';

    render() {
      return <WrappedComponent {...this.props} translate={this.translate} />;
    }
  }

  const mapStateToProps = state => ({
    translations: state.content.translations.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {}),
  });

  return connect(mapStateToProps)(TranslationHoc);
};

export default withTranslation;
