import Page from './page';
import PopupModal from './popupModal';
import PageNotFound from './pageNotFound';
import AlertBanner from './alertBanner';

export const CONTENTFUL_MODELS_TYPES = {
  page: 'page',
  popupModal: 'popupModal',
  error: 'error',
  formError: 'formError',
  vanityRedirect: 'vanityRedirect',
  alertBanner: 'alertBanner',
  question: 'componentQuestion',
  supportCategory: 'supportCategory',
  article: 'article',
};

export default class ContenfulData {
  constructor(payload) {
    this.pages = {};
    this.popupModals = {};
    this.formErrors = {};
    this.globalAlertBanner = new AlertBanner();
    this.error = {};

    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }
    for (let count = 0; count < payload.length; count += 1) {
      if (payload[count] && payload[count].sys) {
        const { id } = payload[count].sys.contentType.sys;
        if (id === CONTENTFUL_MODELS_TYPES.page) {
          const { url } = payload[count].fields;
          this.pages[url] = new Page(payload[count]);
        } else if (id === CONTENTFUL_MODELS_TYPES.popupModal) {
          const { identifier } = payload[count].fields;
          this.popupModals[identifier] = new PopupModal(payload[count]);
        } else if (id === CONTENTFUL_MODELS_TYPES.formError) {
          const { id: errorId } = payload[count].fields;
          this.formErrors[errorId] = payload[count];
        } else if (id === CONTENTFUL_MODELS_TYPES.error) {
          const { url } = payload[count].fields;
          this.error[url] = new PageNotFound(payload[count]);
        } else if (id === CONTENTFUL_MODELS_TYPES.alertBanner) {
          this.globalAlertBanner = new AlertBanner(payload[count]);
        }
      }
    }
  }
}
