export default class TertiaryPromotion {
  constructor(data) {
    if (data && data.fields) {
      const { promotionTitle, subText, content, url } = data.fields;
      this.promotionTitle = promotionTitle;
      this.subText = subText;
      this.content = content;
      this.url = url;
    }
  }
}
