import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import TextBlock from 'web_component_library/textBlock';

const defaultTextBlockVariant = 'light';

const RichTextBlock = ({
  title,
  textBlockVariant = defaultTextBlockVariant,
}) => (
  <Box width={1}>
    <TextBlock data={title} blockVariant={textBlockVariant} />
  </Box>
);

RichTextBlock.propTypes = {
  textBlockVariant: PropTypes.string,
  title: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default RichTextBlock;
