import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setUtagForLinks } from '../../lib/analytics';

export const ModalContext = React.createContext();

export class ModalProvider extends Component {
  mounted = false;

  state = {
    component: null,
    props: {},
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { children } = this.props;
    const { component, props } = this.state;
    return (
      <ModalContext.Provider
        value={{
          component,
          props,
          showModal: this.showModal,
          hideModal: this.hideModal,
          setProps: this.setProps,
        }}
      >
        {children}
      </ModalContext.Provider>
    );
  }

  setProps = newProps => {
    this.setState({
      props: newProps,
    });
  };

  showModal = (component, props = {}) => {
    if (this.mounted) {
      this.setState({
        component,
        props,
      });
    }
  };

  hideModal = () => {
    setUtagForLinks({
      ev_type: 'interaction',
      ev_action: 'click',
      ev_name: 'close',
    });
    if (this.mounted) {
      this.setState({
        component: null,
        props: {},
      });
    }
  };
}

ModalProvider.displayName = 'ModalProvider';

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ModalConsumer = ModalContext.Consumer;
