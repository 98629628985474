import ContentfulModel from './contentfulModel';
import PlanDataHeader from './planDataHeader';
import PlanSpec from './planSpec';

export default class PlanCoverageZone extends ContentfulModel {
  constructor(data) {
    super(data);
    if (data?.fields) {
      const { header, specs } = data.fields;
      this.header = header ? new PlanDataHeader(header) : null;
      this.specs = this.extractEntriesToList(specs, PlanSpec);
    }
  }
}
