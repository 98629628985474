import { createSelector } from 'reselect';

export const selectContent = state => state.content;

export const selectEntries = state => selectContent(state).entries;

export const selectSupportEntries = state =>
  selectContent(state).supportEntries;

export const selectTranslations = state => selectContent(state).translations;

export const selectProductCatalog = state =>
  selectEntries(state).productCatalog;

export const selectLocale = () =>
  createSelector(
    selectEntries,
    entries => entries.locale,
  );

export const selectRegion = () =>
  createSelector(
    selectEntries,
    entries => entries.region || global?.region,
  );

export const selectAvailableRegions = () =>
  createSelector(
    selectEntries,
    entries => entries.availableRegions,
  );

export const selectBrowseCatalog = () =>
  createSelector(
    selectEntries,
    entries => entries?.browseCatalog,
  );

export const selectCurrentPage = () =>
  createSelector(
    selectBrowseCatalog(),
    browseCatalog => browseCatalog?.currentPage,
  );

export const selectFormErrors = () =>
  createSelector(
    selectEntries,
    entries => {
      const formErrors = {};
      Object.keys(entries.browseCatalog.formErrors).forEach(error => {
        formErrors[error] =
          entries.browseCatalog.formErrors[error].fields.message;
      });
      return formErrors;
    },
  );

export const selectCommonCatalog = () =>
  createSelector(
    selectEntries,
    entries => entries.commonCatalog,
  );

export const selectPrepaidPlans = () =>
  createSelector(
    selectProductCatalog,
    content => content.prepaidPlans,
  );
export const selectPopupModals = () =>
  createSelector(
    selectEntries,
    entries => entries.browseCatalog.popupModals,
  );

export const selectAddOns = () =>
  createSelector(
    selectProductCatalog,
    content => content.addOns,
  );

export const selectGlobalAlertBanner = () =>
  createSelector(
    selectEntries,
    entries => entries.browseCatalog.globalAlertBanner,
  );

export const selectCompatibilityCheckOptions = () =>
  createSelector(
    selectContent,
    state => state.compatibilityCheckOptions,
  );

export const selectQuestions = () =>
  createSelector(
    selectSupportEntries,
    state => state.questions,
  );

export const selectCategories = () =>
  createSelector(
    selectSupportEntries,
    state => state.categories,
  );

export const selectSupportEntriesHasFinishedFetching = () =>
  createSelector(
    selectContent,
    state => state.hasFinishedEntriesFetching,
  );

export const selectSearchQueryEntries = () =>
  createSelector(
    selectContent,
    state => state.searchQueryEntries,
  );

export const selectSearchQueryEntriesFetching = () =>
  createSelector(
    selectContent,
    state => state.searchQueryEntriesFetching,
  );

export const selectArticlesList = () =>
  createSelector(
    selectSupportEntries,
    state => state.articlesList,
  );

export const selectArticle = () =>
  createSelector(
    selectContent,
    state => state.article,
  );

export const selectPlans = () =>
  createSelector(
    selectProductCatalog,
    content => content.plans,
  );
