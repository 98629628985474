import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from 'rebass/styled-components';
import ListBox from 'web_component_library/listBox';
import {
  setRegion,
  getClientSideEntries,
} from '../../containers/contentfulHoc/actions';
import {
  selectRegion,
  selectAvailableRegions,
  selectLocale,
} from '../../containers/contentfulHoc/selectors';
import { selectLocation } from '../../containers/navigationRouter/selectors';

const RegionsDropdown = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale());
  const location = useSelector(selectLocation());

  const region = useSelector(selectRegion());
  const availableRegions = useSelector(selectAvailableRegions());
  const regionsOptions = availableRegions.map(r => ({
    label: r.name,
    value: r.code,
  }));

  useEffect(() => {
    dispatch(getClientSideEntries({ locale, region, url: location.pathname }));
  }, [region]);

  const updateRegion = ({ target: { value } }) => dispatch(setRegion(value));

  return (
    <Box bg="white" py={17} px={7}>
      <Box className="restrict-content">
        <ListBox
          id="regions"
          onChange={updateRegion}
          options={regionsOptions}
          value={region}
          label="Select Region"
        />
      </Box>
    </Box>
  );
};

export default RegionsDropdown;
