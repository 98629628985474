import React from 'react';
import PropTypes from 'prop-types';
import { IframeWrapper, Container } from './css';

const Iframe = ({ iframeUrl, title }) => (
  <Container>
    <IframeWrapper title={title} src={iframeUrl} />
  </Container>
);

Iframe.propTypes = {
  // iframe url
  iframeUrl: PropTypes.string.isRequired,
  // title of iframe
  title: PropTypes.string.isRequired,
};

export default Iframe;
