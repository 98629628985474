import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Flex from 'web_component_library/flex';
import Text from 'web_component_library/text';
import Image from 'web_component_library/image';
import { Box } from 'rebass/styled-components';

const RateBlock = ({
  rateTypeIcon = undefined,
  rateType,
  rate,
  pt = 0,
  pb = 0,
  renderAsRow = false,
}) => {
  const imageData = useMemo(() => {
    if (rateTypeIcon) {
      const {
        fields: {
          title,
          file: { url },
        },
      } = rateTypeIcon;
      return <Image url={url} mobileUrl={url} alt={title || ''} />;
    }
    return undefined;
  }, [rateTypeIcon]);
  return (
    <Flex pt={pt} pb={pb} flexDirection={renderAsRow ? 'row' : 'column'}>
      <Box width={1}>
        <Flex alignItems="center">
          {imageData}
          <Text lineHeight={[0, 5]} fontWeight={1} color="freedomCharcoal">
            {rateType}
          </Text>
        </Flex>
      </Box>
      <Flex justifyContent={renderAsRow ? 'end' : 'start'} width={1}>
        <Text lineHeight={[0, 5]} fontWeight={2} color="black" te>
          {rate}
        </Text>
      </Flex>
    </Flex>
  );
};

RateBlock.propTypes = {
  rateTypeIcon: PropTypes.object,
  rateType: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
  pt: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  pb: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  renderAsRow: PropTypes.bool,
};

export default RateBlock;
