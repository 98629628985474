export default class PlanSpec {
  constructor(data) {
    if (data && data.fields) {
      const {
        title,
        size,
        bonusSize,
        isUnlimited,
        shortDescription,
        longDescription,
        isUnlimitedFineprint,
      } = data.fields;
      this.isUnlimitedFineprint = isUnlimitedFineprint || '';
      this.title = title || '';
      this.size = size || '';
      this.bonusSize = bonusSize || '';
      this.isUnlimited = isUnlimited;
      this.shortDescription = shortDescription;
      this.longDescription = longDescription;
    }
  }
}
