import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from 'rebass';

import { selectCurrentPage } from '../../containers/contentfulHoc/selectors';

import { naturalToKebab, camelToKebab } from '../../../utils/stringHelpers';
import {
  getPageName,
  getModuleTitle,
  findCta,
  getCtaText,
  getClosestHeading,
} from './utils';
import { genCtaInfo } from '../../../utils/analytics';
import { setUtagForLinks } from '../../lib/analytics';
import { MODAL_ANALYTICS_NAME, CONTENT_ANALYTICS_NAME } from '../../constants';

export const isAbsolutePath = url =>
  url.indexOf('://') > 0 ||
  url.indexOf('//') === 0 ||
  url.indexOf('tel:') === 0 ||
  url.indexOf('mailto:') === 0;

const defaultProps = {
  identifier: '',
  entryTitle: '',
  title: '',
  enhancedImageTitle: '',
  groupRowTitle: '',
};

const withCtaAnalytics = (
  Component,
  {
    // Use box instead of flex for components with breaking styles
    useFlexContainer = true,
    customContainerCss = { width: '100%' },
    containerType = null,
    // If provided, uses static string for module type + drops module title
    staticModuleType = '',
    includeModuleTitle = true,
    // Adds subtitle after module title for components with multiple parts
    includeSubtitle = false,
    // uses all the names in this array to use the props with the same name from the component (instead of getting from DOM)
    customSubtitleKeys = [],
    // Different values generate different CTA text (see getCtaText function)
    ctaType = 'normal',
    customCtaTextKey = '',
  } = {},
) => {
  const ComponentWithAnalytics = (props = defaultProps) => {
    const {
      identifier,
      entryTitle,
      title,
      enhancedImageTitle, // for EnhancedImage component
      groupRowTitle,
    } = props;

    const currentPage = useSelector(selectCurrentPage());

    const ref = useRef();

    // Whenever we have a link using React Router and it gets clicked
    // the redirect happens before withCtaAnalytics has a chance to run
    // So this useEffect is being used to set the "previous" location
    useEffect(() => {
      if (ref.current) {
        ref.current.pathNameRef = window.location.pathname;
      }
    }, []);

    const listenForCtaClick = event => {
      const containerElement = event.currentTarget;
      const isPortal =
        (containerElement.dataset.containerType || containerType) === 'portal';
      // Clicks inside portals were bubbling up causing double firing of the analytics, this ensures only portals stop propagation as we still want other components to be able to bubble (ie. plansFilter popup)
      if (isPortal) {
        event.stopPropagation();
      }
      const bubblingPath =
        event.nativeEvent.path || event.nativeEvent.composedPath?.();
      const cta = findCta(bubblingPath);
      if (cta) {
        const pageArea = isPortal
          ? MODAL_ANALYTICS_NAME
          : CONTENT_ANALYTICS_NAME;
        const pageName = getPageName(currentPage);
        const moduleType = staticModuleType || camelToKebab(identifier);
        let moduleTitle;
        let moduleSubtitle;
        const ctaText = getCtaText(
          cta,
          cta.dataset.type || ctaType,
          customCtaTextKey,
        );

        const datapoints = [pageArea, pageName, moduleType];

        if (includeModuleTitle) {
          moduleTitle = getModuleTitle(containerElement, groupRowTitle, [
            entryTitle,
            title,
            enhancedImageTitle,
          ]);
          datapoints.push(moduleTitle);
        }
        if (includeSubtitle) {
          if (customSubtitleKeys.length) {
            const moduleSubtitleArray = [];
            customSubtitleKeys.forEach(key => {
              if (props[key])
                moduleSubtitleArray.push(naturalToKebab(props[key]));
            });
            moduleSubtitle = moduleSubtitleArray.join('|');
          } else {
            moduleSubtitle = getClosestHeading(cta, containerElement);
          }
          if (
            moduleSubtitle &&
            moduleSubtitle !== moduleTitle &&
            moduleSubtitle !== pageName
          ) {
            datapoints.push(moduleSubtitle);
          }
        }

        const additionalText = [];
        // If there is a tool tip name in the data set of the container then use it to identify different tooltips
        if (containerElement.dataset.tooltipName) {
          additionalText.push(containerElement.dataset.tooltipName);
        }
        // When the accordion is inside a tooltip we need to add the inner text of the accordion before the close or open text
        if (
          cta.dataset.type === 'accordion' &&
          // Checking for includeModuleTitle lets us know if the accordion is one provided inside a text block (tooltip) or if its one applied through the helpers util
          !includeModuleTitle
        ) {
          additionalText.push(naturalToKebab(cta.innerText));
        }

        if (additionalText.length) {
          datapoints.push(additionalText.join('|'));
        }

        datapoints.push(ctaText);

        const ctaInfo = genCtaInfo(...datapoints);

        const href = cta.dataset.url || cta.href;

        const isAbsoulteUrl =
          href && isAbsolutePath(cta.attributes.href?.value);

        // We let setUtagForLinks take care of the redirect if its an absolute url
        // If its a relative path React Router Link takes care of it
        if (isAbsoulteUrl) {
          event.preventDefault();
        }

        const incomingUrl = href ? new URL(href) : {};
        const isSameSite = incomingUrl.host === window.location.host;
        const isSamePathname = incomingUrl.pathname === ref.current.pathNameRef;
        const isSameUrl = isSameSite && isSamePathname;

        setUtagForLinks(
          {
            custom_link: ctaInfo,
            // We only want to add the link_href property when the href is pointing to a different url
            link_href: isSameUrl ? null : href,
          },
          // We want to let the original CTA handle the link redirect if it's a link to the same site or if its not an absolute url
          isSameSite && !isAbsoulteUrl ? null : href,
        );
      }
    };

    switch (containerType) {
      case 'portal':
        return (
          <Component
            ref={ref}
            handleContainerClick={listenForCtaClick}
            {...props}
          />
        );
      default:
        return (
          <Box
            ref={ref}
            onClick={listenForCtaClick}
            {...customContainerCss}
            sx={
              useFlexContainer
                ? { '> *': { width: '100%' }, display: 'flex' }
                : {}
            }
          >
            <Component handleContainerClick={listenForCtaClick} {...props} />
          </Box>
        );
    }
  };

  ComponentWithAnalytics.propTypes = {
    identifier: PropTypes.string,
    entryTitle: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    enhancedImageTitle: PropTypes.string,
    groupRowTitle: PropTypes.string,
  };

  return ComponentWithAnalytics;
};

export default withCtaAnalytics;
