import AddOn from './addOn';

export default class Addons {
  constructor(payload) {
    this.addOns = [];
    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }
    for (let count = 0; count < payload.length; count += 1) {
      if (payload[count] && payload[count].sys) {
        if (
          !payload[count].fields.hideFromBrowse &&
          payload[count].fields.category !== 'Legacy'
        ) {
          this.addOns.push(new AddOn(payload[count]));
        }
      }
    }
  }
}
