import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { Flex, Text, Box } from 'rebass/styled-components';

import { useSelector } from 'react-redux';
import TextBlock from 'web_component_library/textBlock';
import Button from 'web_component_library/button';
import {
  VARIANT_LIGHT,
  ANALYTICS_QUESTION_TEXT,
  ANALYTICS_RESULTS_TEXT,
  PAGE_SEARCH,
} from '../../constants';
import { FeedbackForm } from './css';
import { setUtagForLinks } from '../../lib/analytics';
import {
  selectArticle,
  selectSearchQueryEntries,
} from '../../containers/contentfulHoc/selectors';

const SupportFeedback = ({ title, text, buttons, thankYouText }) => {
  const location = useLocation();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);

  const searchQueryEntries = useSelector(selectSearchQueryEntries());
  const { total } = searchQueryEntries;

  const match = useRouteMatch();
  const isArticlePage = Boolean(match?.params?.article);

  const isSearchResultsPage = location.pathname.endsWith(PAGE_SEARCH);
  // NOTE: We store location.state in a ref as it gets cleared whenever route changes happen,
  // which is common with the TableOfContents component (rendered by TextBlock)
  const { current: articleFromRouter } = useRef(location.state); // passed from SupportSearchInput
  const articleFromRedux = useSelector(selectArticle()); // fetched if articleFromRouter not available
  const article = articleFromRedux || articleFromRouter;

  if (typeof window === 'undefined') return null;

  useEffect(() => {
    if (window.isArticleShowing || (total !== undefined && total !== 0)) {
      setRenderComponent(true);
    } else {
      setRenderComponent(false);
    }
  }, [window.isArticleShowing, article, total]);

  if ((isArticlePage || isSearchResultsPage) && !renderComponent) return null;

  const dataName = isArticlePage
    ? ANALYTICS_QUESTION_TEXT // used when on article page
    : ANALYTICS_RESULTS_TEXT; // used when on any other page (usually results page)

  const renderForm = () => (
    <FeedbackForm>
      <TextBlock data={text} blockVariant={VARIANT_LIGHT} />
      <Flex pt={15} width={1} flexWrap="wrap">
        {' '}
        {buttons.map((item, index) => {
          const {
            fields: { text: buttonText, name },
          } = item;
          const key = `${title}${buttonText}`;
          const analytics = {
            ev_type: 'interaction',
            ev_action: 'click',
            ev_name: dataName,
            ev_data: name,
          };

          return (
            <Flex
              key={key}
              width={[1, 1 / 2]}
              justifyContent={index ? 'start' : 'end'}
              pr={index ? 0 : [0, 5]}
              pl={index ? [0, 5] : 0}
            >
              <Box width={[1, '22em']} mb={7}>
                <Button
                  variant="secondary"
                  fontSize={3}
                  lineHeight={4}
                  onClick={() => {
                    setIsSubmitted(true);
                    setUtagForLinks(analytics);
                  }}
                  disabled={isSubmitted}
                >
                  {buttonText}
                </Button>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    </FeedbackForm>
  );

  const renderMessage = () => (
    <Text
      fontSize={[11, 13]}
      fontWeight="bold"
      textAlign="center"
      py={[11, 15]}
      width={1}
    >
      {thankYouText}
    </Text>
  );

  return isSubmitted ? renderMessage() : renderForm();
};

SupportFeedback.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  thankYouText: PropTypes.string.isRequired,
};

export default SupportFeedback;
