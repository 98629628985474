import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { Paragraph } from 'web_component_library/typography';
import Button from 'web_component_library/button';

import RichText from '../richText';
import { ANALYTICS_BTN_PROPS, VARIANT_LIGHT } from '../../constants';

import PopupModal from '../../containers/contentful/popupModal';

const getOptions = (blockVariant, paragraphMargin, clickHandler) => {
  const paragraphColor =
    blockVariant === VARIANT_LIGHT ? 'freedomCharcoal' : 'pureWhite';

  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Paragraph color={paragraphColor} {...paragraphMargin}>
          {children}
        </Paragraph>
      ),
      // Embedded block entry to handle primary, secondary buttons for text block.
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (!(node.data && node.data.target && node.data.target.fields)) {
          return null;
        }
        const { component, value, variant } = node.data.target.fields;
        const popupModalData = new PopupModal(component);
        const { identifier } = popupModalData;
        let buttonAnalytics;
        if (identifier === 'compatibilityCheck') {
          buttonAnalytics = {
            ...ANALYTICS_BTN_PROPS,
            'data-ev_type': 'interaction',
            'data-ev_action': 'modal show',
            'data-ev_name': 'byop compatibility',
            'data-ev_data': 'check compatibility',
          };
        }
        if (popupModalData) {
          return (
            <Button
              variant={variant}
              {...buttonAnalytics}
              onClick={() => clickHandler(popupModalData)}
            >
              {value}
            </Button>
          );
        }
        return null;
      },
    },
  };
};

const defaultData = {
  data: {},
  content: [
    {
      data: {},
      content: [{ data: {}, marks: [], value: '', nodeType: 'text' }],
      nodeType: 'heading-1',
    },
  ],
  nodeType: 'document',
};
const defaultParagraphMargin = { my: 0 };
const defaultClickHandler = () => {};
const defaultBlockVariant = VARIANT_LIGHT;

const CtaTextBlock = ({
  data = defaultData,
  blockVariant = defaultBlockVariant,
  paragraphMargin = defaultParagraphMargin,
  clickHandler = defaultClickHandler,
}) => {
  if (!data) return null;
  return (
    <RichText
      document={data}
      options={getOptions(blockVariant, paragraphMargin, clickHandler)}
    />
  );
};

CtaTextBlock.propTypes = {
  /**
   * Template based text block for page design card
   */
  data: PropTypes.shape({
    data: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object),
    nodeType: PropTypes.string,
  }),
  blockVariant: PropTypes.string,
  paragraphMargin: PropTypes.object,
  clickHandler: PropTypes.func,
};

// To use with styleguidist, we need to use a special annotation
/** @component */
export default CtaTextBlock;
