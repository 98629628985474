import React from 'react';
import PropTypes from 'prop-types';
import Box from 'web_component_library/box';
import Flex from 'web_component_library/flex';
import { H4, Paragraph } from 'web_component_library/typography';
import Divider from 'web_component_library/divider';
import Link from 'web_component_library/link';
import { getSupportUrl } from '../../constants';

import { ArrowRight } from './css';
import withTranslation from '../../containers/translationHoc';

function PopularSearches({ articles, translate }) {
  return (
    <Box mt={['md', 'xxl']} mb={['xl', 'xxxl']}>
      <H4 as="h2" mb="xxl">
        {translate('popularSearches')}
      </H4>

      <Flex
        as="ul"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          pl: 0,
          listStyle: 'none',
        }}
      >
        {articles
          .filter(article => Boolean(article.fields))
          .map(article => {
            const { title, slug } = article.fields;
            return (
              <Box
                key={`${slug}${title}`}
                as="li"
                width={[1, `calc(50% - 1.5rem)`]}
              >
                <Divider color="accessibleGrey" />
                <Box position="relative">
                  <Link
                    to={`${getSupportUrl()}/${slug}`}
                    width={1}
                    display="inline-block"
                    py="lg"
                  >
                    <Paragraph as="span" display="inline-block" pr="xl">
                      {title}
                    </Paragraph>
                    <ArrowRight />
                  </Link>
                </Box>
              </Box>
            );
          })}
      </Flex>
    </Box>
  );
}

PopularSearches.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslation(PopularSearches);
