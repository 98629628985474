import React, { useMemo, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'web_component_library/link';
import Icon from 'web_component_library/icon';

import styled from 'styled-components';

import { isMobile } from '../../../utils/animationHelper';
import { breakpoints } from '../../../utils/mediaQuery';
import { getPaginationLinks } from './getPaginationLinks';

const SearchResultUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

const PaginationComponent = ({
  total = 0,
  skip,
  limit,
  isEnabled,
  getLink,
}) => {
  const [isMobileView, setIsMobileView] = useState(isMobile(breakpoints[1]));

  const currentPageNumer = useMemo(() => skip / limit + 1, [skip, limit]);
  const totalPagesNumber = useMemo(() => Math.ceil(total / limit), [
    total,
    limit,
  ]);

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobileView(isMobile(breakpoints[1]));
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const paginationLinks = useMemo(
    () =>
      isEnabled
        ? getPaginationLinks(
            Math.ceil(total / limit),
            currentPageNumer,
            isMobileView ? 7 : 9,
          )
        : [],
    [isEnabled, total, limit, currentPageNumer, isMobileView],
  );

  return (
    <>
      {isEnabled &&
        paginationLinks &&
        Array.isArray(paginationLinks) &&
        paginationLinks.length > 0 && (
          <SearchResultUL data-testid="pagination-component">
            <li>
              {currentPageNumer === 1 ? (
                <div
                  style={{ transform: 'rotate(180deg)' }}
                  data-testid="pagination-disabled-previous"
                >
                  <Icon size={0.8} color="paleGrey" variant="icon_chevron" />
                </div>
              ) : (
                <Link
                  to={getLink({ skipTo: limit * (currentPageNumer - 2) })}
                  data-testid="pagination-button-previous"
                >
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <Icon size={0.8} color="orange" variant="icon_chevron" />
                  </div>
                </Link>
              )}
            </li>
            {(paginationLinks || []).map(p => (
              <li key={p.pageNumber}>
                {p.showDots ? (
                  <span>...</span>
                ) : (
                  <>
                    {p.pageNumber === currentPageNumer ? (
                      <div data-testid={`pagination-disabled-${p.pageNumber}`}>
                        {p.pageNumber}
                      </div>
                    ) : (
                      <Link
                        to={getLink({ skipTo: limit * (p.pageNumber - 1) })}
                        data-testid={`pagination-button-${p.pageNumber}`}
                      >
                        {p.pageNumber}
                      </Link>
                    )}
                  </>
                )}
              </li>
            ))}
            <li>
              {currentPageNumer >= totalPagesNumber ? (
                <span data-testid="pagination-disabled-next">
                  <Icon size={0.8} color="paleGrey" variant="icon_chevron" />
                </span>
              ) : (
                <Link
                  to={getLink({ skipTo: limit * currentPageNumer })}
                  data-testid="pagination-button-next"
                >
                  <Icon size={0.8} color="orange" variant="icon_chevron" />
                </Link>
              )}
            </li>
          </SearchResultUL>
        )}
    </>
  );
};

PaginationComponent.propTypes = {
  getLink: PropTypes.func.isRequired,
  total: PropTypes.number,
  isEnabled: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  skip: PropTypes.number.isRequired,
};
export default PaginationComponent;
