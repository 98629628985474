import React, { PureComponent } from 'react';
import { object, string } from 'prop-types';
import { Box } from 'rebass/styled-components';
import Maintenance from '../maintenance';

class ErrorBody extends PureComponent {
  static propTypes = {
    maintenance: object,
    textBlock: object.isRequired,
    image: object.isRequired,
    textBlockBackground: string,
    textBlockVariant: string,
  };

  static defaultProps = {
    maintenance: {
      textBlock: {
        data: {},
        content: [],
        nodeType: 'document',
      },
      image: null,
      textBlockBackground: 'white',
    },
    textBlockVariant: 'light',
    textBlockBackground: 'transparent',
  };

  static displayName = 'ErrorBody';

  render() {
    const { maintenance } = this.props;

    const {
      textBlock,
      textBlockVariant,
      image,
      textBlockBackground,
    } = maintenance;

    return (
      <Box data-testid="error-body-component" mb={17}>
        <Maintenance
          textBlock={textBlock}
          textBlockVariant={textBlockVariant}
          image={image}
          textBlockBackground={textBlockBackground}
        />
      </Box>
    );
  }
}

export default ErrorBody;
