import { PT_BYOP, PT_POSTPAID } from '../../constants';
import Plan from './plan';

const categoriesArray = [PT_POSTPAID, PT_BYOP];

export default class ByopPlans {
  constructor(payload) {
    this.plans = {};
    if (!Array.isArray(payload)) {
      throw new Error('object is not of contentful array');
    }
    for (let count = 0; count < payload.length; count += 1) {
      if (payload[count] && payload[count].sys) {
        const { sku } = payload[count].fields;
        const plan = new Plan(payload[count]);
        if (categoriesArray.every(category => plan.categories[category])) {
          this.plans[sku] = plan;
        }
      }
    }
  }
}
