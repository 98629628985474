import React from 'react';
import { shape, string, bool, object } from 'prop-types';

import { StyledReactPlayer } from './css';

const getDeepValue = (path, obj) => {
  if (!obj) return null;
  const pathArray = path.split('.');
  return pathArray.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);
};

const getUrl = (video, videoUrl) => {
  if (getDeepValue('fields.file.url', video)) {
    return video.fields.file.url;
  }
  if (videoUrl) {
    return videoUrl;
  }
  return null;
};

const defaultVideoVttContent = {};
const defaultVideoUrl = '';
const defaultClassName = '';
const defaultPoster = {
  fields: {
    file: {
      url: '',
    },
  },
};

export default function Video({
  poster = defaultPoster,
  video,
  videoVttContent = defaultVideoVttContent,
  videoUrl = defaultVideoUrl,
  controls = true,
  loop = false,
  inline = false,
  light = true,
  muted = true,
  isBackgroundVideo = false,
  className = defaultClassName,
  isAutoplay = false,
}) {
  const url = getUrl(video, videoUrl);
  const videoVttUrl = getDeepValue('url', videoVttContent);
  const subtitleTracks = videoVttUrl
    ? [
        {
          kind: 'subtitles',
          src: videoVttUrl,
          srcLang: 'en',
          default: false,
        },
      ]
    : [];
  const { url: posterURL } = poster.fields.file;
  if (!url) return null;

  return (
    <StyledReactPlayer
      className={className}
      data-testid="modal-video"
      height="100%"
      width="100%"
      url={url}
      light={light}
      loop={isBackgroundVideo || loop}
      muted={isBackgroundVideo || muted}
      playing
      playsinline={isBackgroundVideo || inline}
      controls={isAutoplay ? false : !isBackgroundVideo || controls}
      config={{
        file: {
          attributes: {
            crossOrigin: 'true',
            poster: posterURL,
          },
          tracks: subtitleTracks,
        },
        youtube: {
          playerVars: { cc_load_policy: 1 },
        },
      }}
    />
  );
}

Video.propTypes = {
  video: shape({
    fields: shape({
      file: shape({
        url: string.isRequired,
      }),
    }),
  }).isRequired,
  poster: shape({
    fields: shape({
      file: shape({
        url: string,
      }),
    }),
  }),
  controls: bool,
  loop: bool,
  light: bool,
  inline: bool,
  muted: bool,
  isAutoplay: bool,
  isBackgroundVideo: bool,
  videoVttContent: object,
  videoUrl: string,
  className: string,
};
