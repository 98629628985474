export default class PromoCard {
  constructor(data) {
    if (data && data.fields && data.sys) {
      const { name, image, mobileImage } = data.fields;
      this.name = name;
      this.image = image;
      this.mobileImage = mobileImage;
      this.id = data.sys.id;
    }
  }
}
