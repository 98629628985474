import { ANALYTICS_BTN_PROPS } from '../common/constants';

export function getExpandButtonProps(data) {
  return {
    'data-ev_type': 'expansion',
    'data-ev_action': 'click',
    'data-ev_name': 'plan details',
    'data-ev_data': data,
  };
}

export function getChooseButtonProps(
  data,
  linkName,
  sku,
  price,
  discountedPrice,
  promo,
  productCategory,
  url,
) {
  return {
    'data-ev_action': 'submit',
    'data-ev_name': 'tile add plan',
    'data-ev_data': data,
    'data-link_obj': 'button',
    'data-link_name': linkName,
    'data-link_type': 'o',
    'data-link_region': 'body',
    'data-product_ids': [sku],
    'data-product_categories': [productCategory],
    'data-product_prices': [price],
    'data-product_discounts': [discountedPrice],
    'data-product_promos': [promo],
    'data-url': url,
  };
}

export function getFilterProps(name, data) {
  return {
    ...ANALYTICS_BTN_PROPS,
    'data-ev_type': 'filter',
    'data-ev_action': 'apply',
    'data-ev_name': name,
    'data-ev_data': data,
  };
}

export function buildEvName(values = []) {
  const sanitizedValues = values.map(v => encodeURIComponent(v.substr(0, 50)));
  return sanitizedValues.join('|');
}

export const genCtaInfo = (...parts) =>
  parts.map(part => part || 'unknown').join('|');
