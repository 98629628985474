import { takeLatest, put, call } from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import request from '../../../utils/request';

import getEnvVar from '../../lib/envvars';
import {
  loadGetUserProfile,
  loadedGetUserProfile,
  errorGetUserProfile,
} from './actions';

export function* loadGetUserProfileEvent({ payload: { token } }) {
  const url = `${getEnvVar('apiBaseUrl')}v1/services/users/current/profile`;
  try {
    const response = yield call(request, url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.errors?.length) {
      yield put(errorGetUserProfile({ errorCode: 'GENERIC_ERROR' }));
    } else {
      yield put(loadedGetUserProfile(response));
    }
  } catch (err) {
    // TODO: Refactor the error handling
    const errorCode = lodashGet(
      err,
      'response.data.errors[0].code',
      'GENERIC_ERROR',
    );
    yield put(errorGetUserProfile({ errorCode }));
  }
}

export default function* GetUserProfileSaga() {
  yield takeLatest(loadGetUserProfile().type, loadGetUserProfileEvent);
}
