import React from 'react';
import Box from 'web_component_library/box';
import ContentLoader from 'react-content-loader';

export const Loader = () => (
  <Box my="xxxl" ml="xxxl" width={1} textAlign="center">
    <ContentLoader
      height="260"
      width="80%"
      data-testid="device-questions-loader"
    >
      <rect y="0" width="80%" height="50" />
      <rect y="70" width="80%" height="50" />
      <rect y="140" width="80%" height="50" />
      <rect y="210" width="80%" height="50" />
    </ContentLoader>
  </Box>
);
