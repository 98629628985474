import styled from 'styled-components';
import { Button as BaseButton } from 'rebass';
import { Flex, Card as BaseCard } from 'rebass/styled-components';

export const CardBlock = styled(Flex)`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled(BaseButton)`
  background: none;
  padding: 0;
  cursor: pointer;
  padding-left: 0;
`;

export const Card = styled(BaseCard)`
  position: relative;
`;
