import React from 'react';
import PropTypes from 'prop-types';
import WclPromoBanner from 'web_component_library/promoBanner';
import { getImageUrl } from '../../../utils/imageHelper';
import withPopup from '../../../utils/withPopup';
import { MEDIUM_HEIGHT } from '../../constants';

const PromoBanner = ({ image = null, mobileImage = null, ...props }) => {
  const passedProps = {
    image,
    mobileImage,
    ...props,
  };

  // `placeholderUrl` is set to opt into image lazy loading
  if (image && image.fields) {
    passedProps.image.placeholderUrl = getImageUrl(image.fields.file.url);
  }
  if (mobileImage && mobileImage.fields) {
    passedProps.mobileImage.placeholderUrl = getImageUrl(
      mobileImage.fields.file.url,
    );
  }

  return (
    <WclPromoBanner
      isHeroBanner={passedProps?.appearance === MEDIUM_HEIGHT}
      {...passedProps}
      paragraphAs="p"
    />
  );
};

PromoBanner.propTypes = {
  image: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  mobileImage: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default withPopup(PromoBanner);
