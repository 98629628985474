import { handleActions } from 'redux-actions';
import {
  getTradeInCalculatorDevicesListSuccess,
  getTradeInCalculatorDevicesListError,
  getTradeInCalculatorDeviceQuestionsSuccess,
  getTradeInCalculatorDeviceQuestionsError,
  getTradeInCalculatorDeviceUpdatedPriceSuccess,
  getTradeInCalculatorDeviceUpdatedPriceError,
  clearTradeInCalculator,
  setIsLoadingDeviceQuestions,
  setIsLoadingDevicePrice,
} from './actions';

export const initialState = {
  // this is the price ajudsted after some questions and answers
  adjustedPrice: null,
  deviceCode: null,
  deviceImage: null,
  deviceModel: null,
  // this is the original amount from device list
  estimatedPrice: 0,
  devicesLists: [],
  devicesListError: false,
  deviceQuestions: [],
  deviceQuestionsError: false,
  isLoadingDeviceQuestions: false,
  isLoadingDevicePrice: false,
};

const reducer = handleActions(
  {
    [getTradeInCalculatorDevicesListSuccess]: (state, { payload }) => ({
      ...state,
      devicesLists: [...payload],
    }),
    [getTradeInCalculatorDevicesListError]: () => ({
      ...initialState,
      devicesListError: true,
    }),
    [getTradeInCalculatorDeviceQuestionsSuccess]: (state, { payload }) => {
      const {
        deviceQuestions,
        estimatedPrice,
        deviceImage,
        deviceModel,
        deviceCode,
      } = payload;
      return {
        ...state,
        deviceQuestions,
        estimatedPrice,
        adjustedPrice: 0,
        deviceImage,
        deviceModel,
        deviceCode,
        devicesListError: false,
        deviceQuestionsError: false,
      };
    },
    [getTradeInCalculatorDeviceQuestionsError]: state => ({
      ...state,
      devicesListError: false,
      deviceQuestionsError: true,
    }),
    [getTradeInCalculatorDeviceUpdatedPriceSuccess]: (state, { payload }) => {
      const { adjustedPrice, deviceQuestions } = payload;
      return {
        ...state,
        adjustedPrice,
        deviceQuestions,
        devicesListError: false,
        deviceQuestionsError: false,
      };
    },
    [getTradeInCalculatorDeviceUpdatedPriceError]: state => ({
      ...state,
      devicesListError: false,
      deviceQuestionsError: true,
      adjustedPrice: 0,
      estimatedPrice: 0,
    }),
    [clearTradeInCalculator]: () => ({
      ...initialState,
    }),
    [setIsLoadingDeviceQuestions]: (state, { payload }) => ({
      ...state,
      isLoadingDeviceQuestions: payload,
    }),
    [setIsLoadingDevicePrice]: (state, { payload }) => ({
      ...state,
      isLoadingDevicePrice: payload,
    }),
  },
  initialState,
);

export default reducer;
