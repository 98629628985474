export default class TertiaryNavigationSectionModel {
  constructor(data) {
    if (data && data.icons) {
      const { icons, iconsVariant } = data;
      this.iconsList = this.getIcons(icons);
      this.iconsVariant = iconsVariant;
    }
  }

  getIcons(icons) {
    if (!icons.length) return [];
    const getIconsList = icons.map(icon => {
      const {
        fields: {
          entryTitle,
          url,
          buttonImage: {
            fields: {
              file: { url: imageUrl },
            },
          },
        },
      } = icon;
      return { title: entryTitle, url, imageUrl };
    });
    return getIconsList;
  }
}
