import freedomTheme from 'web_component_library/theme/freedom';

const { colors } = freedomTheme;

const { orange, pureWhite, pureBlack, blue } = colors;

function getTheme(theme) {
  switch (theme) {
    case 'black':
      return { backgroundColor: pureBlack, accentColor: orange };
    case 'white':
      return { backgroundColor: pureWhite, accentColor: orange };
    case 'blue':
      return { backgroundColor: blue, accentColor: pureBlack };
    default:
      return { backgroundColor: pureBlack, accentColor: orange };
  }
}

export { getTheme };
