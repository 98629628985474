import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image } from 'rebass/styled-components';
import { Form, Formik } from 'formik';
import Box from 'web_component_library/box';
import Checkbox from 'web_component_library/checkbox';
import Divider from 'web_component_library/divider';
import Flex from 'web_component_library/flex';
import Input from 'web_component_library/input';
import { Paragraph } from 'web_component_library/typography';
import TextBlock from 'web_component_library/textBlock';
import Text from 'web_component_library/text';
import ToolTip from 'web_component_library/toolTip';
import ContentLoader from 'react-content-loader';
import { debounce } from '../../../utils/debounce';
import { prettifyPrice } from '../../../utils/number';
import RestrictContent from '../../components/restrictContent';
import { MediaFile } from '../contentful/index';
import {
  getTradeInCalculatorDevicesList,
  getTradeInCalculatorDeviceQuestions,
  getTradeInCalculatorDeviceUpdatedPrice,
  clearTradeInCalculator,
} from './actions';
import {
  selectTradeInDevicesList,
  selectTradeInDeviceAdjustedPrice,
  selectTradeInDeviceEstimatedPrice,
  selectTradeInDeviceQuestions,
  selectTradeInDeviceImage,
  selectTradeInDeviceModel,
  selectTradeInDevicesListError,
  selectTradeInDeviceQuestionsError,
  selectTradeInIsLoadingQuestions,
  selectTradeInIsLoadingPrice,
} from './selectors';
import { selectCurrentPage } from '../contentfulHoc/selectors';
import { naturalToKebab } from '../../../utils/stringHelpers';
import { getPageName } from '../../components/ctaAnalyticsHoc/utils';
import { setUtagForLinks } from '../../lib/analytics';
import withCtaAnalytics from '../../components/ctaAnalyticsHoc';

const ToolTipWithAnalytics = withCtaAnalytics(ToolTip, {
  staticModuleType: 'side-modal',
  containerType: 'portal',
  includeModuleTitle: false,
});

const TradeInCalculator = ({
  header,
  description = null,
  inputBounceTime = 1,
  inputDeviceModelTitle,
  inputDeviceModelSubtitle = null,
  inputDeviceModelPlaceholder = null,
  inputDeviceModelErrorMessage,
  genericErrorMessage,
  tooltipTitle = null,
  tooltipContent = null,
  initialDeviceImage,
  deviceTradeInValueTitle,
  deviceQuestionsHeader,
  deviceQuestionsDescription = null,
}) => {
  const dispatch = useDispatch();

  const devicesList = useSelector(selectTradeInDevicesList());
  const devicesListError = useSelector(selectTradeInDevicesListError());
  const deviceQuestions = useSelector(selectTradeInDeviceQuestions());
  const deviceQuestionsError = useSelector(selectTradeInDeviceQuestionsError());
  const deviceEstimatedPrice = useSelector(selectTradeInDeviceEstimatedPrice());
  const deviceAdjustedPrice = useSelector(selectTradeInDeviceAdjustedPrice());
  const deviceImage = useSelector(selectTradeInDeviceImage());
  const deviceModel = useSelector(selectTradeInDeviceModel());
  const isLoadingQuestions = useSelector(selectTradeInIsLoadingQuestions());
  const isLoadingPrice = useSelector(selectTradeInIsLoadingPrice());

  const currentPage = useSelector(selectCurrentPage());
  // Derived state
  const pageName = getPageName(currentPage);

  const [deviceQuestionsList, setDeviceQuestionsList] = useState([]);
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { url, title: imgTitle } = new MediaFile(initialDeviceImage);

  const initialState = { url, altText: imgTitle };
  const [deviceImageAttr, setDeviceImageAttr] = useState(initialState);

  const clearStoreAndState = () => {
    dispatch(clearTradeInCalculator());
    setDeviceQuestionsList([]);
    setDeviceImageAttr(initialState);
    setError(false);
  };

  useEffect(() => {
    clearStoreAndState();
  }, []);

  useLayoutEffect(() => {
    if (devicesListError) {
      setError(true);
      setErrorMessage(inputDeviceModelErrorMessage);
    }
    if (deviceQuestionsError) {
      setError(true);
      setErrorMessage(genericErrorMessage);
    }
  }, [devicesListError, deviceQuestionsError]);

  useLayoutEffect(() => {
    if (deviceQuestions.length) {
      setDeviceQuestionsList([...deviceQuestions]);
    }
  }, [deviceQuestions]);

  useLayoutEffect(() => {
    if (deviceImage) {
      setDeviceImageAttr(prevState => ({
        ...prevState,
        url: deviceImage,
        altText: deviceModel,
      }));
    }
  }, [deviceImage, deviceModel]);

  // STEP 01. Get the devices list
  const getDeviceListFromApi = useCallback(
    debounce(inputValue => {
      dispatch(
        getTradeInCalculatorDevicesList({
          deviceName: inputValue,
          inputBounceTime,
        }),
      );
    }, inputBounceTime * 100),
    [dispatch, inputBounceTime],
  );

  const handleSubmit = e => {
    const { phoneModel } = e;

    if (phoneModel.length === 0) return;

    const getDevice = devicesList.filter(
      device => device.model.toLowerCase() === phoneModel.toLowerCase(),
    );

    if (getDevice.length === 0) {
      setErrorMessage(inputDeviceModelErrorMessage);
      setError(true);
    } else {
      setErrorMessage(null);
      setError(false);

      setUtagForLinks({
        custom_link: `content|${pageName}|${naturalToKebab(
          inputDeviceModelTitle,
        )}|${naturalToKebab(getDevice[0].model)}`,
      });

      // STEP 02. Get the correct device questions
      dispatch(
        getTradeInCalculatorDeviceQuestions({
          deviceCode: getDevice[0].code,
          deviceImage: getDevice[0].image,
          deviceModel: getDevice[0].model,
          estimatedPrice: getDevice[0].estimatedPrice,
        }),
      );
    }
  };

  const handleDeviceQuestionsChange = e => {
    const {
      target: { name },
    } = e;

    let questionTextToAnalytics = null;
    let questionStateToAnalytics = null;

    const copyDeviceQuestions = [...deviceQuestionsList];
    const questionUpdated = copyDeviceQuestions.map(question => {
      if (question.questionCode === name) {
        questionTextToAnalytics = question.text;
        questionStateToAnalytics = !question.sku;
        return { ...question, sku: !question.sku };
      }
      return { ...question };
    });
    setDeviceQuestionsList([...questionUpdated]);

    setUtagForLinks({
      custom_link: `content|${pageName}|${naturalToKebab(
        deviceQuestionsHeader,
      )}|${naturalToKebab(questionTextToAnalytics)}|${naturalToKebab(
        questionStateToAnalytics ? 'select' : 'unselect',
      )}`,
    });

    // STEP 03. Update the device price accordingly with the questions and answers
    dispatch(
      getTradeInCalculatorDeviceUpdatedPrice({
        deviceQuestions: questionUpdated,
      }),
    );
  };

  const toggleToolTip = () => {
    setTooltipOpen(!isTooltipOpen);
    if (!isTooltipOpen) {
      setUtagForLinks({
        custom_link: `modal|${pageName}|${naturalToKebab(tooltipTitle)}|open`,
      });
    }
  };

  // if the image load from API fails
  // it will replace the url by the default image
  const onImageError = () => {
    setDeviceImageAttr(prevState => ({ ...prevState, ...initialState }));
  };

  return (
    <Box width={1}>
      <RestrictContent>
        <Flex
          flexDirection={['column', 'row']}
          mt="xl"
          justifyContent="space-between"
          width={1}
          mb="xxxl"
          px={['md', 'lg']}
          alignItems="flex-start"
        >
          <Box width={[1, 1 / 2]}>
            <Box data-testid="page-header">
              <TextBlock
                data={header}
                headingStyles={{ mb: 'xl' }}
                paragraphStyles={{ mb: 'xl' }}
              />
              {description && (
                <TextBlock
                  data={description}
                  headingStyles={{ mb: 'xl' }}
                  paragraphStyles={{ mb: 'xl' }}
                />
              )}
              <Box width={1} mt="xxl" mb="xxl" data-testid="device-model-form">
                <Formik
                  initialValues={{ phoneModel: '' }}
                  validateOnChange={false}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue, handleChange }) => (
                    <Form>
                      <Box width={1} mb="xl">
                        <Text
                          fontSize={4}
                          data-testid="device-model-form-title"
                          color="black"
                          fontWeight={2}
                        >
                          {inputDeviceModelTitle}
                        </Text>
                      </Box>
                      {inputDeviceModelSubtitle && (
                        <Box width={1}>
                          <Text
                            as="span"
                            data-testid="device-model-input-subtitle"
                            fontSize={3}
                            mr="md"
                          >
                            {inputDeviceModelSubtitle}
                          </Text>
                        </Box>
                      )}
                      <Box>
                        <Input
                          id="phoneModel"
                          testId="device-model-form-input"
                          onChange={e => {
                            handleChange(e);
                            if (e.target.value.trim().length > 1) {
                              getDeviceListFromApi(e.target.value);
                            }
                          }}
                          value={values.phoneModel}
                          isSearchButtonVisible
                          searchButtonText="Search"
                          clearButtonText=""
                          clearForm={() => clearStoreAndState()}
                          setFieldValue={setFieldValue}
                          list={devicesList.map(device => device.model)}
                          withScrollbar
                          shouldHandleFiltering={false}
                          labelIsHidden
                          autocompleteListMaxHeight="300px"
                          search={e =>
                            handleSubmit({
                              phoneModel: e,
                            })
                          }
                          placeholder={inputDeviceModelPlaceholder}
                        />
                      </Box>
                    </Form>
                  )}
                </Formik>
                <Flex
                  width={1}
                  flexDirection="row"
                  justifyContent={error ? 'space-between' : 'end'}
                  mt={['md', 'xs']}
                >
                  {error && (
                    <Flex alignItems="center">
                      <Text
                        color="errorRed"
                        data-testid="device-model-form-error-msg"
                        fontSize={[2, 3]}
                        fontWeight={2}
                        lineHeight={[2, 6]}
                      >
                        {errorMessage}
                      </Text>
                    </Flex>
                  )}
                  <Flex alignItems="center">
                    {tooltipTitle && (
                      <Text
                        as="span"
                        data-testid="device-model-input-tooltip-title"
                        fontSize={1}
                        lineHeight={[2, 6]}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {tooltipTitle}
                      </Text>
                    )}
                    {tooltipContent && (
                      <Button
                        data-testid="device-model-input-tooltip-cta"
                        aria-label={tooltipTitle}
                        data-label={`${naturalToKebab(tooltipTitle)}`}
                        ml="sm"
                        onClick={toggleToolTip}
                        bg="freedomCharcoal"
                        width="1em"
                        px={0}
                        py={0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="1em"
                        zIndex="1"
                        variant="none"
                        sx={{ borderRadius: '90%', cursor: 'pointer' }}
                      >
                        <Text
                          as="span"
                          fontWeight={500}
                          fontSize={0}
                          lineHeight="0px"
                          color="white"
                        >
                          ?
                        </Text>
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Box>
            {(deviceQuestionsList.length > 0 || isLoadingQuestions) && (
              <Box width={1}>
                <Text
                  fontSize={4}
                  data-testid="device-questions-title"
                  color="black"
                  fontWeight={2}
                >
                  {deviceQuestionsHeader}
                </Text>

                {deviceQuestionsDescription && (
                  <Box my={13}>
                    <Paragraph data-testid="device-questions-description">
                      {deviceQuestionsDescription}
                    </Paragraph>
                  </Box>
                )}
                {isLoadingQuestions ? (
                  <ContentLoader
                    height="260"
                    data-testid="device-questions-loader"
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="50" height="50" />
                    <rect x="90" y="0" rx="4" ry="4" width="400" height="50" />
                    <rect x="0" y="70" rx="4" ry="4" width="50" height="50" />
                    <rect x="90" y="70" rx="4" ry="4" width="400" height="50" />
                    <rect x="0" y="140" rx="4" ry="4" width="50" height="50" />
                    <rect
                      x="90"
                      y="140"
                      rx="4"
                      ry="4"
                      width="400"
                      height="50"
                    />
                    <rect x="0" y="210" rx="4" ry="4" width="50" height="50" />
                    <rect
                      x="90"
                      y="210"
                      rx="4"
                      ry="4"
                      width="400"
                      height="50"
                    />
                  </ContentLoader>
                ) : (
                  deviceQuestionsList?.map(question => (
                    <Flex
                      as="li"
                      flexDirection="column"
                      width={1}
                      mt={13}
                      key={question.questionCode}
                    >
                      <Checkbox
                        name={question.questionCode}
                        value={question.questionCode}
                        label={question.text}
                        id={question.questionCode}
                        variant={question.sku ? 'checked' : 'unchecked'}
                        testId="device-question-checkbox"
                        size={0.7}
                        alignLabel="center"
                        onChange={handleDeviceQuestionsChange}
                      />
                    </Flex>
                  ))
                )}
              </Box>
            )}
          </Box>
          <Box position={['static', 'sticky']} top={24} width={[1, 2 / 6]}>
            <Flex
              mb="xl"
              flexDirection={['row', 'column']}
              mt={['xxl', 0]}
              alignItems="center"
            >
              <Flex
                width={[2 / 5, 1]}
                data-testid="device-image"
                textAlign="center"
                height={['10rem', '22rem']}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  maxHeight={['10rem', '22rem']}
                  src={deviceImageAttr.url}
                  alt={deviceImageAttr.altText}
                  onError={onImageError}
                />
              </Flex>
              <Box width={[2 / 3, 1]} mt="xl" pl={['sm', 0]} mb="xl">
                <Text
                  as="span"
                  data-testid="device-estimated-price-title"
                  fontSize={3}
                >
                  {deviceTradeInValueTitle}
                </Text>
                <Box>
                  <Flex width={1} flexDirection="row" alignItems="center">
                    <Text as="span" fontSize={3} mr="md">
                      $
                    </Text>
                    {isLoadingPrice || isLoadingQuestions ? (
                      <ContentLoader
                        height="54"
                        data-testid="device-estimated-price-loader"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="4"
                          ry="4"
                          width="60"
                          height="54"
                        />
                      </ContentLoader>
                    ) : (
                      <Text
                        as="span"
                        data-testid="device-estimated-price-value"
                        fontSize={10}
                        fontWeight={600}
                        color="black"
                      >
                        {prettifyPrice(
                          deviceAdjustedPrice || deviceEstimatedPrice,
                        )}
                      </Text>
                    )}
                  </Flex>
                  <Box width={1} mt="xs" pr={['md', 0]}>
                    <Divider thickness={2} color="black" />
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </RestrictContent>
      <ToolTipWithAnalytics
        open={isTooltipOpen}
        content={<TextBlock data={tooltipContent} blockVariant="light" />}
        hideBackdrop={toggleToolTip}
      />
    </Box>
  );
};

TradeInCalculator.propTypes = {
  header: PropTypes.shape({
    content: PropTypes.array,
    data: PropTypes.object,
  }).isRequired,
  description: PropTypes.shape({
    content: PropTypes.array,
    data: PropTypes.object,
  }),
  inputBounceTime: PropTypes.number,
  inputDeviceModelTitle: PropTypes.string.isRequired,
  inputDeviceModelSubtitle: PropTypes.string,
  inputDeviceModelPlaceholder: PropTypes.string,
  inputDeviceModelErrorMessage: PropTypes.string.isRequired,
  genericErrorMessage: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
  tooltipContent: PropTypes.shape({
    content: PropTypes.array,
    data: PropTypes.object,
  }),
  initialDeviceImage: PropTypes.shape({
    fields: PropTypes.object,
    metadata: PropTypes.object,
    sys: PropTypes.object,
  }).isRequired,
  deviceTradeInValueTitle: PropTypes.string.isRequired,
  deviceQuestionsHeader: PropTypes.string.isRequired,
  deviceQuestionsDescription: PropTypes.string,
};
export default compose(TradeInCalculator);
