import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';

import ContactUsDetailsColumn from '../contactUsDetailsColumn';

const defaultColumns = [];

const ContactUsDetails = ({ columns = defaultColumns }) => (
  <Box width={1}>
    <Flex
      flexDirection="row"
      flexWrap="wrap"
      pb={[0, 26]}
      pt={[13, 23]}
      bg="pureWhite"
    >
      {columns &&
        columns.map(({ fields: { title, sections } }) => (
          <Box key={title} width={[1, 4 / 12]}>
            <ContactUsDetailsColumn title={title} sections={sections} />
          </Box>
        ))}
    </Flex>
  </Box>
);

ContactUsDetails.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
};
export default ContactUsDetails;
